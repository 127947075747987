import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useStateContext } from '../../contexts/ContextProvider';
import Avatar5 from '../../data/Avatar5.png'
import { BsFillFilePdfFill, BsFileTextFill, BsFillPlayBtnFill, BsFileZipFill } from 'react-icons/bs'
import { DownloadIcon } from '../../app-constants'

const FILE_FORMATS = ['jpg', 'jpeg', 'gif', 'png', 'mp4', 'pdf', 'docx']

const FILES_BUILDS_FORMATS = ['apk', 'ipa', 'zip']

const TaskAttachments = ({ task }) => {
  const [selected, setSelected] = useState('btn1');
  const [attachments, setAttachments] = useState([])
  const { project } = useStateContext();

  useEffect(() => {
    setAttachments([]);
    if (task) {
      setAttachments(task.attachments)
    }
    else{
      setAttachments([])
    }
  }, [task, project])

  // console.log(attachments);

  const filterFiles = (attachments) => {
    if (selected === 'btn2') {
      return attachments.filter(item => {
        const fileExtension = item.path?.substring(item.path.lastIndexOf('.') + 1)?.toLowerCase();
        return FILES_BUILDS_FORMATS.includes(fileExtension) === true;
      });
    } else {
      return attachments.filter(item => {
        const fileExtension = item.path?.substring(item.path.lastIndexOf('.') + 1)?.toLowerCase();
        return FILES_BUILDS_FORMATS.includes(fileExtension) === false && FILE_FORMATS.includes(fileExtension) === true;
      });
    }
  }

  return (
    <div className='attached_files_boxs' >
      <h5>Attached Files</h5>
      <div className="attachments_box">
       {
          attachments?.length > 0 ? (
            attachments.map((item, index) => {
              const fileExtension = item.path?.substring(item.path.lastIndexOf('.') + 1)?.toLowerCase();
              const imageName = item.path.match(/[^/]+$/)[0];
              const date = new Date(item.created_at);
              const formattedDate = date.toLocaleDateString();
              let icon;
              if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'gif') {
                icon =
                  <img src={item.path}  alt={`${item.path}`} />
              } else if (fileExtension === 'pdf') {
                icon = <BsFillFilePdfFill size={35}  color='red' />
              } else if (fileExtension === 'mp4') {
                icon = <BsFillPlayBtnFill size={35} color='gray' />;
              } else if (fileExtension === 'zip') {
                icon = <BsFileZipFill size={35}  color='gray' />;
              } else {
                icon = <BsFileTextFill size={35} color='blue' />;
              }
              return (

                   <div className="attached_files" key={index}>
                                  <div className="icon_attached">
                                    {icon}
                                  </div>
                                  <div className="name_attached">
                                    {imageName && (imageName.length > 30 ? `${imageName.substring(0, 30)}...` : imageName)}
                                  </div>
                                  {/* <div className="date_attached">
                                    {formattedDate}
                                  </div> */}
                                  <div className="view_attached">
                                    <a href={item.path} download target='_blank'>
                                      {/* view */}
                                      {DownloadIcon}
                                    </a>
                                  </div>
                              </div>


              )
            })
          ) : <p className='text-center no-files'>No Files</p>
        }
    </div>
    </div>
  )
}

export default TaskAttachments;