import React, {useState, useEffect} from 'react'
import {useStateContext} from '../contexts/ContextProvider';
import {MdDelete} from 'react-icons/md';
import {RiEditBoxFill} from 'react-icons/ri';
import axios from 'axios';
import Swal from 'sweetalert2';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {useFormik} from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';
import {TextField, MenuItem, InputLabel, FormControl, Select} from '@material-ui/core';
import {Oval} from 'react-loader-spinner'
import DialogTitle from '@mui/material/DialogTitle';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import Slide from '@mui/material/Slide';
import {GoLinkExternal} from 'react-icons/go'
import link from '../data/link1.svg'
import {TimePicker} from "@mui/x-date-pickers/TimePicker";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const validationSchema = yup.object({
    agenda: yup
        .string()
        .min(6, 'Too Short!')
        .max(70, 'Too Long!')
        .required('Meeting Agenda is required'),
    time: yup
        .date().required('Meeting Time is required'),
    status: yup
        .string()
        .required('Meeting Status is required'),
});

const UpComingTable = ({meeting, loader}) => {
    const {user, client, table, getAllMeetings, sessionProject} = useStateContext();
    const {role} = user.user;
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [editValues, setEditValues] = useState({});

    useEffect(() => {
        setLoading(loader);
    }, [loader])

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteMeeting(id)
            }
        })
    }

    const handleClickOpen = (item) => {
        setEditValues(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formik = useFormik({
        initialValues: {
            agenda: editValues?.name,
            time: editValues?.date_time,
            client: editValues?.client?.first_name + " " + editValues?.client?.last_name,
            status: editValues?.status === 0 ? 'To be held' : 'Done',
            meeting_link: editValues?.meeting_link,
            recording_link: editValues?.recording_link,
            desc: editValues?.description,
        },
        validationSchema: validationSchema,
        onSubmit: (values, {resetForm}) => {
            handleClose();
            EditMeeting();
            resetForm({values: ''})
        },
    });

    const EditMeeting = async () => {
        setLoading(true);
        const data = {
            "name": formik.values.agenda,
            "date_time": new Date(formik.values.time)?.toUTCString(),
            "user_id": user.user.id,
            "project_type": editValues.project_type,
            "status": formik.values.status === 'Done' ? 1 : 0,
            "meeting_link": formik.values.meeting_link,
            "recording_link": formik.values.recording_link,
            "priority": 0,
        }

        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}meeting/${editValues.id}`, {...data, id: editValues.id}, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        getAllMeetings(sessionProject);
                        setLoading(false);
                        Swal.fire(
                            {
                                icon: 'success',
                                title: 'Meeting Updated successfully!',
                                showConfirmButton: false,
                                timer: 1000
                            }
                        )
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1000
                    })
                })
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };


    const deleteMeeting = async (id) => {
        setLoading(true)
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}meeting/${id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        getAllMeetings(sessionProject);
                        setLoading(false);
                        Swal.fire(
                            'Deleted!',
                            'Meeting has been deleted.',
                            'success'
                        )
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1000
                    })
                })
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }


    useEffect(() => {
        formik.setValues({
            agenda: editValues.name || '',
            date: dayjs(editValues.date_time) || '',
            time: dayjs(editValues.date_time) || '',
            client: editValues.client?.first_name + " " + editValues.client?.last_name || '',
            status: editValues?.status === 0 ? 'To be held' : 'Done',
            desc: editValues?.description || '',
            meeting_link: editValues?.meeting_link,
            recording_link: editValues?.recording_link,
        });
    }, [editValues]);


    /*if (loading) {
      return (
        <div className=' justify-center items-center flex h-[80vh]' >
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#F37A20"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>

      )
    }*/
    return (
        <div className=''>


                <table className="table_meeting_board">
                    <thead>
                    <tr>
                        <th>Project Name</th>
                        <th>Date & Time</th>
                        <th>Meeting Title</th>
                        <th>Meeting Link</th>
                        <th>Meeting Status</th>
                        <th>Recording</th>
                                          {role !== 'client' ?
                            <th>Actions</th>
                            : null}
                        </tr>
                    </thead>
                    <tbody className="">
                    {meeting?.length !== 0 ?
                        meeting?.map((item, index) => {
                            let project_name;
                            if (item?.manual_project) {
                                project_name = item.manual_project.name;
                            } else {
                                // console.log(item);
                                const selectedProject = table.find(asana => `${asana.gid}` === item.asana_project_id);
                                if (selectedProject) {
                                    project_name = selectedProject.name;
                                }

                            }

                            return (
                                <tr key={index}>
                                    <td>
                                        {project_name}
                                    </td>
                                    <td>
                                        {item.date_time}
                                    </td>
                                    <td>
                                        {item.name}
                                    </td>
                                    <td>
                                        {
                                            item?.meeting_link ?
                                                <a target="_blank" href={item.meeting_link}>
                                                    <img src={link}/>
                                                </a> :
                                                <p>N/A</p>
                                        }
                                    </td>
                                    <td className={`${item.status === 0 ? 'text-[#F37A20]' : 'text-[#7ACB4A]'}`}>
                                    {item.status === 0 ? 'Pending' : 'Done'}
                                    </td>
                                    <td>
                                        {
                                            item?.recording_link ?
                                            <a target="_blank" href={item?.recording_link}>
                                                <img src={link}/>
                                            </a> :
                                            <p>N/A</p>
                                        }
                                    </td>

                                    {role !== 'client' ?
                                        <td>
                                            <div className="btn_flex">
                                                <MdDelete className='cursor-pointer'
                                                          onClick={() => handleDelete(item.id)}
                                                          color="red"
                                                          size={28}/>
                                                <RiEditBoxFill onClick={() => handleClickOpen(item)}
                                                               color="orange" size={28}/>
                                            </div>
                                        </td>
                                        : null
                                    }
                                </tr>
                            )
                        })
                        :
                        <p className='text-center text-[13px] text-[#979797] font-bold	'>No
                            Meetings</p>
                    }
                    </tbody>

                </table>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Update a Meeting"}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={formik.handleSubmit} className='space-y-6 '>
                            <TextField
                                className='w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%] '
                                id="agenda"
                                name="agenda"
                                label="Meeting Agenda"
                                value={formik.values.agenda}
                                onChange={formik.handleChange}
                                error={formik.touched.agenda && Boolean(formik.errors.agenda)}
                                helperText={formik.touched.agenda && formik.errors.agenda}
                                InputLabelProps={{shrink: true}}
                            />
                            <div
                                className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <FormControl className="custom-input" fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={["DateTimePicker"]}>
                                            <DatePicker
                                                name="date"
                                                id="date"
                                                inputFormat="E MMM dd yyyy "
                                                label="Meeting Date"
                                                value={formik.values.date}
                                                onChange={(value) => formik.setFieldValue("date", value)}
                                                // error={formik.touched.date && Boolean(formik.errors.date)}
                                                // disablePast
                                            />
                                            <TimePicker
                                                fullWidth
                                                className="w-[100%]"
                                                name="time"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                id="time"
                                                inputFormat="E MMM dd yyyy HH:MM:SS O"
                                                label="Meeting Time"
                                                value={formik.values.time}
                                                onChange={(value) => formik.setFieldValue("time", value)}
                                                error={formik.touched.time && Boolean(formik.errors.time)}
                                                // disablePast
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </FormControl>
                            </div>
                            <div
                                className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Meeting Status</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="status"
                                        label="Meeting Status"
                                        id="status"
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                        error={formik.touched.status && Boolean(formik.errors.status)}
                                        helperText={formik.touched.status && formik.errors.status}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem value="To be held">To Be Held</MenuItem>
                                        <MenuItem value="Done">Done</MenuItem>
                                    </Select>
                                </FormControl>

                            </div>
                            <div
                                className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>

                                <TextField
                                    className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                    id="meeting_link"
                                    name="meeting_link"
                                    label="Meeting Link"
                                    value={formik.values.meeting_link}
                                    onChange={formik.handleChange}
                                    error={formik.touched.meeting_link && Boolean(formik.errors.meeting_link)}
                                    helperText={formik.touched.meeting_link && formik.errors.meeting_link}
                                    InputLabelProps={{shrink: true}}
                                />
                            </div>
                            <div
                                className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>

                                <TextField
                                    className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                    id="recording_link"
                                    name="recording_link"
                                    label="Recording Link"
                                    value={formik.values.recording_link}
                                    onChange={formik.handleChange}
                                    error={formik.touched.recording_link && Boolean(formik.errors.recording_link)}
                                    helperText={formik.touched.recording_link && formik.errors.recording_link}
                                    InputLabelProps={{shrink: true}}
                                />
                            </div>

                            <div className='flex justify-end mt-3'>
                                <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white'
                                        variant="contained" fullWidth type="submit">
                                    Save
                                </button>
                            </div>
                        </form>
                    </DialogContent>

                </Dialog>
            </div>

    )
}


export default UpComingTable;
