import React, { useEffect, useState } from "react";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

function ActivityCard({ id, claimed, status, title, points, setPointId, handleClickOpen, isClaimed,item }) {

    const [tooltipVisible, setTooltipVisible] = useState(false);

    const handleMouseOver = () => {

        setTooltipVisible(true);
        setTooltipVisible(true);
    };

    const handleMouseOut = () => {
        setTooltipVisible(false);
    };


    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow  classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: '#272b3e',
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#272b3e',
            color: 'rgb(255,255,255)',
            // maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #272b3e',
        },
    }));

    return (
        <div>
            <HtmlTooltip title={claimed ? status == 0 ? 'This reward is in review' :  <React.Fragment>
                <Typography color="inherit">{item?.points} Points Claimed</Typography>
                <p className='mt-2'>{`Date of submission: `} {item?.submission}</p>
                <p className='mt-2'>{`Date of claim: `} {item?.claim}</p>
           </React.Fragment> : 'Claim'}>
            <div className={claimed ? "rewards_slider disable" : "rewards_slider"}
                //  onMouseOver={handleMouseOver}
                // onMouseOut={handleMouseOut}
            >
                <h4>{title}</h4>
                <div className="rewards_slider_img">
                    <h6>{points}</h6>
                </div>


                <div className="rewards_slider_btn" onClick={() => {

                    if(isClaimed) {
                        setPointId(id);
                        handleClickOpen();
                    }
                }}>

                    {claimed && status == 0 ? 'In Review' : '' }
                    {claimed && status == 1 ? 'Claimed' : '' }
                    {claimed && status == 2 ? 'Rejected' : '' }
                    {!claimed ? 'Claim' : '' }
                </div>

                {/*<Tooltip text={claimed ? status == 0 ? 'This Reward is in Review' : 'This Reward has been Claimed' : 'Claim'} visible={tooltipVisible}  />*/}
            </div>
            </HtmlTooltip>
        </div>
    )
}

export default ActivityCard
