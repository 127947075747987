import React, { useState, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import { CheckIcon, PlusIcon } from "../../app-constants";
import Form from 'react-bootstrap/Form';
import DummyImg1 from "../../img/img1.jpeg";
import DummyImg2 from "../../img/img2.jpeg";
import DummyImg3 from "../../img/img3.jpeg";
import DummyImg4 from "../../img/img4.jpeg";
import KeyResources from "./KeyResources";
import { useStateContext } from "../../contexts/ContextProvider";
import axios from "axios";
import Swal from 'sweetalert2'
import axiosInstance from '../../axios/axiosInstance';
import { Loader, Toast } from '../../components/General';
import { getFirstLetters, getRandomColor } from '../../utils/Helper'
import { MilestoneAddIcon } from "../../utils/svg";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {useFormik} from "formik";
import * as yup from "yup";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {Oval} from "react-loader-spinner";
import Slide from "@mui/material/Slide";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function ProjectDesc() {

    const { user, selectedProject, sessionProjectDetail, getSessionProjectDetails} = useStateContext();
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const [open, setOpen] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [milestones, setMilestones] = useState([]);

    const getMilestones = async (loader = false) => {
        setLoading(loader);

        if(selectedProject){

            await axiosInstance.get(`get-milestone/${selectedProject}`)
            .then((res) => {
    
                setMilestones(res?.data);
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: 'Something went wrong!',
                })
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }


    const getOverview = async () => {

        if (user) {
            setLoading(true);

            await axiosInstance.get(`asana-project-overview/${selectedProject}`)
                .then((res) => {

                    setData(res?.data);
                })
                .catch(err => {
                    if (err?.response?.status == 401) {
                        localStorage.removeItem('tekrevol_user');
                        window.location.href = '/signin';
                    } else {
                        console.log(err);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        getOverview();
        getMilestones(true);
        getSessionProjectDetails();
    }, [selectedProject]);

    const handleCheckboxChange = async (event) => {

        Toast.fire({
            icon: "info",
            title: "Processing..."
        });

        let value = event.target.checked;
        let milestoneId = event.target.value;

        let payload = {
            "completed": value
        };

        await axios.put(`${process.env.REACT_APP_BASE_URL}update-milestone/${milestoneId}`, payload, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    Toast.fire({
                        icon: "success",
                        title: "Milestone Updated Successfully"
                    });
                }
            })
            .catch((error) => {
                Toast.fire({
                    icon: "error",
                    title: 'Something went wrong!',
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleAddMilestone = () => {
        setOpen(true);
    }
  
    const handleCloseMiletstoneModal = () => {
        setOpen(false);

        formik.resetForm();
    }

    const validationSchema = yup.object({
        title: yup.string().required('Milestone is required'),
    });

    const formik = useFormik({
        initialValues: {
            title: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {

            if (values) {
                addMilestone(values, resetForm)
            }
        },
    });

    const addMilestone = async (values, resetForm) => {
        setBtnLoading(true);

        let payload = {
            projectId: sessionProjectDetail.asana_project_id,
            taskName: values.title
        }

        await axios.post(`${process.env.REACT_APP_BASE_URL}create-milestone`, payload, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((res) => {
            if (res.status === 200) {

                getMilestones();

                Toast.fire({
                    icon: 'success',
                    title: 'Milestone Added successfully!',
                 
                })
            }
        })
        .catch((error) => {
            Toast.fire({
                icon: 'error',
                title: error?.response?.data?.errors[0]?.message || 'Something went wrong!',
            })
        })
        .finally(() => {
            handleCloseMiletstoneModal()
            setBtnLoading(false);
            resetForm({ values: '' })
        });
    }


    return (
        <>
            <div className="main_style_box project_main">
                <div className="project_title">Project Description

                </div>
                <div className="project_txt">
                    {loading ? <Loader /> : data?.description}
                </div>
            </div>
            <div className="main_style_box  project_role">
                <div className="project_title">Project roles</div>
                <div className="project_txt">
                    <Row className="role_section">
                        {
                            loading ? <Loader /> :
                                data?.members?.map((item, index) => {
                                    return (

                                        <Col key={index} xxl={2} xl={3} lg={4} className="role_item">
                                            <div className="role_img">
                                                {item?.photo?.image_60x60 ?
                                                    <img src={item?.photo?.image_60x60} alt="Image_alt" /> :
                                                    <div className="img_placeholder" style={{ backgroundColor: getRandomColor() }}>
                                                        {getFirstLetters(item.name || 'User')}
                                                    </div>
                                                }

                                            </div>
                                            <div className="role_info">
                                                <div className="role_name">{item.name}</div>
                                                <div className="role_desg"></div>
                                            </div>
                                        </Col>
                                    )
                                })
                        }
                    </Row>
                </div>
            </div>
            <div className="main_style_box project_key">
                <div className="project_title">Key resources</div>
                <div className="project_txt">
                    <KeyResources />
                </div>
            </div>

            <div className="main_style_box project_milestone">
                <div className="project_title d-flex align-items-start">
                    <span className='mr-2'>
                        Milestones
                    </span>

                    <button type='button' onClick={handleAddMilestone} >
                        <MilestoneAddIcon />
                    </button>
                    
                </div>

                <div className="project_txt">
                    {
                        loading ? <Loader /> :
                            milestones?.map((item, index) => {
                                return (

                                    <div key={index} className={isChecked ? "pro_milestone_list isChecked" : "pro_milestone_list"}>
                                        <div className="pro_milestone_check">
                                            <Form.Check
                                                className="check_input"
                                                value={item?.gid}
                                                type="checkbox"
                                                defaultChecked={item?.completed}
                                                onChange={handleCheckboxChange} // Handle changes
                                            />
                                        </div>
                                        <div className="pro_milestone_name">{item?.name}</div>
                                    </div>
                                )
                            })
                    }
                </div>
            </div>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseMiletstoneModal}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Create a Milestone"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit} className='space-y-6 '>
                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <TextField
                                className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                id="title"
                                name="title"
                                label="Milestone"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                helperText={formik.touched.title && formik.errors.title}
                            />
                        </div>
                        

                            {/* <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Assignee</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="assignee"
                                        label="Assignee"
                                        id="assignee"
                                        value={formik.values.assignee}
                                        onChange={formik.handleChange}
                                        error={formik.touched.assignee && Boolean(formik.errors.assignee)}
                                        helperText={formik.touched.assignee && formik.errors.assignee}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        {allAssignee?.filter((element) => element.name !== 'Private User').map((item, index) => {
                                            // console.log(item);
                                            return (

                                                <MenuItem key={index}
                                                        value={`${item.gid}`}>{item.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div> */}


                        <div className='flex justify-end mt-3'>
                            <button
                                className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-2 text-white'
                                variant="contained" fullWidth type="submit" disabled={btnLoading}>
                                {btnLoading ?  <Oval
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#ffffff"
                                    secondaryColor="#ffffff"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{ display: 'flex', justifyContent: 'center' }}
                                    wrapperClass=""
                                /> : "Create" }
                            </button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default ProjectDesc;