import React, {useEffect, useState} from "react";
import {useStateContext} from "../contexts/ContextProvider";
import {Navigate} from "react-router-dom";
import axios from "axios";
import {Oval} from 'react-loader-spinner'
import Avatar from '../data/backCover.png'
import {TiPlus} from 'react-icons/ti';
import {FaCalendar} from 'react-icons/fa';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {
    TextField,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel
} from '@material-ui/core';
import Slide from '@mui/material/Slide';
import Swal from 'sweetalert2';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    description: yup
        .string()
        .min(6, 'Too Short!')
        .max(255, 'Too Long!')
        .required('Description is required'),
});


const UserCard = ({user, role}) => {
    return (
        <div className="appboard-grid-role-item">
            <div className="role-img">
                <img src={user.photo?.image_60x60 || Avatar} alt={user.name} className="w-10 h-10 rounded-full mr-4"/>
          </div>
            <div className="role-name">
                    <div className="txt-name">{user.name}</div>
                    <div className="txt-role">{role}</div>
                </div>
        </div>
    );
};

const UserRow = ({ user }) => {
  return (
    <div className="appboard-grid-milestones-item">
      <div className="milestones-box1">
        <input type="checkbox" className="mr-2" />
        <span>{user.name}</span>
      </div>
      <div className="milestones-box2">
          <div className="milestones-item">
              <FaCalendar className="text-gray-500" />
          </div>
          <div className="milestones-item">
        <img
          src={user.image || Avatar}
          alt={user.name}
          className="rounded-full"
        />
          </div>

      </div>
    </div>
  );
};


const Overview = () => {
    const {user, table, project, sessionProject, getSessionProjectDetails, sessionProjectDetail} = useStateContext();
    const {role} = user.user;
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [open, setOpen] = React.useState(false);
    useEffect(() => {
        setLoading(true);
        getSessionProjectDetails()
        getOverview()
        setTimeout(() => {
            setLoading(false);
        }, [3000])
    }, [project]);

    const formik = useFormik({
        initialValues: {
            description: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, {resetForm}) => {
            handleClose()
            updateDescription(values)
            resetForm({values: ''})
        },
    });

    const updateDescription = async (values) => {
        setLoading(true)
        try {
            let payload = {
                'notes': values.description,
                'project_id': sessionProject,
            }
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}update-project-notes`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`
                    }
                }
            );
            if (response.status === 200) {
                if (response?.data?.error) {
                    Swal.fire({
                        icon: 'success',
                        title: response?.data?.error[0]?.message || 'Asana Error',
                        showConfirmButton: false,
                        timer: 1000
                    });

                } else {
                    Swal.fire({
                        icon: 'success',
                        title: 'Notes added successfully!',
                        showConfirmButton: false,
                        timer: 1000
                    });
                }
                handleClose();
                resetForm();
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                showConfirmButton: false,
                timer: 1000
            });
        }
    }

    const getOverview = () => {
        setLoading(true);
        if (user) {
            let url = `${process.env.REACT_APP_BASE_URL}asana-project-overview/${sessionProject}`;
            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    console.log('==========', res?.data?.data);
                    setData(res?.data?.data)
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    if (err.response.status == 401) {
                        localStorage.removeItem('tekrevol_user');
                        window.location.href = '/signin';
                    } else {
                        console.log(err);
                    }
                })
        }
        setLoading(false);
    }

    const addMember = () => {
        alert('Add a new member is in process');
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (

        <div className="">
            {
                loading ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }} className='mt-5'>
                        <Oval
                            visible={true}
                            height="20"
                            width="20"
                            color="#f37a20"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                        <small>Please wait while the content is loading</small>
                    </div> :
                    <div className="appboard-grid">
                        <div className={`appboard-sub-grid pt-4`}>

                            <div className="main-top-banner">
                                <div className="main-title">
                                    <h1>Overview<span> ({sessionProjectDetail?.name})</span></h1>
                                </div>
                                <div className="main-btn-txt"></div>
                            </div>
                            <div className="bg-main-bg">
                                    <div className="appboard-overview-project-main">
                                            <div className="appboard-grid-project-title">Project Description
                                                <div className="appboard-grid-project-plus">
                                                    <TiPlus onClick={handleClickOpen}
                                                            className='text-[20px] lg:text-[18px] md:text-[16px]'/>
                                                </div>
                                            </div>
                                        <div className="appboard-grid-project-txt">{data?.description || 'No Description Added'}</div>
                                    </div>


                                <div
                                    className="appboard-overview-project-main">
                                           <div className="appboard-grid-project-title">Project Roles</div>

                                            <div className="appboard-grid-project-role">


                                                {
                                                    data?.owner && <UserCard user={data?.owner} role='Owner'/>
                                                }

                                                {data?.members?.map((user, index) => (
                                                    <UserCard key={index} user={user} role='User'/>
                                                ))}
                                            </div>

                                </div>

                                <div
                                    className="appboard-overview-project-main">

                                        <div className="appboard-grid-project-title">Milestones</div>

                                        <div className="appboard-grid-project-milestones">
                                            {data?.milestones?.map((user, index) => (
                                                <UserRow key={index} user={user}/>
                                            ))}
                                        </div>

                                </div>

                            </div>
                        </div>
                    </div>
            }

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Update Project Description"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit} className='space-y-6 '>
                        <TextField
                            className='w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%] '
                            id="description"
                            name="description"
                            label="Project Description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                        />
                        <div className='flex justify-end mt-3'>
                            <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white'
                                    variant="contained" fullWidth type="submit">
                                Save
                            </button>
                        </div>
                    </form>
                </DialogContent>

            </Dialog>
        </div>
    );
};

export default Overview;
