import React, { useState } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { intToString } from '../../utils/PriceToString';
import { useEffect } from 'react';
import axios from "axios";
import projectImg1 from "../../img/projectImg1.png"
import projectImg2 from "../../img/projectImg2.png"
import projectImg3 from "../../img/projectImg3.png"
import projectImg4 from "../../img/projectImg4.png"
import {convertToRequiredDateFormat} from '../../utils/Helper'
import {formatMDY, formatDrawerDate} from "../../utils/Helper";

const ProjectBox = (props) => {

    const { user, table, invoices, selectedProject, manualProjects, project } = useStateContext();
    const [projectList, SetProjectList] = useState(false);
    const [loading, setLoading] = useState(false);

    // const[day,setDay] = useState(null);
    // const[month,setMonth] = useState(null);
    /*const [data, setData] = useState([]);

    useEffect(() => {
        setData(props.dashboardData)
    }, [project])*/

    let day, month;
    let payDate = new Date(invoices[0]?.due_date);
    if ((payDate.getMonth() + 1) < 10) {
        month = '0' + (payDate.getMonth() + 1);
    } else {
        month = (payDate.getMonth() + 1);
    }
    if (payDate.getDate() < 10) {
        day = '0' + payDate.getDate();
    } else {
        day = payDate.getDate()
    }


    useEffect(() => {
        getProjects();
    }, [selectedProject])


    const getProjects = async () => {

        if (selectedProject) {
            setLoading(true);

            await axios.get(`${process.env.REACT_APP_BASE_URL}manual-project/${selectedProject}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {
                SetProjectList(response.data.data);
            }).catch((err) => {
                console.log('err====', err);
            })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const formatDate = (inputDateString) => {

        // Create a Date object from the input string
        const inputDate = new Date(inputDateString);

        // Convert the date to the desired format
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = inputDate.toLocaleDateString('en-US', options);

        // Add suffix to the day
        const day = inputDate.getDate();
        const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
                    (day % 10 === 2 && day !== 12) ? 'nd' :
                    (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

        // Construct the final formatted string
        const finalFormattedDate = `${inputDate.toLocaleString('default', { month: 'long' })} ${day}${suffix}, ${inputDate.getFullYear()}`;

        return finalFormattedDate;
    }

    


    return (
        <>
        <div className="project-box">
            <div className="project-inner">
                <img src={projectImg1} alt="Total Task" />
                <div className="project-wrap">
                    <span>Total Tasks</span>
                    <a href="/list-view">{props.totalTaskCount}</a>
                </div>
            </div>
            <div className="project-inner">
                <img src={projectImg2} alt="Total Task" />
                <div className="project-wrap">
                    <span>Next Invoice Date</span>
                    <>
                        
                        {/* <a href="/invoice-view">{props?.UnpaidInvoice?.due_date ? new Date(props.UnpaidInvoice.due_date).toLocaleDateString('en-US', { month: 'numeric', day: '2-digit', year: 'numeric' }) : 'N/A'}</a> */}
                        <a href="/invoice-view-upcoming">{ props?.UnpaidInvoice?.due_dates ? formatDate(props?.UnpaidInvoice?.due_dates) : '-'}</a>
                    </>
                    {/* <><a href="/invoice-view">{props?.UnpaidInvoice?.due_date ? convertToRequiredDateFormat(props.UnpaidInvoice.due_date) : 'N/A'}</a></> */}
                </div>
            </div>
            {/* <div className="project-inner">
                <img src={projectImg3} alt="Next Invoice Amount" />
                <div className="project-wrap">
                    <span>Next Invoice Amount</span>
                    <><a href="/invoice-view">${props?.UnpaidInvoice?.amount ? intToString(props?.UnpaidInvoice?.amount) : '0'}</a></>
                </div>
            </div> */}
            <div className="project-inner">
                <img src={projectImg4} alt="Completed / Total task" />
                <div className="project-wrap">
                    <span>Completed / Total task</span>
                    <><a href="/list-view">{props?.completedTaskCount} / {props?.totalTaskCount}</a></>
                </div>
            </div>
        </div>
       


        {/* <div className={`grid grid-cols-2 gap-[15px]`}>
            <div className='shadow pt-[18px] pb-[13px]    justify-center items-center '>
                <p className='text-center justify-center   text-[#505887] font-medium text-[15px] lg:text-[15px]  md:text-[11px]  sm:text-[9px] xm:text-[8px]'>Total <br /> Tasks
                </p>
                <p className='text-center text-[#505887] mb-[0px]  font-bold text-[40px] lg:text-[30px] md:text-[25px]  sm:text-[20px] xm:text-[18px] '>{props.totalTaskCount}</p>
            </div>
            <div className='shadow pt-[18px] pb-[13px]  justify-center items-center '>
                <p className='text-center justify-center   text-[#505887] font-medium text-[15px] lg:text-[15px]  sm:text-[9px] xm:text-[8px]  md:text-[11px]'>
                    Next Invoice <br /> Date</p>
                <p className='text-center text-[#505887]  font-bold text-[40px] lg:text-[30px]  md:text-[25px]  sm:text-[20px] xm:text-[18px]  '>{props?.UnpaidInvoice?.due_date ? new Date(props.UnpaidInvoice.due_date).toLocaleDateString('en-US', { month: 'numeric', day: '2-digit', year: 'numeric' }) : 'N/A'}

                </p>
            </div>
            <div className='shadow pt-[18px] pb-[13px]    justify-center items-center '>
                <p className='text-center justify-center   text-[#505887] font-medium text-[15px] lg:text-[15px] md:text-[11px]  sm:text-[9px] xm:text-[8px]'>
                    Next Invoice <br /> Amount</p>
                <p className='text-center text-[#505887]  font-bold text-[40px] lg:text-[30px] md:text-[25px]   sm:text-[20px] xm:text-[18px] '>${props?.UnpaidInvoice?.amount ? intToString(props?.UnpaidInvoice?.amount) : '0'}</p>
            </div>
            <div className='shadow pt-[18px] pb-[13px]   justify-center items-center '>
                <p className='text-center justify-center   text-[#505887] font-medium text-[15px] lg:text-[15px] md:text-[11px]  sm:text-[9px] xm:text-[8px]'>
                    Completed / <br />Total task</p>
                <p className='text-center text-[#505887]  font-bold text-[40px] lg:text-[30px] md:text-[25px]   sm:text-[20px] xm:text-[18px] '>
                    {props?.completedTaskCount} / {props?.totalTaskCount}

                   
                            
                            </p>
            </div>

        </div> */}
        </>
    )
}

export default ProjectBox;