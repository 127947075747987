import React, {useEffect, useState} from 'react';
import clutch from '../data/clutch.png'

import {useStateContext} from '../contexts/ContextProvider';
import axios from 'axios'
import {Navigation} from "swiper";
import {Swiper, SwiperSlide, useSwiper} from "swiper/react";
import diamond from '../data/diamond.svg'
import diamond1 from '../data/diamond1.svg'
import Swal from 'sweetalert2';
import {BsInfoCircle, BsInfoCircleFill} from 'react-icons/bs'
import {TooltipComponent} from '@syncfusion/ej2-react-popups';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {useFormik} from "formik";
import {Formik, Form, Field} from 'formik';
import * as yup from "yup";
import Slide from "@mui/material/Slide";
import {TextField, MenuItem, Select, FormControl, InputLabel} from '@material-ui/core';
import {S3Upload} from '../utils/S3Upload'

window.Buffer = window.Buffer || require("buffer").Buffer;
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const TotalPoints = () => {
    const {points, user, setPoints, sessionProject} = useStateContext();
    const swiper = useSwiper();

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [pointId, setPointId] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [values, setValues] = useState({screenshot: null, link: ''});
    const [errors, setErrors] = useState({screenshot: '', link: ''});
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const validationSchema = yup.object({
        screenshot: yup.mixed().required('Screenshot is required'),
        link: yup.string().optional(),
    });

    /*const formik = useFormik({
        initialValues: {screenshot: null, link: ''},
        validationSchema: validationSchema,
        onSubmit: async (values, {resetForm}) => {
            //handleClose();
            handleUpload(selectedFile, values);
            resetForm();
        },
    });*/

    const createClaim = async (formData) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}activity-reward-claim`, formData, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                getAllPoints();
                Swal.fire({
                    icon: 'success',
                    title: 'Successfully claimed!',
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: error?.response?.data?.message || 'Something went wrong!',
                showConfirmButton: false,
                timer: 2000,
            });
        }
    };

    const getAllPoints = () => {
        setLoading(true);
        if (user) {
            axios
                .get(`${process.env.REACT_APP_BASE_URL}activity-reward-list?project_id=${sessionProject}&client_id=${user?.user?.id}`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                })
                .then((response) => {
                    setLoading(false);
                    setPoints(response.data.data);
                })
                .catch((error) => {
                    setLoading(false);
                    Swal.fire({
                        icon: 'error',
                        title: error?.response?.data?.message || 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 2000,
                    });
                });
        }
    };

    useEffect(() => {
        getAllPoints();
    }, []);

    /*const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    };*/

    const handleUpload = async () => {
        if (values.screenshot) {
            let file = values.screenshot
            setLoading(true);
            try {
                const response = await S3Upload(file.type, file.name, file);
                if (response) {
                    const formData = new FormData();
                    formData.append('file_path', response);
                    formData.append('link', values.link);
                    formData.append('activity_reward_id', pointId);
                    formData.append('project_id', sessionProject);
                    await createClaim(formData);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
                // Handle error state or display error message to the user
            } finally {
                setLoading(false); // Always set loading state to false whether success or failure
            }
        }
    };

    const handleChange = (event) => {
        const {name, value, files} = event.target;
        if (name === 'screenshot') {
            setValues({...values, screenshot: files[0]});
        } else {
            setValues({...values, [name]: value});
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        validationSchema
            .validate(values, {abortEarly: false})
            .then(() => {
                // Validation successful, handle form submission
                handleUpload(selectedFile);
                handleClose();
            })
            .catch((validationErrors) => {
                // Validation failed, update errors state
                const newErrors = {};
                validationErrors.inner.forEach((error) => {
                    newErrors[error.path] = error.message;
                });
                setErrors(newErrors);
            });
    };

    return (
        <div
            className="bg-[#0F1328] min-h-[226px] md:min-h-[200px] sm:min-h-[170px] xm:min-h-[150px] relative rounded-t-[15px] p-[20px] md:p-[17px] sm:p-[17px] xm:p-[15px]">
            <span className="text-[#F37A20] absolute top-[-5px] left-[-5px] bg-[#D9D9D9] rounded-full p-[2px]">
                <TooltipComponent title="   Perform these activities to earn points and unlock exciting rewards"
                                  position="BottomCenter">
                    <BsInfoCircleFill color="#F37A20" size={20}/>
                </TooltipComponent>
            </span>
            <Swiper
                slidesPerView={7}
                spaceBetween={20}
                breakpoints={{
                    1801: {slidesPerView: 7},
                    1600: {slidesPerView: 5},
                    1400: {slidesPerView: 5},
                    1200: {slidesPerView: 4},
                }}
                navigation={{
                    nextEl: '.review-swiper-button-next',
                    prevEl: '.review-swiper-button-prev',
                }}
                modules={[Navigation]}
                className="mySwiper"
            >
                {points && points.length > 0 ? (
                    points.map((item, index) => (
                        <SwiperSlide key={index}>
                            {item?.claimed ? (
                                item?.status ? (
                                    <div
                                        className="white_style border-1 w-[125px] h-[186px] md:h-[165px] sm:h-[145px] xm:h-[130px] space-y-[10px] xm:space-y-[8px] rounded-[10px] p-[19px] md:p-[15px] sm:p-[10px] xm:p-[7px] items-center border-[#EEEEEE] border-dashed">

                                        <div className="tooltip-container">
                                            <span className="info-icon">
                                                <BsInfoCircleFill color="#F37A20" size={20}/>
                                            </span>
                                            <div className="tooltip-content">
                                                <p>{item?.points} Points Claimed</p>
                                                <p className='mt-2'>Date of submission: <br/>{item?.submission}</p>
                                                <p className='mt-2'>Date of claim: <br/>{item?.claim}</p>
                                            </div>
                                        </div>

                                        <p className={`text-[13px] md:text-[11px] sm:text-[9px] xm:text-[8px] h-[39px] md:h-[30px] sm:h-[25px] xm:h-[25px] text-[#FFFFFF] break-words text-center `}>{item.title.length > 15 ? item.title.slice(0, 15) + "..." : item.title}</p>
                                        <div className="flex justify-center m items-center">
                                            <img src={diamond} alt="diamond"/>
                                        </div>
                                        <p className={`text-[14px] md:text-[12px]  sm:text-[10px] xm:text-[8px] text-[#FFFFFF] text-center `}>{item.points}</p>
                                        <div className="flex justify-center items-center">
                                            <p className="rounded-[10px] font-medium text-[#0F1328] text-[14px] md:text-[12px] sm:text-[10px] xm:text-[8px] md:w-[60px] md:h-[22px] sm:w-[50px] sm:h-[20px] xm:w-[50px] xm:h-[20px] w-[69px] h-[31px] flex justify-center items-center  "> Claimed </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="disable_style border-1 w-[125px] h-[186px] md:h-[165px] sm:h-[145px] xm:h-[130px] space-y-[10px] xm:space-y-[8px] rounded-[10px] p-[19px] md:p-[15px] sm:p-[10px] xm:p-[7px] items-center border-[#EEEEEE] border-dashed">
                                        <p className={`text-[13px] md:text-[11px] sm:text-[9px] xm:text-[8px] h-[39px] md:h-[30px] sm:h-[25px] xm:h-[25px] text-[#FFFFFF] break-words text-center `}>{item.title.length > 15 ? item.title.slice(0, 15) + "..." : item.title}</p>
                                        <div className="flex justify-center m items-center">
                                            <img src={diamond} alt="diamond"/>
                                        </div>
                                        <p className={`text-[14px] md:text-[12px]  sm:text-[10px] xm:text-[8px] text-[#FFFFFF] text-center `}>{item.points}</p>
                                        <div className="flex justify-center items-center">
                                            <p className="bg-[#D9D9D9] rounded-[10px] font-medium text-[#0F1328] text-[14px] md:text-[12px] sm:text-[10px] xm:text-[8px] md:w-[60px] md:h-[22px] sm:w-[50px] sm:h-[20px] xm:w-[50px] xm:h-[20px] w-[69px] h-[31px] flex justify-center items-center  "> In
                                                Review </p>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div
                                    className="orange_style border-1 w-[125px] h-[186px] md:h-[165px] sm:h-[145px] xm:h-[130px] space-y-[10px] xm:space-y-[8px] rounded-[10px] p-[19px] md:p-[15px] sm:p-[10px] xm:p-[7px] items-center border-[#EEEEEE] border-dashed">
                                    <p className={`text-[13px] md:text-[11px] sm:text-[9px] xm:text-[8px] h-[39px] md:h-[30px] sm:h-[25px] xm:h-[25px] text-[#FFFFFF] break-words text-center `}>{item.title.length > 15 ? item.title.slice(0, 15) + "..." : item.title}</p>
                                    <div className="flex justify-center m items-center">
                                        <img src={diamond} alt="diamond"/>
                                    </div>
                                    <p className={`text-[14px] md:text-[12px]  sm:text-[10px] xm:text-[8px] text-[#FFFFFF] text-center `}>{item.points}</p>
                                    <div className="flex justify-center items-center">
                                        <button onClick={() => {
                                            setPointId(item?.id);
                                            handleClickOpen()
                                        }}
                                                className="bg-[#D9D9D9] rounded-[10px] font-medium text-[#0F1328] text-[14px] md:text-[12px] sm:text-[10px] xm:text-[8px] md:w-[60px] md:h-[22px] sm:w-[50px] sm:h-[20px] xm:w-[50px] xm:h-[20px] w-[69px] h-[31px] flex justify-center items-center  ">Claim
                                        </button>
                                    </div>
                                </div>
                            )}
                        </SwiperSlide>

                        /*<SwiperSlide key={index}>
                            {item?.claimed && !item?.status && (
                                <div
                                    className="disable_style border-1 w-[125px] h-[186px] md:h-[165px] sm:h-[145px] xm:h-[130px] space-y-[10px] xm:space-y-[8px] rounded-[10px] p-[19px] md:p-[15px] sm:p-[10px] xm:p-[7px] items-center border-[#EEEEEE] border-dashed">
                                    <p className={`text-[13px] md:text-[11px] sm:text-[9px] xm:text-[8px] h-[39px] md:h-[30px] sm:h-[25px] xm:h-[25px] text-[#FFFFFF] break-words text-center `}>{item.title.length > 15 ? item.title.slice(0, 15) + "..." : item.title}</p>
                                    <div className="flex justify-center m items-center">
                                        <img src={diamond} alt="diamond"/>
                                    </div>
                                    <p className={`text-[14px] md:text-[12px]  sm:text-[10px] xm:text-[8px] text-[#FFFFFF] text-center `}>{item.points}</p>
                                    <div className="flex justify-center items-center">
                                        <p className="bg-[#D9D9D9] rounded-[10px] font-medium text-[#0F1328] text-[14px] md:text-[12px] sm:text-[10px] xm:text-[8px] md:w-[60px] md:h-[22px] sm:w-[50px] sm:h-[20px] xm:w-[50px] xm:h-[20px] w-[69px] h-[31px] flex justify-center items-center  "> In
                                            Review </p>
                                    </div>
                                </div>
                            )}
                            {item?.claimed && item?.status && (
                                <div
                                    className="white_style border-1 w-[125px] h-[186px] md:h-[165px] sm:h-[145px] xm:h-[130px] space-y-[10px] xm:space-y-[8px] rounded-[10px] p-[19px] md:p-[15px] sm:p-[10px] xm:p-[7px] items-center border-[#EEEEEE] border-dashed">
                                    <p className={`text-[13px] md:text-[11px] sm:text-[9px] xm:text-[8px] h-[39px] md:h-[30px] sm:h-[25px] xm:h-[25px] text-[#FFFFFF] break-words text-center `}>{item.title.length > 15 ? item.title.slice(0, 15) + "..." : item.title}</p>
                                    <div className="flex justify-center m items-center">
                                        <img src={diamond} alt="diamond"/>
                                    </div>
                                    <p className={`text-[14px] md:text-[12px]  sm:text-[10px] xm:text-[8px] text-[#FFFFFF] text-center `}>{item.points}</p>
                                    <div className="flex justify-center items-center">
                                        <p className="rounded-[10px] font-medium text-[#0F1328] text-[14px] md:text-[12px] sm:text-[10px] xm:text-[8px] md:w-[60px] md:h-[22px] sm:w-[50px] sm:h-[20px] xm:w-[50px] xm:h-[20px] w-[69px] h-[31px] flex justify-center items-center  "> Claimed </p>
                                    </div>
                                </div>
                            )}
                            {!item.claimed && (
                                <div
                                    className="orange_style border-1 w-[125px] h-[186px] md:h-[165px] sm:h-[145px] xm:h-[130px] space-y-[10px] xm:space-y-[8px] rounded-[10px] p-[19px] md:p-[15px] sm:p-[10px] xm:p-[7px] items-center border-[#EEEEEE] border-dashed">
                                    <p className={`text-[13px] md:text-[11px] sm:text-[9px] xm:text-[8px] h-[39px] md:h-[30px] sm:h-[25px] xm:h-[25px] text-[#FFFFFF] break-words text-center `}>{item.title.length > 15 ? item.title.slice(0, 15) + "..." : item.title}</p>
                                    <div className="flex justify-center m items-center">
                                        <img src={diamond} alt="diamond"/>
                                    </div>
                                    <p className={`text-[14px] md:text-[12px]  sm:text-[10px] xm:text-[8px] text-[#FFFFFF] text-center `}>{item.points}</p>
                                    <div className="flex justify-center items-center">
                                        <button onClick={() => {
                                            setPointId(item?.id);
                                            handleClickOpen()
                                        }}
                                                className="bg-[#D9D9D9] rounded-[10px] font-medium text-[#0F1328] text-[14px] md:text-[12px] sm:text-[10px] xm:text-[8px] md:w-[60px] md:h-[22px] sm:w-[50px] sm:h-[20px] xm:w-[50px] xm:h-[20px] w-[69px] h-[31px] flex justify-center items-center  ">Claim
                                        </button>
                                    </div>
                                </div>
                            )}
                        </SwiperSlide>*/
                    ))
                ) : ''
                }
            </Swiper>
            <div onClick={() => swiper.slideNext()}
                 className="swiper-button-next rewards-icon-swiper-next cursor-pointer icon-arrow-long-right review-swiper-button-next"></div>
            <div onClick={() => swiper.slidePrev()}
                 className="swiper-button-prev rewards-icon-swiper-prev cursor-pointer icon-arrow-long-left review-swiper-button-prev"></div>

            <Dialog open={open} onClose={handleClose} aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{"Claim Points"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div
                            className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                            <div className="file-upload-container">
                                <div><small>Screenshot</small></div>
                                <input
                                    type="file"
                                    name="screenshot"
                                    onChange={handleChange}
                                />
                                {errors.screenshot &&
                                <div style={{color: '#f44336', fontSize: '0.75rem'}}>{errors.screenshot}</div>}
                            </div>
                        </div>

                        <div
                            className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                            <TextField
                                className="w-[350px] tab:w-[100%] mobile:w-[100%] small:w-[100%]"
                                id="link"
                                name="link"
                                label="URL"
                                value={values.link}
                                onChange={handleChange}
                                error={errors.link}
                                helperText={errors.link}
                            />
                        </div>

                        <div className="flex justify-end mt-3">
                            <button
                                className="bg-[#F37A20] w-[70px] rounded-md text-[12px] p-3 text-white"
                                type="submit"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default TotalPoints;
