import React, { useState, useEffect } from 'react';
import { Loader, NoRecordFound, Toast } from '../../components/General';
import { useStateContext } from "../../contexts/ContextProvider";
import { Modal, Table, Form } from "react-bootstrap";
import { TextField, MenuItem, InputLabel, FormControl, Select, Radio, RadioGroup, FormControlLabel, FormLabel } from '@material-ui/core';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import { DateFormatWithSlash } from '../../utils/DateFormatWithSlash';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Slide from '@mui/material/Slide';
import Swal from 'sweetalert2';
import { Link, NavLink } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { PlusIcon } from '../../app-constants';
import dayjs from 'dayjs';
import { Oval } from "react-loader-spinner";
import CustomSelect from 'react-select';
import { RiEditBoxFill } from 'react-icons/ri';
import { formatMDY } from '../../utils/Helper';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function Project() {
    const { user, selectedProject, role, getAllManualProjects } = useStateContext();
    const [loading, setLoading] = useState(false);
    const [projectList, SetProjectList] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [categoryType, setCategoryType] = useState([]);
    const [phaseType, setPhaseType] = useState([]);
    // const [projectType, setProjectType] = useState([]);
    const [asanaProject, setAsanaProject] = useState([]);
    const [client, setClient] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [projectRegion, setProjectRegion] = useState([]);
    const [editOpen, setEditOpen] = useState(false);


    const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);
    const [projectError, setProjectError] = useState(false);


    const handleProjectOnBlur = async (e) => {

        const name = e.target.value;

        if (name) {
            setProjectError(false);

            await axios.get(`${process.env.REACT_APP_BASE_URL}is_project_unique/${name}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {

                setIsDisabledSubmit(response.data.data);
                setProjectError(response.data.data);

            }).catch((err) => {
                console.log('err====', err);
            })
            .finally(() => {
                // setLoading(false);
            })
        }
    };



    const getClientByProject = () => {
        // axios.get(`${process.env.REACT_APP_BASE_URL}get_clients_by_project/${selectedProject}`, {

        setLoading(true);

        axios.get(`${process.env.REACT_APP_BASE_URL}get_clients_by_user/${user.user.id}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((response) => {
                setClient(response.data.data);
            })
            .catch((err) => {
                console.log('err====', err);
            })
            .finally(() => {
                setLoading(false);
            })
    }



    // useEffect(() => {
    const options = client.length ? client.map(item => ({
        value: item.id,
        label: `${item.first_name} ${item.last_name}`
    })) : [];

    // }, [client]);



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        formik.resetForm();
        setOpen(false);
        setIsDisabledSubmit(false);
        setProjectError(false);
    };

    const getProjectType = () => {
        if (user) {
            axios.get(`${process.env.REACT_APP_BASE_URL}project-type`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    setProjectType(res.data.data)
                })
                .catch(err => {
                    console.log('err====', err);
                })
        }
    }

    const getPhaseType = () => {
        if (user) {
            axios.get(`${process.env.REACT_APP_BASE_URL}project-phase`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    setPhaseType(res.data.data)
                })
                .catch(err => {
                    console.log('err====', err);
                })
        }
    }

    const getProjectRegion = () => {
        if (user) {
            axios.get(`${process.env.REACT_APP_BASE_URL}get-project-region`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    setProjectRegion(res.data.data)
                })
                .catch(err => {
                    console.log('err====', err);
                })
        }
    }

    const getCategoryType = () => {
        if (user) {
            axios.get(`${process.env.REACT_APP_BASE_URL}project-category`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    setCategoryType(res.data.data)
                })
                .catch(err => {
                    console.log('err====', err);
                })
        }
    }

    const getAsanaProject = () => {
        if (user) {

            // console.log(user.user.asana_token);
            // const ASANA_BEARER_TOKEN = "Bearer 2/1204614194882962/1206884941289659:6993e942413ab55624207ae4379afb7f"
            // const ASANA_BEARER_TOKEN = "Bearer 2/1201167042555272/1207685887228689:89315ac24055e5eca3d60167553f13fe"
            axios.get(`${process.env.REACT_APP_ASANA_BASE_URL}projects`, {
                headers: {
                    'Authorization': user?.user?.asana_token || ''
                }
            })
                .then((res) => {
                    console.log(res);
                    setAsanaProject(res.data.data)
                })
                .catch(err => {
                    console.log('err====', err);
                })
        }
    }

    const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:(www\.)?|[a-zA-Z0-9.-]+\.)+[a-zA-Z]{2,}(?:\/|\?.*)?$/;


    const validationSchema = yup.object({
        name: yup
            .string()
            .min(6, 'Too Short!')
            .max(70, 'Too Long!')
            .required('Project name is required'),
        category_type: yup
            .string()
            .required('Project category is required'),
        phase_type: yup
            .string()
            .required('Phase type is required'),
        // project_type: yup
        //     .string()
        //     .required('Project Type is required'),

        // client: yup.string().required('Client name is required'),

        client_ids: yup.array().min(1, 'At least one Client is required').required('Client is required'),

        desc: yup
            .string()
            .min(6, 'Too Short!')
            .max(70, 'Too Long!')
            .required('Description is required'),
        deadline: yup
            .date().required('Deadline is required'),
        asana_project_url: yup
            .string()
            .url('Invalid URL format')
            .matches(urlRegex, 'Please enter a valid URL')
            .nullable('URL is required'),

        project_region_id: yup
            .number()
            .required('Project Region is required'),

        asana_project_id: yup
            .number()
            .required('Asana Project is required'),
    });


    const formik = useFormik({
        initialValues: {
            name: '',
            asana_project_id: null,
            asana_project_url: '',
            category_type: null,
            phase_type: null,
            // project_type: null,
            // client: null,
            desc: '',
            deadline: null,
            project_region_id: null,
            client_ids: [],
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            createProject(resetForm)
        },
    });

    const updateFormik = useFormik({
        initialValues: {
            name: '',
            asana_project_id: "",
            asana_project_url: '',
            category_type: '',
            phase_type: '',
            // project_type: null,
            // client: null,
            desc: '',
            deadline: '',
            project_region_id: '',
            client_ids: [],
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {

            updateProject(resetForm);
        },
    });

    const updateProject = async (resetForm) => {

        setBtnLoading(true);

        const clientArray = updateFormik?.values?.client_ids;

        let deadline = dayjs(updateFormik.values.deadline).format("YYYY-MM-DD");

        const data = {
            "name": updateFormik.values.name,
            "category_id": updateFormik.values.category_type,
            "phase_id": updateFormik.values.phase_type,
            "business_dev": user.user.id,
            "asana_project_id": updateFormik.values.asana_project_id,
            "asana_project_url": updateFormik.values.asana_project_url,
            "deadline": deadline,
            "description": updateFormik.values.desc,
            "project_region_id": updateFormik.values.project_region_id,
            "client_ids": clientArray.length > 0 ? clientArray.map(item => item.value).toString() : ""
        };

        // console.log(data);

        await axios.put(`${process.env.REACT_APP_BASE_URL}manual-project/${projectList.id}`, data, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {

                if (res.status === 200) {

                    getProjects();
                    handleEditClose();
                    resetForm({ values: '' });

                    Toast.fire({
                        icon: "success",
                        title: "Project Updated successfully"
                    });
                }
            })
            .catch((error) => {

                console.log(error);

                Toast.fire({
                    icon: "error",
                    title: 'Something went wrong!',
                });
            })
            .finally(() => {
                setBtnLoading(false);
            });


    }

    const createProject = async (resetForm) => {



        setBtnLoading(true);

        const selectedClient = client.find(item => `${item.first_name} ${item.last_name}` === formik.values.client);
        // const selectedProjectType = projectType.find(item => `${item.name}` === formik.values.project_type);
        const selectedCategoryType = categoryType.find(item => `${item.name}` === formik.values.category_type);
        const selectedPhaseType = phaseType.find(item => `${item.name}` === formik.values.phase_type);

        const clientArray = formik?.values?.client_ids;

        let deadline = dayjs(formik.values.deadline).format("YYYY-MM-DD");

        const data = {
            "name": formik.values.name,
            "category_id": selectedCategoryType.id,
            "phase_id": selectedPhaseType.id,
            // "type_id": selectedProjectType.id,
            "business_dev": user.user.id,
            // "client_id": selectedClient.id,
            "asana_project_id": formik.values.asana_project_id,
            "asana_project_url": formik.values.asana_project_url,
            "deadline": deadline,
            "description": formik.values.desc,
            "project_region_id": formik.values.project_region_id,
            "client_ids": clientArray.length > 0 ? clientArray.map(item => item.value).toString() : ""
        };

        await axios
            .post(
                `${process.env.REACT_APP_BASE_URL}manual-project`,
                { ...data },
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {

                    Toast.fire({
                        icon: 'success',
                        title: 'Project Created successfully!',
                    });

                    sessionStorage.setItem('session_project', response?.data?.data?.id);

                    setTimeout(() => {
                        location.reload();
                    }, 3000);

                    handleClose();
                    resetForm({ values: '' });
                }
            })
            .catch((error) => {
                handleClose()
                resetForm({ values: '' })
                console.log(error);
            })
            .finally(() => {
                setBtnLoading(false);
            });
    };

    const getProjects = async (loader = false) => {

        if (selectedProject) {
            setLoading(loader);

            await axios.get(`${process.env.REACT_APP_BASE_URL}manual-project/${selectedProject}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((response) => {

                    console.log(response.data.data);

                    SetProjectList(response.data.data);
                })
                .catch((err) => {
                    console.log('err====', err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const handleEditProject = () => {
        setEditOpen(true);

        updateFormik.setValues({
            name: projectList?.name || '',
            category_type: projectList?.category?.id || '',
            phase_type: projectList?.phase?.id || '',
            project_region_id: projectList?.project_region_id,
            asana_project_id: projectList?.asana_project_id || '',
            asana_project_url: projectList?.asana_project_url || '',
            desc: projectList?.description || '',
            deadline: projectList?.deadline ? dayjs(projectList.deadline) : null,
            project_region_id: projectList?.project_region_id || '',
            client_ids: projectList?.selected_clients,
        });
    }

    const handleEditClose = () => {
        updateFormik.resetForm();
        setEditOpen(false);
    };

    useEffect(() => {
        // getProjectType();
        getAsanaProject();
        getCategoryType();
        getPhaseType();

        getProjectRegion();
    }, [])


    useEffect(() => {
        getProjects(true);
        getClientByProject();

    }, [selectedProject])

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <div className="main_top_title">
                <div className="main_title"><h1>Projects <span>List</span></h1></div>
                <div className="main_btn_txt">
                    <ul>
                        <li>
                            <NavLink to="">
                                Dashboard
                            </NavLink>
                        </li>
                        <li className="active">
                            <NavLink to="" >
                                Projects
                            </NavLink >
                        </li>
                    </ul>
                </div>
            </div>
            {role !== 'client' && (
                <div className="list_fillter add__button" >
                    <div className="add_task" onClick={() => handleClickOpen()}>
                        {PlusIcon}  Add Project
                    </div>
                </div>
            )
            }
            <Container fluid className="p-0">
                <Row>
                    <Col xxl={12}>
                        <div className="main_style_box">
                            <div className="download_tabs">

                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th className="text-center">Project Name</th>
                                            <th className="text-center">Project Category</th>
                                            <th className="text-center">Project Phase</th>
                                            <th className="text-center">Project Region</th>
                                            {/* <th className="text-center">Project Type</th> */}
                                            <th className="text-center">Deadline</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center">{projectList?.name}</td>
                                            <td className="text-center">{projectList?.category?.name}</td>
                                            <td className="text-center">{projectList?.phase?.name}</td>
                                            <td className="text-center">{projectList?.project_region?.name || '-'}</td>
                                            {/* <td className="text-center">{projectList?.project_type?.name}</td> */}
                                            <td className="text-center">{formatMDY(projectList?.deadline)}</td>

                                            <td className="text-center">
                                                <div className="btn_flex">
                                                    <RiEditBoxFill className='cursor-pointer' onClick={() => handleEditProject()} color="orange" size={28} />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>

                                <Dialog
                                    open={open}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleClose}
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogTitle>{"Create a Project"}</DialogTitle>
                                    <DialogContent>
                                        <form onSubmit={formik.handleSubmit} className='space-y-4'>

                                                <TextField
                                                    className='w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%] '
                                                    id="name"
                                                    name="name"
                                                    label="Project Name"
                                                    value={formik.values.name}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                                    helperText={formik.touched.name && formik.errors.name}
                                                    onBlur={(e) => handleProjectOnBlur(e)}
                                                />

                                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
                                                {projectError ? <div style={{ color: 'red', marginTop: '7px' }} className='display-error'>This Project Name is already taken.</div> : ''}
                                            </div>

                                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Project Category</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        name="category_type"
                                                        label="Project Category"
                                                        id="category_type"
                                                        value={formik.values.category_type}
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.category_type && Boolean(formik.errors.category_type)}
                                                        helperText={formik.touched.category_type && formik.errors.category_type}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left"
                                                            },
                                                            getContentAnchorEl: null
                                                        }}
                                                    >
                                                        {categoryType?.map((item, index) => {
                                                            return (
                                                                <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>

                                                    {formik.touched.category_type && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.category_type}</div>}
                                                </FormControl>

                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Project Phase</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        name="phase_type"
                                                        label="Project Phase"
                                                        id="phase_type"
                                                        value={formik.values.phase_type}
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.phase_type && Boolean(formik.errors.phase_type)}
                                                        helperText={formik.touched.phase_type && formik.errors.phase_type}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left"
                                                            },
                                                            getContentAnchorEl: null
                                                        }}
                                                    >
                                                        {phaseType?.map((item, index) => {
                                                            return (

                                                                <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>

                                                    {formik.touched.phase_type && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.phase_type}</div>}
                                                </FormControl>

                                            </div>

                                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Project Region</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        name="project_region_id"
                                                        label="Project Region"
                                                        id="project_region_id"
                                                        value={formik.values.project_region_id}
                                                        onChange={(event) => {
                                                            formik.setFieldValue('project_region_id', event.target.value);
                                                        }}
                                                        error={formik.touched.project_region_id && Boolean(formik.errors.project_region_id)}
                                                        helperText={formik.touched.project_region_id && formik.errors.project_region_id}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left"
                                                            },
                                                            getContentAnchorEl: null
                                                        }}
                                                    >
                                                        {projectRegion?.map((item, index) => {
                                                            return (

                                                                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>

                                                    {formik.touched.project_region_id && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.project_region_id}</div>}
                                                </FormControl>
                                            </div>

                                            {/* <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' > */}
                                            {/* <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Project Type</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        name="project_type"
                                                        label="Project Type"
                                                        id="project_type"
                                                        value={formik.values.project_type}
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.project_type && Boolean(formik.errors.project_type)}
                                                        helperText={formik.touched.project_type && formik.errors.project_type}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left"
                                                            },
                                                            getContentAnchorEl: null
                                                        }}
                                                    >
                                                        {projectType?.map((item, index) => {
                                                            return (

                                                                <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>

                                                    {formik.touched.project_type && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.project_type}</div>}
                                                </FormControl> */}

                                            {/* Client */}

                                            {/* <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Client</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        name="client"
                                                        label="Client"
                                                        id="client"
                                                        value={formik.values.client}
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.client && Boolean(formik.errors.client)}
                                                        helperText={formik.touched.client && formik.errors.client}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left"
                                                            },
                                                            getContentAnchorEl: null
                                                        }}
                                                    >
                                                        {client?.map((item, index) => {
                                                            return (

                                                                <MenuItem key={index} value={`${item.first_name} ${item.last_name}`}>{item.first_name} {item.last_name}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>

                                                    {formik.touched.client && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.client}</div>}

                                                </FormControl> */}

                                            {/* </div> */}

                                            {/* <div className="m-0 flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8 project-client-field">
                                                <FormControl fullWidth error={formik.touched.client_ids && Boolean(formik.errors.client_ids)}>

                                                    <label htmlFor="demo-simple-select-labels" id="demo-simple-select-labels" class="text-secondary pb-1 MuiFormLabel-root">Client</label>
                                                    <CustomSelect
                                                        fullWidth
                                                        labelId="demo-simple-select-labels"
                                                        name="client_ids"
                                                        label="Client"
                                                        isMulti
                                                        id="client_ids"
                                                        options={options}
                                                        value={formik.values.client_ids} // Ensure it's a single value
                                                        helperText={formik.touched.client_ids && formik.errors.client_ids}
                                                        onChange={(event) => {
                                                            formik.setFieldValue('client_ids', event);
                                                        }}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left",
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left",
                                                            },
                                                            getContentAnchorEl: null,
                                                        }}
                                                    >
                                                    </CustomSelect>

                                                    

                                                    <p style={{ color: '#dc3545' }} className='m-0'>
                                                        {formik.touched.client_ids && formik.errors.client_ids}
                                                    </p>
                                                </FormControl>
                                            </div> */}

                                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
                                                <FormControl fullWidth error={formik.touched.client_ids && Boolean(formik.errors.client_ids)}>
                                                    <label htmlFor="demo-simple-select-labels" id="demo-simple-select-labels" className="text-secondary pb-1">Client</label>
                                                    <CustomSelect
                                                        fullWidth
                                                        labelId="demo-simple-select-labels"
                                                        name="client_ids"
                                                        label="Client"
                                                        isMulti
                                                        id="client_ids"
                                                        options={options}
                                                        value={formik.values.client_ids}
                                                        helperText={formik.touched.client_ids && formik.errors.client_ids}
                                                        onChange={(event) => {
                                                            formik.setFieldValue('client_ids', event);
                                                        }}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left",
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left",
                                                            },
                                                            getContentAnchorEl: null,
                                                        }}
                                                    />
                                                    <p style={{ color: '#dc3545' }} className='m-0'>
                                                        {formik.touched.client_ids && formik.errors.client_ids}
                                                    </p>
                                                </FormControl>


                                                {/* <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-labels">Clients</InputLabel>
                                                    <CustomSelect
                                                        labelId="demo-simple-select-labels"
                                                        name="client_ids"
                                                        label="Client"
                                                        id="client_ids"
                                                        value={formik.values.client_ids}
                                                        onChange={(event) => formik.setFieldValue('client_ids', event.target.value)}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left",
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left",
                                                            },
                                                            getContentAnchorEl: null,
                                                        }}
                                                    >
                                                        {options.map((option, index) => (
                                                            <MenuItem key={index} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </CustomSelect>

                                                    <p style={{ color: '#dc3545' }} className='m-0'>
                                                        {formik.touched.client_ids && formik.errors.client_ids}
                                                    </p>
                                                </FormControl> */}
                                            </div>

                                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Asana Project</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        name="asana_project_id"
                                                        label="Project Projects"
                                                        id="asana_project_id"
                                                        value={formik.values.asana_project_id}
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.asana_project_id && Boolean(formik.errors.asana_project_id)}
                                                        helperText={formik.touched.asana_project_id && formik.errors.asana_project_id}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left"
                                                            },
                                                            getContentAnchorEl: null
                                                        }}
                                                    >
                                                        {asanaProject?.map((item, index) => {
                                                            return (

                                                                <MenuItem key={index} value={item.gid}>{item.name}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>

                                                    {formik.touched.asana_project_id && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.asana_project_id}</div>}
                                                </FormControl>
                                                <TextField
                                                    className='w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%] '
                                                    id="asana_project_url"
                                                    name="asana_project_url"
                                                    label="Asana Project URL"
                                                    value={formik.values.asana_project_url}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.asana_project_url && Boolean(formik.errors.asana_project_url)}
                                                    helperText={formik.touched.asana_project_url && formik.errors.asana_project_url}
                                                />

                                            </div>

                                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
                                                <FormControl className="custom-input" fullWidth>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                            <DatePicker
                                                                fullWidth
                                                                className='w-[100%]'
                                                                name="deadline"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                id="deadline"
                                                                inputFormat="E MMM dd yyyy HH:MM:SS O"
                                                                label="Deadline"
                                                                value={formik.values.deadline}
                                                                onChange={value => formik.setFieldValue('deadline', value)}
                                                                error={formik.touched.deadline && Boolean(formik.errors.deadline)}
                                                                minDate={dayjs()}


                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>

                                                    {formik.touched.deadline && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.deadline}</div>}
                                                </FormControl>

                                            </div>
                                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
                                                <TextField
                                                    className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                                    id="desc"
                                                    name="desc"
                                                    label="Description"
                                                    value={formik.values.desc}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.desc && Boolean(formik.errors.desc)}
                                                    helperText={formik.touched.desc && formik.errors.desc}
                                                />
                                            </div>
                                            <div className='flex justify-end mt-3' >
                                                <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white'
                                                    variant="contained"
                                                    fullWidth type="submit"
                                                    disabled={isDisabledSubmit}
                                                >
                                                    {btnLoading ? <Oval
                                                        visible={true}
                                                        height="20"
                                                        width="20"
                                                        color="#ffffff"
                                                        secondaryColor="#ffffff"
                                                        ariaLabel="oval-loading"
                                                        wrapperStyle={{ disply: 'flex', justifyContent: 'center' }}
                                                        wrapperClass=""
                                                    /> : 'Save'}


                                                </button>
                                            </div>
                                        </form>
                                    </DialogContent>

                                </Dialog>


                                <Dialog
                                    open={editOpen}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleEditClose}
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogTitle>{"Update a Project"}</DialogTitle>
                                    <DialogContent>
                                        <form onSubmit={updateFormik.handleSubmit} className='space-y-4'>

                                            <TextField
                                                className='w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                                id="name"
                                                name="name"
                                                label="Project Name"
                                                value={updateFormik.values.name}
                                                onChange={updateFormik.handleChange}
                                                error={updateFormik.touched.name && Boolean(updateFormik.errors.name)}
                                                helperText={updateFormik.touched.name && updateFormik.errors.name}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />

                                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Project Category</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        name="category_type"
                                                        label="Project Category"
                                                        id="category_type"
                                                        value={updateFormik.values.category_type}
                                                        onChange={updateFormik.handleChange}
                                                        error={updateFormik.touched.category_type && Boolean(updateFormik.errors.category_type)}
                                                        helperText={updateFormik.touched.category_type && updateFormik.errors.category_type}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left"
                                                            },
                                                            getContentAnchorEl: null
                                                        }}
                                                    >
                                                        {categoryType?.map((item, index) => (
                                                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    {updateFormik.touched.category_type && <div style={{ color: 'red', marginTop: '7px' }}>{updateFormik.errors.category_type}</div>}
                                                </FormControl>

                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Project Phase</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        name="phase_type"
                                                        label="Project Phase"
                                                        id="phase_type"
                                                        value={updateFormik.values.phase_type}
                                                        onChange={updateFormik.handleChange}
                                                        error={updateFormik.touched.phase_type && Boolean(updateFormik.errors.phase_type)}
                                                        helperText={updateFormik.touched.phase_type && updateFormik.errors.phase_type}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left"
                                                            },
                                                            getContentAnchorEl: null
                                                        }}
                                                    >
                                                        {phaseType?.map((item, index) => (
                                                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    {updateFormik.touched.phase_type && <div style={{ color: 'red', marginTop: '7px' }}>{updateFormik.errors.phase_type}</div>}
                                                </FormControl>
                                            </div>

                                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Project Region</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        name="project_region_id"
                                                        label="Project Region"
                                                        id="project_region_id"
                                                        value={updateFormik.values.project_region_id}
                                                        onChange={(event) => {
                                                            updateFormik.setFieldValue('project_region_id', event.target.value);
                                                        }}
                                                        error={updateFormik.touched.project_region_id && Boolean(updateFormik.errors.project_region_id)}
                                                        helperText={updateFormik.touched.project_region_id && updateFormik.errors.project_region_id}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left"
                                                            },
                                                            getContentAnchorEl: null
                                                        }}
                                                    >
                                                        {projectRegion?.map((item, index) => (
                                                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    {updateFormik.touched.project_region_id && <div style={{ color: 'red', marginTop: '7px' }}>{updateFormik.errors.project_region_id}</div>}
                                                </FormControl>
                                            </div>

                                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                            </div>


                                            <div className="mt-0">
                                                <FormControl fullWidth error={updateFormik.touched.client_ids && Boolean(updateFormik.errors.client_ids)}>
                                                    <label htmlFor="demo-simple-select-labels" id="demo-simple-select-labels" className="text-secondary pb-1">Client</label>
                                                    <CustomSelect
                                                        fullWidth
                                                        labelId="demo-simple-select-labels"
                                                        name="client_ids"
                                                        label="Client"
                                                        isMulti
                                                        id="client_ids"
                                                        options={options}
                                                        value={updateFormik.values.client_ids}
                                                        helperText={updateFormik.touched.client_ids && updateFormik.errors.client_ids}
                                                        onChange={(event) => {
                                                            updateFormik.setFieldValue('client_ids', event);
                                                        }}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left",
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left",
                                                            },
                                                            getContentAnchorEl: null,
                                                        }}
                                                    />
                                                    <p style={{ color: '#dc3545' }} className='m-0'>
                                                        {updateFormik.touched.client_ids && updateFormik.errors.client_ids}
                                                    </p>
                                                </FormControl>
                                            </div>

                                            <div className='mt-3 flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Asana Project</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        name="asana_project_id"
                                                        label="Asana Projects"
                                                        id="asana_project_id"
                                                        value={updateFormik.values.asana_project_id}
                                                        onChange={updateFormik.handleChange}
                                                        error={updateFormik.touched.asana_project_id && Boolean(updateFormik.errors.asana_project_id)}
                                                        helperText={updateFormik.touched.asana_project_id && updateFormik.errors.asana_project_id}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left"
                                                            },
                                                            getContentAnchorEl: null
                                                        }}
                                                    >
                                                        {asanaProject?.map((item, index) => (
                                                            <MenuItem key={index} value={item.gid}>{item.name}</MenuItem>
                                                        ))}
                                                    </Select>


                                                    {formik.touched.asana_project_id && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.asana_project_id}</div>}
                                                </FormControl>

                                                <TextField
                                                    className='w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                                    id="asana_project_url"
                                                    name="asana_project_url"
                                                    label="Asana Project URL"
                                                    value={updateFormik.values.asana_project_url}
                                                    onChange={updateFormik.handleChange}
                                                    error={updateFormik.touched.asana_project_url && Boolean(updateFormik.errors.asana_project_url)}
                                                    helperText={updateFormik.touched.asana_project_url && updateFormik.errors.asana_project_url}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </div>

                                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                                <FormControl className="custom-input" fullWidth>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                            <DatePicker
                                                                fullWidth
                                                                className='w-[100%]'
                                                                name="deadline"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                id="deadline"
                                                                inputFormat="E MMM dd yyyy HH:MM:SS O"
                                                                label="Deadline"
                                                                value={updateFormik.values.deadline}
                                                                onChange={value => updateFormik.setFieldValue('deadline', value)}
                                                                error={updateFormik.touched.deadline && Boolean(updateFormik.errors.deadline)}

                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                    {updateFormik.touched.deadline && <div style={{ color: 'red', marginTop: '7px' }}>{updateFormik.errors.deadline}</div>}
                                                </FormControl>
                                            </div>

                                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                                <TextField
                                                    className='w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                                    id="desc"
                                                    name="desc"
                                                    label="Description"
                                                    value={updateFormik.values.desc}
                                                    onChange={updateFormik.handleChange}
                                                    error={updateFormik.touched.desc && Boolean(updateFormik.errors.desc)}
                                                    helperText={updateFormik.touched.desc && updateFormik.errors.desc}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </div>

                                            <div className='flex justify-end mt-3'>
                                                <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white'
                                                    variant="contained"
                                                    fullWidth type="submit"
                                                    disabled={btnLoading}
                                                >
                                                    {btnLoading ? <Oval
                                                        visible={true}
                                                        height="20"
                                                        width="20"
                                                        color="#ffffff"
                                                        secondaryColor="#ffffff"
                                                        ariaLabel="oval-loading"
                                                        wrapperStyle={{ disply: 'flex', justifyContent: 'center' }}
                                                        wrapperClass=""
                                                    /> : 'Save'}
                                                </button>
                                            </div>
                                        </form>

                                    </DialogContent>

                                </Dialog>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>

    );
}

export default Project
