import React, { useState } from 'react'
import WhiteLogo from '../data/tekLogo.svg'
import WhiteLogoCRM from '../data/white-logo-crm.svg'
import axios from 'axios';
import TextField from '@mui/material/TextField';
import { useNavigate, Link } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { ThreeDots, TailSpin } from 'react-loader-spinner'
import { useFormik } from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2'
import email from '../data/signin-email.png'
import { Toast } from "../components/General";
import {Logo} from "../utils/svg";
import {NewLogoForDarkBg} from "../utils/svg";

const validationSchema = yup.object({
  email: yup.string().email("Invalid Email Format").required("Email is required"),
  password: yup.string()
    .required("Password is required")
});

const SignIn = () => {

  const Navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      Login();
      resetForm({ values: '' })

    },
  });

  const { setUser, setIsToken } = useStateContext();
  const [loading, setLoading] = useState(false);

  const Login = () => {
    const userData = {
      email: formik.values.email,
      password: formik.values.password
    }
    // console.log(userData);
    setLoading(true);
    try {
      axios.post(`${process.env.REACT_APP_BASE_URL}user_login`,
        userData)
        .then((response) => {
          if (response.status === 200) {

            localStorage.setItem('tekrevol_user', JSON.stringify(response.data.data))

            // console.log(response);
            // console.log('sign',response.data.data.user);
            setUser(response.data.data);
            const { role } = response.data.data.user;
            console.log(role);
            switch (role) {
              case 'admin':
                Navigate('/appboard');
                break;
              case 'business developer':
                Navigate('/appboard');
                break;
              case 'account manager':
                Navigate('/');
                break;
              case 'client':
                Navigate('/');
                break;
              default:
                console.log('Unknown user role');
            }
            setLoading(false);

          }
        }).catch((e) => {
          console.log(e);
           Toast.fire({
            icon: 'error',
            title: 'Your Email or Password is incorrect!',
          })
          setLoading(false);
        })

    } catch (error) {

      setLoading(false);
    }
  }
  return (
    <div className="signinform min-h-screen">
      <div className='grid grid-cols-7' >

        <div className='signin-form'  >
          <div className='signin-form-div' >
            <div className='mb-[60px]' >
              {/* <Logo/> */}
              <NewLogoForDarkBg />
              {/*<img src={WhiteLogoCRM} alt="WhiteLogo" />*/}
            </div>
            <h1 className='signin-form-div-title login-after ' >Login</h1>
            <div className='flex justify-center items-center' >
              <form onSubmit={formik.handleSubmit} className='pt-[20px] space-y-4 custom-input' >
                {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}

                <div className='settings-email-div' >
                  <label className='text-white text-[14px] font-medium' >Email</label><br />
                  <div className='relative pt-[7px] ' >
                    <div className="settings-email-div-img mt-2">
                      <img src={email} className="pl-2" alt="searchIcon" />
                    </div>
                    <input
                      id='email'
                      name='email'
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      className='input-email'
                      placeholder='someone@example.com' />
                  </div>
                  {formik.errors.email && <span className="error-message">{formik.errors.email}</span>}

                </div>
                <div className='mt-[19px]' >
                  <label className='text-white text-[14px] font-medium' >Password</label><br />
                  <div className='pt-[7px]' >
                    <input
                      id='password'
                      name='password'
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      type='password'
                      className='input-password '
                      placeholder='***********' />
                    <br />{formik.errors.password && <span className="error-message">{formik.errors.password}</span>}
                  </div>
                </div>
                <div className='flex justify-between  pt-[20px]' >
                  <div className='flex justify-around gap-1' >
                    <div className="signin-check">
                      <input type='checkbox' />
                    </div>
                    <p className='signin-form-div-cancel' ><a href='#' >Remember Passsword</a></p>
                  </div>
                  <Link to='/forgot' >
                    <p className='signin-form-div-cancel' >Forget Password</p>
                  </Link>
                </div>
                {!loading ?
                  <button type='submit' className='signin-form-div-save' >Login</button>
                  : <button className='signin-form-div-save justify-center flex items-center' >
                    <TailSpin
                      height="30"
                      width="30"
                      color="#ffffff"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </button>
                }
              </form>
            </div>

          </div>

        </div>
        <div className='col-span-4 left ' >
          <div className='flex justify-center items-center h-full ' >
            {/* <img width={350} src={WhiteLogo} alt='WhiteLogo' /> */}
          </div>
        </div>

      </div>
    </div>
  )
}

export default SignIn;