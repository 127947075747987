import React, { useState } from 'react';
import { BsFillFilePdfFill, BsFileTextFill, BsFillPlayBtnFill, BsFileZipFill } from 'react-icons/bs'
import { niceBytes } from '../utils/ConvertBites'

const FILE_FORMATS = ['jpg', 'jpeg', 'gif', 'png', 'mp4', 'pdf', 'docx']

const FILES_BUILDS_FORMATS = ['apk', 'ipa', 'zip', 'exe']

const FilesWhatsNew = ({ attachments, onImageClick }) => {
  const [selected, setSelected] = useState('btn1');

  const filterFiles = (attachments) => {
    if (selected === 'btn2') {
      return attachments.filter(item => {
        const fileExtension = item.name?.substring(item.name.lastIndexOf('.') + 1)?.toLowerCase();

        return FILES_BUILDS_FORMATS.includes(fileExtension) === true;
      });
    } else {
      return attachments.filter(item => {
        const fileExtension = item.name?.substring(item.name.lastIndexOf('.') + 1)?.toLowerCase();

        return FILES_BUILDS_FORMATS.includes(fileExtension) === false && FILE_FORMATS.includes(fileExtension) === true;
      });
    }
  }

  const handleClick = (imageUrl) => {
    onImageClick(imageUrl);
  }


  return (
    <div className='' >
      <div className='flex justify-start gap-[40px]' >
        <button onClick={() => setSelected('btn1')} className={`pb-[10px]  ${selected === 'btn1' ? 'selected whats-new-tab' : 'un-selected '}  `} >Documentation</button>
        <button onClick={() => setSelected('btn2')} className={`pb-[10px] ${selected === 'btn2' ? 'selected whats-new-tab' : 'un-selected '}  `} >Builds</button>
      </div>
      <div className={`space-y-6 filesBuild-innerdiv h-[353px]  md:h-[330px] sm:h-[280px] xm:h-[260px] p-6 w-[100%] overflow-y-auto  `} >
        {attachments && attachments.length !== 0 ? filterFiles(attachments).map((item, index) => {
          const fileExtension = item.name?.substring(item.name.lastIndexOf('.') + 1)?.toLowerCase();
          console.log("FF EXT", fileExtension)
          console.log(item);

          const date = new Date(item.created_at);
          const formattedDate = date.toLocaleDateString();
          let icon;
          if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'gif') {
            icon = <a download target='_blank' href={item.download_url}>
              <img src={item.view_url} className="mr-2 w-[50px] h-[50px] lg:w-[35px] lg:h-[35px] md:w-[30px] md:h-[30px] sm:w-[25px] sm:h-[25px] xm:w-[22px] xm:h-[22px] " alt={`${item.view_url}`} />
            </a>;
          } else if (fileExtension === 'pdf') {
            icon = <BsFillFilePdfFill size={35} className='mr-2' color='red' />;
          } else if (fileExtension === 'mp4') {
            icon = <BsFillPlayBtnFill size={35} className='mr-2' color='gray' />;
          } else if (fileExtension === 'zip') {
            icon = <BsFileZipFill size={35} className='mr-2' color='gray' />;
          }
          else {
            icon = <BsFileTextFill size={35} className='mr-2' color='blue' />;
          }
          return (
            <div key={index} onClick={() => handleClick(item.view_url)} className='flex items-start ml-[-24px] mr-[-24px] pl-[24px] pr-[24px] pt-[5px] pb-[5px]  builds-hover cursor-pointer justify-between' >
              <div className="flex items-center " >
                {icon}
                <div className='' >
                  <p className='text-[13px] break-words md:text-[11px] sm:text-[9px] xm:text-[8px] font-medium builds-file '  >{item.name}</p>
                  <p className='text-[10px] md:text-[9px] sm:text-[8px] xm:text-[7px] font-medium ' >{formattedDate}</p>
                </div>
              </div>
              {/* <p className='text-[12px] md:text-[10px] sm:text-[8px] xm:text-[7px] ml-6 ' >{Math.round(item.size / 1000)} MB</p> */}
              <p className='text-[12px] md:text-[10px] sm:text-[8px] xm:text-[7px] ml-6 ' >{niceBytes(item.size)}</p>
            </div>

          )
        }) :
          <div className='flex justify-center items-center' >
            <p>No Files Here...</p>
          </div>}


      </div>
    </div>
  )
}

export default FilesWhatsNew;