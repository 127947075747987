import React, { useEffect } from "react";
import Rewards from "../components/Rewards";
import OverviewTab from "../components/OverviewTab";
import { useStateContext } from "../contexts/ContextProvider";
import OverviewResource from "../components/OverviewResources";
import WhatsNew from "../components/WhatsNew";
import FilesTab from "../components/FilesTab";
import DownloadContentTabs from "../components/DownloadContentTabs";
import Avatar5 from '../data/avatar.jpg'
import { useMemo } from "react";
import { MdCreateNewFolder } from 'react-icons/md';
import { useState } from "react";
import { BsFillFilePdfFill, BsFileTextFill, BsFillPlayBtnFill, BsFileZipFill } from 'react-icons/bs'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    TextField,
    TextareaAutosize,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel
} from '@material-ui/core';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import axios from 'axios'
import Swal from 'sweetalert2';
import S3FileUpload from 'react-s3';
import { S3Upload } from '../utils/S3Upload'
import { ThreeDots, Oval } from 'react-loader-spinner'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

window.Buffer = window.Buffer || require("buffer").Buffer;

const S3_BUCKET = 'tek-crm';
const REGION = 'us-east-1';
const ACCESS_KEY = 'AKIAUPLSLQUZCEBB22HJ';
const SECRET_ACCESS_KEY = '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';

const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const validationSchema = yup.object({
    file_type: yup
        .string()
        .required('File Type is required'),
    // client: yup
    //   .string()
    //   .required('Client is required'),
    file_name: yup
        .string()
        .required('File Name is required'),
    link: yup
        .string(),
    description: yup
        .string(),
    industry_id: yup
        .string(),
    event_date: yup
        .string()
});

const FILE_FORMATS = ['jpg', 'jpeg', 'gif', 'png', 'mp4', 'pdf', 'docx']

const DOWNLOADABLE_TYPES = {
    'Marketing Collateral': 1,
    'Articles': 2,
    'Blogs': 3,
    'Brand Assets': 4,
    'Documentation': 5,
    'Portfolio': 6,
    'Whats New': 7,
}

const DownloadContent = () => {
    const { activeDownloadTab, setActiveDownloadTab, user, client } = useStateContext();
    const { role, id } = user.user;
    const [content, setContent] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [users, setUsers] = useState([]);

    const [data, setData] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [searchValue, setSearchValue] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleOpenDialog = (item) => {
        setSelectedItem(item);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedItem(null);
    };

    const downloadableContentType = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}downloadable-content-type`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setData(response.data.data)
                setActiveDownloadTab(data[0]?.id);
            }
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    const getIndustries = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}get_industry`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setIndustries(response.data.data)
            }
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    const filterFiles = (attachments) => {
        return attachments.filter(item => {
            const fileExtension = item.file_path?.substring(item.file_path.lastIndexOf('.') + 1)?.toLowerCase();
            // console.log(fileExtension);
            return FILE_FORMATS.includes(fileExtension) === true;
        });
    }


    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const handleUpload = async (file) => {

        setLoading(true);

        try {
            const response = await S3Upload(file.type, file.name, file)
            if (response) {
                console.log("RESPONSE URL IMAGE", response)
                UploadContent(response);
            }
        } catch (error) {
            setLoading(false)
            console.error("error", error)
        }
    }

    const UploadContent = async (file_uri) => {
        setLoading(true);
        //const selectedClient = users.find(item => `${item.first_name} ${item.last_name}` === formik.values.client);

        const data = {
            "file_type": formik.values.file_type,
            //"client_id": selectedClient.id,
            "business_dev": user.user.id,
            "file_name": formik.values.file_name,
            "file_path": file_uri,
            "link": formik.values.link ? formik.values.link : '',
            "description": formik.values.description ? formik.values.description : '',
            "industry_id": formik.values.industry_id ? formik.values.industry_id : '',
            "event_date": formik.values.event_date ? formik.values.event_date : '',
        }

        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}downloadable-content`, { ...data }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        getAllFiles();
                        setLoading(false);
                        Swal.fire(
                            {
                                icon: 'success',
                                title: 'File Uploaded successfully!',
                                showConfirmButton: false,
                                timer: 1000
                            }
                        )
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1000
                    })
                })
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const getAllFiles = () => {
        setLoading(true);
        //let url = `${process.env.REACT_APP_BASE_URL}downloadable-content?file_type=${activeDownloadTab + 1}&content_by_client=${id}`
        let url = `${process.env.REACT_APP_BASE_URL}downloadable-content?file_type=${activeDownloadTab}&keyword=${searchValue}`
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            setContent(response?.data?.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log('err====', err);
        })
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formik = useFormik({
        initialValues: {
            file_type: null,
            client: null,
            file_name: '',
            file_path: '',

        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            handleClose()
            handleUpload(selectedFile)
            resetForm({ values: '' })
        },
    });

    useEffect(() => {
        downloadableContentType()
        getIndustries()
    }, [])

    useEffect(() => {
        if (activeDownloadTab) {
            getAllFiles();
        }
    }, [activeDownloadTab])

    const getAllUsers = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}get_users_by_role/client`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            setUsers(response.data.data);
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    useEffect(() => {
        getAllUsers();
    }, []);

    useEffect(() => {
        getAllFiles();
    }, [searchValue])

    /*if (loading) {
        return (
            <div className=' justify-center items-center flex h-[80vh]'>
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#F37A20"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </div>
        )
    }*/
    return (
        <div className="appboard-grid">
            <div className="appboard-sub-grid pt-4">

                <div className="main-top-banner">
                    <div className="main-title">
                        <h1>Downloadable<span> Content</span></h1>
                    </div>
                    {role !== "client" ? (
                        <div className="main-btn-txt">
                            <button className="btn-banner" onClick={handleClickOpen}>Upload New Upload</button>
                        </div>
                    ) : null}
                </div>

                <div className='appboard-overview-project-main'>
                    <div className='appboard-grid-project-title'>
                        <DownloadContentTabs type={DOWNLOADABLE_TYPES} data={data} searchValue={searchValue}
                            setSearchValue={setSearchValue} />
                    </div>
                    <div className="appboard-grid-project-txt">

                        {loading ?
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Oval
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#f37a20"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                                <small>Please wait while the content is loading</small>
                            </div>
                            : <div className="downloadable_content">
                                <div className="project_files" >
                                    {content && content.length > 0 ?
                                        content.map((item, index) => {
                                            const fileExtension = item.file_path?.substring(item.file_path.lastIndexOf('.') + 1)?.toLowerCase();

                                            const date = new Date(item.created_at);
                                            const formattedDate = date.toLocaleDateString();
                                            let icon;
                                            if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'gif') {
                                                icon =
                                                    <div className="img-icon">
                                                        <img src={item.file_path} alt={`${item.file_path}`} />
                                                    </div>
                                            } else if (fileExtension === 'pdf') {
                                                icon =
                                                    <div className="img-icon">
                                                        <BsFillFilePdfFill size={35} />
                                                    </div>
                                            } else if (fileExtension === 'mp4') {
                                                icon = <div className="img-icon">
                                                    <BsFillPlayBtnFill size={35} />
                                                </div>
                                            } else if (fileExtension === 'zip') {
                                                icon =
                                                    <div className="img-icon">
                                                        <BsFileZipFill size={35} />
                                                    </div>
                                            } else {
                                                icon =
                                                    <div className="img-icon">
                                                        <BsFileTextFill size={35} color={'blue'} />
                                                    </div>
                                            }

                                            if (item.file_type == DOWNLOADABLE_TYPES["Marketing Collateral"] ||
                                                item.file_type == DOWNLOADABLE_TYPES["Brand Assets"] ||
                                                item.file_type == DOWNLOADABLE_TYPES["Documentation"]) {
                                                return (
                                                    <div className="project_file_list">
                                                        <div className="project_files_img">
                                                            <a href={item.file_path} download
                                                                target="_blank"> {icon}
                                                            </a>
                                                        </div>
                                                        <div className="project_files_txt">
                                                            <a key={index} href={item.file_path} download target="_blank">
                                                                <p className="name_file"> {item.file_name}</p>
                                                                <p className="date_file">{formattedDate}</p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                );
                                            } else if (item.file_type == DOWNLOADABLE_TYPES["Portfolio"] ||
                                                item.file_type == DOWNLOADABLE_TYPES["Whats New"]) {
                                                return (
                                                    <div className="project_profile_list" key={index}>
                                                        <div className="img_box">
                                                            <img src={item.file_path} alt={item.file_name} />
                                                        </div>
                                                        <div className="img_content">
                                                            <div className="name_pro">{item.file_name}</div>
                                                            <div className="date_pro">{formattedDate}</div>
                                                            {item.file_type === DOWNLOADABLE_TYPES["Portfolio"] && (
                                                                <a href={item.link} download target="_blank"
                                                                    className="btn-download">
                                                                    View Full Case Study
                                                                </a>
                                                            )}
                                                        </div>

                                                    </div>

                                                );

                                            } else {
                                                return (
                                                    <div className="project_profile_list" key={index}>
                                                        <Link to={'https://www.tekrevol.com/'} style={{cursor:'Pointer'}}>
                                                            <div className="img_box">
                                                                <img src={item.file_path} alt={item.file_name} />
                                                            </div>
                                                        </Link>
                                                        <div className="img_content">
                                                            <div className="name_pro">{item.file_name}</div>
                                                            <div className="date_pro">{formattedDate}</div>
                                                            <p className="txt_pro">{item.description.length > 100 ? `${item.description.substring(0, 100)}...` : item.description}
                                                                {item.description.length > 100 && (
                                                                    <span
                                                                        className="read_more_btn"
                                                                        onClick={() => handleOpenDialog(item)}>Read more</span>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>

                                                );
                                            }
                                        })
                                        : <div className=''>
                                            <p className='text-[13px] text-[#979797] font-bold'>No Data
                                                Exists</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
         
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Upload New File"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit} className='space-y-6 '>
                        <div
                            className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">File Type</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    name="file_type"
                                    label="File Type"
                                    id="file_type"
                                    value={formik.values.file_type}
                                    onChange={formik.handleChange}
                                    error={formik.touched.file_type && Boolean(formik.errors.file_type)}
                                    helperText={formik.touched.file_type && formik.errors.file_type}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    {
                                        data && data?.map((dt, index) => {
                                            return (
                                                <MenuItem value={dt.id}>{dt.type}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>

                        {formik.values.file_type === data[6]?.id && (
                            <div
                                className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Industry</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="industry_id"
                                        label="Select Industry"
                                        id="industry_id"
                                        value={formik.values.industry_id}
                                        onChange={formik.handleChange}
                                        error={formik.touched.industry_id && Boolean(formik.errors.industry_id)}
                                        helperText={formik.touched.industry_id && formik.errors.industry_id}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem value="">Select Industry</MenuItem>
                                        {
                                            industries && industries?.map((dt, index) => {
                                                return (
                                                    <MenuItem value={index + 1}>{dt}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <TextField
                                className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                id="file_name"
                                name="file_name"
                                label="Name Or Title"
                                value={formik.values.file_name}
                                onChange={formik.handleChange}
                                error={formik.touched.file_name && Boolean(formik.errors.file_name)}
                                helperText={formik.touched.file_name && formik.errors.file_name}
                            />
                            <div className="file-upload-container">
                                <input
                                    required
                                    type="file"
                                    onChange={handleFileInput} />
                            </div>
                        </div>

                        {formik.values.file_type === data[5]?.id && (
                            <div
                                className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <TextField
                                    className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                    id="link"
                                    name="link"
                                    label="URL / Case Study Link"
                                    value={formik.values.link}
                                    onChange={formik.handleChange}
                                    error={formik.touched.link && Boolean(formik.errors.link)}
                                    helperText={formik.touched.link && formik.errors.link}
                                />
                            </div>
                        )}

                        {[data[1]?.id, data[2]?.id].includes(formik.values.file_type) && (
                            <div
                                className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <TextareaAutosize
                                    style={{ borderBottom: '1px solid #00000070' }}
                                    className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%] MuiInputBase-input MuiInput-input'
                                    aria-label="minimum height"
                                    minRows={3}
                                    placeholder="Enter Description"
                                    id="description"
                                    name="description"
                                    label="Description"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    error={formik.touched.description && Boolean(formik.errors.description)}
                                    helperText={formik.touched.description && formik.errors.description}
                                />
                            </div>
                        )}

                        {formik.values.file_type === data[6]?.id && (
                            <FormControl className="custom-input" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                        <DatePicker
                                            fullWidth
                                            className='w-[100%]'
                                            name="event_date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="pay_date"
                                            inputFormat="E MMM dd yyyy HH:MM:SS O"
                                            label="Event Date"
                                            value={formik.values.event_date}
                                            onChange={value => formik.setFieldValue('event_date', value)}
                                            error={formik.touched.event_date && Boolean(formik.errors.event_date)}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                        )}
                        <div className='flex justify-end mt-3'>
                            <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white'
                                variant="contained" fullWidth type="submit">
                                Save
                            </button>
                        </div>
                    </form>
                </DialogContent>

            </Dialog>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                {selectedItem && (
                    <>
                        <DialogTitle>{selectedItem.file_name}</DialogTitle>
                        <DialogContent>
                            <p>{selectedItem.description}</p>
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </div>
    );
};

export default DownloadContent;
