import React, { useRef, useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Col, Row, Modal } from "react-bootstrap";
import moment from "moment";
import { EventData } from "./eventData";
import { DotValueIcon, DropDownArrowIcon } from "../../../../app-constants";

import { useStateContext } from "../../../../contexts/ContextProvider";
import axios from 'axios';

const EventCalendar = () => {

    const { user, selectedProject } = useStateContext();


    const leftCalendarRef = useRef(null);
    const rightCalendarRef = useRef(null);
    const [selectedDateEvents, setSelectedDateEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [view, setView] = useState(false)

    console.log("selectedDateEvents", selectedDateEvents);
    const [events, setEvents] = useState([]); //Event Data for Events
    const [eventModal, setEventModal] = useState(false); //Event modal state

    // New state variable for the currently selected event ID
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        getCalenderResponse();
    }, [selectedProject])


    const getCalenderResponse = async () => {


        if (selectedProject) {
            setLoading(true);

            await axios.get(`${process.env.REACT_APP_BASE_URL}get_calender_meeting/${selectedProject}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            .then((response) => {

                setEvents(response?.data?.data);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
        }
    };

    const handlePrev = () => {
        leftCalendarRef.current?.getApi().prev();
        rightCalendarRef.current?.getApi().prev();
        setSelectedDateEvents([]);
    };

    const handleNext = () => {
        leftCalendarRef.current?.getApi().next();
        rightCalendarRef.current?.getApi().next();
        setSelectedDateEvents([]);
    };

    const handleDateClick = (info) => {
        const clickedDate = new Date(info.dateStr);
        setSelectedDate(clickedDate);
        setSelectedDateEvents(
            events.filter((event) => {
                const eventStart = new Date(event.start);
                return eventStart.toDateString() === clickedDate.toDateString();
            })
        );

        // Use the selected date to navigate the other calendar
        const otherCalendarApi = rightCalendarRef.current?.getApi();
        if (otherCalendarApi) {
            otherCalendarApi.gotoDate(clickedDate);
        }
    };

    const handleRightCalendarDateSet = (info) => {
        const leftCalendarApi = leftCalendarRef.current?.getApi();
        if (leftCalendarApi) {
            leftCalendarApi.gotoDate(info.view.currentStart);
        }
    };

    const handleEventClose = () => {
        setEventModal(false);
        setSelectedEventId(null);
    };
    if (view) {
        return <EventMapView view={setView} />
    }

    const highlightToday = (arg) => {
        const today = new Date().toISOString().slice(0, 10); // Get today's date in ISO format (YYYY-MM-DD)
        const currentDate = arg.dateStr;

        if (currentDate === today && arg.view.type === 'dayGridMonth') {
            arg.el.classList.add('today-highlight');
        }
    };
    return (
        <>
            <div className="eventCalendarWrapper">
                <Row >
                    <Col lg={3}>
                        <div className="left-calendar">
                            <div className="calendar-nav-buttons">
                                <button onClick={handlePrev} className="sqrBtn prev_btn">
                                    {DropDownArrowIcon}
                                </button>
                                <button onClick={handleNext} className="sqrBtn next_btn">
                                    {DropDownArrowIcon}
                                </button>
                            </div>
                            <FullCalendar
                                className="EventCalendarLeft"
                                dayHeaderClassNames="eventCalendarHeader"
                                dayCellClassNames="eventCalendarCell"
                                ref={leftCalendarRef}
                                plugins={[dayGridPlugin, interactionPlugin]}
                                initialView="dayGridMonth"
                                events={events}
                                weekends={true}
                                height={300}
                                contentHeight={"auto"}
                                selectable={false}
                                dateClick={handleDateClick}
                                dayRender={highlightToday} // Call the highlightToday function
                                headerToolbar={{
                                    left: "title",
                                    right: false,
                                    center: false,
                                }}
                                dayCellContent={(e) => {
                                    const dateString = moment(e.date).format("YYYY-MM-DD");
                                    const eventCount = events.filter((event) =>
                                        event.start.includes(dateString)
                                    ).length;

                                    return (
                                        <>
                                            {e.dayNumberText}
                                            {eventCount > 0 && (
                                                <span>{DotValueIcon}</span>
                                            )}
                                        </>
                                    );
                                }}
                            />
                        </div>
                    </Col>
                    <Col lg={9}>
                        <div className="right-calendar">
                            <FullCalendar
                                className="EventCalendarRight"
                                dayHeaderClassNames="eventCalendarHeader"
                                dayCellClassNames="eventCalendarCell"
                                ref={rightCalendarRef}
                                themeSystem="darkly"
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                dateClick={(info) => {
                                    const clickedDate = new Date(info.dateStr);
                                    setSelectedDate(clickedDate);

                                    const otherCalendarApi = leftCalendarRef.current?.getApi();
                                    if (otherCalendarApi) {
                                        otherCalendarApi.gotoDate(clickedDate);
                                    }
                                }}
                                initialView="dayGridMonth"
                                headerToolbar={{
                                    left: "title",
                                    center: false,
                                    right: false,
                                }}
                                // headerToolbar={false}
                                events={events}
                                weekends={true}
                                eventContent={(eventInfo) => (
                                    <>
                                        {console.log("currentEvent Detail", eventInfo)}
                                        {selectedEventId === eventInfo.event.id && (
                                            // <Modal
                                            //     centered
                                            //     closable={false}
                                            //     open={eventModal}
                                            //     closeIcon={null}
                                            //     footer={null}
                                            //     width={"auto"}
                                            //     className="onEdgeModal"
                                            //     maskClosable={true}
                                            //     onCancel={handleEventClose}
                                            // >
                                            //     <HoverEventDetailCard
                                            //         eventInfo={eventInfo}
                                            //         setEventModal={setEventModal}
                                            //     />
                                            //     <p>modal Here</p>
                                            // </Modal>
                                            <p>Modal Here</p>
                                        )}
                                        {/* Set the selected event ID on click */}
                                        <div
                                            className="eventLabel"
                                            onClick={() => {
                                                setSelectedEventId(eventInfo.event.id);
                                                setEventModal(true);
                                            }}
                                        >
                                            {eventInfo.event.title}
                                        </div>
                                    </>
                                )}
                                // Sync right calendar with left calendar when the date is set
                                datesSet={handleRightCalendarDateSet}
                            />
                        </div>
                    </Col>
                </Row>
            </div>

        </>
    );
};

export default EventCalendar;
