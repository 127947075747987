import CanvasJSReact from '@canvasjs/react-charts';
import { useStateContext } from "../../contexts/ContextProvider";
import React, { useEffect, useState } from "react";
import { Oval } from 'react-loader-spinner';
import axiosInstance from '../../axios/axiosInstance';

const ColumnChart = () => {
    const { CanvasJSChart } = CanvasJSReact;

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const { user, selectedProject } = useStateContext();

    const getDashboard = async () => {

        if (selectedProject) {
            setLoading(true);

            await axiosInstance.get(`asana-dashboard/${selectedProject}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    setData(res?.data)
                })
                .catch(err => {
                    if (err?.response?.status == 401) {
                        localStorage.removeItem('tekrevol_user');
                        window.location.href = '/signin';
                    } else {
                        console.log(err);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }


    useEffect(() => {
        getDashboard();
    }, [selectedProject]);


    // Sample data provided
    // const responseData = {
    //     "success": true,
    //     "message": "Dashboard data retrieved successfully",
    //     "data": {
    //         "label": [
    //             "Requirement Elicitation Phase",
    //             "Design Phase",
    //             "Alpha Phase",
    //             "Required Account",
    //             "Beta Phase",
    //             "UAT Phase",
    //             "Deployment Phase",
    //             "Billing Section"
    //         ],
    //         "data": [
    //             7,
    //             0,
    //             8,
    //             1,
    //             6,
    //             5,
    //             5,
    //             6
    //         ]
    //     }
    // };

    const responseData = {
        "success": true,
        "message": "Dashboard data retrieved successfully",
        "data": {
            "label": data.label ? data.label : [],
            "data": data.data ? data.data : []
        }
    };

    // Convert the response data to dataPoints for CanvasJS
    const dataPoints = responseData.data.label.map((label, index) => ({
        label: label,
        y: responseData.data.data[index]
    }));

    const options = {
        animationEnabled: true,
        theme: "light2",
        axisY: {
            includeZero: true,
            lineThickness: 0, // Remove axis line
            tickThickness: 0, // Remove ticks
            gridThickness: 0.2, // Remove grid lines
            labelFontSize: 10, // Increase label font size for better visibility
            labelFontColor: "#979797" // Color to match the columns or distinguish
        },
        axisX: {
            lineThickness: 0, // Remove axis line
            tickThickness: 0, // Remove ticks
            labelAngle: 0, // Tilt labels for better space management
            labelFontSize: 10, // Font size for X-axis labels
            labelFontColor: "#979797", // Color for X-axis labels
            labelAutoFit: true, // Automatically adjust label fit
            interval: 1
        },
        data: [
            {
                type: "column",
                color: "#F37A20", // Example color, you can change it as needed
                dataPoints: dataPoints,
            }
        ]
    };

    return loading ? (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }} className='m-10'>
            <Oval
                visible={true}
                height="20"
                width="20"
                color="#F37A20"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    ) : <CanvasJSChart options={options} />;
};

export default ColumnChart;
