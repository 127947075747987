import React, {useEffect, useState, useCallback, useRef, useMemo} from "react";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import InvoiceHistory from "../components/Invoice/InvoiceHistory";
import InvoiceTable from "../components/Invoice/InvoiceTable";
import Rewards from "../components/Rewards";
import {useStateContext} from "../contexts/ContextProvider";
import {MdCreateNewFolder} from 'react-icons/md';
import {Link} from "react-router-dom";
import Progress from "../components/charts/Progress";
import {intToString} from '../utils/PriceToString'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {
    TextField,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel
} from '@material-ui/core';
import DialogTitle from '@mui/material/DialogTitle';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import Slide from '@mui/material/Slide';
import axios from 'axios'
import Swal from 'sweetalert2';
import {ThreeDots} from 'react-loader-spinner'
import UserRewards from "../components/Rewards/UserRewards";
import { S3Upload } from '../utils/S3Upload'
window.Buffer = window.Buffer || require("buffer").Buffer;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    // client: yup
    //     .string()
    //     .required('Client Name is required'),
    // project: yup
    //     .string()
    //     .required('Project Name is required'),
    // project_type: yup
    //     .string()
    //     .required('Project Type is required'),
    amount: yup
        .number()
        .required('Amount is required'),
    due_date: yup
        .date().required('Due Date is required'),
    pay_date: yup
        .date().required('Pay Date is required'),
    // link: yup
    //   .string()
    //   .required('Invoice Link is required'),
});

const ProjectInvoices = () => {
    const {invoices, user, setInvoices, getAllInvoice, client, setClient, table, manualProjects, project, sessionProject} = useStateContext();
    const {role} = user.user;
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const params = useParams();
    const [open, setOpen] = React.useState(false);
    const [projectState, setProjectState] = useState('manual');
    const [projectType, setProjectType] = useState([]);
    const navigate = useNavigate();
    const [currentdate, setCurrentdate] = useState(new Date());
    const [next, setNext] = useState(null);
    const [previous, setPrevious] = useState(null);
    const [data, setData] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);

    const Refresher = useCallback(() => {
        getAllInvoiceByPid();
    }, [invoices])

    const getAllInvoiceByPid = () => {
        if (user) {
            let url = `${process.env.REACT_APP_BASE_URL}invoice?manual_project_id=${sessionProject}`
            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {
                if (response && response.data) {
                    const formattedData = response?.data?.data?.invoices?.map(item => {
                        const due_date = new Date(item.due_date);
                        const pay_date = new Date(item.pay_date);
                        const formattedDueDate = due_date.toLocaleDateString();
                        const formattedPayDate = pay_date.toLocaleDateString();
                        const nextInvoice = item.nextInvoice;
                        const previousInvoice = item.previousInvoice;
                        const paid_invoice_sum = item.paid_invoice_sum;
                        const unpaid_invoice_sum = item.unpaid_invoice_sum;
                        return {
                            ...item,
                            due_date: formattedDueDate,
                            pay_date: formattedPayDate,
                            nextInvoice,
                            previousInvoice,
                            paid_invoice_sum,
                            unpaid_invoice_sum
                        };
                    });
                    setInvoices(formattedData)
                }
            }).catch((err) => {
                console.log('err====', err);
            })
        }
    }

    const createInvoice = async (file_uri = "") => {
        setLoading(true);

        const selectedClient = client.find(item => `${item.first_name} ${item.last_name}` === formik.values.client);

        const data = {
            "client_id": '',//selectedClient.id,
            "due_date": new Date(formik.values.due_date)?.toUTCString(),
            "pay_date": new Date(formik.values.pay_date)?.toUTCString(),
            "manual_project_id": parseInt(sessionProject), //projectState === 'manual' ? selectedProject.id : null,
            "asana_project_id": '',//projectState === 'asana' ? selectedProject.gid : null,
            "status": formik.values.status,
            "link": formik.values.link,
            "project_type": parseInt(sessionProject), //selectedProjectType.id,
            "project_id": parseInt(sessionProject),
            "user_id": parseInt(user.user.id),
            "amount": formik.values.amount,
        }

        if (file_uri) {
            data['file_path'] = file_uri;
        }

        // console.log('data======',data);
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}invoice`, {...data}, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        getAllInvoiceByPid(params.pid);
                        // navigate('/projectInvoices');
                        setLoading(false);
                        Swal.fire(
                            {
                                icon: 'success',
                                title: 'Invoice Created successfully!',
                                showConfirmButton: false,
                                timer: 1000
                            }
                        )
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1000
                    })
                })
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpload = async (file) => {
        if (file) {
            setLoading(true);
            try {
                const response = await S3Upload(file.type, file.name, file)
                if (response) {
                    console.log("RESPONSE URL IMAGE", response)
                    createInvoice(response);
                }
            } catch (error) {
                setLoading(false)
                console.error("error", error)
            }
        }
        else{
            createInvoice();
        }
    }


    const formik = useFormik({
        initialValues: {
            client: null,
            project: null,
            //project_type: null,
            amount: null,
            //project_type: '',
            due_date: null,
            pay_date: null,
            status: '',
            link: ''

        },
        validationSchema: validationSchema,
        onSubmit: (values, {resetForm}) => {
            handleClose()
            handleUpload(selectedFile || '')
            //createInvoice()
            resetForm({values: ''})
        },
    });

    const getAllUsers = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}get_clients_by_user/${user.user.id}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {

            setClient(response.data.data);

        }).catch((err) => {
            console.error('error retrieving client', err);
        })
    }

    useEffect(() => {
        setLoading(true);
        let url = `${process.env.REACT_APP_BASE_URL}invoice?manual_project_id=${sessionProject}`;
        (async () => {
            let response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (response && response.data) {
                const invoiceData = response?.data?.data?.invoices?.map(item => {
                    const due_date = new Date(item.due_date);
                    const pay_date = new Date(item.pay_date);
                    const formattedDueDate = due_date.toLocaleDateString();
                    const formattedPayDate = pay_date.toLocaleDateString();
                    return {
                        ...item,
                        due_date: formattedDueDate,
                        pay_date: formattedPayDate,
                    };
                });
                setInvoices(invoiceData)

                const formattedData = {
                    'nextInvoice': response?.data?.data?.nextInvoice,
                    'previousInvoice': response?.data?.data?.previousInvoice,
                    'paid_invoice_sum': response?.data?.data?.paid_invoice_sum,
                    'unpaid_invoice_sum': response?.data?.data?.unpaid_invoice_sum,
                }
                setData(formattedData)
                setLoading(false);
            }
        })();
    }, [project])

    useEffect(() => {
        getAllUsers();
        // getProjectType();
    }, [])


    useEffect(() => {
        //getNextMonthInvoices(currentdate)
        //getPreviousMonthInvoices(currentdate)
    }, [invoices])

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    /*if (loading) {
        return (
            <div className=' justify-center items-center flex h-[80vh]'>
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#F37A20"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </div>

        )
    }*/

    return (
        <div className="appboard-grid">
            <div className="appboard-sub-grid pt-4">

                <div className="main-top-banner">
                    <div className="main-title">
                        <h1>Project<span> Invoices</span></h1>
                    </div>
                    {role !== "client" ? (
                        <div className="main-btn-txt">
                            <button className="btn-banner" onClick={handleClickOpen}>Create New Invoice</button>
                        </div>
                    ) : null}
                </div>

                <div className={`${role === "client" ? "meeting_box" : ""}`}>
                    <div className="meeeting_item">
                        <div className="">
                            <InvoiceTable data={invoices} detailed={true}/>
                        </div>
                        <div className="pt-[23px]">
                            <div className="invoice-grid-table">
                                <InvoiceHistory pid={params.pid} refresher={Refresher} data={invoices} loader={loading}/>
                            </div>
                        </div>
                    </div>
                    <div className="meeeting_item">
                        {role === 'client' ?
                            <div className="invoice-grid-chart">
                                <div className="invoice-grid-sub-section pb-[30px]">
                                    <UserRewards/>
                                    <div className="invoice-grid-sub-section-history">
                                        <div className="invoice-grid-sub-section-box">
                                            <p className="invoice-grid-sub-section-box-title ">${data?.nextInvoice?.amount || 0}</p>
                                            <p className="invoice-grid-sub-section-box-desc ">
                                                Next Invoice
                                            </p>
                                            <p className="invoice-grid-sub-section-box-date ">
                                                Due
                                                Date: {data?.nextInvoice?.due_date ? new Date(data.nextInvoice.due_date).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit'
                                            }) : 'No Date'}
                                            </p>
                                        </div>
                                        <div className="invoice-grid-sub-section-box ">
                                            <p className="invoice-grid-sub-section-box-title">${data?.previousInvoice?.amount || 0}</p>
                                            <p className="invoice-grid-sub-section-box-desc">
                                                Previous Invoice
                                            </p>
                                            <p className="invoice-grid-sub-section-box-date">
                                                Pay
                                                Date: {data?.previousInvoice?.pay_date ? new Date(data.previousInvoice.pay_date).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit'
                                            }) : 'No Date'}
                                            </p>
                                            <p className="invoice-grid-sub-section-box-date">
                                                Status: <span
                                                style={{color: data.status === 0 ? 'red' : 'green'}}>{data.status === 0 ? 'Unpaid' : 'Paid'}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-[30px]">
                                        <div className="w-[230px] sm:w-[60%] xm:w-[60%] m-auto">
                                            <Progress
                                                value={(data?.paid_invoice_sum / (data?.paid_invoice_sum + data?.unpaid_invoice_sum)) * 100}
                                                isPercentage={false}
                                                text={intToString(data?.paid_invoice_sum + data?.unpaid_invoice_sum)}
                                                supportingText="in total"/>
                                        </div>
                                        <div className="flex mt-[20px] sm:mt-[10px] xm:mt-[8px] justify-center gap-[50px]">
                                            <div className="">
                                                <p className="text-center text-[#7CAB0E] lg:text-[16px] xm:text[10px] md:text-[14px] sm:text-[12px] ">Paid</p>
                                                <p className="text-center font-extrabold lg:text-[20px] xm:text[14px] md:text-[18px] sm:text-[16px]">${intToString(data?.paid_invoice_sum || 0)}</p>
                                            </div>
                                            <div className="">
                                                <p className="text-center text-[#F5822C] lg:text-[16px] xm:text[10px] md:text-[14px] sm:text-[12px]">Remaining</p>
                                                <p className="text-center font-extrabold lg:text-[20px] xm:text[14px] md:text-[18px] sm:text-[16px]">${intToString(data?.unpaid_invoice_sum || 0)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Create New Invoice"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit} className='space-y-6 '>
                        {/*<div
                            className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                            <FormControl fullWidth>
                                <FormLabel id="demo-row-radio-buttons-group-label">Project Type</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={(e) => setProjectState(e.target.value)}
                                >
                                    <FormControlLabel value="manual" control={<Radio/>} label="Manual"/>
                                    <FormControlLabel value="asana" control={<Radio/>} label="Asana"/>
                                </RadioGroup>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Project</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    name="project"
                                    label="Project"
                                    id="project"
                                    value={formik.values.project}
                                    onChange={formik.handleChange}
                                    error={formik.touched.project && Boolean(formik.errors.project)}
                                    helperText={formik.touched.project && formik.errors.project}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    {projectState === 'asana' ? table?.map((item, index) => {
                                            return (

                                                <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                            )
                                        }) :
                                        manualProjects?.map((item, index) => {
                                            return (

                                                <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>*/}
                        {/*<div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Project Category</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    name="project_type"
                                    label="Project Category"
                                    id="project_type"
                                    value={formik.values.project_type}
                                    onChange={formik.handleChange}
                                    //error={formik.touched.project_type && Boolean(formik.errors.project_type)}
                                    //helperText={formik.touched.project_type && formik.errors.project_type}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    {projectType?.map((item, index) => {
                                        return (

                                            <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Client</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    name="client"
                                    label="Client"
                                    id="client"
                                    value={formik.values.client}
                                    onChange={formik.handleChange}
                                    error={formik.touched.client && Boolean(formik.errors.client)}
                                    helperText={formik.touched.client && formik.errors.client}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    {client?.map((item, index) => {
                                        return (

                                            <MenuItem key={index}
                                                      value={`${item.first_name} ${item.last_name}`}>{item.first_name} {item.last_name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>

                        </div>*/}
                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>

                            <FormControl className="custom-input" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                        <DatePicker
                                            fullWidth
                                            className='w-[100%]'
                                            name="pay_date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="pay_date"
                                            inputFormat="E MMM dd yyyy HH:MM:SS O"
                                            label="Pay Date"
                                            value={formik.values.pay_date}
                                            onChange={value => formik.setFieldValue('pay_date', value)}
                                            error={formik.touched.pay_date && Boolean(formik.errors.pay_date)}


                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                            <FormControl className="custom-input" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                        <DatePicker
                                            fullWidth
                                            className='w-[100%]'
                                            name="due_date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="due_date"
                                            inputFormat="E MMM dd yyyy HH:MM:SS O"
                                            label="Due Date"
                                            value={formik.values.due_date}
                                            onChange={value => formik.setFieldValue('due_date', value)}
                                            error={formik.touched.due_date && Boolean(formik.errors.due_date)}


                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>


                        </div>
                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <TextField
                                className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                id="amount"
                                name="amount"
                                label="Amount"
                                value={formik.values.amount}
                                onChange={formik.handleChange}
                                error={formik.touched.amount && Boolean(formik.errors.amount)}
                                helperText={formik.touched.amount && formik.errors.amount}
                            />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    name="status"
                                    label="Status"
                                    id="status"
                                    value={formik.values.status}
                                    onChange={formik.handleChange}
                                    error={formik.touched.status && Boolean(formik.errors.status)}
                                    helperText={formik.touched.status && formik.errors.status}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    <MenuItem value={1}>Paid</MenuItem>
                                    <MenuItem value={0}>Unpaid</MenuItem>
                                </Select>
                            </FormControl>

                        </div>
                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <TextField
                                className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                id="link"
                                name="link"
                                label="Invoice Link"
                                value={formik.values.link}
                                onChange={formik.handleChange}
                                error={formik.touched.link && Boolean(formik.errors.link)}
                                helperText={formik.touched.link && formik.errors.link}
                            />
                        </div>

                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <div className="file-upload-container">
                                <input
                                    type="file"
                                    onChange={handleFileInput}/>
                            </div>
                        </div>
                        <div className='flex justify-end mt-3'>
                            <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white'
                                    variant="contained" fullWidth type="submit">
                                Save
                            </button>
                        </div>
                    </form>
                </DialogContent>

            </Dialog>
        </div>
    );
}

/*const ProjectInvoices = () => {
    const { invoices, user, getAllInvoice, benefits, loading, setInvoices } = useStateContext();
    const { role } = user.user;


    const totalPaid = useMemo(() => calculateTotal(invoices, 1), [invoices])
    const totalUnPaid = useMemo(() => calculateTotal(invoices, 0), [invoices])


    useEffect(() => {
        getAllInvoice();


        return () => {
            setInvoices([])
        }
    }, [])

    return (
        <div className="invoice-grid px-[30px] gap-[40px] xm:gap-[20px] " >
            <div className={` ${role !== 'client' ? 'invoice-grid-sub-full' : 'invoice-grid-sub'}   pt-4  `}>
                <div className="flex justify-between pb-[41px] md:pb-[30px] sm:pb-[20px]" >
                    <h1 className=" meeting-text">
                        Invoice{" "}
                        <span style={{ fontWeight: "700" }} className="text-[#F37A20]">
                            Board
                        </span>
                    </h1>


                </div>
                <div className=" ">
                    <div className="invoice-grid-table">
                        <InvoiceTable data={invoices} loading={loading} />
                    </div>
                </div>
            </div>
            {role === 'client' ?
                <div className="invoice-grid-chart pt-[30px]" style={{}}>
                    <div className="invoice-grid-sub-section">
                        <div className="" >
                            <UserRewards />
                            <div className="w-[80%] m-auto">
                                <Progress
                                    value={(totalPaid / (totalPaid + totalUnPaid)) * 100}
                                    isPercentage={false}
                                    text={intToString(totalPaid + totalUnPaid)}
                                    supportingText="in total" />

                                <div className="flex mt-5 justify-center">
                                    <div className="w-[100%]">
                                        <p className="invoice-grid-sub-section-paid">Paid</p>
                                        <p className="invoice-grid-sub-section-value">${intToString(totalPaid)}</p>
                                    </div>
                                    <div className="w-[100%]">
                                        <p className="invoice-grid-sub-section-remaining">Remaining</p>
                                        <p className="invoice-grid-sub-section-remaining-value">${intToString(totalUnPaid)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}

        </div>
    );
};

const calculateTotal = (invoices, type) => {
    let total = 0;
    console.log("INVOICES",invoices)
    let filteredInvoices = invoices.filter(obj => obj.status === type)

    filteredInvoices.forEach((obj) => {
        total += obj.amount;
    })

    return total

}*/


export default ProjectInvoices;
