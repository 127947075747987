import React, { useEffect } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
// import googleIcon from '../data/google-meet-icon.png';
import googleIcon from '../../data/google_meet.svg';
import axios from 'axios'
import {convertToRequiredDateFormat} from '../../utils/Helper'
import { Link } from 'react-router-dom';

const InvoiceView = (props) => {
  return (

    <>
    <div className="main_style_box project-invoice">
      <div className="project_title">
        Invoice History
        {props.paidInvoice && props.paidInvoice.length !== 0 &&
        <Link to='/invoice-view'>
            See All
        </Link>
        }
      </div>
      {
        props.paidInvoice && props.paidInvoice.length !== 0 ? (
      <ul className='invoice_listing'>
        <li>
          <span className='title'>Invoice #{props.paidInvoice?.id}</span>
          <span className='content'>
            { props.paidInvoice?.link ? 
              (<a target='_blank'
                href={props.paidInvoice?.link}
                >
                View Invoice
              </a>) :  props.paidInvoice?.file_path ?
                      (<a target='_blank'
                          href={props.paidInvoice?.file_path}
                      >
                        View Invoice
                      </a>) : '-'  }

          </span>
        </li>
        <li className='amount'>
          <span className="title">Amount</span>
          <span className="content">${props.paidInvoice?.amount}</span>
        </li>
        <li className='date'>
          <span className="title date">Due Date</span>
          {/* <span className="content">{props.paidInvoice?.due_date}</span> */}
          <span className="content">{convertToRequiredDateFormat(props.paidInvoice?.due_date)}</span>
        </li>
        <li className='date'>
          <span className="title">Pay Date</span>
          {/* <span className="content">{props.paidInvoice?.pay_date}</span> */}
          <span className="content">{convertToRequiredDateFormat(props.paidInvoice?.pay_date)}</span>
        </li>
        <li className='status'>
          <span className='title'>Status</span>
          <span className={`${props.paidInvoice?.status === 0 ? 'Unpaid' : 'Paid'} content `}>{props.paidInvoice?.status === 0 ? 'Unpaid' : 'Paid'}</span>
        </li>
      </ul>
      ) : (
        <div className='no-records'>
            <p>No Data Exists</p>
        </div>
      )
      }
    </div>


    {/* <div className='relative whats-new' >
      <div className='flex justify-between items-center p-[30px] md:p-[20px] sm:p-[15px] xm:px-[30px] sm:px-[30px] xm:p-[10px]  pb-[17px] md:pb-[14px] sm:pb-[10px] xm:pb-[6px]' >
        <p className='text-[#131313] text-[20px] md:text-[18px] sm:text-[16px] xm:text-[14px] font-bold' > Invoice History </p>
        {props.paidInvoice && props.paidInvoice.length !== 0 && <Link to='/invoice-view' >
          <button className='text-[#718EBF] text-[12px] md:text-[10px] items-center flex  sm:text-[8px] xm:text-[7px]' >See All</button>
        </Link>}
      </div>
      {
        props.paidInvoice && props.paidInvoice.length !== 0 ? (
          <div className='pt-[23px] sm:pt-[20px] xm:pt-[15px] flex justify-between px-[30px] pb-[10px] md:pb-[5px]  sm:pb-[4px] xm:pb-[4px] ' >
            <div>
              <p className='text-[#2A303C] text-[13px] md:text-[11px]  sm:text-[9px] xm:text-[8px] font-semibold  ' >Invoice #{props.paidInvoice?.id}</p>
              
              { props.paidInvoice?.link ? 
              (<a target='_blank'
                href={props.paidInvoice?.link}
                >
                <p className='text-[#F47A1F] font-semibold text-[12px]  md:text-[10px]  sm:text-[8px] xm:text-[7px]  ' >View Invoice</p>
              </a>) : '' }
            </div>
            <div className='flex justify-around items-center gap-[30px]' >
              <div>
                <p className='text-[#2A303C] text-[13px]  md:text-[11px]  sm:text-[9px] xm:text-[8px] font-semibold '   >Amount</p>
                <p className='text-[#505887] text-[12px] md:text-[10px]  sm:text-[8px] xm:text-[7px] '   >${props.paidInvoice?.amount}</p>
              </div>
              <div className='border-r-1 border-[#EFF2F5] h-[80%]'></div>
              <div>
                <p className='text-[#2A303C] text-[13px] md:text-[11px]  sm:text-[9px] xm:text-[8px] font-semibold '>Due Date</p>
                <p className='text-[#505887] text-[12px] md:text-[10px]  sm:text-[8px] xm:text-[7px]'>{props.paidInvoice?.due_date}</p>
              </div>
              <div className='border-r-1 border-[#EFF2F5] h-[80%]'></div>
              <div>
                <p className='text-[#2A303C] text-[13px] md:text-[11px]  sm:text-[9px] xm:text-[8px] font-semibold '>Pay Date</p>
                <p className='text-[#505887] text-[12px] md:text-[10px]  sm:text-[8px] xm:text-[7px] '>{props.paidInvoice?.pay_date}</p>
              </div>
              <div className='border-r-1 border-[#EFF2F5] h-[80%]'></div>
              <div>
                <p className={` text-[13px] md:text-[11px]  sm:text-[9px] xm:text-[8px] font-semibold `}>Status</p>
                <p className={`${props.paidInvoice?.status === 0 ? 'text-red-500' : 'text-[#069952]'} text-[12px] md:text-[10px]  sm:text-[8px] xm:text-[7px] `}>{props.paidInvoice?.status === 0 ? 'Unpaid' : 'Paid'}</p>
              </div>


            </div>
          </div>
        ) : (
            <div className='no-records'>
                <p>No Data Exists</p>
            </div>
  
        )
      }

    </div> */}
    </>
  )
}

export default InvoiceView;