import React,{useState} from 'react';
import {  useFormik } from 'formik';
import * as yup from 'yup';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {TfiClose} from 'react-icons/tfi';
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import {TextField,MenuItem,InputLabel,FormControl ,Select} from '@material-ui/core';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Chip from '@mui/material/Chip';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

const validationSchema = yup.object({
    name: yup
      .string()
      .min(6,'Too Short!')
      .max(70,'Too Long!')
      .required('Project Name is required'),
    category: yup
      .string()
      .required('Project Category is required'),
      phase: yup
      .string()
      .required('Project Phase is required'),
      assignee: yup
      .string()
      .required('Assign Business Developer is required'),
      client: yup
      .string()
      .required('Project Client is required'),
      deadline: yup
      .date()
      .required('Project Deadline is required'),
      employee: yup
      .array()
      .min(1, 'Minimum of 1 Employee')
      .required('Employees are required'),
  });

const validationSchemaMilestone = yup.object({
    title: yup
      .string()
      .min(6,'Too Short!')
      .max(70,'Too Long!')
      .required('Title is required'),
      start_date: yup
      .date()
      .required('Start Date is required'),
      end_date: yup
      .date()
      .required('End Date is required'),
      desc: yup
      .string()
      .min(6,'Too Short!')
      .max(255,'Too Long!')
      .required('Description is required'),
})
  

const EditProject=()=>{
    const[values,setValues] = useState({});
    const[list,setList] = useState([]);
    
    const formik = useFormik({
        initialValues: {
          name: '',
          category: '',
          phase:'',
          assignee:'',
          client:'',
          deadline: null,
          employee:[],
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
        if(list.length!==0){
        const newValues = { ...values, Timeline: list };
          setValues(newValues);
        } else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Atleast One Milestone is Required!',
                footer: '<a href="">Why do I have this issue?</a>'
              })
        }
          
        },
      });

      const formikMilestone = useFormik({
        initialValues: {
          title: '',
          start_date:null,
          end_date:null,
          desc:''
          
        },
        validationSchema: validationSchemaMilestone,
        onSubmit: (values,{resetForm}) => {
            const formattedStartDate = values.start_date.$d.toLocaleDateString()
            const formattedEndDate = values.end_date.$d.toLocaleDateString()
            setList([...list,{...values,start_date:formattedStartDate,end_date:formattedEndDate}]);
            resetForm({values:''})
        },
      });

      list.map((item)=>{
        console.log(item);
      })

      const handleDelete = (e, value) => {
    e.preventDefault();
    const updatedEmployees = formik.values.employee.filter(e => e !== value);
    formik.setFieldValue('employee', updatedEmployees);
    };
    return(
        <div className='w-[80%] h-full mx-auto bg-white p-8 ' >
             <form onSubmit={formik.handleSubmit}>
             <div className='flex justify-end gap-4 pb-6' >
                <Link to="/appboard" >
            <button className='bg-red-500 w-[100px]  rounded-md text-[16px] p-3 text-white'  variant="contained" fullWidth>
          Cancel
        </button>
        </Link>
        <button className='bg-[#F37A20] w-[100px] rounded-md text-[16px] p-3 text-white'   variant="contained" fullWidth type="submit">
          Save
        </button>
        </div>
                <div className='space-y-10' >
                <div className='flex gap-16 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
        <TextField
          fullWidth
          id="name"
          name="name"
          label="Project Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Project Category</InputLabel>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          name="category"
          label="category"
          id="category"
          value={formik.values.category}
          onChange={formik.handleChange}
          error={formik.touched.category && Boolean(formik.errors.category)}
          helperText={formik.touched.category && formik.errors.category}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
        >
            <MenuItem value="Mobile App">Mobile App</MenuItem>
            <MenuItem value="Web App">Web App</MenuItem>
            <MenuItem value="Marketing">Marketing</MenuItem>
            </Select>
            </FormControl>
            </div>
            <div className='flex gap-16 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
            <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Project Phase</InputLabel>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          name="phase"
          label="phase"
          id="phase"
          value={formik.values.phase}
          onChange={formik.handleChange}
          error={formik.touched.phase && Boolean(formik.errors.phase)}
          helperText={formik.touched.phase && formik.errors.phase}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
        >
            <MenuItem value="Mobile App">Mobile App</MenuItem>
            <MenuItem value="Web App">Web App</MenuItem>
            <MenuItem value="Marketing">Marketing</MenuItem>
            </Select>
            </FormControl>
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Assign Business Developer</InputLabel>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          name="assignee"
          label="assignee"
          id="assignee"
          value={formik.values.assignee}
          onChange={formik.handleChange}
          error={formik.touched.assignee && Boolean(formik.errors.assignee)}
          helperText={formik.touched.assignee && formik.errors.assignee}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
        >
            <MenuItem value="Mobile App">Mobile App</MenuItem>
            <MenuItem value="Web App">Web App</MenuItem>
            <MenuItem value="Marketing">Marketing</MenuItem>
            </Select>
            </FormControl>
            </div>
            <div className='flex gap-16 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
            <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select Client</InputLabel>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          name="client"
          label="client"
          id="client"
          value={formik.values.client}
          onChange={formik.handleChange}
          error={formik.touched.client && Boolean(formik.errors.client)}
          helperText={formik.touched.client && formik.errors.client}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
        >
            <MenuItem value="Mobile App">Mobile App</MenuItem>
            <MenuItem value="Web App">Web App</MenuItem>
            <MenuItem value="Marketing">Marketing</MenuItem>
            </Select>
            </FormControl>
        <FormControl fullWidth>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker', 'DatePicker']}>
        <DatePicker 
          fullWidth
          className='w-[100%]'
          name="deadline"
          InputLabelProps={{
            shrink: true,
          }}
          id="deadline"
          label="Select Deadline"
          value={formik.values.deadline}
          onChange={value=>
            formik.setFieldValue('deadline',value)
        }
          error={formik.touched.deadline && Boolean(formik.errors.deadline)}
          
          
        />
        </DemoContainer>
        </LocalizationProvider>
            </FormControl>
            </div>
            <div className='flex gap-16 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
            <FormControl fullWidth>
        <InputLabel id="demo-multiple-chip-label">Assign Employees</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="employee"
          name="employee"
          label="Assign Employees"
          error={formik.touched.employee && Boolean(formik.errors.employee)}
          multiple
          value={formik.values.employee}
          onChange={formik.handleChange}
          renderValue={(selected) => (
            <div className='space-x-4'   >
              {(selected).map((value) => (
                <Chip
                  key={value}
                  label={value}
                  clickable
                  deleteIcon={
                    <TfiClose color="black" size={12}
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  onDelete={(e) => handleDelete(e, value)}
                  onClick={() => console.log("clicked chip")}
                />
              ))}
            </div>
          )}
        >
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={formik.values.employee.includes(name)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
            </FormControl>
            </div>
            
           
        </div>
      </form>
      <div className='border border-gray-300 rounded-md p-3 mt-10' >
            <p className='text-[#F37A20] text-[24px] font-semibold pb-4 ' >Add Milestone</p>

            <form onSubmit={formikMilestone.handleSubmit}>
            <div className='flex gap-16 p-2 xm:inline xm:space-y-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8 ' >
            <TextField
          fullWidth
          id="title"
          name="title"
          label="Title"
          value={formikMilestone.values.title}
          onChange={formikMilestone.handleChange}
          error={formikMilestone.touched.title && Boolean(formikMilestone.errors.title)}
          helperText={formikMilestone.touched.title && formikMilestone.errors.title}
        />
        <FormControl fullWidth>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker', 'DatePicker']}>
        <DatePicker 
          fullWidth
          className='w-[100%]'
          name="start_date"
          InputLabelProps={{
            shrink: true,
          }}
          id="start_date"
          inputFormat="E MMM dd yyyy HH:MM:SS O"
          label="Start Date"
          value={formikMilestone.values.start_date}
          onChange={value=>formikMilestone.setFieldValue('start_date',value)}
          error={formikMilestone.touched.start_date && Boolean(formikMilestone.errors.start_date)}
          
          
        />
        </DemoContainer>
        </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker', 'DatePicker']}>
      <DatePicker 
          fullWidth
          className='w-[100%]'
          name="end_date"
          InputLabelProps={{
            shrink: true,
          }}
          id="end_date"
          label="End Date"
          value={formikMilestone.values.end_date}
          onChange={value=>formikMilestone.setFieldValue('end_date',value)}
          error={formikMilestone.touched.end_date && Boolean(formikMilestone.errors.end_date)}
          
          
        />
        </DemoContainer>
        </LocalizationProvider>
            </FormControl>
        </div>
        <div className='p-2' >
        <TextField
          fullWidth
          id="desc"
          name="desc"
          label="Description"
          value={formikMilestone.values.desc}
          onChange={formikMilestone.handleChange}
          error={formikMilestone.touched.desc && Boolean(formikMilestone.errors.desc)}
          helperText={formikMilestone.touched.desc && formikMilestone.errors.desc}
        />
        </div>
        <div className='flex justify-end pt-6 ' >
        <button className='bg-[#F37A20] w-[80px] rounded-md text-[16px] p-3 text-white'   variant="contained" fullWidth type="submit">
          Add
        </button>
        </div>
            </form>
            </div>
            <div  className=' mb-[20px]  mt-10 ' >
            <div className='grid grid-cols-4 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 xm:grid-cols-2  tab:grid-cols-1 mobile:grid-cols-1 small:grid-cols-1 gap-5   ' >
               {list.length !==0 ? list.map((item,index)=>{
                return(
                    <div key={index}  className='bg-white border-1 cursor-pointer   border-[#F37A20] rounded-md' >
                <div className='flex' >
                
                <div className='p-4' >
                    <p className='text-[#505887]  text-[24px] lg:text-[28px] md:text-[24px] font-semibold ' >{item.title}</p>
                    <p className='text-[#505887]  text-[18px] lg:text-[18px] md:text-[18px] font-semibold ' >~ {item.start_date} - {item.end_date}</p>
                    <p className='text-[#505887]  text-[16px] lg:text-[16px] md:text-[16px] font-semibold ' >{item.desc}</p>
                    </div>
                    
            </div>
                </div>

                )
               }):null}
               </div>
               </div>
        </div>
    )
}

export default EditProject;