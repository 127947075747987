import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { BsFillFilePdfFill, BsFileTextFill, BsFillPlayBtnFill, BsFileZipFill } from 'react-icons/bs'
import { Oval } from 'react-loader-spinner'


import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import PDFIMG from "../../img/files/pdf.svg";
import JPGIMG from "../../img/files/jpg.svg";
import DOCIMG from "../../img/files/doc.svg";
import XLSIMG from "../../img/files/xls.svg";

import axiosInstance from '../../axios/axiosInstance';
import FileViewer from "../../components/FileViewer";


const AttachmentCard = ({ attachments }) => {
    useEffect(() => {
        console.log('=====', attachments);
    }, []);

    function bytesToMB(bytes) {
        if (bytes === 0) return '0 MB';

        const megabytes = bytes / (1024 * 1024);
        return megabytes.toFixed(2) + ' MB';
    }

    return (
        <div className="project_files">
            {attachments?.map((attachment, index) => {
                const fileExtension = attachment.name?.substring(attachment.name.lastIndexOf('.') + 1)?.toLowerCase();
                let icon;

                if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'gif') {
                    icon = (
                        // <div className='img_file'>
                        //     <img
                        //         src={attachment.view_url} alt={attachment.name}
                        //     />
                        // </div>
                        <img src={JPGIMG} alt="JPGIMG" />

                    );
                } else if (fileExtension === 'pdf') {
                    icon = (
                        // <div className='img_file'>
                        //     <BsFillFilePdfFill size={35} color='red'/>
                        // </div>
                        <img src={PDFIMG} alt="PDFIMG" />
                    );
                }
                else if (fileExtension === 'doc' || fileExtension === 'docx') {
                    icon = (
                        // <div className='img_file'>
                        //     <BsFillFilePdfFill size={35} color='red'/>
                        // </div>
                        <img src={DOCIMG} alt="DOCIMG" />
                    );
                }
                // else if (fileExtension === 'mp4') {
                //     icon = (
                //         <div className='img_file'>
                //             <BsFillPlayBtnFill size={35} color='gray'/>
                //         </div>
                //     );
                // } else if (fileExtension === 'zip') {
                //     icon = (
                //         <div className='img_file'>
                //             <BsFileZipFill size={35} color='gray'/>
                //         </div>
                //     );
                // } 
                else {
                    icon = (
                        // <div className='img_file'>
                        //     <BsFileTextFill size={35} color='blue'/>
                        // </div>
                        <img src={PDFIMG} alt="PDFIMG" />
                    );
                }

                return (

                    <div key={index} className="project_file_list">
                        <FileViewer icon={icon} src={attachment?.download_url} name={attachment?.name && (attachment.name.length > 50 ? `${attachment.name.substring(0, 50)}...` : attachment.name)}/>
                        {/*<div className="file_icon">*/}
                        {/*    {icon}*/}
                        {/*</div>*/}
                        {/*<div className="project_files_txt">*/}
                        {/*    <ul className="top_content">*/}
                        {/*        <li>{attachment?.name && (attachment.name.length > 50 ? `${attachment.name.substring(0, 50)}...` : attachment.name)}*/}
                        {/*            <span>{attachment?.created_at}</span></li>*/}
                        {/*        <li>{attachment?.size ? bytesToMB(attachment.size) : ''} <span><a*/}
                        {/*            href={attachment?.download_url} target="_blank" download>Download</a></span></li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                    </div>

                );
            })}


        </div>
    );
};

const Files = () => {
    const { user, selectedProject } = useStateContext();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [nextPage, setNextPage] = useState("");
    const [offset, setOffset] = useState("");

    useEffect(() => {
        if (offset != nextPage) {
            setData([]);
        }
        getAttachments();
    }, [selectedProject, offset]);

    const getAttachments = () => {
        setLoading(true);
        if (user) {

            axiosInstance.get(`get-asana-files/${selectedProject}?offset=${offset}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    setNextPage(res?.data?.next_page);
                    // Append new data to existing data
                    setData(prevData => [...prevData, ...res.data?.data]);
                })
                .catch(err => {
                    if (err?.response?.status === 401) {
                        localStorage.removeItem('tekrevol_user');
                        window.location.href = '/signin';
                    } else {
                        console.log(err);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const loadMore = () => {

        console.log(nextPage);
        if (nextPage) {
            setOffset(nextPage);
        }
    };

    return (
        <>
            <div className="main_top_title">
                <div className="main_title"><h1>Files <span>View</span></h1></div>
                <div className="main_btn_txt">
                    {/* <ul>
                        <li>
                            <NavLink to="">
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="">
                                Appboard
                            </NavLink>
                        </li>
                        <li className="active">
                            <NavLink to="" >
                                Files
                            </NavLink>
                        </li>
                    </ul> */}
                </div>
            </div>
            <div className="main_content_container">
                <Container fluid className="p-0">
                    <Row>
                        <Col xxl={10} xl={12} lg={12}>

                            {
                                !loading && data.length <= 0 &&
                                <div className=" bg-[white] pt-[20px] pl-[31px] pb-[23px] h-[100%] xm:pb-[15px] xm:pt-[15px]">
                                    No attachment exist
                                </div>
                            }
                            {
                                loading ?
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }} className='mt-5'>
                                        <Oval
                                            visible={true}
                                            height="20"
                                            width="20"
                                            color="#f37a20"
                                            ariaLabel="oval-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                        />
                                        <small>Please wait while the content is loading</small>
                                    </div> :
                                    data && data.length > 0 && <AttachmentCard attachments={data} />
                            }

                        </Col>
                    </Row>
                </Container>
            </div>
            {
                nextPage && <div className="appboard_grid_project_button flex justify-center p-5"
                    onClick={loadMore}>
                    <button>Load More</button>
                </div>
            }
        </>
    );
}

export default Files;