import React, { useState, useEffect } from 'react'
import { useStateContext } from '../../contexts/ContextProvider';
import axios from 'axios';
import Swal from 'sweetalert2';
import { MenuItem, InputLabel, FormControl, Select } from '@material-ui/core';
import { Oval } from 'react-loader-spinner'
import { Loader, NoRecordFound, Toast } from '../General';
import { Table } from "react-bootstrap";

const ActivityRewardTable = ({ item }) => {
    const { activeMenu, user, project, selectedProject } = useStateContext();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleClaim = async (value, id) => {

        const data = {
            "status": value,
        }

        await axios.put(`${process.env.REACT_APP_BASE_URL}activity-reward-claim/${id}`, { ...data, id: id }, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((response) => {

            if (response.status === 200) {
                getAllClaim();

                Toast.fire({
                    icon: 'success',
                    title: response?.data?.message || '',
                })
            }
        })
        .catch((error) => {

            Toast.fire({
                icon: 'success',
                title: error?.response?.data?.message || 'Something went wrong!',
            })
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const getAllClaim = (loader = false) => {
        setLoading(loader);

        axios.get(`${process.env.REACT_APP_BASE_URL}activity-reward-claim?client_id=${item.id}&project_id=${selectedProject}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((response) => {

            setUsers(response.data.data);
            setLoading(false)
        })
        .catch((err) => {
            console.log('err====', err);
        })
        .finally(() => {
            setLoading(false);
        })
        
    }
    useEffect(() => {
        getAllClaim(true);
    }, [selectedProject]);


    if (loading) {
        return <Loader />;
    }

    return (
        <div className="download_tabs">
            <Table responsive>
                <thead>
                    <tr>

                        <th className="text-center">Client Name</th>
                        <th className="text-center">Client Email</th>
                        <th className="text-center">Total Points</th>
                        <th className="text-center">Reward Name</th>
                        <th className="text-center">Reward Points</th>
                        <th className="text-center">Screenshot</th>
                        <th className="text-center">Link</th>
                        <th className="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>

                    {users && users.length > 0 ?
                        users.map((item, index) => {

                            return (

                                <tr key={index}>

                                    <td className='text-center'>{item?.client?.first_name} {item.client?.last_name}</td>
                                    <td className='text-center'>{item?.client?.email}</td>
                                    <td className='text-center'>{item?.client?.points}</td>
                                    <td className='text-center'>{item?.activity_reward?.title}</td>
                                    <td className='text-center'>{item?.points}</td>

                                    <td className='text-center d-flex justify-content-center'>
                                        {item?.file_path ? (
                                            <div style={{ width: "50px" }}>
                                                <a download={item?.file_path} href={item?.file_path}
                                                    target="_blank">
                                                    <img src={item?.file_path} alt="-"
                                                        className="border border-gray-300 rounded p-1 text-[#505887] text-[14px] lg:text-[12px] md:text-[12px] sm:text-[11px] xm:text-[10px] font-medium"
                                                        style={{ width: "100%", height: "auto" }} />
                                                </a>
                                            </div>
                                        ) : (
                                            'N/A'
                                        )}
                                    </td>
                                    <td className='text-center'>
                                        {
                                            item?.link ? <a href={item?.link}
                                                target="_blank">View</a> : 'N/A'
                                        }
                                    </td>

                                    <td className='text-center'>
                                        <FormControl fullWidth>
                                            {/* <InputLabel id="demo-simple-select-label"></InputLabel> */}
                                            <Select
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                name="Request"
                                                label="Request"
                                                id="Request"
                                                disabled={item.status === 1 ? true : false}
                                                displayEmpty
                                                value={item.status}
                                                key={item.id}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                onChange={(e) => handleClaim(e.target.value, item.id)}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "left"
                                                    },
                                                    getContentAnchorEl: null
                                                }}

                                            >
                                                <MenuItem value={0}>Claimed</MenuItem>
                                                <MenuItem value={1}>Approved</MenuItem>
                                                <MenuItem value={2}>Reject</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </td>

                                </tr>

                            )
                        }) : <NoRecordFound />

                    }


                </tbody>
            </Table>

        </div>
    )
}

export default ActivityRewardTable;
