import React, { useState, useEffect } from 'react';
import { BsFillFlagFill } from 'react-icons/bs'
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios'
import { Oval } from 'react-loader-spinner'
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import { Formik, Form, Field } from 'formik';
import * as yup from "yup";
import Slide from "@mui/material/Slide";

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { Loader } from './General';
import { Col } from 'react-bootstrap';
// import { CheckboxIcon } from '../app-constants';
import CheckboxIcon from "../img/check_circle.svg"


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    title: yup.string().required("Meeting Time is required"),
});

const BarChart = (props) => {

    const { user, sessionProject, table, manualProjects, setProject, project, getDashboard, selectedProject } = useStateContext();


    const [progress, setProgress] = useState([])
    const [projectProgress, setProjectProgress] = useState([])

    const [loading, setLoading] = useState(true)
    const [emptyProgress, setEmptyProgress] = useState([
        { title: 'Scope & Design', status: 0 },
        { title: 'Alpha Development', status: 0 },
        { title: 'Beta Development', status: 0 },
        { title: 'UAT', status: 0 },
        { title: 'Deployment', status: 0 },
    ])
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState(false);

    const [initialValues, setInitialValues] = useState({
        scopeAndDesign: 0,
        alphaDevelopment: 0,
        betaDevelopment: 0,
        uat: 0,
        deployment: 0,
    });

    useEffect(() => {
        if (props.projectProgress) {
            setInitialValues({
                scopeAndDesign: parseInt(props?.projectProgress[0]?.total_status),
                alphaDevelopment: parseInt(props?.projectProgress[1]?.total_status),
                betaDevelopment: parseInt(props?.projectProgress[2]?.total_status),
                uat: parseInt(props?.projectProgress[3]?.total_status),
                deployment: parseInt(props?.projectProgress[4]?.total_status)
            })
            setLoading(false);
        }
    }, [props.projectProgress, project]);

    const initialData = {
        scopeAndDesign: 1,
        alphaDevelopment: 2,
        betaDevelopment: 3,
        uat: 4,
        deployment: 5,
    };

    const marks = [
        { value: 0, label: '0%' },
        { value: 25, label: '25%' },
        { value: 50, label: '50%' },
        { value: 75, label: '75%' },
        { value: 100, label: '100%' },
    ];


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            handleClose();
            //createMeeting();
            resetForm({ values: "" });
        },
    });

    const getProgress = (pid, project_type) => {
        if (user && pid) {
            setLoading(true)
            let url;
            if (project_type === 'asana_project_id') {
                url = `${process.env.REACT_APP_BASE_URL}project-progress-activity?asana_project_id=${pid}&sortBy=status&client_id=${user.user.id}`
            } else {
                url = `${process.env.REACT_APP_BASE_URL}project-progress-activity?manual_project_id=${pid}&sortBy=status&client_id=${user.user.id}`
            }

            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {
                setLoading(false)
                setProgress(response.data.data);
            }).catch((err) => {
                setLoading(false)
                console.log('err====', err);
            })
        }

    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        const data = Object.entries(values).map(([key, value]) => ({
            manual_project_id: parseInt(sessionProject),
            project_progress_id: initialData[key],
            project_type: 1,
            status: value
        }));

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}update_project_progress/${parseInt(sessionProject)}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`
                    }
                }
            );
            if (response.status === 200) {
                handleClose();
                getDashboard(sessionProject);
                resetForm();
                Swal.fire({
                    icon: 'success',
                    title: 'Progress update successfully!',
                    showConfirmButton: false,
                    timer: 1000
                });
                setLoading(false);
                // console.log(res.data);
                //navigate('/user');
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                showConfirmButton: false,
                timer: 1000
            });
        }
    };


    // useEffect(() => {
    //     setLoading(true);
    //     let url = `${process.env.REACT_APP_BASE_URL}get-project-progress/${selectedProject}`;
    //     (async () => {
    //         let response = await axios.get(url, {
    //             headers: {
    //                 'Authorization': `Bearer ${user.token}`
    //             }
    //         });

    //         if (response && response.data) {

    //             setProjectProgress(response.data)

    //             setLoading(false);
    //         }

    //     })();
    // }, [project])


    useEffect(() => {
        const fetchProjectProgress = async () => {
            setLoading(true);
            let url = `${process.env.REACT_APP_BASE_URL}get-project-progress/${selectedProject}`;

            try {
                let response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    }
                });

                if (response?.data) {
                    setProjectProgress(response?.data?.data);
                }
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    localStorage.removeItem('tekrevol_user');
                    window.location.href = '/signin';
                } else {
                    console.log(err);
                }
            } finally {
                setLoading(false);
            }
        };

        // fetchProjectProgress();
    }, [selectedProject, user.token]);






    return (
        <>


                                        <div className='main_style_box project-progress'>
                                            <div className="project_title">Project Progress</div>
                                      
                                            <div className="project_txt">
                                                {
                                                    loading ? <Loader /> : (
                                                       
                                                            <ul>
                                                            {
                                                                props.projectProgress && props.projectProgress.length > 0 ?
                                                                    props.projectProgress.map((item, index) => {
                                                                        return (

                                                                            <li key={index} className="progress-inner">
                                                                               <div className={`perc-wrap ${item.percentage === '100.00' ? 'opacity-100' : 'opacity-50'}`}>
                                                                                    <div className="title-wrap">
                                                                                        <div className="title">{item?.name}</div>
                                                                                        <div className="content">
                                                                                        {item.percentage === '100.00' ? (
                                                                                            <>
                                                                                                <span className='d-flex gap-2'>Completed! <img src={CheckboxIcon} alt=""/></span>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {item.percentage}%
                                                                                            </>
                                                                                        )}


                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="perc" style={{ width: `${item?.percentage}%` }}></div>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    }) : 
                                                                    
                                                                    <div className='no-data'>No Data Exists</div>
                                                                    
                                                            }
                                                            </ul>
                                                      
                                                    )
                                                }
                                            </div>

                                        </div>

                                        






            {/* <div class="appboard-overview-project-main">
                <div class="appboard-grid-project-title">Project Progress</div>
                <div class="appboard-grid-project-txt">
                    {
                        loading ? <Loader /> : (
                            <div className='space-y-[25px]'>
                                {
                                    props.projectProgress && props.projectProgress.length > 0 ?
                                        props.projectProgress.map((item, index) => {
                                            return (
                                                <div key={index} className=' flex justify-between items-center'>
                                                    <div>
                                                        <p className='text-[#344054] text-[14px] md:text-[12px] sm:text-[10px] xm:text-[8px] font-semibold '>{item?.name}</p>
                                                    </div>
                                                    <div className='flex justify-between gap-[18px] items-center'>
                                                        <div className='w-[450px] md:w-[350px] sm:w-[300px] xm:w-[220px]'>
                                                            <div className="skill-box  flex items-center">

                                                                <div className="verticalskill-bar">
                                                                    <span className={`skill-per delay-500`} style={{ width: `${item?.percentage}%` }}>

                                                                    </span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='flex justify-around items-center w-[100px] gap-[9px]'>
                                                            <p className='text-[#505887] text-[16px] md:text-[14px] sm:text-[12px] xm:text-[10px] font-bold '>
                                                                {item.percentage}%
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : <div className='flex justify-center items-center p-4 bg-[white]'>
                                            <p className='text-center text-[13px] text-[#979797] font-bold'>No Data Exists</p>
                                        </div>
                                }
                            </div>
                        )
                    }
                </div>
            </div> */}

            <Dialog
                fullWidth={true}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Update Project Progress"}</DialogTitle>
                <DialogContent>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={handleSubmit}>
                        {(formik) => (
                            <Form className="space-y-6 custom-slider">
                                <div className="rounded-md text-[12px] p-3 text-black">
                                    <Typography id="scopeAndDesignLabel" gutterBottom>
                                        Scope & Design
                                    </Typography>
                                    <Field name="scopeAndDesign">
                                        {({ field }) => (
                                            <Slider
                                                {...field}
                                                valueLabelDisplay="auto"
                                                aria-labelledby="scopeAndDesignLabel"
                                                min={0}
                                                max={100}
                                                marks={marks}
                                            />
                                        )}
                                    </Field>
                                </div>

                                <div className="rounded-md text-[12px] p-3 text-black">
                                    <Typography id="alphaDevelopment" gutterBottom>
                                        Alpha Development
                                    </Typography>
                                    <Field name="alphaDevelopment">
                                        {({ field }) => (
                                            <Slider
                                                {...field}
                                                valueLabelDisplay="auto"
                                                aria-labelledby="alphaDevelopmentLabel"
                                                min={0}
                                                max={100}
                                                marks={marks}
                                            />
                                        )}
                                    </Field>
                                </div>

                                <div className="rounded-md text-[12px] p-3 text-black">
                                    <Typography id="betaDevelopment" gutterBottom>
                                        Beta Development
                                    </Typography>
                                    <Field name="betaDevelopment">
                                        {({ field }) => (
                                            <Slider
                                                {...field}
                                                valueLabelDisplay="auto"
                                                aria-labelledby="betaDevelopmentLabel"
                                                min={0}
                                                max={100}
                                                marks={marks}
                                            />
                                        )}
                                    </Field>
                                </div>

                                <div className="rounded-md text-[12px] p-3 text-black">
                                    <Typography id="uat" gutterBottom>
                                        UAT
                                    </Typography>
                                    <Field name="uat">
                                        {({ field }) => (
                                            <Slider
                                                {...field}
                                                valueLabelDisplay="auto"
                                                aria-labelledby="uatLabel"
                                                min={0}
                                                max={100}
                                                marks={marks}
                                            />
                                        )}
                                    </Field>
                                </div>

                                <div className="rounded-md text-[12px] p-3 text-black">
                                    <Typography id="deployment" gutterBottom>
                                        Deployment
                                    </Typography>
                                    <Field name="deployment">
                                        {({ field }) => (
                                            <Slider
                                                {...field}
                                                valueLabelDisplay="auto"
                                                aria-labelledby="deploymentLabel"
                                                min={0}
                                                max={100}
                                                marks={marks}
                                            />
                                        )}
                                    </Field>
                                </div>

                                {/* Repeat the above pattern for other fields */}

                                <div className="flex justify-end mt-3">
                                    <button
                                        className="bg-[#F37A20] w-[70px] rounded-md text-[12px] p-3 text-white"
                                        variant="contained"
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default BarChart;