import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField } from '@material-ui/core';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios'
import Swal from 'sweetalert2';
import { ThreeDots } from 'react-loader-spinner'
import { useStateContext } from '../../contexts/ContextProvider';


const validationSchema = yup.object({
  first_name: yup
    .string()
    .required('First Name is required')
    .max(20, "First name should be maximun of 20 characters")
    .min(6, "First name must be atleast of 6 characters"),
  last_name: yup
    .string()
    .required('Last Name is required')
    .max(20, "Last Name should be maximun of 20 characters")
    .min(6, "Last Name must be atleast of 6 characters"),
  email: yup
    .string().email("Invalid Email Format").required("Email is Required"),
  phone: yup
    .string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'Phone number is not valid'
    )
    .min(10, "too short")
    .max(14, "too long")
    .required("A phone number is required"),
  company: yup
    .string()
    .required('Company Name is required')
    .max(20, "Company Name should be maximun of 20 characters")
    .min(6, "Company Name must be atleast of 6 characters"),

});

const EditClient = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useStateContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { item } = location.state;

  const formik = useFormik({
    initialValues: {
      first_name: item.first_name,
      last_name: item.last_name,
      email: item.email,
      phone: item.phone,
      company: item.company_name,

    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      editUser()
      resetForm({ values: '' })
    },
  });

  const editUser = async () => {

    setLoading(true);
    const data = {
      "first_name": formik.values.first_name,
      "last_name": formik.values.last_name,
      "email": formik.values.email,
      "phone": formik.values.phone,
      "dob": ' ',
      "designation": null,
      "address": ' ',
      "company_name": formik.values.company,
      "points": 0
    }
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}update_user/${item.id}`, { ...data, id: item.id }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            Swal.fire(
              {
                icon: 'success',
                title: 'Client updated successfully!',
                showConfirmButton: false,
                timer: 1000
              }

            )

            // console.log(res.data);
            navigate('/management');
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1000
          })
        })
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  if (loading) {
    return (
      <div className=' justify-center items-center flex h-[80vh]' >
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#F37A20"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>

    )
  }

  return (
    <div className='w-[80%] mb-[240px] h-full mx-auto bg-white p-8 ' >
      <h1 className=" user-text">
        Edit{" "}
        <span style={{ fontWeight: "700" }} className="text-[#F37A20]">
          Client
        </span>
      </h1>
      <form onSubmit={formik.handleSubmit}>

        <div className='space-y-10' >
          <div className='flex gap-16 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
            <TextField
              fullWidth
              id="first_name"
              name="first_name"
              label="First Name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={formik.touched.first_name && Boolean(formik.errors.first_name)}
              helperText={formik.touched.first_name && formik.errors.first_name}
            />
            <TextField
              fullWidth
              id="last_name"
              name="last_name"
              label="Last Name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={formik.touched.last_name && Boolean(formik.errors.last_name)}
              helperText={formik.touched.last_name && formik.errors.last_name}
            />

          </div>
          <div className='flex gap-16 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              fullWidth
              id="phone"
              name="phone"
              label="Phone Number"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </div>
          <div className='flex gap-16 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
            <TextField
              fullWidth
              id="company"
              name="company"
              label="Company Name"
              value={formik.values.company}
              onChange={formik.handleChange}
              error={formik.touched.company && Boolean(formik.errors.company)}
              helperText={formik.touched.company && formik.errors.company}
            />

          </div>
          <div className='flex gap-16 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
            <TextField
              fullWidth
              type="password"
              id="password"
              name="password"
              label="Password"
              disabled
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <TextField
              fullWidth
              type="password"
              disabled
              id="confirm_password"
              name="confirm_password"
              label="Confirm Password"
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
              error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
              helperText={formik.touched.confirm_password && formik.errors.confirm_password}
            />
          </div>

          <div className='flex justify-end gap-4 pb-4' >
            <Link to="/management" >
              <button className='bg-red-500 w-[100px]  rounded-md text-[16px] p-3 text-white' variant="contained" fullWidth>
                Cancel
              </button>
            </Link>
            <button className='bg-[#F37A20] w-[100px] rounded-md text-[16px] p-3 text-white' variant="contained" fullWidth type="submit">
              Save
            </button>
          </div>
        </div>
      </form>

    </div>
  )
}

export default EditClient;