import React, {useEffect, useState} from 'react';
import RewardsSection from './RewardsSection';
import UserRewards from './UserRewards';
import {useStateContext} from "../../../contexts/ContextProvider"
import {useFormik} from 'formik';
import * as yup from 'yup';
import {Oval} from 'react-loader-spinner'
import {TextField} from '@material-ui/core';
import axios from "axios";
import Swal from 'sweetalert2';
import TekPoints from "../../../img/Loyalty.svg"
const validationSchema = yup.object({
    email: yup.string().required('email is required')
});

const Refferal = ({role, user}) => {
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, {resetForm}) => {
            if (values) {
                let payload = {
                    client_id: user.user.id,
                    email: values.email
                }
                submitForm(payload)
                resetForm({values: ''})
                handleClose()
            }
        },
    });

    const submitForm = async (payload) => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BASE_URL}referal`, payload, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    Swal.fire(
                        {
                            icon: 'success',
                            title: 'Invitation sent successfully!',
                            showConfirmButton: false,
                            timer: 1000
                        }
                    )
                }
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Error...',
                    text: error?.response?.data?.message || 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 5000
                })
            })
    }

    return (
        <div>
            {
                <div className="appboard-overview-project-main">
                    <div className="appboard-grid-project-title referral-title">
                        Refer Friends and Family Today!
                    </div>
                    <div className="appboard-grid-project-txt referral_input">
                        <form onSubmit={formik.handleSubmit}>
                            <TextField
                                id="email"
                                name="email"
                                label="Referral Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            {
                                loading ? <button><Oval
                                        visible={true}
                                        height="20"
                                        width="20"
                                        color="#ffffff"
                                        secondaryColor="#ffffff"
                                        ariaLabel="oval-loading"
                                        wrapperStyle={{disply: 'flex', justifyContent: 'center'}}
                                        wrapperClass=""
                                    /></button> :
                                    <button>Submit</button>
                            }
                        </form>
                    </div>
                </div>
            }
        </div>
    )
}

const Rewards = (props) => {
    const {project, user} = useStateContext();
    const {role} = user.user;
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(props?.benefitRewardList)
    }, [project])

    return (
        // <div className='col-span-3 pt-1 '>
        //     <div className=' relative rewards-div h-[100px]'>
        //         <div className={`reward-innerdiv w-[100%] pt-[18px] mt-[-10px]`}>
        //             {
        //                 role === 'client' && (
        //                     <UserRewards/>
        //                 )
        //             }
        //             <div className='relative h-[100%]'>
        //                 <div className=''>
        //                     <div className={` w-[100%] h-[100%]`}>
        //                         <RewardsSection data={data}/>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>

        //     </div>
        // </div>

        <div className="side_content">
            <div className="rewads_section">
                {
                    role === 'client' && (
                        <UserRewards/>
                    )
                }
            </div>
            <RewardsSection data={data}/>
            {/* <div className="reward_box">
                <div className="icons">
                    <img src={TekPoints} alt="TekPoints"/>
                </div>
                <div className="txt">Landing Page <span>50 More Points Remaining</span></div>
                <div className="points">50</div>
            </div>
            <div className="reward_box">
                <div className="icons">
                    <img src={TekPoints} alt="TekPoints"/>
                </div>
                <div className="txt">On Page <span>200 More Points Remaining</span></div>
                <div className="points">200</div>
            </div>
            <div className="reward_box">
                <div className="icons">
                    <img src={TekPoints} alt="TekPoints"/>
                </div>
                <div className="txt">ASO <span>250 More Points Remaining</span></div>
                <div className="points">250</div>
            </div>
            <div className="reward_box">
                <div className="icons">
                    <img src={TekPoints} alt="TekPoints"/>
                </div>
                <div className="txt">content strategy <span>400 More Points Remaining</span></div>
                <div className="points">400</div>
            </div> */}
            
        </div>                      


    )
}

export default Rewards;