import React, { useState, useEffect } from 'react';
import FilesWhatsNew from './FilesWhatsNew';
import imgCover from '../data/imgCover.png'
import axios from 'axios'
import { ThreeDots } from 'react-loader-spinner'
import { useStateContext } from '../contexts/ContextProvider';
import { niceBytes } from '../utils/ConvertBites'

const FilesTab = ({ gid }) => {

  const { asanaToken } = useStateContext();
  const [attachments, setAttachments] = useState([]);
  // const[loading,setLoading] = useState(false);

  const [imageUrl, setImageUrl] = useState('');

  const handleImageClick = (imageUrl) => {
    setImageUrl(imageUrl);
  };

  function ResolveAttachments(task) {
    console.log('resolve fnc', task);
    const ASANA_BEARER_TOKEN = "Bearer 2/1204614194882962/1206884941289659:6993e942413ab55624207ae4379afb7f"

    return new Promise(function (resolve, reject) {
      axios
        .get(
          `${process.env.REACT_APP_ASANA_BASE_URL}attachments?parent=${task.gid}&opt_fields=name,view_url,download_url,created_at,host,size`,
          {
            headers: {
              Authorization: ASANA_BEARER_TOKEN,
            },
          }
        ).then((response) => {
          // console.log(response.data.data);
          resolve(response.data.data)
        }).catch((e) => {
          reject(e);
        })
    })
  }

  const getAllAttachments = () => {
    // setLoading(true);
    const ASANA_BEARER_TOKEN = "Bearer 2/1204614194882962/1206884941289659:6993e942413ab55624207ae4379afb7f"

    axios
      .get(
        `${process.env.REACT_APP_ASANA_BASE_URL}projects/${gid}/tasks`,
        {
          headers: {
            Authorization: ASANA_BEARER_TOKEN,
          },
        }
      )
      .then((response) => {
        const tasks = response.data.data;
        // const allAttachments = [];

        // Loop through each task and retrieve its attachments

        Promise.all(tasks.map(item => ResolveAttachments(item)))
          .then((response) => {
            let filteredAttachments = response.filter((e) => e.length !== 0)
            // console.log('attachments=======',filteredAttachments);

            const flatAttachments = filteredAttachments.flat()
            console.log(flatAttachments);
            setAttachments(flatAttachments);
          }).catch((e) => {
            console.log(e);
          })
      })
      .catch((error) => {
        console.error(error);
        // setLoading(false);
      });

  }

  useEffect(() => {

    getAllAttachments();
  }, [gid]);

  // useEffect(()=>{

  //   console.log('attachmetns======',attachments);
  // },[attachments])

  // console.log(attachments);

  return (
    <div>
      {attachments.length > 0 ?
        <div className='grid grid-cols-3 gap-6' >
          <div className=' relative md:h-[320px] sm:h-[280px] xm:h-[260px] h-[353px] pt-4' >

            <FilesWhatsNew attachments={attachments} onImageClick={handleImageClick} />

          </div>
          <div className='col-span-2 pt-[53px]' >
            <div className='w-[737px] h-[353px] sm:w-[481px] sm:h-[280px] xm:w-[403px] xm:h-[260px] md:w-[551px] md:h-[330px]  rounded-xl' >
              {imageUrl !== '' ?
                <img src={imageUrl} alt={imageUrl} className='rounded-xl w-[737px] md:w-[551px] md:h-[330px] xm:w-[403px] xm:h-[260px] h-[353px] ' />
                :
                <img src={imgCover} alt='imgCover' className='rounded-xl w-[737px] h-[353px] md:w-[551px] md:h-[330px] xm:w-[403px] xm:h-[260px]  ' />
              }
            </div>
          </div>
        </div>
        :

        <div className=' justify-center items-center flex h-[40vh] w-[100%]' >
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#F37A20"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      }
    </div>
  )
}

export default FilesTab;