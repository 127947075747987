import React, { useEffect, useState } from 'react';
import Rewards from '../../components/Rewards';
import AppBoardTabs from '../Appboard/AppBoardTabs';
import OverviewTab from '../OverviewTab';
import { useStateContext } from '../../contexts/ContextProvider';
import OverviewResource from '../OverviewResources';
import WhatsNew from '../../components/WhatsNew'
import FilesTab from '../../components/FilesTab';
import BoardTab from '../Appboard/BoardTab';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import MessageTab from '../Messagetab';
import ProjectProgress from '../charts/ProjectProgress';
import { useParams } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner'
import axios from 'axios'

const ProjectOverview = () => {
  const { activeTab, user, handleTabClick, asanaToken } = useStateContext();
  const [project, setProject] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  console.log(asanaToken);
  const { role } = user.user;

  const handleDelete = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })

  }

  useEffect(() => {
    console.log("PROJECT", project)
  }, [project])

  useEffect(() => {
    let isMounted = true;
    const ASANA_BEARER_TOKEN = "Bearer 2/1204614194882962/1206884941289659:6993e942413ab55624207ae4379afb7f"
    const getProjectById = () => {
      // asana-project?client_id=31
      axios.get(`${process.env.REACT_APP_ASANA_BASE_URL}projects/${id}?opt_fields=archived,color,completed,created_at,current_status.author.name,current_status.author.resource_type,current_status.color,current_status.created_at,current_status.created_by,current_status.created_by.name,current_status.created_by.resource_type,current_status.modified_at,current_status.resource_type,current_status.text,current_status.title,current_status_update.resource_type,current_status_update.resource_subtype,current_status_update.title,due_on,due_date,followers.name,followers.resource_type,members.resource_type,members.name,members.photo,members.role,modified_at,name,notes,owner.name,owner.resource_type,permalink_url,resource_type,start_on`, {
        headers: {
          Authorization: ASANA_BEARER_TOKEN,
        }
      }).then((response) => {

        if (isMounted) {
          setProject(response.data.data);
        }

        // console.log(response.data.data);

      }).catch((err) => {
        // console.log('err====', err.response.status);
        if (err?.response?.status === 401) {
          navigate(-1);
        }
      })


    }
    handleTabClick(0);
    getProjectById();
    return () => {
      isMounted = false;
    };
  }, [id])

  // console.log(project);

  // const {timeline} = project
  // console.log(timeline[0].title);

  let start_date;
  let end_date;
  if (project?.created_at !== null) {
    start_date = new Date(project?.created_at);
    start_date = start_date.toLocaleDateString();
  }
  if (project?.due_date !== null) {
    console.log("COMPLETED", project)
    end_date = new Date(project?.due_date);
    end_date = end_date.toLocaleDateString();
  }

  return (
    <div className='appboard-grid px-[30px] py-4' >
      <div className='col-span-12' >

        {project === null ?
          <div className=' justify-center items-center flex h-[80vh]' >
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#F37A20"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div> :
          <>
            <div className="flex justify-between " >
              <h1 className=' meeting-text'><span style={{ fontWeight: '700' }} className='text-[#F37A20]' >
                {`${project?.name}`}</span></h1>

            </div>
            <div className='tabs pt-6 px-2'>
              <AppBoardTabs activeTab={activeTab} />
              {/* <div className={` mobile:hidden small:hidden tab:hidden  tab-indicator`} style={{ left: activeTab === 2 ? 'calc(180px)' : `calc(90px * ${activeTab})` }}></div>
              <hr className='border-1 border-[#EFF1F4]' /> */}
              <div className='tab-body'>
                <div className={`${activeTab === 0 ? 'active' : ''}`} >
                  <div className='flex justify-between gap-[25px] p-[25px] bg-white border-1 border-[#F4F5F8] rounded-[8px] ' >
                    <div className='border-r-1 flex-1 border-[#F5F6F9]' >
                      <div className={` flex justify-between pr-[30px] gap-[60px]`}>
                        <OverviewTab project={project} />
                        <OverviewResource project={project} />

                      </div>
                      <div className=' ml-[40px]' >
                        <FilesTab gid={project.gid} />
                      </div>
                    </div>
                    <div>
                      <div className=" bg-[white] border-1 border-[#EDF0F4] rounded-t-lg p-2 h-[232px] w-[246px]  md:h-[200px] md:w-[212px] sm:h-[180px] sm:w-[200px] xm:h-[180px] xm:w-[180px]">
                        <span className="text-[#F47A1F] text-[14px] md:text-[12px] sm:text-[10px] xm:text-[9px] text-left bg-[#FFF9F0] p-1 rounded-sm font-semibold">Project Progress</span>
                        <div className='m-auto flex flex-shrink-0 py-6 w-[60%] '>
                          <ProjectProgress projectData={project.gid} />
                          {/* <Progress value={0} /> */}
                        </div>
                      </div>
                      <div className='space-y-6 pt-[40px]  ' >
                        <div className='' >
                          <p className='text-[#505887] text-[13px] md:text-[11px] sm:text-[9px] xm:text-[8px] font-medium ' >Status
                            <br /><span className={` ${project.completed ? 'text-[#069952]' : 'text-red-500'} text-[12px] md:text-[10px] sm:text-[8px] xm:text-[7px] font-medium `} > {project.completed ? 'Completed' : 'In Progress'} </span></p>
                          {/* <div>
                        <img src={note} alt='note' />
                    </div> */}

                        </div>
                        {/* <div>
                    <p className='text-[13px] text-[#505887] font-medium  ' >Milestone Completed</p>
                    <p className='text-[12px] text-[#718EBF] font-medium  '>03</p>
                </div> */}
                        <div>
                          <p className='text-[13px] md:text-[11px] sm:text-[9px] xm:text-[8px] text-[#505887] font-medium  ' >Date Started</p>
                          <p className='text-[12px] md:text-[10px] sm:text-[8px] xm:text-[7px] text-[#718EBF] font-medium  '>{start_date ? start_date : '2022-11-24'} </p>
                        </div>
                        <div>
                          <p className='text-[13px] text-[#505887] font-medium  ' >Dead Line</p>
                          <p className='text-[12px] text-[#718EBF] font-medium  '>{end_date ? end_date : 'no date'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={activeTab === 1 ? 'active w-[100%] overflow-x-auto board-tab-scroll ' : ''}>
                  <BoardTab gid={project.gid} />
                </div>


              </div>
            </div>
          </>
        }

      </div>
    </div>
  )
}

export default ProjectOverview;
