import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField, MenuItem, InputLabel, FormControl, Select } from '@material-ui/core';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Link } from 'react-router-dom';

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

const validationSchema = yup.object({
  amount: yup
    .number()
    .required('Amount is required'),
  status: yup
    .string()
    .required('Status is required'),
  due_date: yup
    .date()
    .required('Due Date is required'),
  pay_date: yup
    .date(),
  // /*link: yup
  //   .string()
  //   .required('Invoice Link is required'),*/
});

const CreateInvoice = () => {
  const [values, setValues] = useState({});

  const formik = useFormik({
    initialValues: {
      amount: '',
      status: '',
      due_date: null,
      pay_date: null,
      link: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setValues(values);
    },
  });
  return (
    <div className='w-[80%] mb-[240px] h-full mx-auto bg-white p-8 ' >
      <h1 className=" user-text">
        Create{" "}
        <span style={{ fontWeight: "700" }} className="text-[#F37A20]">
          New Invoice
        </span>
      </h1>
      <form onSubmit={formik.handleSubmit}>

        <div className='space-y-10' >
          <div className='flex gap-16 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
            <TextField
              fullWidth
              id="amount"
              name="amount"
              label="Amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                name="status"
                label="status"
                id="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                error={formik.touched.status && Boolean(formik.errors.status)}
                helperText={formik.touched.status && formik.errors.status}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                <MenuItem value="Paid">Paid</MenuItem>
                <MenuItem value="UnPaid">UnPaid</MenuItem>
              </Select>
            </FormControl>

          </div>
          <div className='flex gap-16 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                    fullWidth
                    className='w-[100%]'
                    name="due_date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="due_date"
                    label="Select Due Date"
                    value={formik.values.due_date}
                    onChange={value =>
                      formik.setFieldValue('due_date', value)
                    }
                    error={formik.touched.due_date && Boolean(formik.errors.due_date)}


                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                    fullWidth
                    className='w-[100%]'
                    name="pay_date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="pay_date"
                    label="Select Pay Date"
                    value={formik.values.pay_date}
                    onChange={value =>
                      formik.setFieldValue('pay_date', value)
                    }
                    error={formik.touched.pay_date && Boolean(formik.errors.pay_date)}


                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
          </div>
          <div className='flex gap-16 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
            <TextField
              fullWidth
              id="link"
              name="link"
              label="Invoice Link"
              value={formik.values.link}
              onChange={formik.handleChange}
              error={formik.touched.link && Boolean(formik.errors.link)}
              helperText={formik.touched.link && formik.errors.link}
            />
          </div>

          <div className='flex justify-end gap-4 pb-4' >
            <Link to="/invoice" >
              <button className='bg-red-500 w-[100px]  rounded-md text-[16px] p-3 text-white' variant="contained" fullWidth>
                Cancel
              </button>
            </Link>
            <button className='bg-[#F37A20] w-[100px] rounded-md text-[16px] p-3 text-white' variant="contained" fullWidth type="submit">
              Save
            </button>
          </div>
        </div>
      </form>

    </div>
  )
}

export default CreateInvoice;