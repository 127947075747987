import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { CheckListIcon, StarsIcon } from "../../app-constants";
import ClientPage from '../../components/Referrals/ClientPage';
import ManagerPage from '../../components/Referrals/ManagerPage';
import { useStateContext } from "../../contexts/ContextProvider";


function ReferralsView() {
    const { user, role } = useStateContext();

    return (
        <div className="appboard-grid">
            <div className={`appboard-sub-grid pt-4`}>
                <div className="main-top-banner">
                    <div className="main-title">
                        <h1>Referrals<span> Program</span></h1>
                    </div>
                </div>
                {role == 'client' ?  <ClientPage user={user} /> : <ManagerPage user={user} /> }

            </div>
        </div>
    );

        // <>
        //     <div className="main_top_title">
        //         <div className="main_title"><h1>Promotions & <span>Offers</span></h1></div>
        //         <div className="main_btn_txt">
        //             <ul>
        //                 <li>
        //                     <NavLink to="">
        //                         Dashboard
        //                     </NavLink>
        //                 </li>
        //                 <li className="active">
        //                     <NavLink to="" >
        //                         Promotions & Offers
        //                     </NavLink>
        //                 </li>
        //             </ul>
        //         </div>
        //     </div>
        //     <div className="main_content_container">
        //         <Container fluid className="p-0">
        //             <Row>
        //                 <Col xxl={12}>
        //                     <div className="banner_img">
        //                         <img src={BannerImg} alt="BannerImg"/>
        //                         <h2>Refer a Friend,<br/>
        //                             Earn Rewards!</h2>
        //                     </div>
        //                     <div className="points_banner">
        //                         <div className="points_number">
        //                             <div className="points_list">
        //                                 <div className="iccons">
        //                                     {StarsIcon}
        //                                 </div>
        //                                 <div className="value">
        //                                     <h5>
        //                                         <span>No. of claims</span>
        //                                         14
        //                                     </h5>
        //                                 </div>
        //                             </div>
        //                             <div className="points_list">
        //                                 <div className="iccons">
        //                                     {CheckListIcon}
        //                                 </div>
        //                                 <div className="value">
        //                                 <h5>
        //                                     <span>Status</span>
        //                                      Active
        //                                 </h5>
        //                             </div>
        //                           </div>

        //                         </div>
        //                         <div className="points_txt">
        //                             <div className="points_sec">
        //                                 <h4>Unlock Limitless Potential with Our Referral Program!</h4>
        //                                 <p>Refer a friend, colleague, or business partner to our platform, and they'll discover the same comprehensive suite of tools that have revolutionized your workflow. Simply fill out the form below with your referral's Full Name and Email Address, and we'll take care of the rest.</p>
        //                             </div>
        //                         </div>
        //                         <div className="points_from">
        //                             <Form>
        //                                 <Form.Group className="mb-3" controlId="formBasicPassword">
        //                                     <Form.Label>Referral Name</Form.Label>
        //                                     <Form.Control type="text" placeholder="Name" />
        //                                 </Form.Group>
        //                                 <Form.Group className="mb-3" controlId="formBasicEmail">
        //                                     <Form.Label>Referral Email</Form.Label>
        //                                     <Form.Control type="email" placeholder="Email address" />
        //                                 </Form.Group>
        //                                 <div className="btn_submit" type="submit">
        //                                     Submit
        //                                 </div>
        //                             </Form>
        //                         </div>
        //                     </div>
        //                 </Col>
        //             </Row>
        //         </Container>
        //     </div>
        //  </>
}

export default ReferralsView;