import React, { useEffect, useState } from "react";
import Rewards from "../components/Rewards";
import star from '../data/star.svg'
import MarketingProjects from "../components/Marketing/MarketingProjects";
import { useStateContext } from "../contexts/ContextProvider";
import { MdCreateNewFolder } from 'react-icons/md';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField, MenuItem, InputLabel, FormControl, Select, Radio, RadioGroup, FormControlLabel, FormLabel } from '@material-ui/core';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Slide from '@mui/material/Slide';
import axios from 'axios'
import Swal from 'sweetalert2';
import { ThreeDots } from 'react-loader-spinner'
import { Footer } from "../components";
import { Navigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const validationSchema = yup.object({
  name: yup
    .string()
    .min(6, 'Too Short!')
    .max(70, 'Too Long!')
    .required('Project Name is required'),
  category_type: yup
    .string()
    .required('Category Type is required'),
  phase_type: yup
    .string()
    .required('Phase Type is required'),
  project_type: yup
    .string()
    .required('Project Type is required'),
  client: yup
    .string()
    .required('Client Name is required'),
  desc: yup
    .string()
    .min(6, 'Too Short!')
    .max(70, 'Too Long!')
    .required('Description is required'),
  deadline: yup
    .date().required('Deadline is required'),

});

const Marketing = () => {

  const { user, manualProjects, client, getAllManualProjects, setClient } = useStateContext();
  const { role } = user.user;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [projectType, setProjectType] = useState([]);
  const [asanaProject, setAsanaProject] = useState([]);
  const [categoryType, setCategoryType] = useState([]);
  const [phaseType, setPhaseType] = useState([]);



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      asana_project_id: null,
      asana_project_url: null,
      category_type: null,
      phase_type: null,
      project_type: null,
      client: null,
      desc: '',
      deadline: null

    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleClose()
      createProject()
      resetForm({ values: '' })
    },
  });

  const getProjectType = () => {
    if (user) {
      axios.get(`${process.env.REACT_APP_BASE_URL}project-type`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
        .then((res) => {
          setProjectType(res.data.data)
        })
        .catch(err => {
          console.log('err====', err);
        })
    }
  }

  const getAsanaProject = () => {
    if (user) {
      const ASANA_BEARER_TOKEN = "Bearer 2/1204614194882962/1206884941289659:6993e942413ab55624207ae4379afb7f"
      axios.get(`${process.env.REACT_APP_ASANA_BASE_URL}projects`, {
        headers: {
          'Authorization': ASANA_BEARER_TOKEN
        }
      })
        .then((res) => {
          console.log(res);
          setAsanaProject(res.data.data)
        })
        .catch(err => {
          console.log('err====', err);
        })
    }
  }
  const getCategoryType = () => {
    if (user) {
      axios.get(`${process.env.REACT_APP_BASE_URL}project-category`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
        .then((res) => {
          setCategoryType(res.data.data)
        })
        .catch(err => {
          console.log('err====', err);
        })
    }
  }
  const getPhaseType = () => {
    if (user) {
      axios.get(`${process.env.REACT_APP_BASE_URL}project-phase`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
        .then((res) => {
          setPhaseType(res.data.data)
        })
        .catch(err => {
          console.log('err====', err);
        })
    }
  }

  const createProject = async () => {
    setLoading(true);

    const selectedClient = client.find(item => `${item.first_name} ${item.last_name}` === formik.values.client);
    const selectedProjectType = projectType.find(item => `${item.name}` === formik.values.project_type);
    const selectedCategoryType = categoryType.find(item => `${item.name}` === formik.values.category_type);
    const selectedPhaseType = phaseType.find(item => `${item.name}` === formik.values.phase_type);

    const data = {
      "name": formik.values.name,
      "category_id": selectedCategoryType.id,
      "phase_id": selectedPhaseType.id,
      "type_id": selectedProjectType.id,
      "business_dev": user.user.id,
      "client_id": selectedClient.id,
      "asana_project_id": formik.values.asana_project_id,
      "asana_project_url": formik.values.asana_project_url,
      "deadline": new Date(formik.values.deadline)?.toUTCString(),
      "description": formik.values.desc
    }
    console.warn('CREATE PROJECT BODY DATA======', data);
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}manual-project`, { ...data }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
        .then((res) => {
          if (res.status === 200) {
            getAllManualProjects();
            setLoading(false);
            Swal.fire(
              {
                icon: 'success',
                title: 'Project Created successfully!',
                showConfirmButton: false,
                timer: 1000
              }

            )
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("ERROR CREATIND", error);
          let errorInfo = error.response.data.errors.map(el => { return el.message.name });
          console.log("ERROR CREATIND", errorInfo);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: errorInfo.map(el => { return el }),
            showConfirmButton: false,
          })
        })
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const getAllUsers = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}get_users_by_role/client`, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    }).then((response) => {

      setClient(response.data.data);
    }).catch((err) => {
      console.log('err====', err);
    })
    // console.log(table);
  }
  useEffect(() => {

    getProjectType();
    getAsanaProject();
    getCategoryType();
    getPhaseType();
  }, [])

  useEffect(() => {
    getAllUsers()
    getAllManualProjects();
  }, [])

  if (loading) {
    return (
      <div className=' justify-center items-center flex h-[80vh]' >
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#F37A20"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>

    )
  }

  return (
    <div className="appboard-grid">
      <div className={`appboard-sub-grid pt-4`}>
        <div className="main-top-banner">
          <div className="main-title">
            <h1>App<span> Project</span></h1>
          </div>
          {role !== 'client' ?
          <div className="main-btn-txt">
            <button className="btn-banner" onClick={handleClickOpen}>Create New Project</button>
          </div>
              : null}
        </div>

        <div className="appboard-overview-project-main">
          <div className="appboard-grid-project-title">Project List</div>
          <div className="appboard-grid-project-txt p-0">
            <MarketingProjects projects={manualProjects} />
          </div>
        </div>

      </div>
      {/* {role === 'client' ?
                <div className="appboard-grid-rewards pt-5 lg:pr-[25px] lg:pl-[25px] md: pr-[10px] md: pl-[10px]">
                    <Rewards>
                        <img src={star} className="fixed m-auto left-0 right-0 top-[-2.5rem] w-[73px] h-[73px]" />
                    </Rewards>
                </div>
                : null} */}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Create a Project"}</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit} className='space-y-6 ' >
            <TextField
              className='w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%] '
              id="name"
              name="name"
              label="Project Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Project Category</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  name="category_type"
                  label="Project Category"
                  id="category_type"
                  value={formik.values.category_type}
                  onChange={formik.handleChange}
                  error={formik.touched.category_type && Boolean(formik.errors.category_type)}
                  helperText={formik.touched.category_type && formik.errors.category_type}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                >
                  {categoryType?.map((item, index) => {
                    return (

                      <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Project Phase</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  name="phase_type"
                  label="Project Phase"
                  id="phase_type"
                  value={formik.values.phase_type}
                  onChange={formik.handleChange}
                  error={formik.touched.phase_type && Boolean(formik.errors.phase_type)}
                  helperText={formik.touched.phase_type && formik.errors.phase_type}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                >
                  {phaseType?.map((item, index) => {
                    return (

                      <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

            </div>
            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Project Type</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  name="project_type"
                  label="Project Type"
                  id="project_type"
                  value={formik.values.project_type}
                  onChange={formik.handleChange}
                  error={formik.touched.project_type && Boolean(formik.errors.project_type)}
                  helperText={formik.touched.project_type && formik.errors.project_type}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                >
                  {projectType?.map((item, index) => {
                    return (

                      <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Client</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  name="client"
                  label="Client"
                  id="client"
                  value={formik.values.client}
                  onChange={formik.handleChange}
                  error={formik.touched.client && Boolean(formik.errors.client)}
                  helperText={formik.touched.client && formik.errors.client}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                >
                  {client?.map((item, index) => {
                    return (

                      <MenuItem key={index} value={`${item.first_name} ${item.last_name}`}>{item.first_name} {item.last_name}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

            </div>
            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Asana Projects</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  name="asana_project_id"
                  label="Project Projects"
                  id="asana_project_id"
                  value={formik.values.asana_project_id}
                  onChange={formik.handleChange}
                  error={formik.touched.asana_project_id && Boolean(formik.errors.asana_project_id)}
                  helperText={formik.touched.asana_project_id && formik.errors.asana_project_id}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                >
                  {asanaProject?.map((item, index) => {
                    return (

                      <MenuItem key={index} value={item.gid}>{item.name}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <TextField
              className='w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%] '
              id="asana_project_url"
              name="asana_project_url"
              label="Asana Project URL"
              value={formik.values.asana_project_url}
              onChange={formik.handleChange}
              error={formik.touched.asana_project_url && Boolean(formik.errors.asana_project_url)}
              helperText={formik.touched.asana_project_url && formik.errors.asana_project_url}
            />

            </div>

            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
              <FormControl className="custom-input" fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <DatePicker
                      fullWidth
                      className='w-[100%]'
                      name="deadline"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id="deadline"
                      inputFormat="E MMM dd yyyy HH:MM:SS O"
                      label="Deadline"
                      value={formik.values.deadline}
                      onChange={value => formik.setFieldValue('deadline', value)}
                      error={formik.touched.deadline && Boolean(formik.errors.deadline)}



                    />
                  </DemoContainer>
                </LocalizationProvider>
              </FormControl>

            </div>
            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8' >
              <TextField
                className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                id="desc"
                name="desc"
                label="Description"
                value={formik.values.desc}
                onChange={formik.handleChange}
                error={formik.touched.desc && Boolean(formik.errors.desc)}
                helperText={formik.touched.desc && formik.errors.desc}
              />
            </div>
            <div className='flex justify-end mt-3' >
              <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white' variant="contained" fullWidth type="submit">
                Save
              </button>
            </div>
          </form>
        </DialogContent>

      </Dialog>
    </div>
  );
};

export default Marketing;
