const s3Credential = {
    app_name: "spotfinder",
    bucketName: "tek-staging-spotfinder-backend",
    region: "us-east-1",
    accessKeyId: "AKIAUPLSLQUZEQVGAIYW",
    secretAccessKey: "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",
    s3EndPoint: "https://tek-staging-spotfinder-backend.s3.us-east-1.amazonaws.com/",
    app_token: "lQK2WzLQP2VUEj5c0WVm"
}

const SetS3BaseUrl = () => {
    return 'https://s3-presigned-svc.tekstagearea.com/api/v1/';
}
export async function S3Upload(fileType, fileName, fileContents, folder_name = 'other') {

    try {
        const body = {
            contentType: fileType
            // mimetype
        };

        const config = {
            headers: {

                'x-access-token': s3Credential.app_token,
                'Access-Control-Allow-Origin': '*'
            }
        };

        //Here we are posting request with payload file type to get one time key access for uploading file
        const presignedPostUrlResponse = await fetch(SetS3BaseUrl() + 'files', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...config.headers
            },
            body: JSON.stringify(body)
        });

        //In case our request get failed

        if (!presignedPostUrlResponse.ok) {
            throw new Error('Failed to get presigned URL');
        }

        //Here from previous post response separating key and url and saving it in var for future use
        const presignedPostUrl = await presignedPostUrlResponse.json();
        let key;
        let url;
        if (presignedPostUrl?.data?.result?.fields?.Key) {
            key = presignedPostUrl?.data?.result?.fields?.Key
        }
        if (presignedPostUrl?.data?.url) {
            url = presignedPostUrl?.data?.url
        }

        //Here appending fields from previous response in next request payload
        const formData = new FormData();
        Object.entries(presignedPostUrl?.data?.result?.fields).forEach(([k, v]) => {
            formData.append(k, v);
        });
        //Here appending file which we have to upload to S3
        formData.append('file', fileContents); // The file has to be the last element
        const response = await fetch(presignedPostUrl?.data?.result?.url, {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            body: formData
        });

        //After successfull upload it will gonna return empty response just with status 200 or 204
        //In case above condition return previously saved URL in var or return dynamic url which is comment below
        if (response.status == 200 || response.status == 204) {
            //For sending dynamic URL as a response uncomment below line of code and remove next return line
            //below URL include S3 endpoint and token and previously saved key 
            // return constants.S3CREDENTIAL.s3EndPoint + apiUrl.get_s3_token +'/'+ key;
            return url
        }
        return false
    } catch (error) {
        console.error(error);
        return false;

    }
}