import React, { useMemo } from 'react';
import { Link } from 'react-router-dom'
import { useStateContext } from '../../contexts/ContextProvider';
import Progress from '../charts/Progress';
import MarketingProjectProgress from '../charts/MarketingProjectProgress';
import Rectangle89 from '../../data/Rectangle89.png'
import { Oval } from 'react-loader-spinner'
import { DateFormatWithSlash } from '../../utils/DateFormatWithSlash';

const MarketingProjects = ({ projects }) => {
    return (
        <>
            {projects && (
                <div className='project-listing'>
                    {projects.map(project => (
                        <div key={project?.gid} className="marketing_borad_dtails">
                            <div className="marketing_borad_item">
                                <div className="class_img">
                                    <div className="img_icon">
                                         {project.name.charAt(0)}
                                    </div>
                                </div>
                                <div className="class_txt">
                                    <h5>{project?.name}</h5>
                                    <p dangerouslySetInnerHTML={{
                                        __html: project.description === '<body></body>' ?
                                            `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus minus accusantium sed. Cum quod, inventore porro facilis labore pariatur quia deleniti necessitatibus doloremque assumenda aliquid expedita temporibus laboriosam ipsam dolores.
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur laudantium sint ipsa, aliquam ullam obcaecati atque sunt quia, repellendus odio deleniti, minima veritatis iste placeat? Molestiae dignissimos magnam repellendus iure.`
                                            : project.description
                                    }}></p>
                                    <ul>
                                        <li>
                                            <span className="name_title">Status :</span>
                                            <span className="warning">{project.phase.name}</span>
                                        </li>
                                        <li>
                                            <span className="name_title">Category :</span>
                                            <span className="name_title">{project.category.name}</span>
                                        </li>
                                        <li className="date_listing">
                                            <span className="name_title">DeadLine :</span> <span>{DateFormatWithSlash(project?.deadline)}</span>
                                        </li>

                                    </ul>
                                    <div className="marketing_percentage">
                                        <h5>Project Progress</h5>
                                        <MarketingProjectProgress projectData={project} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        ))}
                    <div className='flex justify-center pt-4'>
                        {projects.length === 0 && <p className="text-[14px] font-bold">No Projects Here...</p>}
                    </div>
                </div>
            )}
        </>

    )
}

export default MarketingProjects;