import React, {useEffect, useState} from 'react';
import {useStateContext} from '../../contexts/ContextProvider';
import {CircularProgressbar} from 'react-circular-progressbar';
import axios from "axios";

const UserRewards = () => {

    const {user, userprofile, benefits, sessionProject, project} = useStateContext();
    const [data, setData] = useState([]);

    const getUserPoints = () => {
        //get_user_points
        axios.get(`${process.env.REACT_APP_BASE_URL}get_user_points?project_id=${sessionProject}&client_id=${user?.user?.id}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            setData(response.data.data)
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    useEffect(() => {
        getUserPoints()
    }, [project])


    return (
        <div
            className={` relative rewards-container p-[40px] md:p-[30px] sm:p-[20px] xm:p-[15px] mx-[16px] mb-[20px] h-[226px] sm:h-[180px] xm:h-[160px] md:h-[200px] rounded-t-[15px] `}>
            {/* <img src={transparentLogo} className='absolute right-0 bottom-0' /> */}
            <div className=''>
                <div className='pb-[20px]'>
                    {
                        data?.client &&
                        <p className='text-[36px] md:text-[24px] sm:text-[22px] xm:text-[20px] font-semibold text-white '>{data?.client?.first_name} {data?.client?.last_name}</p>
                    }

                </div>
                <div className='flex justify-start gap-3'>
                    <div className='w-[36px] h-[36px] pt-[7px] md:pt-[5px] sm:pt-[4px] xm:pt-[3px]'>
                        {
                            data && data.points &&
                                <CircularProgressbar
                                value={(data?.points / (data?.points + data?.remaining_points)) * 100}
                                background={true}
                                strokeWidth={12}
                                styles={{
                                    // Customize the root svg element
                                    root: {},
                                    // Customize the path, i.e. the "completed progress"
                                    path: {
                                        // Path color
                                        stroke: `#F37A20`,
                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                        strokeLinecap: 'butt',
                                        // Customize transition animation
                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                        // Rotate the path
                                    },
                                    // Customize the circle behind the path, i.e. the "total progress"
                                    trail: {
                                        // Trail color
                                        stroke: '#FFE5D1',
                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                        strokeLinecap: 'butt',
                                        // Rotate the trail
                                    },
                                    // Customize the text

                                    // Customize background - only used when the `background` prop is true
                                    background: {
                                        fill: 'transparent',
                                    },
                                }}
                            />
                        }

                    </div>
                    <div>
                        <p className='text-[#F37A20] font-bold text-[26px] md:text-[24px] sm:text-[16px] xm:text-[20px] pb-[7px] '>{data?.points || 0} Points accumulated</p>
                        <p className='text-white text-[14px] md:text-[12px] sm:text-[10px] xm:text-[8px]  '>{data?.remaining_points || 0} Points claimed</p>
                        <p className='text-white text-[14px] md:text-[12px] sm:text-[10px] xm:text-[8px]  '>{data?.remaining_benefit_points || 0} Points remaining for your next reward</p>

                        {/*<p className='text-white text-[14px] md:text-[12px] sm:text-[10px] xm:text-[8px] mb-2'>{data?.remaining_points || 0} points
                            remaining to claim</p>*/}
                        {/*<p className='text-white text-[14px] md:text-[12px] sm:text-[10px] xm:text-[8px]  '>{data?.remaining_points || 0} points
                            remaining for your next reward</p>*/}
                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>
    );
}

export default UserRewards;
