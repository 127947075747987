import React, { useState, useEffect } from 'react'
import { useStateContext } from '../../contexts/ContextProvider';
import { RiEditBoxFill } from 'react-icons/ri';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { MdDelete } from 'react-icons/md';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField } from '@material-ui/core';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Swal from 'sweetalert2';
import { Oval } from 'react-loader-spinner';
import { Loader, NoRecordFound, Toast } from '../../components/General';
import { Modal, Table } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const validationSchema = yup.object({
  points: yup.number()
    .required('This field is required!')
    .typeError('Total Points must be a number') // Custom validation message for the number type
    .positive('Total Points must be a positive number') // Ensure the number is positive
    .integer('Total Points must be an integer')
});

const validationSchemaPoints = yup.object({
  title: yup
    .string()
    .required('This field is required!'),
  points: yup.number()
    .required('This field is required!')
    .typeError('Total Points must be a number') // Custom validation message for the number type
    .positive('Total Points must be a positive number') // Ensure the number is positive
    .integer('Total Points must be an integer')
});

const Activity = () => {
  // const[values,setValues] = useState('');
  // const[valuesPoints,setValuesPoints] = useState('');
  const [open, setOpen] = React.useState(false);
  const [openPoints, setOpenPoints] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [activity, setActivity] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);


  const formikPoints = useFormik({
    initialValues: {
      title: '',
      points: '',

    },
    validationSchema: validationSchemaPoints,
    onSubmit: (values, { resetForm }) => {
      CreateActivity();
      // resetForm({values: ''});
      // handleClosePoints()
    },
  });

  const formik = useFormik({
    initialValues: {
      points: activity.points,

    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      EditRewards()
    }
  });

  const handleClickOpen = (item) => {
    // console.log(item);
    setActivity(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setActivity({});
  };

  const handleClickOpenPoints = () => {
    setOpenPoints(true);
  };

  const handleClosePoints = () => {
    formikPoints.resetForm();
    formik.resetForm();
    setErrorMessage(false);
    setOpenPoints(false);
  };

  const { activeMenu, user } = useStateContext();
  const [points, setPoints] = useState([]);

  const getAllRewards = (loader = false) => {

    setLoading(loader);

    axios.get(`${process.env.REACT_APP_BASE_URL}activity-reward-list`, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    })
      .then((response) => {
        setPoints(response.data.data);
        setLoading(false)
      })
      .catch((err) => {
        console.log('err====', err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const CreateActivity = async () => {
    setBtnLoading(true);
    setErrorMessage(false);

    const data = {
      "title": formikPoints.values.title,
      "points": formikPoints.values.points
    }

    await axios.post(`${process.env.REACT_APP_BASE_URL}add_activity_reward`, { ...data }, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    })
      .then((res) => {
        if (res.status === 200) {
          handleClosePoints();
          getAllRewards();

          Toast.fire({
            icon: 'success',
            title: 'Activity Rewards Created Successfully!',
          })
        }
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.message)
      })
      .finally(() => {
        setBtnLoading(false);
      })


  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRewards(id)
      }
    })

  }

  const deleteRewards = async (id, loader = false) => {
    setLoading(loader);

    await axios.delete(`${process.env.REACT_APP_BASE_URL}delete_activity_reward/${id}`, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    })
      .then((res) => {
        if (res.status === 200) {
          getAllRewards();

          Toast.fire({
            icon: 'success',
            title: 'Activity Rewards Deleted Successfully!',
          })
        }
      })
      .catch((error) => {

        Toast.fire({
          icon: "error",
          title: error?.response?.data?.errors[0]?.message || "Something went wrong!, Please try again Later",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const EditRewards = async () => {
    setBtnLoading(true);

    const data = {
      "title": activity.title,
      "points": formik.values.points
    }

    await axios.put(`${process.env.REACT_APP_BASE_URL}update_activity_reward/${activity.id}`, { ...data, id: activity.id }, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    })
    .then((res) => {
      if (res.status === 200) {
        getAllRewards();
        handleClose();
        Toast.fire({
          icon: 'success',
          title: 'Activity Rewards Updated Successfully!',
        })
      }
    })
    .catch((error) => {
      console.log(error);
      handleClose();
      Toast.fire({
        icon: "error",
        title: "Something went wrong!, Please try again Later",
      });
    })
    .finally(() => {
      setBtnLoading(false);
    });

  };

  const resetForm = () => {
    formik.setValues({
      points: activity.points || ''
    });
  };

  useEffect(() => {
    getAllRewards();
  }, []);

  useEffect(() => {
    formik.setValues({
      points: activity.points || ''
    })
  }, [activity])

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <div className="download_tabs">
        <div className="list_fillter add__button">
          <div className="add_task" onClick={handleClickOpenPoints}>
            Add Activity and Points
          </div>
        </div>


        <Table responsive>
          <thead>
            <tr>

              <th className="text-center">Title</th>
              <th className="text-center">Point</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {points && points.length > 0 ? points.map((item, index) => {

              return (
                <tr>
                  <td className="text-center">
                    {item.title}
                  </td>
                  <td className="text-center">
                    {item.points}
                  </td>

                  <td className="text-center">
                    <ul>

                      <li>
                        <RiEditBoxFill className='cursor-pointer' onClick={() => handleClickOpen(item)}
                          color="orange" size={20} />
                      </li>

                      <li>
                        <MdDelete className='cursor-pointer' onClick={() => handleDelete(item.id)} color="red"
                          size={20} />
                      </li>

                    </ul>
                  </td>
                </tr>
              )

            }) : (
              <NoRecordFound />
            )

            }
          </tbody>
        </Table>
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{`Update Reward Points - ${activity.title}`}</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              className='w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%]'
              id="points"
              name="points"
              label="Total Points"
              value={formik.values.points}
              onChange={formik.handleChange}
              error={formik.touched.points && Boolean(formik.errors.points)}
              helperText={formik.touched.points && formik.errors.points}
              InputLabelProps={{ shrink: true }}
            />
            <div className='flex justify-end mt-3' >
              {/* <div onClick={resetForm} className="bg-white w-[70px] rounded-md text-[16px] p-3 text-[#F37A20] border border-[#F37A20] mr-4">
                Reset
              </div> */}

              <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white'
                variant="contained"
                fullWidth type="submit"
                disabled={btnLoading}
              >
                {btnLoading ? <Oval
                  visible={true}
                  height="20"
                  width="20"
                  color="#ffffff"
                  secondaryColor="#ffffff"
                  ariaLabel="oval-loading"
                  wrapperStyle={{ disply: 'flex', justifyContent: 'center' }}
                  wrapperClass=""
                /> : 'Save'}


              </button>
            </div>
          </form>
        </DialogContent>

      </Dialog>

      <Dialog
        open={openPoints}
        TransitionComponent={Transition}
        onClose={handleClosePoints}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Add Points"}</DialogTitle>
        <DialogContent>
          <form onSubmit={formikPoints.handleSubmit}>
            <div className='flex gap-8' >
              <TextField
                className='w-[700px]'
                id="title"
                name="title"
                label="Title"
                value={formikPoints.values.title}
                onChange={formikPoints.handleChange}
                error={formikPoints.touched.title && Boolean(formikPoints.errors.title)}
                helperText={formikPoints.touched.title && formikPoints.errors.title}
              />
            </div>

            <div className='flex gap-8' >
              {errorMessage ? <div style={{ color: 'red', marginTop: '7px' }}>{errorMessage}</div> : ''}
            </div>
            <br />

            <div className='flex gap-8' >
              <TextField
                className='w-[700px]'
                id="points"
                name="points"
                label="Points"
                value={formikPoints.values.points}
                onChange={formikPoints.handleChange}
                error={formikPoints.touched.points && Boolean(formikPoints.errors.points)}
                helperText={formikPoints.touched.points && formikPoints.errors.points}
              />
            </div>

            <div className='flex justify-end mt-3'>
              <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white'
                variant="contained"
                fullWidth type="submit"
                disabled={btnLoading}
              >
                {btnLoading ? <Oval
                  visible={true}
                  height="20"
                  width="20"
                  color="#ffffff"
                  secondaryColor="#ffffff"
                  ariaLabel="oval-loading"
                  wrapperStyle={{ disply: 'flex', justifyContent: 'center' }}
                  wrapperClass=""
                /> : 'Save'}


              </button>
            </div>

          </form>
        </DialogContent>

      </Dialog>
    </>
  )
}

export default Activity;
