import React, { useEffect, useState, useLayoutEffect } from "react";
import {
   BrowserRouter as Router,
   Route,
   Routes,
} from "react-router-dom";
import {
   Dashboard,
   MeetingBoard,
   Calendar,
   Invoice,
   // ClientManagement,
   Pyramid,
   Customers,
   Kanban,
   Line,
   Area,
   Bar,
   Pie,
   Financial,
   ColorPicker,
   ColorMapping,
   Editor,
   Setting,
   RewardPoint,
   AppBoard,
   DownloadContent,
   Users,
   ProjectInVoices,
   Marketing,
   ClientRewardClaim,
   Overview,
   ListView,
   BoardView,
   AsanaDashboard,
   Status,
   Files
} from '../pages';
import SignIn from '../pages/SignIn'
import EditProject from './Projects/EditProject';
import CreateUser from './Users/CreateUser'
import EditUser from './Users/EditUser'
import CreateClient from './Clients/CreateClient'
import CreateInvoice from './Invoice/CreateInvoice';
import EditClient from './Clients/EditClient'
import ProjectOverview from './Projects/ProjectOverview';
import MarketingOverview from './Marketing/MarketingOverview';
import ClientDetails from './Clients/ClientDetails';
import ManageRewards from './Rewards/ManageRewards'
import InvoiceDetail from './Invoice/InvoiceDetail';
import AsanaCallback from '../axios/AsanaCallback'
import ResetPassword from '../pages/ResetPassword';
import ForgotPassword from '../pages/ForgotPassword';
import RequireAuth from "./RequireAuth";
import DashboardLayout from "./DashboardLayout";
import Promotions from "../pages/Promotions";
import Referrals from "../pages/Referrals";
import OverviewBoard from "../pages/new/Overview";
import ListViewCom from "../pages/new/ListView";
import BoardViewComp from "../pages/new/BoardView";
import CalendarView from "../pages/new/CalendarView";
import StatusView from "../pages/new/StatusView";
import FileView from "../pages/new/FileView";
import MarketingBoard from "../pages/new/MarketingBoard";
import RewardPoints from "../pages/new/RewardPoints";
import MarketingListView from "../pages/new/MarketingListView";
import MarketingBoardViewCom from "../pages/new/MarketingBoardView"
import DownloadableContent from "../pages/new/DownloadableContent";
import DownloadableContentDemo from "../pages/new/DownloadableContentDemo";
import ReferralsView from "../pages/new/ReferralsView";
import MeetingBoardView from "../pages/new/MeetingBoard";
import InvoiceVeiw from "../pages/new/InvoiceVeiw";
import TeamMember from "../pages/new/TeamMember";
import SettingView from "../pages/new/SettingView";
import SettingViewDemo from "../pages/new/SettingViewDemo";
import ClientManagement from "../pages/new/ClientManagement";
import Project from "../pages/new/Project";
import MeetingView from "../pages/new/MeetingView";
import DashboardNew from "../pages/new/DashboardNew";
import TermAndConditions from "../pages/TermAndConditions";

export const Navigation = () => {

   return (
      <div className={`layout-container`}>
         <Routes>
            <Route path="/signin" element={(<SignIn />)} />
            <Route path="/forgot" element={(<ForgotPassword />)} />
            {/*<Route path="/reset" element={(<ResetPassword />)} />*/}
            <Route path="/reset/:id" element={(<ResetPassword />)} />

            <Route element={<RequireAuth />}>

               {/* dashboard  */}
               <Route path="/auth/asana/callback" element={
                  <DashboardLayout
                     children={<AsanaCallback />} />
               } />

               <Route path="/" element={
                  <DashboardLayout
                     children={<Dashboard />} />
               } />

               <Route path="/new" element={
                  <DashboardLayout
                     children={<DashboardNew />} />
               } />
               <Route path="/Calendar" element={
                  <DashboardLayout
                     children={<Calendar />} />
               } />

               {/* pages  */}
               <Route path="/meeting" element={
                  <DashboardLayout
                     children={<MeetingBoard />} />
               } />
               <Route path="/setting" element={
                  <DashboardLayout
                     children={<Setting />} />
               } />
               <Route path="/projectInvoices" element={
                  <DashboardLayout
                     children={<ProjectInVoices />} />
               } />
               <Route path='/marketing' element={
                  <DashboardLayout
                     children={<MarketingOverview />} />
                  /*<DashboardLayout
                     children={<Marketing />} />*/
               } />

               <Route path='/projects' element={
                  <DashboardLayout
                     children={<Marketing />} />
               } />

               {/* apps  */}
               {/*<Route path="/rewards" element={*/}
               {/*   <DashboardLayout*/}
               {/*      children={<RewardPoint />} />*/}
               {/*} />*/}
               <Route path="/rewards/client/:id" element={
                  <DashboardLayout
                     children={<ClientRewardClaim />} />
               } />
               <Route path="/rewards/manage" element={
                  <DashboardLayout
                     children={<ManageRewards />} />
               } />
               <Route path="/appboard" element={
                  <DashboardLayout
                     children={<AppBoard />} />
               } />
               <Route path='/appboard/project/:id' element={
                  <DashboardLayout
                     children={<ProjectOverview />} />
               } />

               <Route path="/overview" element={
                  <DashboardLayout
                     children={<Overview />} />
               } />

               <Route path="/listview" element={
                  <DashboardLayout
                     children={<ListView />} />
               } />

               <Route path="/boardview" element={
                  <DashboardLayout
                     children={<BoardView />} />
               } />

               <Route path="/asana-dashboard" element={
                  <DashboardLayout
                     children={<AsanaDashboard />} />
               } />

               <Route path="/status" element={
                  <DashboardLayout
                     children={<Status />} />
               } />

               <Route path="/files" element={
                  <DashboardLayout
                     children={<Files />} />
               } />

               <Route path="/content" element={
                  <DashboardLayout
                     children={<DownloadContent />} />
               } />
               <Route path="/promotions" element={
                  <DashboardLayout
                     children={<Promotions />} />
               } />
               <Route path="/management" element={
                  <DashboardLayout
                     children={<ClientManagement />} />
               } />
               <Route path="/project-list" element={
                  <DashboardLayout
                     children={<Project />} />
               } />
               <Route path="/management/details/:id" element={
                  <DashboardLayout
                     children={<ClientDetails />} />
               } />
               <Route path="/user" element={
                  <DashboardLayout
                     children={<Users />} />
               } />
               <Route path="/referrals" element={
                  <DashboardLayout
                     children={<Referrals />} />
               } />
               <Route path="/marketing/editproject/:id" element={
                  <DashboardLayout
                     children={<EditProject />} />
               } />
               <Route path="/user/createuser" element={
                  <DashboardLayout
                     children={<CreateUser />} />
               } />
               <Route path="/user/edituser/:id" element={
                  <DashboardLayout
                     children={<EditUser />} />
               } />
               <Route path="/management/createclient" element={
                  <DashboardLayout
                     children={<CreateClient />} />
               } />
               <Route path="/management/editclient/:id" element={
                  <DashboardLayout
                     children={<EditClient />} />
               } />
               <Route path="/projectInvoices/invoice/:pid" element={
                  <DashboardLayout
                     children={<Invoice />} />
               } />
               <Route path="/marketing/overview/:pid" element={
                  <DashboardLayout
                     children={<MarketingOverview />} />
               } />
               {/* <Route path="/createinvoice" element={<CreateInvoice />} /> */}
               <Route path="/invoice/details/:id" element={
                  <DashboardLayout
                     children={<InvoiceDetail />} />
               } />

               <Route path="/overview-new" element={
                  <DashboardLayout
                     children={<OverviewBoard />} />
               } />

               <Route path="/list-view" element={
                  <DashboardLayout
                     children={<ListViewCom />} />
               } />
               <Route path="/board-view" element={
                  <DashboardLayout
                     children={<BoardViewComp />} />
               } />
               <Route path="/calender-view" element={
                  <DashboardLayout
                     children={<CalendarView />} />
               } />
               <Route path="/status-view" element={
                  <DashboardLayout
                     children={<StatusView />} />
               } />
               <Route path="/file-view" element={
                  <DashboardLayout
                     children={<FileView />} />
               } />
               <Route path="/marketing-list-view" element={
                  <DashboardLayout
                     children={<MarketingListView />} />
               } />
               <Route path="/marketing-board-view" element={
                  <DashboardLayout
                     children={<MarketingBoardViewCom />} />
               } />
               <Route path="/growth-board-view" element={
                  <DashboardLayout
                     children={<MarketingOverview />} />
               } />
               <Route path="/rewards" element={
                  <DashboardLayout
                     children={<RewardPoints />} />
               } />

               <Route path="/downloadable-view" element={
                  <DashboardLayout
                     children={<DownloadableContent />} />
               } />

               <Route path="/downloadable-view/whats-new" element={
                  <DashboardLayout
                     children={<DownloadableContent />} />
               } />

               <Route path="/downloadable-demo" element={
                  <DashboardLayout
                     children={<DownloadableContentDemo />} />
               } />

               {/* <Route path="/downloadable-view" element={
                  <DashboardLayout
                      children={<DownloadableContentDemo />} />
               } />

                <Route path="/downloadable-view/whats-new" element={
                    <DashboardLayout
                        children={<DownloadableContentDemo />} />
                } /> */}


               <Route path="/referrals-view" element={
                  <DashboardLayout
                     children={<ReferralsView />} />
               } />
               <Route path="/meeting-board-view" element={
                  <DashboardLayout
                     children={<MeetingView />} />
               } />
               <Route path="/invoice-view" element={
                  <DashboardLayout
                     children={<InvoiceVeiw />} />
               } />

               <Route path="/invoice-view-upcoming" element={
                  <DashboardLayout
                     children={<InvoiceVeiw />} />
               } />
               <Route path="/team-member-view" element={
                  <DashboardLayout
                     children={<TeamMember />} />
               } />
               <Route path="/setting-view" element={
                  <DashboardLayout
                     children={<SettingView />} />
               } />
               <Route path="/setting-demo" element={
                  <DashboardLayout
                     children={<SettingViewDemo />} />
               } />
               <Route path="/terms-and-conditions" element={
                  <DashboardLayout
                     children={<TermAndConditions />} />
               } />

            </Route>
         </Routes>
      </div>
   )
}

export default Navigation;