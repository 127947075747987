import React from 'react';
import { useStateContext } from "../../contexts/ContextProvider";
import ClientMeeting from './ClientMeeting';
import MeetingBoardView from './MeetingBoard';

function MeetingView() {

    const { user} = useStateContext();
    const { role } = user.user;

    return (
        <div>
            <div>
                {role == 'client' ?  <ClientMeeting /> : <MeetingBoardView /> }
            </div>
        </div>
    )
}

export default MeetingView
