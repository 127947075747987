import React, {useEffect} from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import {ThreeDots} from "../app-constants";

function FileViewer({src,icon,name}) {

    return (
        <div class="viewer-container">
            <div className="viewer-header">
                <div className="title">
                    {icon}
                    {name}
                </div>
                <div className="dot_edit">
                    <Dropdown >
                        <Dropdown.Toggle id="dropdown-basic" >
                            {ThreeDots}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => window.open(src, '_blank')}>Download</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="viewer-body">
                <iframe
                    id={'file-iframe'}
                    src={src}
                    style={{ width: '100%', height: '150px' }}
                    frameBorder="0"
                    scrolling="no"
                    seamless
                ></iframe>
            </div>
        </div>
    );
}

export default FileViewer;