import React from 'react'
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import reactangle from '../../data/rectangle.svg'

function Progress({ value, isPercentage, supportingText, text }) {
    return (
        <div>
            <CircularProgressbarWithChildren
                value={value}
                background={true}
                strokeWidth={15}
                styles={{
                    // Customize the root svg element
                    root: {},
                    // Customize the path, i.e. the "completed progress"
                    path: {
                        // Path color
                        stroke: `#F47A1F`,
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',
                        // Customize transition animation
                        transition: 'stroke-dashoffset 0.5s ease 0s',
                        // Rotate the path
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                        // Trail color
                        stroke: '#0F1328',
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',
                        // Rotate the trail
                    },
                    // Customize background - only used when the `background` prop is true
                    background: {
                        fill: 'white',
                    },
                }}
            >
                <p className='text-[#F47A1F]'>In Total</p>
                <p className='lg:text-[32px] md:text-[22px] font-bold text-[#F47A1F]'>${text || 0} </p>
            </CircularProgressbarWithChildren>
        </div>
    )
}

export default Progress

