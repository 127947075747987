import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../../../contexts/ContextProvider';
import axios from 'axios';
import { Loader, NoRecordFound, Toast } from '../../../../components/General';
import { RiEditBoxFill } from 'react-icons/ri';
import {
    AddPersonIcon, ArrowIcon, AttchedIcon,
    BellJoinIcon, ClosedIcon,
    DotValueIcon,
    DropDownArrowIcon,
    LikeIcon, LinksIcon,
    PlusIcon,
    SendMsgIcon, ThreeDots
} from "../../../../app-constants";
import { useFormik } from 'formik';

import { Container, Row, Col, Modal, Button, Form, Table } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import * as yup from 'yup';
import { MdDelete } from 'react-icons/md';
import Swal from 'sweetalert2';
import {Oval} from "react-loader-spinner";


function ClientTable() {
    const { user, selectedProject, role} = useStateContext();

    const [client, setClient] = useState(false);
    const [editClient, setEditClient] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formSubmitLoading, setFormSubmitLoading] = useState(false);
    const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);
    const [emailError, setEmailError] = useState(false);

    // const validationSchema = yup.object({
    //     first_name: yup.string().required('First Name is required'),
    //     last_name: yup.string().required('Last Name is required')
    // });
    const validationSchema = yup.object({
        first_name: yup
            .string()
            .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed')
            .required('First name is required')
            .max(20, 'First name should be a maximum of 20 characters')
            .min(4, 'First name must be at least 4 characters'),
            
        last_name: yup
            .string()
            .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed')
            .required('Last name is required')
            .max(20, 'Last name should be a maximum of 20 characters')
            .min(4, 'Last name must be at least 4 characters'),
            
        email: yup.string().email("Invalid Email Format").required("Email is required"),
        phone: yup
            .string()
            .matches(
                /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                'Phone number is not valid'
            )
            .min(10, "too short")
            .max(14, "too long")
            .required("Phone number is required"),
        company_name: yup
            .string()
            .required('Company name is required')
            .max(20, "Company name should be maximum of 20 characters")
            .min(4, "Company name must be at least 4 characters"),
        password: !editClient ? yup.string()
            .required("Password is required")
            .max(20, "Password should be maximum of 20 characters")
            .min(8, "Password must be at least 8 characters") : '',
        confirm_password: !editClient ? yup.string()
            .when('password', (password, schema) => {
                if (password) {
                    return schema
                        .required('Confirm password is required')
                        .oneOf([yup.ref("password")], "Passwords do not match");
                }
                return schema.notRequired();
            }) : ''
    });

    const handleClose = () => {
        setOpen(false);
        setEditClient(false);
        setEmailError(false);
        formik.resetForm();
    };

    const handleEmailOnBlur = async (e) => {

        const email = e.target.value;

        if(email){

            setEmailError(false);

            await axios.get(`${process.env.REACT_APP_BASE_URL}is_email_unique/${email}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {
    
                setIsDisabledSubmit(response.data.data);
                setEmailError(response.data.data);
    
            }).catch((err) => {
                console.log('err====', err);
            })
            .finally(() => {
                // setLoading(false);
            })
        }
    };

    const getAllUsers = async (loader = false) => {

        if (user?.user?.id) {
            setLoading(loader);

            await axios.get(`${process.env.REACT_APP_BASE_URL}get_clients_by_user/${user.user.id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {
                setClient(response.data.data);
            }).catch((err) => {
                console.log('err====', err);
            })
            .finally(() => {
                setLoading(false);
            })
        }

    }

    const updateClient = async (updatedItem,resetForm) => {

        if (editClient?.id) {
            setFormSubmitLoading(true);

            await axios.put(`${process.env.REACT_APP_BASE_URL}update_user/${editClient?.id}`, updatedItem, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            .then((response) => {

                if (response.status === 200) {

                    Toast.fire({
                        icon: 'success',
                        title: 'Client Updated successfully!',
                    })

                    getAllUsers();
                }

            }).catch((err) => {
                console.log('err====', err);
            })
            .finally(() => {
                setFormSubmitLoading(false);
                handleClose();
                resetForm({ values: "" });
            })
        }
    }


    useEffect(() => {
        getAllUsers(true);
    }, []);

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            company_name: "",
            password: "",
            confirm_password: "",
            asana_token : ""
        },
        validationSchema: validationSchema,

        onSubmit: (values, { resetForm }) => {
            if (editClient) {

                updateClient(values,resetForm);
            } else {

                addClient(values,resetForm);
            }

        },
    });

    useEffect(() => {
        formik.setValues({
            first_name: editClient.first_name,
            last_name: editClient.last_name,
            email: editClient.email,
            phone: editClient.phone,
            company_name: editClient.company_name,
            asana_token: editClient.asana_token,
        });
    }, [editClient]);

    const handleEditClickOpen = (item) => {
        setEditClient(item);
        setOpen(true);

        console.log(validationSchema);
    };

    const handleOpenAddClientModal = () => {
        setOpen(true);
    };

    // const handleCloseAddClientModal = () => {
    //     setOpenAddClientModal(false);
    // };

    const addClient = async (values,resetForm) => {

        setFormSubmitLoading(true);

        const data = {
            "first_name": values.first_name,
            "last_name": values.last_name,
            "email": values.email,
            "phone": values.phone,
            "asana_token": values.asana_token,
            "dob": null,
            "designation": null,
            "address": ' ',
            "role_id": 2,
            "password": values.password,
            "company_name": values.company_name,
            "points": 0,
            "created_by": user?.user?.id
        }

        await axios.post(`${process.env.REACT_APP_BASE_URL}create_user`, data, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((response) => {

            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Client Created successfully!',
                })
                getAllUsers();
            }

        }).catch((err) => {
            console.log('err====', err);

            Toast.fire({
                icon: "error",
                title: "Something went wrong!, Please try again Later",
            });
        })
        .finally(() => {
            setFormSubmitLoading(false);
            handleClose();
            resetForm({ values: "" });
        })
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure you want to Delete?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#F37A20",
            cancelButtonColor: "#0F1328",
            confirmButtonText: "Yes, Delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteClient(id);
            }
        });
    }

    const deleteClient = async (id) => {

        if (id) {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}delete_user/${id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            .then((response) => {

                if (response.status === 200) {

                    Toast.fire({
                        icon: 'success',
                        title: 'Client Deleted successfully!',
                    })

                    getAllUsers();
                }

            }).catch((err) => {
                console.log('err====', err);

                Toast.fire({
                    icon: "error",
                    title: "Something went wrong!, Please try again Later",
                });
            })
            .finally(() => {
                // setFormSubmitLoading(false);
            })
        }

    }

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <div className="download_tabs">
                {role !== 'client' && (
                    <div className="list_fillter add__button" >
                        <div className="add_task" onClick={() => handleOpenAddClientModal()}>
                            {PlusIcon} Add Client
                        </div>
                    </div>
                )
                }
                <Table responsive>
                    <thead>
                        <tr>

                            <th className="text-center">Client Name</th>
                            <th className="text-center">Phone Number</th>
                            <th className="text-center">Email</th>
                            <th className="text-center">Company Name</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {client && client.length > 0 ?
                            client.map((item, index) => {

                                return (
                                    <tr>

                                        <td className="text-center">{item?.first_name} {item?.last_name}</td>
                                        <td className="text-center">{item.phone}</td>
                                        <td className="text-center">{item.email}</td>
                                        <td className="text-center">{item.company_name}</td>
                                        <td className='text-center'>
                                            <ul >
                                                <li><RiEditBoxFill color="orange" size={20} onClick={() => handleEditClickOpen(item)} /></li>
                                                <li><MdDelete className='cursor-pointer' onClick={() => handleDelete(item.id)} color="red" size={20} /></li>
                                            </ul>
                                        </td>
                                    </tr>
                                )

                            })
                            : <NoRecordFound />
                        }
                    </tbody>
                </Table>
            </div>
            <Modal show={open} onHide={handleClose}>
                <div className="modal_poopup">
                    {/*<div className="colsed" onClick={handleClose}>*/}
                    {/*    {ClosedIcon}*/}
                    {/*</div>*/}
                    <h4>{editClient ? 'Edit' : 'Add'} Client</h4>

                    <form onSubmit={formik.handleSubmit}>

                        <Form.Group controlId="first_name">
                            <Form.Label>First name</Form.Label>
                            <Form.Control type="text"
                                name="first_name"
                                label="First Name"
                                value={formik.values.first_name || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.first_name && Boolean(formik.errors.first_name)}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formik.touched.first_name && formik.errors.first_name}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-3" controlId="last_name">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control type="text"
                                name="last_name"
                                label="Last Name"
                                value={formik.values.last_name || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.last_name && Boolean(formik.errors.last_name)}
                            />


                            <Form.Control.Feedback type="invalid">
                                {formik.touched.last_name && formik.errors.last_name}
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group className="mt-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text"
                                name="email"
                                label="Email"
                                value={formik.values.email || ''}
                                onChange={formik.handleChange}
                                onBlur={(e) => handleEmailOnBlur(e)}
                                isInvalid={formik.touched.email && Boolean(formik.errors.email)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.touched.email && formik.errors.email}
                            </Form.Control.Feedback>

                            {emailError ? <div className='display-error'>This email is already taken.</div> : ''}
                        </Form.Group>

                        <Form.Group className="mt-3" controlId="asana_token">
                            <Form.Label>Asana Token</Form.Label>
                            <Form.Control type="text"
                                name="asana_token"
                                label="asana_token"
                                value={formik.values.asana_token || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.asana_token && Boolean(formik.errors.asana_token)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.touched.asana_token && formik.errors.asana_token}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-3" controlId="phone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                name="phone"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.phone && Boolean(formik.errors.phone)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.touched.phone && formik.errors.phone}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-3" controlId="company_name">
                            <Form.Label>Company</Form.Label>
                            <Form.Control type="text"
                                name="company_name"
                                label="company_name"
                                value={formik.values.company_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.company_name && Boolean(formik.errors.company_name)}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formik.touched.company_name && formik.errors.company_name}
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group className="mt-3" controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.password && Boolean(formik.errors.password)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.touched.password && formik.errors.password}
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group className="mt-3" controlId="confirm_password">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="confirm_password"
                                value={formik.values.confirm_password || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formik.touched.confirm_password && formik.errors.confirm_password}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <div className="btn_submit">
                            <button type="submit" className="custom_btn" disabled={isDisabledSubmit}>

                                {formSubmitLoading ? <Oval
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#ffffff"
                                    secondaryColor="#ffffff"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{disply: 'flex', justifyContent: 'center'}}
                                    wrapperClass=""
                                />: 'Submit'}

                            </button>
                        </div>

                    </form>
                </div>
            </Modal>
        </>
    );
}

export default ClientTable
