import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route, useLocation, useNavigate} from 'react-router-dom';
import {useStateContext} from '../contexts/ContextProvider';
import axios from 'axios';

const AsanaCallback = () => {
    const location = useLocation();
    const history = useNavigate();
    const {user, setToken} = useStateContext();
    const {role} = user.user;

    const updateUserToken = async (code) => {
        console.log('user', user)
        if (user) {
            await axios.post(`${process.env.REACT_APP_BASE_URL}get_asana_token`, {
                asana_token: code,
                id: user?.user?.id
            }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                },
            })
                .then((res) => {
                    console.log('asana_token', res.data.data);
                    if (res.data.hasOwnProperty('error')) {
                        return;
                    }
                    localStorage.setItem('tekrevol_user', JSON.stringify(res.data.data))

                    if (role === 'client') {
                        if (state == 'listview') {
                            history('/listview');
                        } else {
                            history('/');
                        }
                    } else {
                        if (state == 'listview') {
                            history('/listview');
                        } else {
                            history('/appboard');
                        }
                    }
                })
                .catch(err => {
                    console.log('err====', err);
                })
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        const state = params.get('state');
        updateUserToken(code);
        //getAccessToken(code)
    }, [location.search, history]);

    return <div>Processing...</div>;


};


export default AsanaCallback;