import React, { useState, useEffect } from 'react';
import {  NavLink } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import UpComing from "./components/UpComing";
import TekPoints from "../../img/Loyalty.svg"
import { useStateContext } from "../../contexts/ContextProvider";
import Rewards from "./components/Rewards";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useFormik } from "formik";
import * as yup from "yup";
import {
    TextField,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
} from "@material-ui/core";
import  CustomSelect from 'react-select';
import DialogTitle from "@mui/material/DialogTitle";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Slide from "@mui/material/Slide";
import axios from "axios";
import Swal from "sweetalert2";
import { Loader, Toast } from '../../components/General';
import dayjs from 'dayjs';
import { S3Upload } from "../../utils/S3Upload";
import { Modal, Table, Form } from "react-bootstrap";
import {Oval} from "react-loader-spinner";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

// const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:(www\.)?|[a-zA-Z0-9.-]+\.)+[a-zA-Z]{2,}(?:\/|\?.*)?$/;
const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)(:[0-9]{1,5})?(\/.*)?$/;


const validationSchema = yup.object({
    title: yup.string().required("Meeting title is required"),
    agenda: yup.string().min(6, "Too Short!").max(70, "Too Long!").required("Meeting agenda is required"),
    time: yup.date().required('Time is required').test(
        'is-greater',
        'Time must be later than the current time',
        function (value) {
            const { date } = this.parent;
            if (!date || !value) return true;
            const selectedDate = dayjs(date);
            const selectedDateTime = dayjs(`${selectedDate.format('YYYY-MM-DD')}T${dayjs(value).format('HH:mm:ss')}`);
            return selectedDateTime.isAfter(dayjs());
        }
    ),
    date: yup.string().required("Meeting date is required"),
    project_type: yup.string().required("Project type is required"),
    // client_id: yup.string().required("Client name is required"),
    status: yup.string().required("Meeting status is required"),
    client_ids: yup.array().min(1, 'At least one Client is required').required('Client is required'),

    desc: yup
    .string()
    .url('Invalid URL format')
    .matches(urlRegex, 'Please enter a valid URL')
    .required('Meeting Link is required'),

    // desc: yup
    //     .string()
    //     .min(6, "Too Short!")
    //     .max(70, "Too Long!")
    //     .required("Meeting Link is required"),
    //project: yup.string().required("Project Name is required"),
    // status: yup.string().required("Meeting Status is required"),
    // record: yup
    //   .string()
    //   .min(6, "Too Short!")
    //   .max(70, "Too Long!")
    //   .optional("Recording link is required"),
    // .required('Recording link is required'),
});

function MeetingBoardView(props) {

    // const {
    //     meeting,
    //     user,
    //     client,
    //     table,
    //     manualProjects,
    //     meeting,
    //     setClient,
    //     getAllManualProjects,
    //     selectdedProject,
    //     project
    // } = useStateContext();

    const { user, meeting, selectedProject, project, manualProjects, getAllMeetings, getAllManualProjects } = useStateContext();

    const { role } = user.user;
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [projectState, setProjectState] = useState("manual");
    const [projectType, setProjectType] = useState([]);
    const [currentdate, setCurrentdate] = useState(new Date());
    const [selected, setSelected] = useState("btn2");
    const [users, setUsers] = useState([]);
    const [selectedFile, setSelectedFile] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);


    useEffect(() => {
        setLoading(true);
        getAllMeetings(selectedProject);
    }, [selectedProject])

    // const nextMonthDate = useMemo(
    //     () => getNextMonthDate(currentdate),
    //     [currentdate]
    // );

    const [activeTab, setActiveTab] = useState(1);
    const [currentMeetings, setCurrentMeetings] = useState([]);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };


    const getNextMonthMeeting = (currentDate) => {
        setCurrentMeetings(meeting.upcomming)
    };

    const getPreviousMonthMeeting = (currentDate) => {
        setCurrentMeetings(meeting.previous)
    };


    useEffect(() => {
        if (selected !== "btn1") {
            getNextMonthMeeting(currentdate);
        } else {
            getPreviousMonthMeeting(currentdate);
        }
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }, [selected, meeting]);

    const handleClickOpen = () => {
        setOpen(true);

        formik.resetForm();
    };

    const handleClose = () => {
        setOpen(false);
    };


    const formik = useFormik({
        initialValues: {

            title: "",
            agenda: "",
            // client_id: "",
            date: null,
            time: null,
            project_type: "",
            status: "",
            desc: "",
            recording_link: "",
            client_ids: [],
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {

            handleUpload(resetForm)
            //createMeeting();

        },
    });

    const handleUpload = async (resetForm) => {
        // if (selectedFile) {
        //     setLoading(true);
        //     try {
        //         const response = await S3Upload(file.type, file.name, file)
        //         if (response) {
        //             console.log("RESPONSE URL IMAGE", response)
        //             createMeeting(response,resetForm);
        //         }
        //     } catch (error) {
        //         setLoading(false)
        //         console.error("error", error)
        //     }
        // }
        // else{
            createMeeting(resetForm);
        // }
    }


    const createMeeting = async (resetForm) => {
        setBtnLoader(true);

        const dateTime = new Date(formik.values?.time).toISOString().split("T");

        const date =  formik.values?.date;
        const time = dateTime[1] || '';

        const clientArray = formik?.values?.client_ids;

        const createPostData = {
            user_id: user.user.id,
            manual_project_id: parseInt(selectedProject),//projectState === "manual" ? selectedProject.id : null,
            name: formik.values.title,
            description: formik?.values?.agenda,
            meeting_link: formik?.values?.desc,
            project_type: parseInt(selectedProject),
            priority: 0,
            // client_id: formik?.values?.client_id,
            date_time: date + " " + time,
            status: formik.values.status,
            client_ids: clientArray.length > 0 ? clientArray.map(item => item.value).toString() : ""

        };

        // if (file_uri) {
        //     createPostData['recording_link'] = file_uri;
        // }

        await axios
        .post(
            `${process.env.REACT_APP_BASE_URL}meeting`,
            { ...createPostData },
            {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            }
        )
        .then((res) => {
            if (res.status === 200) {
                getAllMeetings(selectedProject);
                resetForm({values: ''})
                handleClose();
                Toast.fire({
                    icon: "success",
                    title: "Meeting Created Successfully!",
                });
            }
        })
        .catch((error) => {
            resetForm({values: ''})
            handleClose();

            Toast.fire({
                icon: "error",
                title: "Something went wrong!, Please try again Later",
            });
        })
        .finally(() => {
            setBtnLoader(false);
        });
      
    };

    const getProjectType = () => {
        if (user) {
            axios
                .get(`${process.env.REACT_APP_BASE_URL}project-category`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                })
                .then((res) => {
                    setProjectType(res.data.data);
                })
                .catch((err) => {
                    console.log("err====", err);
                });
        }
    };

    const getAllUsers = () => {
        setLoading(true);
        // axios.get(`${process.env.REACT_APP_BASE_URL}get_clients_by_user/${user.user.id}`,{
        axios.get(`${process.env.REACT_APP_BASE_URL}get_clients_by_project/${selectedProject}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            setUsers(response.data.data);
            setLoading(false);
        }).catch((err) => {
            console.log('err====', err);
        })
        // console.log(table);
    }

    useEffect(() => {
        getAllUsers();

    }, [selectedProject]);

    useEffect(() => {
        setLoading(true);
        getProjectType();
        getAllManualProjects();
    }, []);

    useEffect(() => {
        if (selected !== "btn1") {
            getNextMonthMeeting(currentdate);
        } else {
            getPreviousMonthMeeting(currentdate);
        }
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }, [selected, meeting]);


    if (loading) {
        return <Loader />
    }

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }


    const options = users.length ? users.map(item => ({
        value: item.id,
        label: `${item.first_name} ${item.last_name}`
    })) : [];


    return (
        <>
            <div className="main_top_title">
                {/* {role !== "client" ? (
                        <div className="main-btn-txt">
                            <button className="btn-banner"  onClick={handleClickOpen}>Schedule a Meeting</button>
                        </div>
                    ) : null
                } */}
                <div className="main_title"><h1>Meeting <span>Board</span></h1></div>
                <div className="main_btn_txt">
                    <ul>
                        <li>
                            <NavLink to="">
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="">
                                Appboard
                            </NavLink>
                        </li>
                        <li className="active">
                            <NavLink to="">
                                Meeting Board
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="main_content_container">
                {role !== "client" ? (
                    <div className="main-btn-txt">
                        <button className="btn-banner" onClick={handleClickOpen}>Create Meeting</button>
                    </div>
                ) : null}

                <br />

                <Container fluid className="p-0">
                    <Row>
                        <Col xxl={role === 'client' ? 9 : 12}>
                            <div className="main_style_box">
                                <ul className="downloadable_tabs">
                                    {/* <li onClick={() => handleTabClick(1)} className="active">Upcoming</li>
                                    <li onClick={() => handleTabClick(2)}>Previous</li>
                                     */}
                                    <li
                                        onClick={() => setSelected("btn2")}
                                        className={`${selected === "btn2"
                                            ? "active"
                                            : ""
                                            }`}
                                    >Upcoming
                                    </li>
                                    <li
                                        onClick={() => setSelected("btn1")}
                                        className={`${selected === "btn1"
                                            ? "active"
                                            : ""
                                            }`}
                                    >Previous
                                    </li>
                                </ul>
                                <div className="downloadable_content">
                                    {activeTab === 1 &&
                                        <UpComing meeting={currentMeetings} loader={loading} options={options} />
                                    }
                                    {activeTab === 2 &&
                                        <UpComing meeting={currentMeetings} loader={loading} options={options} />
                                    }

                                </div>
                            </div>
                        </Col>

                        {role === "client" ? (

                            <Col xxl={3}>
                                {/* <div className="side_content">
                                    <div className="rewads_section">
                                        <h6 className="points_claim"><span>120</span> Points claimed</h6>
                                        <h6 className="points_claim"><span>400</span> Points remaining for your next reward</h6>
                                        <div class="loyalty_sec">
                                            <div class="img">
                                                <img src={TekPoints} alt="Loyalty" />
                                            </div>
                                            <div class="loyalty_txt">1200 <span>Points</span></div>
                                            </div>
                                    </div>
                                    <div className="reward_box">
                                        <div className="icons">
                                            <img src={TekPoints} alt="TekPoints"/>
                                        </div>
                                        <div className="txt">Landing Page <span>50 More Points Remaining</span></div>
                                        <div className="points">50</div>
                                    </div>
                                    <div className="reward_box">
                                        <div className="icons">
                                            <img src={TekPoints} alt="TekPoints"/>
                                        </div>
                                        <div className="txt">On Page <span>200 More Points Remaining</span></div>
                                        <div className="points">200</div>
                                    </div>
                                    <div className="reward_box">
                                        <div className="icons">
                                            <img src={TekPoints} alt="TekPoints"/>
                                        </div>
                                        <div className="txt">ASO <span>250 More Points Remaining</span></div>
                                        <div className="points">250</div>
                                    </div>
                                    <div className="reward_box">
                                        <div className="icons">
                                            <img src={TekPoints} alt="TekPoints"/>
                                        </div>
                                        <div className="txt">content strategy <span>400 More Points Remaining</span></div>
                                        <div className="points">400</div>
                                    </div>

                                </div> */}
                                <Rewards />
                            </Col>
                            // <div className="meetingboard-grid-rewards pt-5">
                            //     <Rewards/>
                            // </div>
                        ) : null}


                    </Row>
                </Container>

                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Schedule a Meeting"}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={formik.handleSubmit} className="space-y-6 ">
                            <TextField
                                className="w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%] "
                                id="title"
                                name="title"
                                label="Meeting Title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                helperText={formik.touched.title && formik.errors.title}
                            />
                            <TextField
                                className="w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%] "
                                id="agenda"
                                name="agenda"
                                label="Meeting Agenda"
                                value={formik.values.agenda}
                                onChange={formik.handleChange}
                                error={formik.touched.agenda && Boolean(formik.errors.agenda)}
                                helperText={formik.touched.agenda && formik.errors.agenda}
                            />


                            <div
                                className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                                <FormControl fullWidth error={formik.touched.client_ids && Boolean(formik.errors.client_ids)}>
                                    {/* <InputLabel id="demo-simple-select-labels">Client</InputLabel> */}
                                    {/* <label htmlFor="demo-simple-select-labels" id="demo-simple-select-labels">asdasd</label> */}
                                    
                                    <label htmlFor="demo-simple-select-labels" id="demo-simple-select-labels" class="text-secondary pb-1">Client</label>

                                    <CustomSelect
                                        fullWidth
                                        labelId="demo-simple-select-labels"
                                        name="client_ids"
                                        label="Client"
                                        isMulti
                                        id="client_ids"
                                        options={options}
                                        value={formik.values.client_ids} // Ensure it's a single value
                                        helperText={formik.touched.client_ids && formik.errors.client_ids}
                                        onChange={(event) => {
                                            formik.setFieldValue('client_ids', event);
                                        }}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                    >
                                        {/*{users?.map((item, index) => (*/}
                                        {/*    <MenuItem key={index} value={item.id}>*/}
                                        {/*        {item.first_name} {item.last_name}*/}
                                        {/*    </MenuItem>*/}
                                        {/*))}*/}
                                    </CustomSelect>

                                    <p style={{color:'#dc3545'}}>
                                    {formik.touched.client_ids && formik.errors.client_ids}
                                    </p>

                                    {/* {formik.touched.client_ids &&
                                        <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.client_ids}</div>} */}

                                    {/* <FormHelperText>{formik.touched.client && formik.errors.client}</FormHelperText> */}
                                </FormControl>
                            </div>

                            <FormControl className="custom-input m-0" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DateTimePicker"]}>
                                        <DatePicker
                                            name="date"
                                            id="date"
                                            inputFormat="E MMM dd yyyy "
                                            label="Meeting Date"
                                            value={formik.values.date}
                                            // onChange={(value) => formik.setFieldValue("date", value)}

                                            onChange={value => formik.setFieldValue('date',  dayjs(value).format("YYYY-MM-DD"))}
                                            minDate={dayjs()}
                                        />
                                        {formik.touched.date &&
                                            <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.date}</div>}

                                        <TimePicker
                                            fullWidth
                                            className="w-[100%]"
                                            name="time"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="time"
                                            inputFormat="E MMM dd yyyy HH:MM:SS O"
                                            label="Meeting Time"
                                            value={formik.values.time}
                                            onChange={(value) => formik.setFieldValue("time", value)}
                                            error={formik.touched.time && Boolean(formik.errors.time)}
                                        />
                                        {formik.touched.time &&
                                            <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.time}</div>}

                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>

                            <div
                                className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Project Category
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="project_type"
                                        label="Project Category"
                                        id="project_type"
                                        value={formik.values.project_type}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.project_type &&
                                            Boolean(formik.errors.project_type)
                                        }
                                        helperText={
                                            formik.touched.project_type && formik.errors.project_type
                                        }
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                    >
                                        {projectType?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.name}>
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>

                                    {formik.touched.project_type &&
                                        <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.project_type}</div>}
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Meeting Status
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="status"
                                        label="Meeting Status"
                                        id="status"
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.status && Boolean(formik.errors.status)
                                        }
                                        helperText={formik.touched.status && formik.errors.status}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                    >
                                        <MenuItem value={0}>To Be Held</MenuItem>
                                        <MenuItem value={1}>Done</MenuItem>
                                    </Select>


                                    {formik.touched.status &&
                                        <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.status}</div>}
                                </FormControl>

                            </div>
                            <div
                                className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                                <TextField
                                    className="w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]"
                                    id="desc"
                                    name="desc"
                                    label="Meeting Link"
                                    value={formik.values.desc}
                                    onChange={formik.handleChange}
                                    error={formik.touched.desc && Boolean(formik.errors.desc)}
                                    helperText={formik.touched.desc && formik.errors.desc}
                                />
                            </div>

                            {/* <div
                                className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <div className="file-upload-container">
                                    <InputLabel className="pb-2" id="demo-simple-select-label">
                                        Recording Link
                                    </InputLabel>
                                    <input
                                        type="file"
                                        onChange={handleFileInput} />
                                </div>
                            </div> */}

                            <div className="flex justify-end mt-3">
                                <button
                                    className="bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white"
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                    disabled={btnLoader}
                                >
                                    {btnLoader ? <Oval
                                        visible={true}
                                        height="20"
                                        width="20"
                                        color="#ffffff"
                                        secondaryColor="#ffffff"
                                        ariaLabel="oval-loading"
                                        wrapperStyle={{disply: 'flex', justifyContent: 'center'}}
                                        wrapperClass=""
                                    />:"Save"}
                                </button>
                            </div>

                        </form>
                    </DialogContent>
                </Dialog>

            </div>
        </>
    );
}

const getNextMonthDate = (currentDate) => {
    // Get the month and year of the current date
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Calculate the month and year of the next month
    let nextMonth = currentMonth + 1;
    let nextYear = currentYear;

    if (nextMonth > 11) {
        // If the next month is greater than 11 (December), add 1 to the year and set the month to 0 (January)
        nextMonth = 0;
        nextYear += 1;
    }

    // Create a new Date object for the first day of the next month
    const nextMonthDate = new Date(nextYear, nextMonth, 1);

    return nextMonthDate;
};

export default MeetingBoardView;