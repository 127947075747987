import React, { useState, useMemo } from 'react';
import { Modal, Table, Row, Col } from "react-bootstrap";
import DOC from "../../../img/files/DOC_01.svg";
import IMG01 from "../../../img/img1.jpeg";
import { DeletedIcon, DownloadIcon, ViewIcon } from "../../../app-constants";
import PDF from "../../../img/files/PDF_01.svg";
import IMG02 from "../../../img/img2.jpeg";
import JPG from "../../../img/files/JPG_01.jpg";
import IMG03 from "../../../img/img3.jpeg";
import XLS from "../../../img/files/XLS_01.svg";
import IMG04 from "../../../img/img4.jpeg";
import ModalView from "./ModalView";
import { Loader, Toast, NoRecordFound } from "../../../components/General";
import DownloadButton from '../../../components/DownloadButton';
import DownloadButtonDemo from '../../../components/DownloadButtonDemo';
import ReactPaginate from 'react-paginate';


function MarketingCollateral(props) {
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const minimumDataForPagination = 1; // Set the minimum data threshold for pagination

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const downloadFile = async (url) => {
        try {
            setLoader(true);
            const response = await fetch(url);
            const blob = await response.blob();
            const urlObject = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = urlObject;

            // Extract file name from response headers or use a default name
            const contentDisposition = response.headers.get('content-disposition');
            let fileName = 'downloaded_file';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                if (fileNameMatch && fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
            }

            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(urlObject);
            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.error('Error downloading file:', error);
        }
    };

    const offset = currentPage * itemsPerPage;
    const currentPageData = useMemo(() => props.data.slice(offset, offset + itemsPerPage), [props.data, offset, currentPage]);

    const pageCount = Math.ceil(props.data.length / itemsPerPage);

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const handleFirstPageClick = () => {
        setCurrentPage(0);
    };

    const handleLastPageClick = () => {
        setCurrentPage(pageCount - 1);
    };

    const handleNextPageClick = () => {
        if (currentPage < pageCount - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPageClick = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const determineFileType = (item) => {
        return item?.file_extention == 'pdf' ? PDF : DOC;
    }


    return (
        <>
            <div className="download_tabs mr-3 ml-3">
                <Table responsive>
                    <thead>
                        <tr>
                            <th className="text-left">File Name</th>
                            <th className="text-left">Owner</th>
                            <th className="text-center">Date Modified</th>
                            <th className="text-center">Size</th>
                            <th className="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPageData && currentPageData.length > 0 ? (
                            currentPageData.map((item, index) => (
                                <tr key={index}>
                                    
                                    <td className="text-left">
                                        <div className="table_namee">
                                            <div className="img_table">
                                                <img src={determineFileType(item)} alt="IMG01" />
                                            </div>
                                            <div className="title_table">
                                                {item?.file_name}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-left">
                                        <div className="table_owner">
                                            <div className="img_table">
                                                <img src={IMG01} alt="IMG01" />
                                            </div>
                                            <div className="title_table">
                                                {item?.owner}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-center">{item?.created_at}</td>
                                    <td className="text-center">{item?.file_size}</td>
                                    <td className="text-center">
                                        <ul>
                                            <DownloadButtonDemo url={item.file_path} fileName={item.file_name} />
                                        </ul>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5">
                                    <NoRecordFound />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                {props.data.length > minimumDataForPagination && (
                    <div className="pagination-wrapper table-pagination">
                        <div className="pagination-controls">
                            <button onClick={handleFirstPageClick} className="btn btn-link" disabled={currentPage === 0}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="9"
                                fill="none"
                                viewBox="0 0 6 9"
                                >
                                <path
                                    fill="#333"
                                    d="M4.622 8.192l.94-.94-3.054-3.06 3.054-3.06-.94-.94-4 4 4 4z"
                                ></path>
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="6"
                                    height="9"
                                    fill="none"
                                    viewBox="0 0 6 9"
                                    >
                                    <path
                                        fill="#333"
                                        d="M4.622 8.192l.94-.94-3.054-3.06 3.054-3.06-.94-.94-4 4 4 4z"
                                    ></path>
                                    </svg>
                            </button>
                            <button onClick={handlePreviousPageClick} className="btn btn-link" disabled={currentPage === 0}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="6"
                                    height="9"
                                    fill="none"
                                    viewBox="0 0 6 9"
                                    >
                                    <path
                                        fill="#333"
                                        d="M4.622 8.192l.94-.94-3.054-3.06 3.054-3.06-.94-.94-4 4 4 4z"
                                    ></path>
                                </svg>
                            </button>
                            <ReactPaginate
                                previousLabel={''} // Disabled
                                nextLabel={''} // Disabled
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                forcePage={currentPage} // Ensure the component reflects the currentPage state
                                containerClassName={'pagination justify-content-center'}
                                activeClassName={'active'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                renderOnZeroPageCount={null}
                            />
                            <button onClick={handleNextPageClick} className="btn btn-link" disabled={currentPage >= pageCount - 1}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="9"
                                fill="none"
                                viewBox="0 0 6 9"
                                style={{ transform: 'rotate(180deg)' }} // Adjust the angle as needed
                                >
                                <path
                                    fill="#333"
                                    d="M4.622 8.192l.94-.94-3.054-3.06 3.054-3.06-.94-.94-4 4 4 4z"
                                ></path>
                                </svg>
                            </button>
                            <button onClick={handleLastPageClick} className="btn btn-link" disabled={currentPage >= pageCount - 1}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="9"
                                fill="none"
                                viewBox="0 0 6 9"
                                style={{ transform: 'rotate(180deg)' }} // Adjust the angle as needed
                                >
                                <path
                                    fill="#333"
                                    d="M4.622 8.192l.94-.94-3.054-3.06 3.054-3.06-.94-.94-4 4 4 4z"
                                ></path>
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="9"
                                fill="none"
                                viewBox="0 0 6 9"
                                style={{ transform: 'rotate(180deg)' }} // Adjust the angle as needed
                                >
                                <path
                                    fill="#333"
                                    d="M4.622 8.192l.94-.94-3.054-3.06 3.054-3.06-.94-.94-4 4 4 4z"
                                ></path>
                            </svg>
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <Modal show={show} onHide={handleClose}>
                <ModalView />
            </Modal>
        </>
    );
}

export default MarketingCollateral;