import React, { useEffect, useState, memo } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from '../../contexts/ContextProvider';
import ReactReadMoreReadLess from 'react-read-more-read-less'
import { Oval } from 'react-loader-spinner'
import { DateFormatWithSlash, DateAndTimestamp } from '../../utils/DateFormatWithSlash';
import { ThreeDots } from 'react-loader-spinner'

const InvoiceTable = ({ data, detailed, loading }) => {

  const { table } = useStateContext();


  console.log("INVOICE PROJECTS DATA", data)


  return (
    <div className={`w-[100%]  ${detailed ? '' : 'h-[74vh]'}  tab:w-[94%] mobile:w-[91%] small:w-[90%] p-0 table-div mobile:ml-3 small:ml-3 bg-[#FFFFFF] `} >
      {!detailed ?
        <div className='invoice-task' >
          <p className='text-[#131313] lg:text-[20px] sm:text-[14px] xm:text-[12px]  rounded-sm font-semibold ' >Project List</p>
        </div> : null}

      <div className={` w-[100%]  bg-[#FAFAFA]`}>
        {
          loading && <div className=' justify-center items-center flex h-[40vh] w-[100%]' >
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#F37A20"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        }
        {
          !loading && <table className={` w-[100%] `}>
            <thead className="bg-gray-50s table w-[100%] table-fixed">
              <tr>
                <th className="w-[40%] pl-[20px]  p-3 text-[13px] md:text-[11px] sm:text-[9px] xm:text-[8px] text-[#505887]  text-left"><p className='' >Project Name</p></th>
                <th className="  p-3 text-[13px] md:text-[11px] sm:text-[9px] xm:text-[8px] text-[#505887] text-left"><p className=' ' >Next Deadline</p></th>
                <th className="  p-3 text-[13px] md:text-[11px] sm:text-[9px] xm:text-[8px] text-[#505887] text-left"><p className='  ' >Current Phase</p></th>
                <th className="  p-3 text-[13px] md:text-[11px] sm:text-[9px] xm:text-[8px] text-[#505887] text-left"><p className='' >Project Category</p></th>

              </tr>
            </thead>
            <div className={data.length > 5 ? `overflow-y-scroll  ${detailed ? '' : 'h-[200px]'} ` : ''}>

              {data.length !== 0 ?
                <tbody className="divide-y w-[100%]  divide-gray-100" >
                  {data.reduce((uniqueItems, item) => {
                    // console.log(uniqueItems);
                    const existingItem = uniqueItems.find(
                      (uniqueItem) => {
                        // console.log(uniqueItem);
                        if (item.manual_project && uniqueItem.manual_project) {
                          // console.log(uniqueItem.manual_project);
                          return uniqueItem.manual_project.name === item.manual_project.name
                        }
                        else {
                          // console.log(table);
                          const selectedProject = table.find(asana => `${asana.gid}` === item.asana_project_id);

                          return selectedProject?.gid === item.asana_project_id ? item : null

                        }

                      })

                    // console.log('existing======',existingItem);
                    if (!existingItem) {
                      uniqueItems.push(item);
                    }
                    console.log("UNIQUE ITEMS", uniqueItems);
                    return uniqueItems;
                  }, []).map((item, index) => {
                    let project_name;
                    if (item.manual_project) {
                      project_name = item.manual_project.name;
                    } else {
                      // console.log(item);
                      const selectedProject = table.find(asana => `${asana.gid}` === item.asana_project_id);
                      if (selectedProject) {
                        project_name = selectedProject.name;
                      }
                      // console.log(selectedProject);

                    }
                    console.log("ITEM", item)
                    return (
                      project_name &&
                      <tr key={index} className="bg-white w-[100%] hover:bg-[#FAFAFA]  table table-fixed  ">
                        <td className="w-[40%]  pl-[20px] p-3 text-sm  text-gray-700 cursor-pointer">
                          <Link state={{ project_type: item }} to={`/projectInvoices/invoice/${item.manual_project_id !== null ? item.manual_project_id : item.asana_project_id}`} >
                            <p className=" text-[#505887] text-[12px] md:text-[10px] sm:text-[9px] xm:text-[8px]  font-medium ">{project_name}</p>
                          </Link>
                        </td>
                        <td className="p-3 font-medium text-[12px] md:text-[10px] sm:text-[9px] xm:text-[8px]  text-[#718EBF]">
                          <p className={` font-medium  `} >{DateAndTimestamp(item.due_date)}</p>
                        </td>

                        <td className="p-3 font-medium  text-[12px] md:text-[10px] sm:text-[9px] xm:text-[8px]  text-[#718EBF]">
                          <p className={`break-all font-medium pl-[2px]  `}>
                            {
                              item.manual_project_id !== null ? item.manual_project?.phase?.name : 'Alpha Development'
                            }
                          </p>
                        </td>
                        <td className="p-3 ">
                          <span className={`p-2 text-[12px] md:text-[10px] sm:text-[9px] xm:text-[8px]  font-medium w-[43px] h-[24px] text-[#7ACB4A] bg-[#F1FFEF]`} >{
                            item.manual_project_id !== null ? item.manual_project?.category?.name : 'Development'}</span>
                        </td>

                      </tr>
                    )
                  })
                  }
                </tbody>
                :
                <div className='flex justify-center items-center p-4 bg-[white]' >
                  <p className='text-center text-[13px] text-[#979797] font-bold'>No projects with invoices</p>
                </div>
              }
            </div>
          </table>
        }
      </div>
    </div>
  )
}

export default memo(InvoiceTable);
