import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../contexts/ContextProvider';
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import reactangle from '../../data/rectangle.svg'
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner'




const renderChart = (percentage, loading) => {
    if (loading) return <div className='flex justify-center ' >
        <ThreeDots
            width="30"
            radius="9"
            color="#F37A20"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
        />
    </div>
    return <CircularProgressbar
        value={percentage}
        background={true}
        strokeWidth={17}
        text={percentage + '%'}
        styles={{
            // Customize the root svg element
            root: {},
            // Customize the path, i.e. the "completed progress"
            path: {
                // Path color
                stroke: `#F47A1F`,
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
                // Customize transition animation
                transition: 'stroke-dashoffset 0.5s ease 0s',
                // Rotate the path
            },
            // Customize the circle behind the path, i.e. the "total progress"
            trail: {
                // Trail color
                stroke: '#2A303C',
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
                // Rotate the trail
            },
            // Customize the text
            text: {
                // Text color
                fill: '#2A303C',
                // Text size
                fontSize: '14px',
                fontWeight: 'bold'
            },
            // Customize background - only used when the `background` prop is true
            background: {
                fill: 'white',
            },
        }}
    />
}

function ProjectProgress({ projectData }) {
    console.log(projectData, '=================>');
    const { asanaToken } = useStateContext()
    const [percentage, setPercentage] = useState(0);
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(false)
        let ratio = Math.ceil((projectData.num_completed_tasks / projectData.num_tasks || 0) * 100) ?? 0;
        setPercentage(ratio)
        // if (projectId) {
        //     (async () => {

        //         let taskResponse = await axios.get(`${process.env.REACT_APP_ASANA_BASE_URL}projects/${projectId}/task_counts?opt_fields=num_completed_tasks,num_incomplete_tasks,num_tasks,num_incomplete_milestones`, {
        //             headers: {
        //                 Authorization: `Bearer ${asanaToken}`,
        //             },
        //         })

        //         if (taskResponse.status == 200) {

        //             let { data } = taskResponse.data;
        //             let ratio = Math.ceil((data.num_completed_tasks / data.num_tasks || 0) * 100);
        //             // console.log("RATIO", ratio , {completed: data.num_completed_tasks , tasks:  data.num_tasks})
        //             setPercentage(ratio)
        //             setLoading(false)
        //         }
        //         setLoading(false)

        //         return () => {
        //             setPercentage(0)
        //         }
        //     })()
        // } else {
        //     let ratio = Math.ceil((project.complete_task / project.task_count || 0) * 100);
        //     // console.log("RATIO", ratio , {completed: data.num_completed_tasks , tasks:  data.num_tasks})
        //     setPercentage(ratio)
        //     setLoading(false)

        // }
    }, [])

    return (
        <div>
            {
                renderChart(percentage, loading)
            }
        </div>
    )
}

export default ProjectProgress

