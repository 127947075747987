import React, { useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import Activity from './Activity'
import Benefits from './Benefits'
import { Oval } from 'react-loader-spinner'

const ManageRewards = () => {
    const { points, user } = useStateContext();
    const { role } = user.user;

    return (
        <div className='mb-[111px] w-[75%] mx-auto '>

            <h1 className={` meeting-text pt-[5px] mt-2 `}>
                Manage Rewards &{" "}
                <span style={{ fontWeight: "700" }} className="text-[#F37A20]">
                    Points
                </span>
            </h1>

            <p>Here you can manage all your rewards and points</p>

            <div className="rewards-manage">
                <div className='space-y-24'>
                    <Activity />
                    <Benefits />
                </div>
            </div>
        </div>
    );
};

export default ManageRewards;
