import React, { useState, useEffect, memo } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import axios from 'axios'
import { MdDelete } from 'react-icons/md';
import Swal from 'sweetalert2';
import { Oval } from 'react-loader-spinner'
import { RiEditBoxFill } from 'react-icons/ri';
import { IoEyeOutline } from "react-icons/io5";
import { VscGitPullRequestCreate } from "react-icons/vsc";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Table } from "react-bootstrap";
import {
    TextField,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel
} from '@material-ui/core';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DateFormatWithLetters, DateAndTimestamp } from '../../../utils/DateFormatWithSlash'
import Slide from '@mui/material/Slide';
import { S3Upload } from '../../../utils/S3Upload'
import { formatMDY } from '../../../utils/Helper'
import { Toast } from '../../../components/General';

window.Buffer = window.Buffer || require("buffer").Buffer;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)(:[0-9]{1,5})?(\/.*)?$/;

const validationSchema = yup.object({
    // client: yup
    //     .string()
    //     .required('Client Name is required'),
    amount: yup
        .string()
        .required('Amount is required'),
    status: yup
        .string()
        .required('Status is required'),
    due_date: yup
        .date().required('Due Date is required'),

    link: yup
        .string()
        .url('Invalid URL format')
        .matches(urlRegex, 'Please enter a valid URL')
        .nullable('URL is required'),

    // pay_date: yup
    //     .date().required('Pay Date is required'),
    // link: yup
    //   .string()
    //   .required('Invoice Link is required'),
});



const InvoiceHistory = ({ data, refresher, loader, projectPhase, users, recallData }) => {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const [selected, setSelected] = useState(0);
    const [editValues, setEditValues] = useState({});
    const { user, client, selectedProject } = useStateContext();
    const { role } = user.user;
    const [selectedFile, setSelectedFile] = useState(null);
    // const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState([]);


    const [partialInvoice, setPartialInvoice] = useState({});
    const [partialModalOpen, setPartialModalOpen] = useState(false);

    const [partialAmountError, setPartialAmountError] = useState(false);
    const [partialAmountErrorMessage, setPartialAmountErrorMessage] = useState("");

    useEffect(() => {
        const isWhatsNewEndpoint = location.pathname.endsWith('upcoming');
       
        if(isWhatsNewEndpoint){
            setSelected(0);
        }

    }, [])



    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteMeeting(id)
            }
        })

    }

    const handleClickOpen = (item) => {
        setEditValues(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const EditInvoice = async (file_uri = "", resetForm) => {
        setBtnLoader(true);

        // Ensure client is defined and has a default value of an empty array if not provided
        const clients = client || [];
        const selectedClient = clients.find(item => `${item.first_name} ${item.last_name}` === formik.values.client);

        // let date     = formik?.values?.due_date ? new Date(formik.values.due_date)?.toUTCString() : null;
        let pay_date = formik?.values?.pay_date ? new Date(formik.values.pay_date)?.toUTCString() : null;
        let due_dates = dayjs(formik.values.due_date).format("YYYY-MM-DD");


        // Create the data object for the invoice update
        const data = {
            // "due_date": date,
            "due_dates": due_dates,
            "pay_date": pay_date,
            "status": formik.values.status,
            "link": formik.values.link,
            "amount": removeCommaAndConvertToFloat(formik.values.amount),
            "project_phase_id": formik.values.project_phase_id,
            "invoice_type_id" : formik.values.invoice_type_id,
            "partial_amount" : formik.values.partial_amount,
            "remaining_amount" : formik.values.remaining_amount,
        };

       
        // Add client_id to data if selectedClient is found
        if (selectedClient) {
            data["client_id"] = selectedClient.id;
        }

        // Add file_path to data if file_uri is provided
        if (file_uri) {
            data["file_path"] = file_uri;
        }

        try {
            // Send the PUT request to update the invoice
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}invoice/${editValues.id}`,
                { ...data, id: editValues.id }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            // Handle the response
            if (response.status === 200) {
                // refresher();

                getAllInvoices();
                handleClose()
                setBtnLoader(false);
                resetForm({ values: '' })

                Toast.fire({
                    icon: 'success',
                    title: 'Invoice Updated successfully!',
                })
            }
        } catch (error) {
            // Handle errors
            handleClose()
            setBtnLoader(false);
            console.log(error);
            resetForm({ values: '' })

            Toast.fire({
                icon: 'success',
                title: 'Something went wrong!',
            })
        }
    };

    const deleteMeeting = async (id) => {

        await axios.delete(`${process.env.REACT_APP_BASE_URL}invoice/${id}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((res) => {
            if (res.status === 200) {
                getAllInvoices();

                Toast.fire({
                    icon: "success",
                    title: "Invoice Deleted Successfully",
                });
            }
        })
        .catch((error) => {
            
            console.log(error);

            Toast.fire({
                icon: "error",
                title: "Something went wrong!, Please try again Later",
            });
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const handleUpload = async (file, resetForm) => {
        if (file && formik.values.attachment == 1) {
            setBtnLoader(true);
            try {
                const response = await S3Upload(file.type, file.name, file)
                if (response) {
                    console.log("RESPONSE URL IMAGE", response)
                    EditInvoice(response, resetForm);
                }
            } catch (error) {
                handleClose()
                setBtnLoader(false)
                console.error("error", error)
            }
        } else {
            EditInvoice('', resetForm);
        }
    }

    const partialHandleUpload = async (file, resetForm) => {
        if (file && partialFormik.values.attachment == 0) {
            setBtnLoader(true);
            try {
                const response = await S3Upload(file.type, file.name, file)
                if (response) {
                    console.log("RESPONSE URL IMAGE", response)
                    createPartialInvoice(response, resetForm);
                }
            } catch (error) {
                handleClose()
                setBtnLoader(false)
                console.error("error", error)
            }
        } else {
            createPartialInvoice('', resetForm);
        }
    }

    const createPartialInvoice = async (file_uri = "", resetForm) => {
        setBtnLoader(true);

        let payload = {

            user_id: user.user.id,
            manual_project_id: selectedProject,
            client_id: partialFormik.values.client_id,
            amount: partialFormik.values.amount,
            due_dates: partialFormik.values.due_date,
            attachment: partialFormik.values.attachment,
            status: partialFormik.values.status,
            link: file_uri || null,
            file_path: partialFormik.values.link || null,
            project_phase_id: partialFormik.values.project_phase_id,
            invoice_type_id: 1,
            partial_amount: partialFormik.values.partial_amount,
            remaining_amount: partialFormik.values.remaining_amount,
            paid_amount: partialFormik.values.paid_amount || '',
        };

        try {
            // Send the PUT request to update the invoice
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}create-partial-invoice/${partialInvoice.id}`,
                { ...payload, id: partialInvoice.id }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            // Handle the response
            if (response.status === 200) {
                // refresher();

                resetForm({ values: '' })
                setBtnLoader(false);
                getAllInvoices();
                handleClosePartialModal()

                Toast.fire({
                    icon: 'success',
                    title: 'Invoice Created successfully!',
                })
            }
        } catch (error) {
            // Handle errors
            handleClosePartialModal()
            setBtnLoader(false);
            console.log(error);
            resetForm({ values: '' })

            Toast.fire({
                icon: 'success',
                title: 'Something went wrong!',
            })
        }
    
    };


    const handleClosePartialModal = () => {
        setPartialModalOpen(false);
    };

    const handlePartialInvoice =  (item) => {
        console.log("handlePartialInvoice");
        console.log(item);
        setPartialModalOpen(true);
        setPartialInvoice(item);
    }

    const formik = useFormik({
        initialValues: {
            //client: editValues.client?.first_name + " " + editValues.client?.last_name,
            amount: editValues.amount,
            due_date: editValues.due_date,
            attachment:editValues.link !== null && editValues.link !== '' ? 1 : editValues.file_path !== null && editValues.file_path !== '' ? 0 : null,
            // pay_date: editValues.pay_date,
            status: editValues.status === 0 ? 0 : 1,
            link: editValues.link,
            project_phase_id: editValues.project_phase_id,
            invoice_type_id: editValues.invoice_type_id,

        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            handleUpload(selectedFile, resetForm)

        },
    });

    const partialValidationSchema = yup.object({
        client_id: yup.string().required('Client Name is required'),
        due_date: yup.date().required('Due Date is required'),
        pay_date: yup.date().notRequired(),
        project_phase_id: yup.string().notRequired(),
        invoice_type_id: yup.number().notRequired(),

        amount: yup.number().notRequired(),
        paid_amount: yup.number().notRequired(),
        partial_amount: yup.number().notRequired(),
        remaining_amount: yup.number().notRequired(),

        status: yup.number().notRequired(),
        attachment: yup.number().notRequired(),
        link: yup
        .string()
        .url('Invalid URL format')
        .matches(urlRegex, 'Please enter a valid URL')
        .nullable('URL is required'),
    });
    
    const partialFormik = useFormik({
        initialValues: {
            amount: '',
            due_date: '',
            attachment: '',
            status: '',
            link: '',
            project_phase_id: '',
            invoice_type_id: 1,
            partial_amount: null,
            remaining_amount: null,
            paid_amount: null,
        },
        validationSchema: partialValidationSchema,
        onSubmit: (values, { resetForm }) => {

            // console.log(values);
            // handleUpload(selectedFile, resetForm)
            partialHandleUpload(selectedFile, resetForm)

        },
    });

    function removeCommaAndConvertToFloat(numberString) {
        // Remove commas and convert to float
        if (numberString == null) return NaN;

        // Ensure numberString is a string
        numberString = String(numberString);

        // Remove commas and convert to float
        return parseFloat(numberString.replace(/,/g, ''));
    }

    function formatNumberWithCommas(numberString) {
        // Convert the string to a number
        const number = parseFloat(removeCommaAndConvertToFloat(numberString));

        // Check if it's a valid number
        if (isNaN(number)) {
            return numberString; // Return original if not a valid number
        }

        return (number.toLocaleString());
    }

    useEffect(() => {

        // console.log(partialInvoice);

        partialFormik.setValues({
            client_id: partialInvoice.client_id || '',
            due_date: null,
            pay_date: partialInvoice.pay_date !== null && partialInvoice.pay_date !== '' ? dayjs(partialInvoice.pay_date) : null,
            status: 0,
            attachment: null,
            link: null,
            amount: formatNumberWithCommas(partialInvoice.amount) || '',
            project_phase_id: String(partialInvoice.project_phase_id),
            invoice_type_id: 1,
            partial_amount: formatNumberWithCommas(partialInvoice.remaining_amount),
            remaining_amount: 0,
            paid_amount: formatNumberWithCommas(partialInvoice.partial_amount)
        });

    }, [partialInvoice]);
    
    
    useEffect(() => {
        formik.setValues({
            // client_id : editValues.client_id || '',
            due_date: dayjs(editValues.due_dates) || '',
            pay_date: editValues.pay_date !== null && editValues.pay_date !== '' ? dayjs(editValues.pay_date) : null,
            status: editValues.status === 0 ? 0 : 1,
            attachment: editValues.link !== null && editValues.link !== '' ? 1 : editValues.file_path !== null && editValues.file_path !== '' ? 0 : null,
            link: editValues.link || '',
            amount: formatNumberWithCommas(editValues.amount) || '',
            project_phase_id: String(editValues.project_phase_id),
            invoice_type_id: String(editValues.invoice_type_id),

            remaining_amount: formatNumberWithCommas(editValues.remaining_amount),
            partial_amount: formatNumberWithCommas(editValues.partial_amount),
        });


        console.log(editValues);

    }, [editValues]);


    function isNegative(number) {
        return number < 0;
    }

    const getAllInvoices = (loader = false) => {
        setLoading(loader);

        axios.get(`${process.env.REACT_APP_BASE_URL}get-invoices/${selectedProject}/${selected}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((response) => {
            setInvoices(response.data.data);
        })
        .catch((err) => {
            console.log('err====', err);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    function payDate(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        
        // Get day with suffix
        const day = date.getDate();
        const suffix = day => {
            if (day > 3 && day < 21) return 'th'; // because 11th, 12th, 13th etc.
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        };
        
        return formattedDate.replace(/\d+/, day + suffix(day));
    }

    useEffect(() => {
        getAllInvoices(true);
    }, [selectedProject, selected]);

    useEffect(() => {
        getAllInvoices(true);
    }, [recallData]);

    const renderInvoices = (status) => {
        return (
            <div className=" invoice_tab download_tabs m-3">

                <Table responsive>
                    <thead>
                        <tr>
                            <th className="text-center">Invoice #</th>
                            <th className="text-center">Amount</th>
                            <th className="text-center">Pay Date</th>
                            <th className="text-center">Due Date</th>
                            <th className="text-center">Project Phase</th>
                            <th className="text-center">Invoice Type</th>
                            <th className="text-center">Partial Amount</th>
                            <th className="text-center">Remaining Amount</th>
                            <th className="text-center">Status</th>
                            {role !== 'client' ?
                                <th className="text-center">Action</th>
                                : null
                            }

                        </tr>
                    </thead>
                    <tbody>

                        {invoices.length > 0 ? (
                            invoices.map((item, index) => (

                                
                                <tr key={index}>
                                    <td className="text-center">#{item.id}</td>
                                    <td className="text-center">{"$ " +formatNumberWithCommas(item.amount)}</td>
                                    {/* <td className="text-center">{console.log(item)}</td> */}
                                    {/*<td className="text-center">{console.log(item.pay_date)} { item.pay_date !== null && item.pay_date !== undefined && item.pay_date !== '' ? DateFormatWithLetters(new Date(item?.pay_date)) : '-'}</td>*/}
                                    <td className="text-center"> {item.pay_date !== null && item.pay_date !== undefined && item.pay_date !== '' ? payDate(item?.pay_date) : '-'}</td>
                                    <td className="text-center">{item.due_dates !== null && item.due_dates !== undefined && item.due_dates !== '' ? formatMDY(item.due_dates) : '-'}</td>

                                    <td className="text-center"> {item?.project_phase?.name || '-'} </td>
                                    <td className="text-center"> {item?.invoice_type?.name || '-'} </td>
                                    <td className="text-center">{item.partial_amount !== null && item.partial_amount !== undefined && item.partial_amount !== '' ? "$ " + formatNumberWithCommas(item?.partial_amount) : '-'} </td>
                                    <td className="text-center">{item.remaining_amount !== null && item.remaining_amount !== undefined && item.remaining_amount !== '' ? "$ " + formatNumberWithCommas(item?.remaining_amount) : '-'} </td>

                                    <td className="text-center p-2 rounded">
                                        <span className={" " + (item.status === 0 ? 'Unpaid status_info' : 'Paid status_info')}>
                                            {item.status === 0 ? 'Unpaid' : 'Paid'}
                                        </span>
                                    </td>

                                    {role !== 'client' ?
                                        <td className="text-center">
                                            <ul>
                                                { item?.is_completed == 0 && item?.invoice_type_id == 1 ? ( <li>
                                                    <VscGitPullRequestCreate size={20} onClick={() => handlePartialInvoice(item)} />
                                                </li>) : ''}
                                               
                                                <li><RiEditBoxFill color="orange" size={20} onClick={() => handleClickOpen(item)} /></li>
                                                <li><MdDelete className='cursor-pointer' onClick={() => handleDelete(item.id)} color="red" size={20} /></li>
                                            </ul>
                                        </td>
                                        : null
                                    }

                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center">No invoices found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        )
    }

    const handlePartialAmountOnchange =  (e) => {

        let paidAmount  = removeCommaAndConvertToFloat(partialInvoice.partial_amount);
        let totalAmount = removeCommaAndConvertToFloat(partialInvoice.amount);

        let partialAmount   = removeCommaAndConvertToFloat(e.target.value);     
        let remainingAmount = parseFloat(totalAmount) - (parseFloat(paidAmount) + parseFloat(partialAmount));

        partialFormik.setValues(prevValues => ({
            ...prevValues,
            remaining_amount: isNaN(remainingAmount) ? 0 : remainingAmount,
        }));

        if(isNegative(remainingAmount)) {

            setPartialAmountError(true);
            setPartialAmountErrorMessage("Please Enter Valid Partial Amount");
        }
        else{
            setPartialAmountError(false);
            setPartialAmountErrorMessage("");
        }

        partialFormik.handleChange(e);

        formik.setFieldValue('partial_amount',  formatNumberWithCommas(partialAmount))

    }

    const handleEditPartialAmountOnchange =  (e) => {

        // let paidAmount  = editValues.partial_amount;
        let totalAmount = removeCommaAndConvertToFloat(editValues.amount);

        let partialAmount   = removeCommaAndConvertToFloat(e.target.value);     
        let remainingAmount = parseFloat(totalAmount) - parseFloat(partialAmount);

        formik.setValues(prevValues => ({
            ...prevValues,
            remaining_amount: isNaN(remainingAmount) ? 0 : formatNumberWithCommas(remainingAmount),
        }));

        if(isNegative(remainingAmount)) {

            setPartialAmountError(true);
            setPartialAmountErrorMessage("Please Enter Valid Partial Amount");
        }
        else{
            setPartialAmountError(false);
            setPartialAmountErrorMessage("");
        }

        formik.setFieldValue('partial_amount',  formatNumberWithCommas(partialAmount))
        // formik.handleChange(e);
    }

    return (
        <>
            <div>
                <ul className="downloadable_tabs">
                    <li
                        onClick={() => setSelected(1)}
                        className={selected === 1 ? 'selected whats-new-tab' : 'un-selected'}
                    >
                        Paid
                    </li>
                    {/* <li
                        onClick={() => setSelected(0)}
                        className={selected === 0 ? 'selected whats-new-tab' : 'un-selected'}
                    >
                        Unpaid
                    </li> */}
                    <li
                        onClick={() => setSelected(0)}
                        className={selected === 0 ? 'selected whats-new-tab' : 'un-selected'}
                    >
                        Upcoming
                    </li>
                </ul>

                {
                    loading ?
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} className='mt-5'>
                            <Oval
                                visible={true}
                                height="20"
                                width="20"
                                color="#f37a20"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                            <small>Please wait while the content is loading</small>
                        </div> :
                        <>
                            {invoices.length > 0 ?
                                renderInvoices(selected)
                                :
                                <div className='flex justify-center items-center p-4 bg-[white]'>
                                    <p className='text-center text-[13px] text-[#979797] font-bold'>No invoices
                                        found</p>
                                    {/*              <p className='text-center text-[11px] text-[#979797]'>No invoices</p>*/}
                                </div>
                            }
                        </>
                }

                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Update Invoice"}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={formik.handleSubmit} className='space-y-6 '>
                            <div
                                className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>

                                <FormControl className="custom-input" fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                fullWidth
                                                className='w-[100%]'
                                                name="due_date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                id="due_date"
                                                inputFormat="MMM dd yyyy"
                                                label="Due Date"
                                                value={formik.values.due_date}
                                                onChange={value => formik.setFieldValue('due_date', value)}

                                                // onChange={value => formik.setFieldValue('due_date', dayjs(value).format("YYYY-MM-DD"))}
                                                error={formik.touched.due_date && Boolean(formik.errors.due_date)}
                                            // minDate={dayjs()}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </FormControl>

                                <FormControl className="custom-input" fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                                            <DatePicker
                                                fullWidth
                                                className='w-[100%]'
                                                name="pay_date"
                                                InputLabelProps={{
                                                    shrink: false,
                                                }}
                                                id="pay_date"
                                                inputFormat="E MMM dd yyyy HH:MM:SS O"
                                                label="Pay Date"
                                                value={formik.values.pay_date}
                                                onChange={value => formik.setFieldValue('pay_date', value)}
                                                minDate={dayjs()}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </FormControl>
                            </div>

                            <div className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Project Phase</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="project_phase_id"
                                        label="Status"
                                        id="project_phase_id"
                                        value={formik.values.project_phase_id}
                                        onChange={(event) => {
                                            formik.setFieldValue('project_phase_id', event.target.value);
                                        }}
                                        error={formik.touched.project_phase_id && Boolean(formik.errors.project_phase_id)}
                                        helperText={formik.touched.project_phase_id && formik.errors.project_phase_id}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >

                                        {projectPhase?.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </div>

                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <TextField
                                    className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                    id="amount"
                                    name="amount"
                                    label="Amount"
                                    value={formik.values.amount}
                                    // onChange={formik.handleChange}
                                    // error={formik.touched.amount && Boolean(formik.errors.amount)}
                                    onChange={ e => (
                                        formik.setFieldValue('amount',  formatNumberWithCommas(e.target.value))
                                    )}

                                    helperText={formik.touched.amount && formik.errors.amount}
                                />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="status"
                                        label="Status"
                                        id="status"
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                        error={formik.touched.status && Boolean(formik.errors.status)}
                                        helperText={formik.touched.status && formik.errors.status}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem value={1}>Paid</MenuItem>
                                        <MenuItem value={0}>Unpaid</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <FormControl fullWidth>
                                    <InputLabel id="payment-select-label">Invoice Type</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="payment-select-label"
                                        name="invoice_type_id"
                                        label="Invoice Type"
                                        id="invoice_type_id"
                                        value={formik.values.invoice_type_id}
                                        onChange={formik.handleChange}
                                        error={formik.touched.invoice_type_id && Boolean(formik.errors.invoice_type_id)}
                                        helperText={formik.touched.invoice_type_id && formik.errors.invoice_type_id}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem value={1}>Partial</MenuItem>
                                        <MenuItem value={2}>Full</MenuItem>
                                    </Select>

                                    {formik.touched.invoice_type_id && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.invoice_type_id}</div>}
                                </FormControl>
                            </div>

                            { formik.values.invoice_type_id == 1 && 
                                <>
                                    <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                        <TextField
                                            className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                            type="text"
                                            id="partial_amount"
                                            name="partial_amount"
                                            label="Partial Amount"
                                            value={formik.values.partial_amount}
                                            // onChange={(e) => {
                                            //     let paidAmount = partialFormik.values.partial_amount;
                                            //     console.log(paidAmount);
                                            
                                            // }}

                                            onChange={handleEditPartialAmountOnchange}
                                            error={formik.touched.partial_amount && Boolean(formik.errors.partial_amount)}
                                            helperText={formik.touched.partial_amount && formik.errors.partial_amount}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>

                                    <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                        {partialAmountError && <div style={{ color: 'red', marginTop: '7px' }}>{partialAmountErrorMessage}</div>}
                                    </div>
                                    

                                    <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                        <TextField
                                            className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                            type="text"
                                            id="remaining_amount"
                                            name="remaining_amount"
                                            label="Remaining Amount"
                                            value={formik.values.remaining_amount}
                                            onChange={formik.handleChange}
                                            error={formik.touched.remaining_amount && Boolean(formik.errors.remaining_amount)}
                                            helperText={formik.touched.remaining_amount && formik.errors.remaining_amount}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                </>
                            }

                            <div
                                className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Attachment Type</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="attachment"
                                        label="Attachment Type"
                                        id="attachment"
                                        value={formik.values.attachment}
                                        onChange={formik.handleChange}
                                        error={formik.touched.attachment && Boolean(formik.errors.attachment)}
                                        helperText={formik.touched.attachment && formik.errors.attachment}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem value={1}>Link</MenuItem>
                                        <MenuItem value={0}>File</MenuItem>
                                    </Select>

                                    {formik.touched.attachment && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.attachment}</div>}
                                </FormControl>
                            </div>
                            {formik.values.attachment == 1 &&
                            <div
                                className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <TextField
                                    className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                    id="link"
                                    name="link"
                                    label="Invoice Link"
                                    value={formik.values.link}
                                    onChange={formik.handleChange}
                                    error={formik.touched.link && Boolean(formik.errors.link)}
                                    helperText={formik.touched.link && formik.errors.link}
                                />
                            </div>}
                            {formik.values.attachment == 0 &&
                            <div
                                className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <div className="file-upload-container">
                                    <input
                                        type="file"
                                        onChange={handleFileInput} />
                                </div>


                            </div>}

                            { formik.values.attachment == 0 && editValues?.file_path &&
                            <div className="file_link">
                                <a href={editValues?.file_path} target="_blank">Preview Uploaded file <IoEyeOutline size={20} /></a>
                            </div>
                            }


                            <div className='flex justify-end mt-3'>
                                <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white'
                                    variant="contained" fullWidth type="submit" disabled={btnLoader}>
                                    {btnLoader ? <Oval
                                        visible={true}
                                        height="20"
                                        width="20"
                                        color="#ffffff"
                                        secondaryColor="#ffffff"
                                        ariaLabel="oval-loading"
                                        wrapperStyle={{ disply: 'flex', justifyContent: 'center' }}
                                        wrapperClass=""
                                    /> : 'Save'}
                                </button>
                            </div>
                        </form>
                    </DialogContent>
                </Dialog>


                <Dialog
                    open={partialModalOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClosePartialModal}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Create Partial Invoice"}</DialogTitle>
                    <DialogContent>

                    <form onSubmit={partialFormik.handleSubmit} className='space-y-6 '>

                            <div className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                                <FormControl fullWidth error={partialFormik.touched.client_id && Boolean(partialFormik.errors.client_id)}>
                                    <InputLabel id="client_id">Client</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="client_id"
                                        name="client_id"
                                        label="Client"
                                        id="client_id"
                                        value={partialFormik.values.client_id} // Ensure it's a single value
                                        helperText={partialFormik.touched.client_id && partialFormik.errors.client_id}
                                        onChange={(event) => {
                                            partialFormik.setFieldValue('client_id', event.target.value);
                                        }}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                    >
                                        {users?.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.first_name} {item.last_name}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    {partialFormik.touched.client_id && <div style={{ color: 'red', marginTop: '7px' }}>{partialFormik.errors.client_id}</div>}
                                </FormControl>
                            </div>
                            

                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <FormControl className="custom-input" fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                fullWidth
                                                className='w-[100%]'
                                                name="due_date"
                                                id="due_date"
                                                inputFormat="MMM dd yyyy"
                                                label="Due Date"
                                                value={partialFormik.values.due_date}
                                                // onChange={value => {
                                                //         // formik.setFieldValue('due_date', value);
                                                //         console.log(value);
                                                //     }
                                                // }
                                                onChange={value => partialFormik.setFieldValue('due_date', dayjs(value).format("YYYY-MM-DD"))}
                                                // minDate={dayjs()}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>

                                    {partialFormik.touched.due_date && <div style={{ color: 'red', marginTop: '7px' }}>{partialFormik.errors.due_date}</div>}
                                </FormControl>

                                <FormControl className="custom-input" fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                                            <DatePicker
                                                fullWidth
                                                className='w-[100%]'
                                                name="pay_date"
                                                InputLabelProps={{
                                                    shrink: false,
                                                }}
                                                id="pay_date"
                                                inputFormat="E MMM dd yyyy HH:MM:SS O"
                                                label="Pay Date"
                                                value={partialFormik.values.pay_date}
                                                onChange={value => partialFormik.setFieldValue('pay_date', value)}
                                                minDate={dayjs()}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </FormControl>
                            </div>

                            <div className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Project Phase</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="project_phase_id"
                                        label="Status"
                                        id="project_phase_id"
                                        value={partialFormik.values.project_phase_id}
                                        onChange={(event) => {
                                            partialFormik.setFieldValue('project_phase_id', event.target.value);
                                        }}
                                        error={partialFormik.touched.project_phase_id && Boolean(partialFormik.errors.project_phase_id)}
                                        helperText={partialFormik.touched.project_phase_id && partialFormik.errors.project_phase_id}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >

                                        {projectPhase?.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>


                            </div>

                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <FormControl fullWidth>
                                    <InputLabel id="payment-select-label">Invoice Type</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="payment-select-label"
                                        name="invoice_type_id"
                                        label="Invoice Type"
                                        id="invoice_type_id"
                                        value={partialFormik.values.invoice_type_id}
                                        onChange={partialFormik.handleChange}
                                        error={partialFormik.touched.invoice_type_id && Boolean(partialFormik.errors.invoice_type_id)}
                                        helperText={partialFormik.touched.invoice_type_id && partialFormik.errors.invoice_type_id}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem value={1}>Partial</MenuItem>
                                        <MenuItem value={2}>Full</MenuItem>
                                    </Select>

                                    {partialFormik.touched.invoice_type_id && <div style={{ color: 'red', marginTop: '7px' }}>{partialFormik.errors.invoice_type_id}</div>}
                                </FormControl>
                            </div>

                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>

                                <TextField
                                    className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                    id="amount"
                                    name="amount"
                                    label="Total Amount"
                                    value={partialFormik.values.amount}
                                    onChange={partialFormik.handleChange}
                                    error={partialFormik.touched.amount && Boolean(partialFormik.errors.amount)}
                                    helperText={partialFormik.touched.amount && partialFormik.errors.amount}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                    id="paid_amount"
                                    name="paid_amount"
                                    label="Paid Amount"
                                    value={partialFormik.values.paid_amount}
                                    onChange={partialFormik.handleChange}
                                    error={partialFormik.touched.paid_amount && Boolean(partialFormik.errors.paid_amount)}
                                    helperText={partialFormik.touched.paid_amount && partialFormik.errors.paid_amount}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </div>

                            { partialFormik.values.invoice_type_id == 1 && 
                                <>
                                    <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                        <TextField
                                            className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                            type="text"
                                            id="partial_amount"
                                            name="partial_amount"
                                            label="Partial Amount"
                                            value={partialFormik.values.partial_amount}
                                            // onChange={(e) => {
                                            //     let paidAmount = partialFormik.values.partial_amount;
                                            //     console.log(paidAmount);
                                            
                                            // }}

                                            onChange={handlePartialAmountOnchange}
                                            error={partialFormik.touched.partial_amount && Boolean(partialFormik.errors.partial_amount)}
                                            helperText={partialFormik.touched.partial_amount && partialFormik.errors.partial_amount}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>

                                    <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                        {partialAmountError && <div style={{ color: 'red', marginTop: '7px' }}>{partialAmountErrorMessage}</div>}
                                    </div>
                                    

                                    <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                        <TextField
                                            className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                            type="text"
                                            id="remaining_amount"
                                            name="remaining_amount"
                                            label="Remaining Amount"
                                            value={partialFormik.values.remaining_amount}
                                            onChange={partialFormik.handleChange}
                                            error={partialFormik.touched.remaining_amount && Boolean(partialFormik.errors.remaining_amount)}
                                            helperText={partialFormik.touched.remaining_amount && partialFormik.errors.remaining_amount}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            
                                        />
                                    </div>
                                </>
                            }

                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="status"
                                        label="Status"
                                        id="status"
                                        value={partialFormik.values.status}
                                        onChange={partialFormik.handleChange}
                                        error={partialFormik.touched.status && Boolean(partialFormik.errors.status)}
                                        helperText={partialFormik.touched.status && partialFormik.errors.status}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        <MenuItem value={1}>Paid</MenuItem>
                                        <MenuItem value={0}>Unpaid</MenuItem>
                                    </Select>
                                </FormControl>
                                
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Attachment Type</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="attachment"
                                        label="Attachment Type"
                                        id="attachment"
                                        value={partialFormik.values.attachment}
                                        onChange={partialFormik.handleChange}
                                        error={partialFormik.touched.attachment && Boolean(partialFormik.errors.attachment)}
                                        helperText={partialFormik.touched.attachment && partialFormik.errors.attachment}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem value={1}>Link</MenuItem>
                                        <MenuItem value={0}>File</MenuItem>
                                    </Select>

                                    {partialFormik.touched.attachment && <div style={{ color: 'red', marginTop: '7px' }}>{partialFormik.errors.attachment}</div>}
                                </FormControl>
                            </div>

                            {partialFormik.values.attachment == 1 &&
                                <div
                                    className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                    <TextField
                                        className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                        id="link"
                                        name="link"
                                        label="Invoice Link"
                                        value={partialFormik.values.link}
                                        onChange={partialFormik.handleChange}
                                        error={partialFormik.touched.link && Boolean(partialFormik.errors.link)}
                                        helperText={partialFormik.touched.link && partialFormik.errors.link}
                                    />
                                </div>
                            }
                            
                            {
                                partialFormik.values.attachment == 0 &&
                                <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                    <div className="file-upload-container">
                                        <input type="file" onChange={handleFileInput} />
                                    </div>
                                </div>
                            }

                            {
                                partialFormik.values.attachment == 0 && partialInvoice?.file_path &&
                                <div className="file_link">
                                    <a href={partialInvoice?.file_path} target="_blank">Preview Uploaded file <IoEyeOutline size={20} /></a>
                                </div>
                            }

                            <div className='flex justify-end mt-3'>
                                <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white'
                                    variant="contained" fullWidth type={ partialAmountError ? 'button' : 'submit'} disabled={btnLoader}>
                                    {btnLoader ? <Oval
                                        visible={true}
                                        height="20"
                                        width="20"
                                        color="#ffffff"
                                        secondaryColor="#ffffff"
                                        ariaLabel="oval-loading"
                                        wrapperStyle={{ disply: 'flex', justifyContent: 'center' }}
                                        wrapperClass=""
                                    /> : 'Save'}
                                </button>
                            </div>
                        </form>
                      
                    </DialogContent>

                </Dialog>
            </div>
        </>
    )
}

export default memo(InvoiceHistory);


    // const getAllInvoiceByPid = (pid) => {
    //   if(user){
    //   let url;
    //   if(project_type.asana_project_id !== null){
    //     url = `${process.env.REACT_APP_BASE_URL}invoice?asana_project_id=${pid}`

    //   } else{
    //     url = `${process.env.REACT_APP_BASE_URL}invoice?manual_project_id=${pid}`
    //   }
    //   axios.get(url,{
    //     headers:{
    //       'Authorization':`Bearer ${user.token}`
    //     }
    //   }).then((response) => {
    //     const formattedData = response.data.data.map(item => {
    //       // console.log('item========',item)
    //       const due_date = new Date(item.due_date);
    //       const pay_date = new Date(item.pay_date);
    //       const formattedDueDate = due_date.toLocaleDateString();
    //       const formattedPayDate = pay_date.toLocaleDateString();
    //       return { ...item, due_date: formattedDueDate, pay_date: formattedPayDate };
    //   });

    //   setInvoices(formattedData)
    //   }).catch((err) => {
    //     console.log('err====', err);
    //   })
    //   // console.log(table);
    // }
    // }

    // console.log('data=======',data);

    /*if (loading) {
        return (
            <div className=' justify-center items-center flex h-[80vh]'>
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#F37A20"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </div>

        )
    }*/

    
    // const handleLoadFiletype = async (url) => {
    //     try {
    //         const response = await fetch(url);
    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }

    //         const blob = await response.blob();
    //         const mimeType = blob.type;

    //         console.log(mimeType || 'Unknown file type')
    //     } catch (error) {
    //         console.error('Error fetching the file:', error);
    //         return 'Error fetching the file';
    //     }
    // }