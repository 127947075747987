import React, { useEffect, useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
// import googleIcon from '../data/google-meet-icon.png';
import googleIcon from '../data/google_meet.svg';
import googleIcon2 from '../data/google_meet_2.svg';
import zoomIcon from '../data/zoom_icon.svg';

import axios from 'axios'
import { Link } from 'react-router-dom';
import { convertToPST, convertToRequiredFormat } from '../utils/Helper'
import { BsPeopleFill } from 'react-icons/bs';

import { PiUsersFour } from "react-icons/pi";

const UpComing = (props) => {
    /* const {project, meeting, getAllMeetings, sessionProject} = useStateContext();
     //const [data, setData] = useState([]);
     /!*useEffect(() => {
         //getAllMeetings(sessionProject);
         setData(props.meeting ? props.meeting : []);
     }, [project])*!/
 
     /!*useEffect(() => {
       console.log("ALL MEETINGS =>", meeting)
     }, [meeting])*!/*/

    // function convertToRequiredFormat(dateTimeString) {
    //     if (!dateTimeString) return ''; // Handle case where dateTimeString is undefined or null

    //     // Parse the date string into a Date object
    //     const date = new Date(dateTimeString);

    //     // Format the date and time according to the required format
    //     const day = date.getDate(); // Day of the month (1-31)
    //     const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    //     const month = monthNames[date.getMonth()]; // Month name (short form)
    //     const hours = date.getHours(); // Hours (0-23)
    //     const minutes = date.getMinutes().toString().padStart(2, '0'); // Minutes (00-59)
    //     const period = hours >= 12 ? 'pm' : 'am'; // AM/PM period
    //     const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format

    //     // Construct the final formatted string
    //     const formattedDateTime = `${day} ${month}, ${formattedHours}:${minutes}${period}`;

    //     return formattedDateTime;
    // }

    return (
        <>

            <div className="main_style_box project-upcomming">
                <div className="project_title">
                    Upcoming Meetings
                    <Link to='/meeting-board-view'>
                        See All
                    </Link>
                </div>
                {props?.meeting ?
                    <div className="upcomming-file">
                        <div className="img">
                            {/* <img src={googleIcon} /> */}

                            {props?.meeting?.meeting_link ? (
                                props.meeting.meeting_link.includes('https://meet.google.com/') ? (
                                    <img src={googleIcon2} />
                                ) : props.meeting.meeting_link.includes('https://zoom.us/') ? (
                                    <img src={zoomIcon} />
                                ) : (
                                    <PiUsersFour size={35} className='mr-2' color='gray' />
                                )
                            ) : (
                                <PiUsersFour size={35} className='mr-2' color='gray' />
                            )}




                            {/* <BsPeopleFill size={30} color="lightgrey" className='mr-2' /> */}

                        </div>
                        <div className="content">
                            <span className='title'>
                                {/* {props.meeting?.name} */}

                                <a target='_blank' href={props.meeting?.meeting_link} className='link'>{props.meeting?.name || ''}</a>
                            </span>

                            {/* <span className='date'>{convertToPST(props.meeting?.date_time)}</span> */}
                            <span className='date'>{convertToRequiredFormat(props.meeting?.date_time)}</span>

                        </div>
                    </div>
                    :
                    <div className="no-records">
                        <p>No Meetings....</p>
                    </div>}
            </div>


            {/* <div className='relative whats-new min-h-[145px] xm:min-h-[140px]'>
            <div
                className='flex justify-between items-center p-[30px] md:p-[20px] sm:p-[15px] xm:p-[12px] md:pb-[14px] sm:pb-[10px] xm:pb-[8px]  pb-[17px]'>
                <p className='text-[#131313] flex items-center !text-[20px]  lg:!text-[20px] md:!text-[12px] sm:!text-[10px] xm:!text-[10px] font-bold'> Upcoming
                    Meetings </p>
                <Link to='/meeting-board-view'>
                    <button className='text-[#718EBF] text-[12px] md:text-[10px] sm:stext-[8px] xm:text-[7px] flex'>See
                        All
                    </button>
                </Link>
            </div>
            <div className={`space-y-6 upcoming-innerdiv pl-[20px]  overflow-y-auto `}>
                {props?.meeting ?

                    <div className='flex justify-start items-center'>
                        <div className='flex items-center justify-start'>
                            <img src={googleIcon}
                                 className='md:w-[60px] md:h-[60px] sm:w-[50px] sm:h-[50px] xm:w-[40px] xm:h-[40px]'
                                 alt="googleIcon"/>
                            <div className='h-[44px] md:h-[42px] sm:h-[40px] xm:h-[38px] w-[1px] bg-[red]'></div>
                        </div>
                        <div className='ml-1'>
                            <p className='break-all pr-[10px]  font-medium text-[#505887]	capitalize  text-[13px] lg:text-[11px] md:text-[10px] sm:text-[8px] xm:text-[7px]'>{props.meeting?.name}</p>
                            <div className='flex flex-wrap'>
                                <p className='text-[#f37a20] text-[10px] md:text-[9px] sm:text-[8px] xm:text-[7px]'><a
                                    target='_blank'
                                    href={props.meeting?.meeting_link}>{props.meeting?.meeting_link || ''}</a></p>
                            </div>
                            <p className='text-[10px] md:text-[9px] sm:text-[8px] xm:text-[7px] font-medium text-blue-400'>{props.meeting?.date_time}</p>
                        </div>
                    </div>

                    : <div>
                        <p className="pt-[10px] text-[14px] font-bold lg:text-[14px] md:text-[12px] sm:text-[10px] xm:text-[8px]">No
                            Meetings....</p>
                    </div>}


            </div>

        </div> */}
        </>

    )
}

export default UpComing;