import React from 'react';
import EventCalendar from "./components/calendar/EventCalendar";
import {NavLink} from "react-router-dom";

function CalendarView() {

    return (
        <>
            <div className="main_top_title">
                <div className="main_title"><h1>Calendar <span>View</span></h1></div>
                <div className="main_btn_txt">
                    {/* <ul>
                        <li>
                            <NavLink to="">
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="">
                                Appboard
                            </NavLink>
                        </li>
                        <li className="active">
                            <NavLink to="" >
                                Calendar
                            </NavLink>
                        </li>
                    </ul> */}
                </div>
            </div>
            <EventCalendar/>
        </>
    );
}

export default CalendarView;
