import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
    // baseURL: 'https://api.example.com', // Replace with your base URL
    baseURL: process.env.REACT_APP_BASE_URL, // Replace with your base URL
});


// Add a request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        // Retrieve the user token from localStorage (or any other secure place you store it)
        const user = JSON.parse(localStorage.getItem('tekrevol_user'));
        if (user && user.token) {
            config.headers.Authorization = `Bearer ${user.token}`;
        }
        return config;
    },
    (error) => {
        // Handle request errors
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        // Modify response data to be res.data.data if it exists
        if (response.data && response.data.data) {
            response.data = response.data.data;
        }
        return response;
    },
    (error) => {
        // Handle response errors
        if (error.response && error.response.status === 401) {
            // Handle 401 errors (unauthorized)
            // For example, redirect to login page or refresh token
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;