import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import TekPoints from "../../img/Loyalty.svg"
import 'swiper/css';
import { Navigation } from 'swiper';
import 'swiper/css/navigation';
import Tooltip from '../new/components/Tooltip';
import { useStateContext } from "../../contexts/ContextProvider";
import React, { useEffect, useState } from "react";
import axios from 'axios';
import Swal from 'sweetalert2';

import Slide from "@mui/material/Slide";
import RewardsClientTable from '../../components/RewardsClientTable';

// import clutch from '../data/clutch.png';
// import diamond from '../data/diamond.svg';
// import diamond1 from '../data/diamond1.svg';
import ClientRewardPoint from "./components/ClientRewardPoint";
import { FillterIcon, HideIcon, PlusIcon, SortIcon } from "../../app-constants";


function RewardPoints() {
 
    const { role } = useStateContext();

    return (
        <>
            <div className="main_top_title">
                <div className="main_title"><h1>Rewards  <span>& Points</span></h1></div>
                <div className="main_btn_txt">
                    <ul>
                        <li>
                            <NavLink to="">
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="">
                                Appboard
                            </NavLink>
                        </li>
                        <li className="active">
                            <NavLink to="" >
                                Rewards & Points
                            </NavLink>
                        </li>
                    </ul>
                    {/* {role !== "client" ? (
                        <div className="main-btn-txt">
                            <Link className="btn-banner" to={`/rewards/manage`}>Manage Rewards & Points</Link>
                        </div>
                    ) : null
                    } */}
                </div>
            </div>
                 { role !== 'client' && (
                    <div className="list_fillter add__button">
                        <div className="add_task">
                        <Link  to={`/rewards/manage`}>
                            {PlusIcon }Manage Rewards & Points
                            </Link>
                        </div>
                    </div>
                )
            }
            <div className="main_content_container">
                {
                    role == 'client' ?
                        <ClientRewardPoint />
                        :
                        <RewardsClientTable />
                }
            </div>
        </>
    );
}

export default RewardPoints;


{/* <div className="rewards_main_list claim">
    <div className="title">Landing Page</div>
    <div className="points">Ready to Claim</div>
    <div className="butn">
        <button>Claim</button>
    </div>
</div> */}