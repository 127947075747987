 export const CrossIcon =()=>{
    return <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
        <path d="M1.37365 14.2703L0.319824 13.2165L5.91982 7.61647L0.319824 2.01647L1.37365 0.962646L6.97365 6.56265L12.5736 0.962646L13.6275 2.01647L8.02747 7.61647L13.6275 13.2165L12.5736 14.2703L6.97365 8.6703L1.37365 14.2703Z" fill="#2A303C"/>
    </svg>
 }

 export const Logo =({width='441',height='49'})=>{
     return <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 295 29" fill="none">
         <g clip-path="url(#clip0_2763_11208)">
             <path d="M22.9834 22.8652H11.9583L0.93335 28.9998H34.0667L22.9834 22.8652Z" fill="#EA4025"/>
             <path d="M0 27.4385L11.025 21.3596L16.5667 12.2135V0L0 27.4385Z" fill="#EB5626"/>
             <path d="M18.4333 0V12.2135L23.975 21.3596L35 27.4385L18.4333 0Z" fill="#EA7C25"/>
             <path d="M21.6417 21.0803H13.3584L14.2917 19.5188L16.5667 15.7822L17.5001 14.2207L18.4334 15.7822L20.7084 19.5188L21.6417 21.0803Z" fill="white"/>
             <path d="M244.569 24.2046H230.393C228.707 24.2046 227.337 23.6469 226.178 22.4757C225.018 21.3046 224.438 19.8546 224.438 18.07V10.9315C224.438 9.20264 225.018 7.69687 226.178 6.52572C227.337 5.35457 228.76 4.79688 230.393 4.79688H244.569V9.31418H230.393C229.234 9.31418 228.707 9.87187 228.707 10.9315V18.0142C228.707 19.0738 229.287 19.6315 230.393 19.6315H244.569V24.2046Z" fill="#EB5627"/>
             <path d="M267.862 24.2046H262.013L252.843 13.3296H263.857C264.647 13.3296 265.016 12.6603 265.016 11.3219C265.016 9.98341 264.647 9.31418 263.857 9.31418H250.63V24.1488H246.361V4.79688H264.12C265.754 4.79688 267.072 5.46611 268.02 6.80457C268.916 8.03149 269.338 9.53726 269.338 11.2661C269.338 13.0507 268.916 14.5007 268.02 15.7276C267.072 17.0661 265.754 17.7353 264.12 17.7353H262.487L267.862 24.2046Z" fill="#EB5627"/>
             <path d="M294.844 24.2046H290.575V12.3815L282.934 23.8699L275.293 12.3815V24.1488H271.024V4.79688H275.503L282.881 16.0065L290.312 4.79688H294.738V24.2046H294.844Z" fill="#EB5627"/>
             <path d="M126.841 24.4828H121.149L112.191 13.6078H122.941C123.679 13.6078 124.1 12.9386 124.1 11.6001C124.1 10.2616 123.732 9.5924 122.941 9.5924H109.977V24.427H105.814V5.13086H123.205C124.786 5.13086 126.05 5.80009 126.999 7.13855C127.842 8.36548 128.316 9.87124 128.316 11.6001C128.316 13.3289 127.895 14.8347 126.999 16.0616C126.05 17.4001 124.786 18.0693 123.205 18.0693H121.624L126.841 24.4828Z" fill="white"/>
             <path d="M149.87 9.64817H134.904V12.5482H148.869V17.0655H134.904V19.9655H149.817V24.4828H130.74V5.13086H149.87V9.64817Z" fill="white"/>
             <path d="M173.057 5.13086L163.941 24.4828H160.41L151.24 5.13086H155.93L162.202 18.4039L168.42 5.13086H173.057Z" fill="white"/>
             <path d="M197.193 18.3493C197.193 20.0781 196.614 21.5839 195.507 22.6993C194.4 23.8704 192.977 24.4281 191.344 24.4281H180.804C179.17 24.4281 177.8 23.8704 176.641 22.6993C175.534 21.5281 174.955 20.0781 174.955 18.3493V11.2108C174.955 9.48194 175.534 7.97617 176.641 6.80502C177.8 5.63386 179.17 5.07617 180.804 5.07617H191.344C192.977 5.07617 194.348 5.63386 195.507 6.80502C196.614 7.97617 197.193 9.42617 197.193 11.2108V18.3493ZM193.03 18.3493V11.2666C193.03 10.2069 192.451 9.64925 191.344 9.64925H180.804C179.697 9.64925 179.118 10.2069 179.118 11.2666V18.3493C179.118 19.4089 179.697 19.9666 180.804 19.9666H191.344C192.451 19.9666 193.03 19.4089 193.03 18.3493Z" fill="white"/>
             <path d="M217.64 24.4828H199.723V5.13086H203.886V19.9655H217.64V24.4828Z" fill="white"/>
             <path d="M65.3416 9.64817H57.2788V24.4828H53.1156V9.64817H45V5.13086H65.3416V9.64817Z" fill="white"/>
             <path d="M86.7372 9.64817H71.7709V12.5482H85.736V17.0655H71.7709V19.9655H86.6845V24.4828H67.6077V5.13086H86.7372V9.64817Z" fill="white"/>
             <path d="M96.5918 4.51758L88.9506 14.6118L97.3823 24.5387H103.495L95.2217 14.1657L102.231 4.51758H96.5918Z" fill="#EB5627"/>
         </g>
         <defs>
             <clipPath id="clip0_2763_11208">
                 <rect width="295" height="29" fill="white"/>
             </clipPath>
         </defs>
     </svg>
 }
 export const LogoWhite =({width='441',height='49'})=>{
     return <svg
     xmlns="http://www.w3.org/2000/svg"
     width="250"
     height="25"
     fill="none"
     viewBox="0 0 250 25"
   >
     <g clipPath="url(#clip0_2987_2315)">
       <path
         fill="#EA4025"
         d="M19.477 19.423h-9.343L.791 24.5h28.08l-9.393-5.077z"
       ></path>
       <path
         fill="#EB5626"
         d="M0 23.208l9.343-5.031 4.697-7.57V.5L0 23.208z"
       ></path>
       <path
         fill="#EA7C25"
         d="M15.621.5v10.108l4.696 7.569 9.344 5.03L15.62.5z"
       ></path>
       <path
         fill="#000"
         d="M18.34 17.945h-7.02l.791-1.292 1.928-3.092.791-1.292.791 1.292 1.928 3.092.791 1.293z"
       ></path>
       <path
         fill="#EB5627"
         d="M207.262 20.531h-12.013c-1.429 0-2.591-.461-3.573-1.43-.983-.97-1.474-2.17-1.474-3.647V9.547c0-1.431.491-2.677 1.474-3.647.982-.969 2.188-1.43 3.573-1.43h12.013v3.738h-12.013c-.983 0-1.429.462-1.429 1.339v5.861c0 .877.491 1.339 1.429 1.339h12.013v3.784zM227.001 20.531h-4.957l-7.771-9h9.334c.67 0 .983-.554.983-1.661 0-1.108-.313-1.662-.983-1.662h-11.209v12.277h-3.618V4.47h15.051c1.384 0 2.501.554 3.304 1.661.76 1.016 1.117 2.262 1.117 3.693 0 1.476-.357 2.677-1.117 3.692-.803 1.108-1.92 1.661-3.304 1.661h-1.385l4.555 5.354zM249.868 20.531h-3.618v-9.784l-6.475 9.507-6.476-9.507v9.738h-3.617V4.47h3.796l6.252 9.277 6.297-9.277h3.752V20.53h.089z"
       ></path>
       <path
         fill="#000"
         d="M107.492 20.762h-4.823l-7.592-9h9.11c.626 0 .983-.554.983-1.662 0-1.108-.313-1.662-.983-1.662H93.201v12.277h-3.528V4.746h14.738c1.339 0 2.411.554 3.215 1.662.715 1.015 1.117 2.261 1.117 3.692 0 1.43-.358 2.677-1.117 3.692-.804 1.108-1.876 1.662-3.215 1.662h-1.34l4.421 5.308zM127.008 8.485h-12.683v2.4h11.835v3.738h-11.835v2.4h12.639v3.739h-16.167V4.746h16.211v3.739zM146.659 4.746l-7.726 16.016h-2.992L128.17 4.746h3.975l5.314 10.985 5.27-10.985h3.93zM167.113 15.685c0 1.43-.491 2.677-1.429 3.6-.938.969-2.144 1.43-3.528 1.43h-8.932c-1.385 0-2.546-.461-3.528-1.43-.938-.97-1.429-2.17-1.429-3.6V9.777c0-1.43.491-2.677 1.429-3.646.982-.97 2.143-1.43 3.528-1.43h8.932c1.384 0 2.545.46 3.528 1.43.938.97 1.429 2.17 1.429 3.646v5.908zm-3.528 0V9.823c0-.877-.491-1.338-1.429-1.338h-8.932c-.938 0-1.429.461-1.429 1.338v5.862c0 .877.491 1.338 1.429 1.338h8.932c.938 0 1.429-.461 1.429-1.338zM184.441 20.762h-15.184V4.746h3.528v12.277h11.656v3.739zM55.374 8.485h-6.833v12.277h-3.528V8.485h-6.877V4.746h17.238v3.739zM73.506 8.485H60.823v2.4h11.835v3.738H60.823v2.4h12.639v3.739H57.295V4.746h16.211v3.739z"
       ></path>
       <path
         fill="#EB5627"
         d="M81.858 4.238l-6.476 8.354 7.145 8.216h5.18l-7.01-8.585 5.94-7.985h-4.78z"
       ></path>
     </g>
     <defs>
       <clipPath id="clip0_2987_2315">
         <path
           fill="#fff"
           d="M0 0H250V24H0z"
           transform="translate(0 .5)"
         ></path>
       </clipPath>
     </defs>
   </svg>
 }


export const NewLogoForDarkBg =({width='441',height='49'})=>{
  return <svg width="350" height="100" viewBox="0 0 217 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.4775 18.9229H10.1342L0.791016 23.9998H28.8701L19.4775 18.9229Z" fill="#EA4025"/>
  <path d="M0 22.7077L9.34322 17.6769L14.0395 10.1077V0L0 22.7077Z" fill="#EB5626"/>
  <path d="M15.6211 0V10.1077L20.3174 17.6769L29.6606 22.7077L15.6211 0Z" fill="#EA7C25"/>
  <path d="M18.3401 17.4457H11.3203L12.1113 16.1534L14.0392 13.0611L14.8302 11.7688L15.6212 13.0611L17.5491 16.1534L18.3401 17.4457Z" fill="white"/>
  <path d="M155.135 11.8377C156.431 12.64 157.079 13.864 157.079 15.5097C157.079 16.7029 156.698 17.7109 155.937 18.5337C155.114 19.4389 154.004 19.8914 152.605 19.8914H137.207V4H152.327C153.726 4 154.837 4.45257 155.66 5.35772C156.4 6.18057 156.77 7.18857 156.77 8.38171C156.77 9.86286 156.225 11.0149 155.135 11.8377ZM152.327 10.1097C152.862 10.1097 153.129 9.70857 153.129 8.90629C153.129 8.104 152.862 7.70286 152.327 7.70286H140.91V10.1097H152.327ZM152.605 16.1886C153.14 16.1886 153.407 15.7874 153.407 14.9851C153.407 14.1829 153.14 13.7817 152.605 13.7817H140.91V16.1886H152.605Z" fill="#EB5627"/>
  <path d="M177.681 19.8914H173.978V14.8309H164.228V11.128H173.978V8.90629C173.978 8.104 173.711 7.70286 173.176 7.70286H162.685C162.17 7.70286 161.913 8.104 161.913 8.90629V19.8914H158.21V8.90629C158.21 7.56914 158.622 6.41714 159.445 5.45029C160.288 4.48343 161.368 4 162.685 4H173.176C174.513 4 175.593 4.48343 176.416 5.45029C177.26 6.41714 177.681 7.56914 177.681 8.90629V19.8914Z" fill="#EB5627"/>
  <path d="M198.16 14.9851C198.16 16.3223 197.759 17.464 196.957 18.4103C196.093 19.3977 195.002 19.8914 193.686 19.8914H179.985V16.1886H193.686C194.2 16.1886 194.457 15.7874 194.457 14.9851C194.457 14.1829 194.2 13.7817 193.686 13.7817H183.719C182.402 13.7817 181.312 13.288 180.448 12.3006C179.625 11.3749 179.214 10.2434 179.214 8.90629C179.214 7.56914 179.635 6.41714 180.479 5.45029C181.322 4.48343 182.402 4 183.719 4H197.419V7.70286H183.719C183.205 7.70286 182.947 8.104 182.947 8.90629C182.947 9.688 183.205 10.0789 183.719 10.0789H193.686C195.002 10.0789 196.093 10.5726 196.957 11.56C197.759 12.5063 198.16 13.648 198.16 14.9851Z" fill="#EB5627"/>
  <path d="M216.645 7.70286H203.408V10.0789H215.781V13.7817H203.408V16.1886H216.615V19.8914H199.705V4H216.645V7.70286Z" fill="#EB5627"/>
  <path d="M56.6377 19.8914H51.5771L43.6469 10.9737H53.1817C53.8606 10.9737 54.2 10.4286 54.2 9.33829C54.2 8.248 53.8606 7.70286 53.1817 7.70286H41.7029V19.8914H38V4H53.3977C54.8171 4 55.9383 4.55543 56.7611 5.66629C57.5223 6.67429 57.9029 7.89829 57.9029 9.33829C57.9029 10.7783 57.5223 12.0023 56.7611 13.0103C55.9383 14.1211 54.8171 14.6766 53.3977 14.6766H51.9783L56.6377 19.8914Z" fill="white"/>
  <path d="M76.3959 7.70286H63.1582V10.0789H75.5319V13.7817H63.1582V16.1886H76.3651V19.8914H59.4554V4H76.3959V7.70286Z" fill="white"/>
  <path d="M96.4109 4L88.3263 19.8914H85.1789L77.0635 4H81.1983L86.7835 14.8617L92.276 4H96.4109Z" fill="white"/>
  <path d="M117.201 14.8617C117.201 16.3017 116.697 17.5051 115.689 18.472C114.681 19.4183 113.457 19.8914 112.017 19.8914H102.699C101.259 19.8914 100.035 19.4183 99.0265 18.472C98.0185 17.5051 97.5145 16.3017 97.5145 14.8617V9.02971C97.5145 7.58971 98.0185 6.39657 99.0265 5.45029C100.035 4.48343 101.259 4 102.699 4H112.017C113.457 4 114.681 4.48343 115.689 5.45029C116.697 6.39657 117.201 7.58971 117.201 9.02971V14.8617ZM113.499 14.8617V9.02971C113.499 8.14514 113.005 7.70286 112.017 7.70286H102.699C101.711 7.70286 101.217 8.14514 101.217 9.02971V14.8617C101.217 15.7463 101.711 16.1886 102.699 16.1886H112.017C113.005 16.1886 113.499 15.7463 113.499 14.8617Z" fill="white"/>
  <path d="M134.65 19.8914H118.759V4H122.462V16.1886H134.65V19.8914Z" fill="white"/>
  </svg>
}


export const NewLogoForLightBg =({width='441',height='49'})=>{
  return <svg width="217" height="24" viewBox="0 0 217 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.4775 18.9229H10.1342L0.791016 23.9998H28.8701L19.4775 18.9229Z" fill="#EA4025"/>
  <path d="M0 22.7077L9.34322 17.6769L14.0395 10.1077V0L0 22.7077Z" fill="#EB5626"/>
  <path d="M15.6211 0V10.1077L20.3174 17.6769L29.6606 22.7077L15.6211 0Z" fill="#EA7C25"/>
  <path d="M18.3401 17.4457H11.3203L12.1113 16.1534L14.0392 13.0611L14.8302 11.7688L15.6212 13.0611L17.5491 16.1534L18.3401 17.4457Z" fill="#0F1328"/>
  <path d="M155.135 11.8377C156.431 12.64 157.079 13.864 157.079 15.5097C157.079 16.7029 156.698 17.7109 155.937 18.5337C155.114 19.4389 154.004 19.8914 152.605 19.8914H137.207V4H152.327C153.726 4 154.837 4.45257 155.66 5.35772C156.4 6.18057 156.77 7.18857 156.77 8.38171C156.77 9.86286 156.225 11.0149 155.135 11.8377ZM152.327 10.1097C152.862 10.1097 153.129 9.70857 153.129 8.90629C153.129 8.104 152.862 7.70286 152.327 7.70286H140.91V10.1097H152.327ZM152.605 16.1886C153.14 16.1886 153.407 15.7874 153.407 14.9851C153.407 14.1829 153.14 13.7817 152.605 13.7817H140.91V16.1886H152.605Z" fill="#EB5627"/>
  <path d="M177.681 19.8914H173.978V14.8309H164.228V11.128H173.978V8.90629C173.978 8.104 173.711 7.70286 173.176 7.70286H162.685C162.17 7.70286 161.913 8.104 161.913 8.90629V19.8914H158.21V8.90629C158.21 7.56914 158.622 6.41714 159.445 5.45029C160.288 4.48343 161.368 4 162.685 4H173.176C174.513 4 175.593 4.48343 176.416 5.45029C177.26 6.41714 177.681 7.56914 177.681 8.90629V19.8914Z" fill="#EB5627"/>
  <path d="M198.16 14.9851C198.16 16.3223 197.759 17.464 196.957 18.4103C196.093 19.3977 195.002 19.8914 193.686 19.8914H179.985V16.1886H193.686C194.2 16.1886 194.457 15.7874 194.457 14.9851C194.457 14.1829 194.2 13.7817 193.686 13.7817H183.719C182.402 13.7817 181.312 13.288 180.448 12.3006C179.625 11.3749 179.214 10.2434 179.214 8.90629C179.214 7.56914 179.635 6.41714 180.479 5.45029C181.322 4.48343 182.402 4 183.719 4H197.419V7.70286H183.719C183.205 7.70286 182.947 8.104 182.947 8.90629C182.947 9.688 183.205 10.0789 183.719 10.0789H193.686C195.002 10.0789 196.093 10.5726 196.957 11.56C197.759 12.5063 198.16 13.648 198.16 14.9851Z" fill="#EB5627"/>
  <path d="M216.645 7.70286H203.408V10.0789H215.781V13.7817H203.408V16.1886H216.615V19.8914H199.705V4H216.645V7.70286Z" fill="#EB5627"/>
  <path d="M56.6377 19.8914H51.5771L43.6469 10.9737H53.1817C53.8606 10.9737 54.2 10.4286 54.2 9.33829C54.2 8.248 53.8606 7.70286 53.1817 7.70286H41.7029V19.8914H38V4H53.3977C54.8171 4 55.9383 4.55543 56.7611 5.66629C57.5223 6.67429 57.9029 7.89829 57.9029 9.33829C57.9029 10.7783 57.5223 12.0023 56.7611 13.0103C55.9383 14.1211 54.8171 14.6766 53.3977 14.6766H51.9783L56.6377 19.8914Z" fill="#0F1328"/>
  <path d="M76.3959 7.70286H63.1582V10.0789H75.5319V13.7817H63.1582V16.1886H76.3651V19.8914H59.4554V4H76.3959V7.70286Z" fill="#0F1328"/>
  <path d="M96.4109 4L88.3263 19.8914H85.1789L77.0635 4H81.1983L86.7835 14.8617L92.276 4H96.4109Z" fill="#0F1328"/>
  <path d="M117.201 14.8617C117.201 16.3017 116.697 17.5051 115.689 18.472C114.681 19.4183 113.457 19.8914 112.017 19.8914H102.699C101.259 19.8914 100.035 19.4183 99.0265 18.472C98.0185 17.5051 97.5145 16.3017 97.5145 14.8617V9.02971C97.5145 7.58971 98.0185 6.39657 99.0265 5.45029C100.035 4.48343 101.259 4 102.699 4H112.017C113.457 4 114.681 4.48343 115.689 5.45029C116.697 6.39657 117.201 7.58971 117.201 9.02971V14.8617ZM113.499 14.8617V9.02971C113.499 8.14514 113.005 7.70286 112.017 7.70286H102.699C101.711 7.70286 101.217 8.14514 101.217 9.02971V14.8617C101.217 15.7463 101.711 16.1886 102.699 16.1886H112.017C113.005 16.1886 113.499 15.7463 113.499 14.8617Z" fill="#0F1328"/>
  <path d="M134.65 19.8914H118.759V4H122.462V16.1886H134.65V19.8914Z" fill="#0F1328"/>
  </svg>
}


 export const NoDateIcon =()=>{
     return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
         <circle cx="12.1776" cy="11.7612" r="11.1167" stroke="#979797" stroke-width="0.766667" stroke-dasharray="3.07 3.07"/>
         <mask id="mask0_1712_10850" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="6" y="5" width="13" height="13">
             <rect x="6.33972" y="5.92334" width="11.6757" height="11.6757" fill="#D9D9D9"/>
         </mask>
         <g mask="url(#mask0_1712_10850)">
             <path d="M8.9219 16.3829C8.67616 16.3829 8.46816 16.2977 8.29789 16.1275C8.12762 15.9572 8.04248 15.7492 8.04248 15.5034V8.99202C8.04248 8.74628 8.12762 8.53828 8.29789 8.368C8.46816 8.19773 8.67616 8.1126 8.9219 8.1126H9.59551V7.0835H10.3439V8.1126H14.03V7.0835H14.7597V8.1126H15.4333C15.6791 8.1126 15.8871 8.19773 16.0573 8.368C16.2276 8.53828 16.3127 8.74628 16.3127 8.99202V15.5034C16.3127 15.7492 16.2276 15.9572 16.0573 16.1275C15.8871 16.2977 15.6791 16.3829 15.4333 16.3829H8.9219ZM8.9219 15.6531H15.4333C15.4707 15.6531 15.5051 15.6375 15.5362 15.6064C15.5674 15.5752 15.583 15.5409 15.583 15.5034V10.938H8.7722V15.5034C8.7722 15.5409 8.78779 15.5752 8.81897 15.6064C8.85017 15.6375 8.88448 15.6531 8.9219 15.6531ZM8.7722 10.2083H15.583V8.99202C15.583 8.9546 15.5674 8.92029 15.5362 8.88909C15.5051 8.85791 15.4707 8.84232 15.4333 8.84232H8.9219C8.88448 8.84232 8.85017 8.85791 8.81897 8.88909C8.78779 8.92029 8.7722 8.9546 8.7722 8.99202V10.2083Z" fill="#979797"/>
         </g>
     </svg>
 }

 export const ClockIcon =()=>{
     return <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24">
         <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 6 L 11 12.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13 11.585938 L 13 6 L 11 6 z"></path>
     </svg>
 }

 export const DateIcon =()=>{
     return <svg className="Icon CalendarIcon" viewBox="0 0 32 32" aria-hidden="true" focusable="false">
         <path
             d="M24,2V1c0-0.6-0.4-1-1-1s-1,0.4-1,1v1H10V1c0-0.6-0.4-1-1-1S8,0.4,8,1v1C4.7,2,2,4.7,2,8v16c0,3.3,2.7,6,6,6h16c3.3,0,6-2.7,6-6V8C30,4.7,27.3,2,24,2z M8,4v1c0,0.6,0.4,1,1,1s1-0.4,1-1V4h12v1c0,0.6,0.4,1,1,1s1-0.4,1-1V4c2.2,0,4,1.8,4,4v2H4V8C4,5.8,5.8,4,8,4z M24,28H8c-2.2,0-4-1.8-4-4V12h24v12C28,26.2,26.2,28,24,28z"></path>
     </svg>
 }

 export const AssigneeIcon =()=>{
     return <svg className="Icon UserIcon" viewBox="0 0 32 32" aria-hidden="true" focusable="false">
         <path
             d="M16,18c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S20.4,18,16,18z M16,4c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S19.3,4,16,4z M29,32c-0.6,0-1-0.4-1-1v-4.2c0-2.6-2.2-4.8-4.8-4.8H8.8C6.2,22,4,24.2,4,26.8V31c0,0.6-0.4,1-1,1s-1-0.4-1-1v-4.2C2,23,5,20,8.8,20h14.4c3.7,0,6.8,3,6.8,6.8V31C30,31.6,29.6,32,29,32z"></path>
     </svg>
 }


 export const AddIcon =()=>{
     return <svg className="MiniIcon--large MiniIcon DashedTile-icon PlusMiniIcon" viewBox="0 0 24 24"
                 aria-hidden="true" focusable="false" height='24px' width='24px'>
         <path
             d="M10,10V4c0-1.1,0.9-2,2-2s2,0.9,2,2v6h6c1.1,0,2,0.9,2,2s-0.9,2-2,2h-6v6c0,1.1-0.9,2-2,2s-2-0.9-2-2v-6H4c-1.1,0-2-0.9-2-2s0.9-2,2-2H10z"></path>
     </svg>
 }

 export const MilestoneAddIcon =()=>{

    return <svg className="MiniIcon--large MiniIcon DashedTile-icon PlusMiniIcon" viewBox="0 0 24 24" aria-hidden="true" focusable="false" height='18px' width='18px'>
    <path d="M10,10V4c0-1.1,0.9-2,2-2s2,0.9,2,2v6h6c1.1,0,2,0.9,2,2s-0.9,2-2,2h-6v6c0,1.1-0.9,2-2,2s-2-0.9-2-2v-6H4c-1.1,0-2-0.9-2-2s0.9-2,2-2H10z"></path>
    </svg>
 }


 export const FileIcon =()=>{
     return <svg className="Icon AssetIcon AssetIcon--default AttachmentCard-svg FileDefaultIcon" viewBox="0 0 32 32"
                 aria-hidden="true" focusable="false">
         <path
             d="M0 6a6 6 0 0 1 6-6h11.84a7 7 0 0 1 4.48 1.622l7.161 5.967A7 7 0 0 1 32 12.967V26a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6zm8 2a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2H8zm0 5a1 1 0 1 0 0 2h15a1 1 0 1 0 0-2H8zm0 10a1 1 0 1 0 0 2h15a1 1 0 1 0 0-2H8zm0-5a1 1 0 1 0 0 2h11.91a1 1 0 1 0 0-2H8z"></path>
     </svg>
 }
