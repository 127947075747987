import React, {useEffect} from "react";
import AppProject from "../components/Appboard/AppProject";
import { useStateContext } from "../contexts/ContextProvider";
import { Navigate } from "react-router-dom";


const AppBoard = () => {

  const { user, table, project } = useStateContext();
  const { role } = user.user;

  useEffect(() => {
        console.log('change on all time session project change', project)
    }, [project])

  if (table.length === 1) {

    return (

      <Navigate state={{ project: table[0] }} to={`/appboard/project/${table[0]?.gid}`} />
    )
  }

  return (
    <div className="appboard-grid">
      <div className={`appboard-sub-grid pt-4 `}>
        <div className="appboard-title" >
          <h1 className=" meeting-text">
            App{" "}
            <span style={{ fontWeight: "700" }} className="text-[#F37A20]">
              Board
            </span>
          </h1>


        </div>
        <div className="appboard-grid-project">
          <div className=" bg-[white] pt-[20px] pl-[31px] pb-[23px] h-[100%] xm:pb-[15px] xm:pt-[15px]">
            <span className=" appboard-grid-project-title">Project List</span>
          </div>
          <div className="appboard-grid-project-wrapper">
            <AppProject />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppBoard;
