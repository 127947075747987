import React from 'react';
import Navigation from './components/navigation';
import './App.css';
import Swal from 'sweetalert2'

const App = () => {


  return (
    <>
      <Navigation />
    </>
  );
};

export default App;
