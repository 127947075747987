export function getFirstLetters(str) {
    // Split the string into words using space as the delimiter
    let words = str.split(" ");

    // Use map to create a new array with the first letter of each word
    let firstLetters = words.map(word => word.charAt(0));

    // Join the array of first letters into a single string
    return firstLetters.join("");
}

const getMimeTypeFromExtension = (extension) => {
    const mimeTypes = {
        png: 'file/png',
        jpg: 'file/jpg',
        jpeg: 'file/jpeg',
        gif: 'file/gif',
        pdf: 'file/pdf',
        zip: 'file/zip',
        docx: 'file/docx'
    };
    return mimeTypes[extension.toLowerCase()] || '';
};

export const getFileMimeType = (file) => {
    const fileNameParts = file.name.split('.');
    const fileExtension = fileNameParts[fileNameParts.length - 1];
    const mimeType = getMimeTypeFromExtension(fileExtension);
    return mimeType;
};

export function getRandomColor() {
    // Generate random values for red, green, and blue components
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    // Combine the values into an RGB color string
    return `rgb(${r}, ${g}, ${b})`;
}


export function convertToPST(utcDateString) {
    // Create a Date object from the UTC date string
    const utcDate = new Date(utcDateString);

    // Convert to Pakistan Standard Time (GMT+5)
    const pstDate = new Date(utcDate);
    pstDate.setHours(pstDate.getHours()); // Add 5 hours for Pakistan time

    // Format the date and time
    const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    };
    const pstFormattedDateTime = pstDate.toLocaleString('en-US', options);

    // Return the formatted PST date and time
    return pstFormattedDateTime;
}


export function convertToRequiredFormat(dateTimeString) {
    if (!dateTimeString) return ''; // Handle case where dateTimeString is undefined or null

    // Parse the date string into a Date object
    const date = new Date(dateTimeString);

    // Format the date and time according to the required format
    const day = date.getDate(); // Day of the month (1-31)
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthNames[date.getMonth()]; // Month name (short form)
    const hours = date.getHours(); // Hours (0-23)
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Minutes (00-59)
    const period = hours >= 12 ? ' PM' : ' AM'; // AM/PM period
    const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format

    // Construct the final formatted string
    const formattedDateTime = `${day} ${month}, ${formattedHours}:${minutes}${period}`;

    return formattedDateTime;
}


export function standardFormat(dateTimeString) {
    if (!dateTimeString) return ''; // Handle case where dateTimeString is undefined or null

    // Parse the date string into a Date object
    const date = new Date(dateTimeString);

    // Format the date and time according to the required format
    const day = date.getDate(); // Day of the month (1-31)
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthNames[date.getMonth()]; // Month name (short form)
    const hours = date.getHours(); // Hours (0-23)
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Minutes (00-59)
    const period = hours >= 12 ? ' PM' : ' AM'; // AM/PM period
    const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format

    // Construct the final formatted string
    const formattedDateTime = `${day} ${month}, ${formattedHours}:${minutes}${period}`;

    return formattedDateTime;
}


export function convertToRequiredDateFormat(dateTimeString) {
    if (!dateTimeString) return ''; // Handle case where dateTimeString is undefined or null

    // Parse the date string into a Date object
    const date = new Date(dateTimeString);

    // Format the date according to the required format
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthNames[date.getMonth()]; // Month name (short form)
    const day = date.getDate(); // Day of the month (1-31)
    const year = date.getFullYear(); // Full year (4 digits)
    
    const formattedDate = `${month} ${day},${year}`;

    return formattedDate;
}


export function convertDateToMMDDYYYY(dateStr) {
    const monthMap = {
        'Jan': '01',
        'Feb': '02',
        'Mar': '03',
        'Apr': '04',
        'May': '05',
        'Jun': '06',
        'Jul': '07',
        'Aug': '08',
        'Sep': '09',
        'Oct': '10',
        'Nov': '11',
        'Dec': '12'
    };

    // Split the input date string
    const [day, month, year] = dateStr.split('/');

    // Get the month number from the map
    const monthNumber = monthMap[month];

    // Format the date as MM/DD/YYYY
    const formattedDate = `${monthNumber}/${day.padStart(2, '0')}/${year}`;

    return formattedDate;
}



export function convertDateFromDDMMYYYYToMMDDYYYY(dateStr) {
    // Split the input date string into day, month, and year
    if(!dateStr){
        return '-'
    }
    const [day, month, year] = dateStr?.split('/');

    // Return the date in MM/DD/YYYY format
    return `${month.padStart(2, '0')}/${day.padStart(2, '0')}/${year}`;
}


export function convertDateFormat(dateString) {
    // Split the date string by the hyphen
    if(!dateString){
        return '-'
    }

    const [year, month, day] = dateString?.split('-');

    // Return the date in MM-DD-YYYY format
    return `${month}-${day}-${year}`;
}


export function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return 'th'; // special case for 11th to 20th
    switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
}

export function formatMDY(dateString) {

    if(!dateString){
        return '-'
    }

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const dateParts = dateString.split("-");
    const year = dateParts[0];
    const month = parseInt(dateParts[1], 10) - 1;
    const day = parseInt(dateParts[2], 10);

    

    const ordinalSuffix = getOrdinalSuffix(day);
    return `${months[month]} ${day}${ordinalSuffix}, ${year}`;

    // Old work
    
    // if(!dateString){
    //     return '-'
    // }

    // return new Date(dateString).toLocaleDateString('en-US', {
    //     year: 'numeric',
    //     month: '2-digit',
    //     day: '2-digit'
    // })
}


export function formatDrawerDate(dateString) {

    if(!dateString){
        return '-'
    }

    const dateParts = dateString.split('/');
    const month = parseInt(dateParts[0]);
    const day = parseInt(dateParts[1]);
    const year = parseInt(dateParts[2]);

    const months = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
    ];

    const formattedDate = `${months[month - 1]} ${day}${getOrdinalSuffix(day)}, ${year}`;
    return formattedDate;
    

    // Old work
    
    // if(!dateString){
    //     return '-'
    // }

    // return new Date(dateString).toLocaleDateString('en-US', {
    //     year: 'numeric',
    //     month: '2-digit',
    //     day: '2-digit'
    // })
}

export function convertDate(dateString) {
    // Split the date string by spaces
    let parts = dateString.split(' ');
  
    // Extract the date part and split it into components
    let datePart = parts[1];
    let [day, month, year] = datePart.split(' ');
  
    // Map month abbreviations to month numbers
    const months = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12'
    };
  
    // Convert the month abbreviation to a number
    let monthNumber = months[month];
  
    // Format the date
    let formattedDate = `${year}-${monthNumber}-${day}`;
  
    return formattedDate;
}
  
  