import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {BsFillFilePdfFill, BsFileTextFill, BsFillPlayBtnFill, BsFileZipFill} from 'react-icons/bs'
import {useStateContext} from '../contexts/ContextProvider';
import {Link} from 'react-router-dom';
// import { JpgIcon } from '../app-constants';

const FILE_FORMATS = ['jpg', 'jpeg', 'gif', 'png', 'mp4', 'pdf', 'docx']


const WhatsNew = (props) => {
    const [selected, setSelected] = useState('btn1');
    const {files, user, setFiles, project} = useStateContext();
    const [data, setData] = useState([]);

    useEffect(() => {
    }, [project])

    const filterFiles = (attachments) => {

        return attachments.filter(item => {
            const fileExtension = item.file_path?.substring(item.file_path.lastIndexOf('.') + 1)?.toLowerCase();
            // console.log(fileExtension);
            return FILE_FORMATS.includes(fileExtension) === true;
        });
    }

    /*const getAllFiles = () => {
        if (user) {
            try {
                axios.get(`${process.env.REACT_APP_BASE_URL}downloadable-content?content_by_client=${user.user.id}`, {
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    }
                }).then((response) => {

                    setFiles(response.data.data);
                    // console.log('=====',files);
                    // console.log(response);
                }).catch((err) => {
                    console.log('err====', err);
                })

            } catch (e) {
                console.log(e);
            }
        }
    }
    useEffect(() => {
        getAllFiles();
    }, [])*/

    return (

    <>
        <div className="main_style_box project-files">
            <div className="project_title">
                What’s New
                <Link to='/downloadable-view/whats-new'>
                    See All
                </Link>
            </div>
                <div className="whats-file">

                    <div className="whats-file-inner">


                    
                    {/* <div className="main-title">
                        <div className="inner-title">File/Documentation</div>
                        <Link to='/downloadable-view/whats-new'>
                            See All
                        </Link>
                    </div> */}
                    
                        {selected === 'btn1' && props.fileDocumentation && props.fileDocumentation.length !== 0 ? props.fileDocumentation.map((item, index) => {
                            const fileExtension = item.file_path?.substring(item.file_path.lastIndexOf('.') + 1)?.toLowerCase();
                            let icon;
                            if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'gif') {
                                icon =
                                    <img src={item.file_path}
                                        className="mr-2 w-[50px] h-[50px] lg:w-[35px] lg:h-[35px] md:w-[30px] md:h-[30px] sm:w-[25px] sm:h-[25px] xm:w-[22px] xm:h-[22px] "
                                        alt={`${item.file_path}`}/>

                            } else if (fileExtension === 'pdf') {
                                icon = <BsFillFilePdfFill size={35} className='mr-2' color='red'/>
                            } else if (fileExtension === 'mp4') {
                                icon = <BsFillPlayBtnFill size={35} className='mr-2' color='gray'/>;
                            } else if (fileExtension === 'zip') {
                                icon = <BsFileZipFill size={35} className='mr-2' color='gray'/>;
                            }
                            else if (fileExtension === 'webp') {
                                icon = <BsFileZipFill size={35} className='mr-2' color='gray'/>;
                            } else {
                                icon = <BsFileTextFill size={35} className='mr-2' color='blue'/>;
                            }
                            return (
                                <div key={index} className="content">
                                    <div className="title">
                                        {icon}
                                        <div className='inner'>
                                            <p className='title'>{item.file_name}</p>
                                            <p className='date'>11 Oct,
                                                5:00 AM</p>
                                        </div>
                                    </div>
                                    <a href={item.file_path} className='link no-underline' download target='_blank'>
                                        View
                                    </a>
                                </div>

                            )
                        }) :
                            
                        <div className='no-records'>
                            <p>No Data Exists</p>
                        </div>
                    }
                    
                    
                </div>
            </div>
        </div>
        


        {/* <div className='relative whats-new'>
            <p className='text-[#131313] !text-[20px] pt-[21px] pl-[34px] md:p-[20px] sm:p-[15px] xm:p-[10px] pb-[17px] md:pb-[14px]  sm:pb-[12px] xm:pb-[10px] lg:!text-[20px] md:!text-[13px]  sm:!text-[10px] xm:!text-[10px] font-bold'> What’s
                New </p>
            <div
                className=' flex justify-between items-center gap-[80px] md:gap-[70px] sm:gap-[60px] xm:gap-[50px] pb-[9px] px-[34px] md:px-[20px] sm:px-[15px] xm:px-[12px] '>
                <button className='selected files-new-tab'>File/Documentation</button>
                <Link to='/downloadable-view'>
                    <button
                        className='text-[#718EBF] font-semibold text-[12px] md:text-[10px]  sm:text-[8px] xm:text-[7px]'>See
                        All
                    </button>
                </Link>
            </div>
            <hr className='border-1 border-[#EFF1F4]'/>
            <div
                className={`space-y-6 build-innerdiv h-[170px] xm:h-[140px] px-[34px] pt-[21px] w-[100%] overflow-y-auto  `}>
                {selected === 'btn1' && props.fileDocumentation && props.fileDocumentation.length !== 0 ? props.fileDocumentation.map((item, index) => {
                    const fileExtension = item.file_path?.substring(item.file_path.lastIndexOf('.') + 1)?.toLowerCase();
                    let icon;
                    if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'gif') {
                        icon =
                            <img src={item.file_path}
                                 className="mr-2 w-[50px] h-[50px] lg:w-[35px] lg:h-[35px] md:w-[30px] md:h-[30px] sm:w-[25px] sm:h-[25px] xm:w-[22px] xm:h-[22px] "
                                 alt={`${item.file_path}`}/>

                    } else if (fileExtension === 'pdf') {
                        icon =
                            <BsFillFilePdfFill size={35} className='mr-2' color='red'/>
                    } else if (fileExtension === 'mp4') {
                        icon = <BsFillPlayBtnFill size={35} className='mr-2' color='gray'/>;
                    } else if (fileExtension === 'zip') {
                        icon = <BsFileZipFill size={35} className='mr-2' color='gray'/>;
                    } else {
                        icon = <BsFileTextFill size={35} className='mr-2' color='blue'/>;
                    }
                    return (
                        <div key={index} className='flex flex-wrap justify-between'>
                            <div className="flex flex-wrap">
                                {icon}
                                <div className='pt-1'>
                                    <p className='text-[13px] lg:text-[12px] md:text-[11px]  sm:text-[9px] xm:text-[8px] font-medium text-[#505887]'>{item.file_name}</p>
                                    <p className='text-[10px] lg:text-[10px] md:text-[9px]  sm:text-[8px] xm:text-[7px] font-medium text-[#718EBF]'>11/10/2022,
                                        5:00 AM</p>
                                </div>
                            </div>
                            <a href={item.file_path} className=' no-underline' download target='_blank'>
                                <p className='text-[12px] lg:text-[11px] md:text-[10px]  sm:text-[8px] xm:text-[7px] ml-6 pt-2 text-[#F47A1F]'>View</p>
                            </a>
                        </div>

                    )
                }) :
                    <div className='flex justify-center items-center p-4 bg-[white]'>
                                    <p className='text-center text-[13px] text-[#979797] font-bold'>No Data Exists</p>
                                </div>
                  
                   }


            </div>
        </div> */}
        </>
    )
}

export default WhatsNew;