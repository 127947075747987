import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Modal } from "react-bootstrap";
import imageBroken from "../../../img/NoImages.png"
import Img02 from "../../../img/blog2.webp"
import Img03 from "../../../img/blog3.webp"
import { NoRecordFound } from '../../../components/General';
import { convertDateFormat } from '../../../utils/Helper'
import moment from 'moment'
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/navigation";
import { MenuItem, InputLabel, FormControl, Select } from '@material-ui/core';
import { useStateContext } from '../../../contexts/ContextProvider';
import Articles1 from "../../../img/demo/downloadable-demo/articles/Articles1.png"
import Articles2 from "../../../img/demo/downloadable-demo/articles/Articles2.png"
import Articles3 from "../../../img/demo/downloadable-demo/articles/Articles3.png"
import Articles4 from "../../../img/demo/downloadable-demo/articles/Articles4.png"
import Podcasts1 from "../../../img/demo/downloadable-demo/articles/Podcasts1.png"
import Podcasts2 from "../../../img/demo/downloadable-demo/articles/Podcasts2.png"
import Podcasts3 from "../../../img/demo/downloadable-demo/articles/Podcasts3.png"
import Podcasts4 from "../../../img/demo/downloadable-demo/articles/Podcasts4.png"

SwiperCore.use([Navigation]);



function ArticlesView(props) {
    const [show, setShow] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleOpenDialog = (item) => {
        setSelectedItem(item);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedItem(null);
    };

    const HtmlContent = ({ htmlString }) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: htmlString }} />
        );
    };

    const swiperRef1 = useRef(null);
    const swiperRef2 = useRef(null);


    const [isNextButtonDisabled1, setIsNextButtonDisabled1] = useState(false);
    const [isPrevButtonDisabled1, setIsPrevButtonDisabled1] = useState(true);
    const [isNextButtonDisabled2, setIsNextButtonDisabled2] = useState(false);
    const [isPrevButtonDisabled2, setIsPrevButtonDisabled2] = useState(true);



    useEffect(() => {
        const swiperInstance1 = swiperRef1.current.swiper;
        // const swiperInstance2 = swiperRef2.current.swiper;

        const handleSlideChange1 = () => {
            setIsNextButtonDisabled1(swiperInstance1.isEnd);
            setIsPrevButtonDisabled1(swiperInstance1.isBeginning);
        };

        const handleSlideChange2 = () => {
            setIsNextButtonDisabled2(swiperInstance2.isEnd);
            setIsPrevButtonDisabled2(swiperInstance2.isBeginning);
        };

        swiperInstance1.on('slideChange', handleSlideChange1);
        // swiperInstance2.on('slideChange', handleSlideChange2);

        // Initial state setup
        setIsPrevButtonDisabled1(swiperInstance1.isBeginning);
        // setIsPrevButtonDisabled2(swiperInstance2.isBeginning);

        return () => {
            swiperInstance1.off('slideChange', handleSlideChange1);
            // swiperInstance2.off('slideChange', handleSlideChange2);
        };
    }, []);

    const handleNextButtonClick1 = () => {
        swiperRef1.current.swiper.slideNext();
    };

    const handlePrevButtonClick1 = () => {
        swiperRef1.current.swiper.slidePrev();
    };

    const handleNextButtonClick2 = () => {
        swiperRef2.current.swiper.slideNext();
    };

    const handlePrevButtonClick2 = () => {
        swiperRef2.current.swiper.slidePrev();
    };


    // material UI Select
    const [selectedProject1, setSelectedProject1] = useState('all'); // Default value set to 'all'
    const [selectedProject2, setSelectedProject2] = useState('all2'); // Default value set to 'all2'

    ;

    const handleChangeOption1 = (e) => {
        sessionStorage.setItem('session_project1', e.target.value);
        setSelectedProject1(e.target.value);
    };

    const handleChangeOption2 = (e) => {
        sessionStorage.setItem('session_project2', e.target.value);
        setSelectedProject2(e.target.value);
    };

    return (
        <Container fluid>
            <Row>
                <div className="downloadable-layout">
                    <div className="downloadable-title-wrap">
                        <div className="project_title_new">What’s New</div>
                    </div>

                    <div className="articles-inner">
                        <Swiper
                            ref={swiperRef1}
                            spaceBetween={30}
                            slidesPerView={4}
                            navigation={{
                                nextEl: '.downloadable-swiper-button-next',
                                prevEl: '.downloadable-swiper-button-prev'
                            }}

                            autoplay={false}
                            loop={false}
                            modules={[Navigation, Autoplay]}
                            className='mySwiper'
                        >

                            { props.data.length > 0 ? props.data.map((item, index) => (
                                <SwiperSlide>
                                    <div className="articles_section">
                                        <div className="articles_img">
                                            <Link to={'https://www.tekrevol.com/blogs'} style={{ cursor: 'Pointer' }}>
                                                <img src={item?.file_path} alt="Img01" />
                                            </Link>

                                        </div>
                                        <div className="articles_content">
                                            <h4>{item?.file_name}</h4>
                                        </div>
                                    </div>

                                </SwiperSlide>
                            )) : ''}

                        </Swiper>

                        { props.data.length > 4 && (<div className="downloadable-button-wrap">
                            <div
                                className={`downloadable-swiper-button-prev downloadable-swiper-button ${isPrevButtonDisabled1 ? 'swiper-button-disabled' : ''}`}
                                onClick={handlePrevButtonClick1}
                            >
                                <div className="btn_swiper">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 25 24"
                                    >
                                        <path
                                            fill="#F37A20"
                                            fillRule="evenodd"
                                            d="M20.2 11.387l-.083-.094-5-5a1 1 0 00-1.498 1.32l.084.094L16.995 11H5.41a1 1 0 00-.117 1.993L5.41 13h11.585l-3.292 3.293a1 1 0 00-.084 1.32l.084.094a1 1 0 001.32.083l.094-.083 5-5a1 1 0 00.083-1.32z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                            </div>

                            <div
                                className={`downloadable-swiper-button-next downloadable-swiper-button ${isNextButtonDisabled1 ? 'swiper-button-disabled' : ''}`}
                                onClick={handleNextButtonClick1}
                            >
                                <div className="btn_swiper">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 25 24"
                                    >
                                        <path
                                            fill="#F37A20"
                                            fillRule="evenodd"
                                            d="M20.2 11.387l-.083-.094-5-5a1 1 0 00-1.498 1.32l.084.094L16.995 11H5.41a1 1 0 00-.117 1.993L5.41 13h11.585l-3.292 3.293a1 1 0 00-.084 1.32l.084.094a1 1 0 001.32.083l.094-.083 5-5a1 1 0 00.083-1.32z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                        </div>)
                        }


                    </div>
                </div>




                {/* {props.data && props.data.length > 0 ?
                    props.data.map((item, index) => {

                        return (
                            <Col xxl={3} xl={6}>
                                <div className="articles_section" style={props?.event ? { height: "350px" } : undefined}>
                                    <div className="articles_img">
                                        <Link to={'https://www.tekrevol.com/blogs'} style={{cursor:'Pointer'}}>
                                            <img src={item.file_path} alt="Img01" onError={(event) => {
                                                event.target.src = imageBroken; // Replace with your dummy image path
                                            }} />
                                        </Link>

                                    </div>
                                    <div className="articles_content">
                                        <h4>{item.file_name}</h4>
                                        <div className="date">{ props?.event ? item?.event_date : item?.created_at}</div>
                                        <div className="txt">{item?.description?.length > 100 ? `${item?.description?.substring(0, 100)}...` : item?.description}</div>


                                        {item?.description?.length > 100 && (
                                            <div className="readmore" onClick={() => handleOpenDialog(item)}>Read more</div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        )

                    })
                    : <NoRecordFound />
                } */}

            </Row>

            <Modal show={show} onHide={handleClose} className="blog_modals">

                {selectedItem && (
                    <>
                        <Modal.Title>{selectedItem.file_name}</Modal.Title>

                        <Modal.Body>
                            <HtmlContent htmlString={selectedItem?.description?.replace(/\n/g, '<br>')} />
                            {/*<p>{selectedItem.description}</p>*/}
                        </Modal.Body>
                    </>
                )}

            </Modal>

        </Container>
    );
}

export default ArticlesView;