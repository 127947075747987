import React, { useState, useEffect } from 'react';
import { useStateContext } from "../../contexts/ContextProvider";
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import UpComing from "./components/UpComing";
import Rewards from "./components/Rewards";

import { Loader, Toast } from '../../components/General';
import axios from "axios";
import UpComingClient from './components/UpcomingClient';






function ClientMeeting() {


    // const {
    //     meeting,
    //     user,
    //     client,
    //     table,
    //     manualProjects,
    //     meeting,
    //     setClient,
    //     getAllManualProjects,
    //     selectdedProject,
    //     project
    // } = useStateContext();

    const { user, selectedProject, project, manualProjects, getAllManualProjects } = useStateContext();

    const { role } = user.user;
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [projectState, setProjectState] = useState("manual");
    const [projectType, setProjectType] = useState([]);
    const [currentdate, setCurrentdate] = useState(new Date());
    const [selected, setSelected] = useState("btn2");
    const [users, setUsers] = useState([]);
    const [meeting, setMeeting] = useState([]);

    const UpdateReadStatus = async () => {

        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}update-meeting-status/${user.user.id}`, { is_read: 1 }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false);
                    }
                })
                .catch((error) => {
                })
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    useEffect(() => {
        UpdateReadStatus();
    }, [])

    const getAllMeetings = async (projectId) => {
        if (user) {
            let url = `${process.env.REACT_APP_BASE_URL}meeting?meeting_by_client=${user.user.id}&manual_project_id=${projectId}`;
            /*if (user.user.role === 'client') {
                url = `${process.env.REACT_APP_BASE_URL}meeting?meeting_by_client=${user.user.id}&project_id=${projectId}`

            } else {
                url = `${process.env.REACT_APP_BASE_URL}meeting?user_id=${user.user.id}&project_id=${projectId}`
            }*/
            await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {
                if (response && response.data && response.data.data) {
                    // console.log('==============>>>', response.data.data)
                    setMeeting(response.data.data);
                }

                /*const formattedData = response.data.data.map(item => {
                    const date = new Date(item.date_time);
                    const formattedDate = date.toLocaleDateString() + ' | ' + date.toLocaleTimeString([], {
                        hour: 'numeric',
                        minute: 'numeric'
                    });
                    return {...item, date: formattedDate};
                });
                const sortByNewest = formattedData.sort((x, y) => {
                    return new Date(x.created_at) < new Date(y.created_at) ? 1 : -1
                })
                setMeeting(sortByNewest);*/
            }).catch((err) => {
                if (err.response && err.response.status === 401) {
                    localStorage.removeItem('tekrevol_user');
                    window.location.href = '/signin';
                } else if (err.response) {
                    console.log(err.response);
                    // Handle other status codes if needed
                } else {
                    console.log('Error', err.message);
                    //return Toast(err.message, false);
                }
                console.log('err====', err);
            });
            // console.log(table);
        }
    }

    useEffect(() => {
        setLoading(true);
        getAllMeetings(selectedProject);

    }, [selectedProject])

    // const nextMonthDate = useMemo(
    //     () => getNextMonthDate(currentdate),
    //     [currentdate]
    // );

    const [activeTab, setActiveTab] = useState(1);
    const [currentMeetings, setCurrentMeetings] = useState([]);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };


    const getNextMonthMeeting = (currentDate) => {
        setCurrentMeetings(meeting.upcomming)
    };

    const getPreviousMonthMeeting = (currentDate) => {
        setCurrentMeetings(meeting.previous)
    };


    useEffect(() => {
        if (selected !== "btn1") {
            getNextMonthMeeting(currentdate);
        } else {
            getPreviousMonthMeeting(currentdate);
        }
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }, [selected, meeting]);


    useEffect(() => {
        if (selected !== "btn1") {
            getNextMonthMeeting(currentdate);
        } else {
            getPreviousMonthMeeting(currentdate);
        }
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }, [selected, meeting]);


    if (loading) {
        return <Loader />
    }


    return (
        <>
            <div className="main_top_title">
                {/* {role !== "client" ? (
                        <div className="main-btn-txt">
                            <button className="btn-banner"  onClick={handleClickOpen}>Schedule a Meeting</button>
                        </div>
                    ) : null
                } */}
                <div className="main_title"><h1>Meeting <span>Board</span></h1></div>
                <div className="main_btn_txt">
                    <ul>
                        <li>
                            <NavLink to="">
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="">
                                Appboard
                            </NavLink>
                        </li>
                        <li className="active">
                            <NavLink to="" >
                                Meeting Board
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="main_content_container">
                {role !== "client" ? (
                    <div className="main-btn-txt">
                        <button className="btn-banner" onClick={handleClickOpen}>Create Meeting</button>
                    </div>
                ) : null}

                <br />

                <Container fluid className="p-0">
                    <Row>
                        {/* <Col xxl={role === 'client' ? 9 : 12}> */}
                        <Col xxl={12}>
                            <div className="main_style_box">
                                <ul className="downloadable_tabs">
                                    {/* <li onClick={() => handleTabClick(1)} className="active">Upcoming</li>
                                    <li onClick={() => handleTabClick(2)}>Previous</li>
                                     */}
                                    <li
                                        onClick={() => setSelected("btn2")}
                                        className={`${selected === "btn2"
                                            ? "active"
                                            : ""
                                            }`}
                                    >Upcoming</li>
                                    <li
                                        onClick={() => setSelected("btn1")}
                                        className={`${selected === "btn1"
                                            ? "active"
                                            : ""
                                            }`}
                                    >Previous</li>
                                </ul>
                                <div className="downloadable_content">
                                    {activeTab === 1 &&
                                        <UpComingClient meeting={currentMeetings} loader={loading} />
                                    }
                                    {activeTab === 2 &&
                                        <UpComingClient meeting={currentMeetings} loader={loading} />
                                    }

                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>
        </>
    );
}

const getNextMonthDate = (currentDate) => {
    // Get the month and year of the current date
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Calculate the month and year of the next month
    let nextMonth = currentMonth + 1;
    let nextYear = currentYear;

    if (nextMonth > 11) {
        // If the next month is greater than 11 (December), add 1 to the year and set the month to 0 (January)
        nextMonth = 0;
        nextYear += 1;
    }

    // Create a new Date object for the first day of the next month
    const nextMonthDate = new Date(nextYear, nextMonth, 1);

    return nextMonthDate;
};

export default ClientMeeting
