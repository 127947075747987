import React, { useCallback, useState, useEffect } from 'react';

import Drawer from "@mui/material/Drawer";
import { AssigneeIcon, CrossIcon, DateIcon } from "../../utils/svg";
import { formatMDY } from "../../utils/Helper";
import { formatDrawerDate, getFirstLetters } from "../../utils/Helper";
import { DotValueIcon } from "../../app-constants";
import { TiTick } from "react-icons/ti";
import { BsFileTextFill, BsFileZipFill, BsFillFilePdfFill, BsFillPlayBtnFill } from "react-icons/bs";
import Tooltip from "@mui/material/Tooltip";
import LogoImg from "../../img/DummyLogo.png";
import { ThreeDots } from "react-loader-spinner";
import CustomIconTitle from "./components/CustomIconTitle";
import Form from "react-bootstrap/Form";
import { RiEditBoxFill } from 'react-icons/ri';
import { MdDelete } from 'react-icons/md';
import axios from "axios";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useFormik } from 'formik';
import { TextField, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Slide from '@mui/material/Slide';
import { Loader, Toast } from "../../components/General";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
import { Oval } from 'react-loader-spinner'
import { useStateContext } from "../../contexts/ContextProvider";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import * as yup from 'yup';

function DrawerCustom({ loading, tasks, open, toggleDrawer, handleSubmit, setMessage, message, stories, task, productionStatus, taskProgress, attachments, taskPriority, subtasks, getSubtask }) {

    const { user, selectedProject } = useStateContext();
    const [showEditSubtaskPopup, setShowEditSubtaskPopup] = useState(false);
    const [editSubTask, setEditSubTask] = useState({});
    const [subtaskUpdateLoading, setSubtaskUpdateLoading] = useState(false);
    const [allAssignee, setAllAssignee] = useState([]);
    const [showAddSubtaskPopup, setShowAddSubtaskPopup] = useState(false);
    const [Modalopen, setModalOpen] = React.useState(false);
    const [subtaskCreateLoading, setSubtaskCreateLoading] = useState(false);

    const validationSchema = yup.object({
        title: yup
            .string()
            .min(2, 'Too Short!')
            .max(70, 'Too Long!')
            .required('Task Title is required'),
        due_date: yup.date().notRequired(),
        assignee: yup.string().notRequired()
    });

    const closeAddSubtaskPopup = () => {
        setShowAddSubtaskPopup(false);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleSubtaskDelete = (item) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this task?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteSubtask(item.gid);
            }
        })
    };

    const deleteSubtask = async (id) => {

        await axios.delete(`${process.env.REACT_APP_BASE_URL}delete-sub-task/${id}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                if (res.status === 200) {

                    getSubtask(task.gid);

                    Toast.fire({
                        icon: "success",
                        title: "Subtask Deleted Successfully"
                    });
                }
            })
            .catch((error) => {
                console.log(error);

                Toast.fire({
                    icon: "success",
                    title: 'Something went wrong!, Please try again Later.',
                });
            });

    }


    useEffect(() => {
        updateSubTaskformik.setValues({
            title: editSubTask?.name || '',
            due_date: dayjs(editSubTask?.due_on) || '',
            assignee: editSubTask.assignee?.gid || ''
        });

    }, [editSubTask]);

    const getAllAssignee = () => {
        if (user) {
            const ASANA_BEARER_TOKEN = "2/1204614194882962/1206884941289659:6993e942413ab55624207ae4379afb7f"
            axios.get(`${process.env.REACT_APP_ASANA_BASE_URL}users?workspace=146928382234467`, {
                headers: {
                    Authorization: `Bearer ${ASANA_BEARER_TOKEN}`,
                }
            })
                .then((res) => {
                    setAllAssignee(res.data.data)
                })
                .catch(err => {
                    console.log('err====', err);
                })
        }
    }


    const subTaskformik = useFormik({
        initialValues: {
            title: '',
            due_date: null,
            assignee: '',

        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            createSubTask(resetForm)
        },
    });

    const createSubTask = (resetForm) => {

        setSubtaskCreateLoading(true);

        const data = {
            "projectId": selectedProject,
            "taskId": task.gid,
            "taskName": subTaskformik.values.title,
            "assignee": subTaskformik.values.assignee || null,
            "due_on": subTaskformik.values.due_date || null
        };

        axios.post(`${process.env.REACT_APP_BASE_URL}create-sub-task`, data, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((response) => {

            resetForm({ values: '' })
            closeAddSubtaskPopup();
            getSubtask(task.gid);

            Toast.fire({
                icon: "success",
                title: 'Task Created Successfully!',
            });
        })
        .catch((error) => {

            closeAddSubtaskPopup();
            Toast.fire({
                icon: "success",
                title: 'Something went wrong, Please try again later.',
            });
        })
        .finally(() => {
            setSubtaskCreateLoading(false);
        });
    };

    const updateSubTaskformik = useFormik({
        initialValues: {
            title: '',
            due_date: null,
            assignee: '',

        },
        // validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            updateSubTask(resetForm)
        },
    });

    const openEditSubtaskPopup = (task) => {
        setShowEditSubtaskPopup(true);
        setEditSubTask(task);
    };

    const closeEditSubtaskPopup = () => {
        setShowEditSubtaskPopup(false);
    };

    const openAddSubtaskPopup = () => {
        setShowAddSubtaskPopup(true);
    };

    const updateSubTask = (resetForm) => {

        setSubtaskUpdateLoading(true);

        const due_on = updateSubTaskformik.values.due_date ? dayjs(updateSubTaskformik.values.due_date).format("YYYY-MM-DD") : null;

        const data = {
            "projectId": selectedProject,
            "subTaskId": editSubTask.gid, // subtaskId
            "taskName": updateSubTaskformik.values.title,
            "assignee": updateSubTaskformik.values.assignee || null,
            "due_on": due_on || null
        };

        axios.put(`${process.env.REACT_APP_BASE_URL}update-sub-task/${editSubTask.gid}`, data, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {

                resetForm({ values: '' })
                closeEditSubtaskPopup();

                getSubtask(task.gid);

                Toast.fire({
                    icon: "success",
                    title: 'Task Updated Successfully!',
                });
            })
            .catch((error) => {

                closeEditSubtaskPopup();

                Toast.fire({
                    icon: "success",
                    title: 'Something went wrong, Please try again later.',
                });
            })
            .finally(() => {
                setSubtaskUpdateLoading(false);
            });
    };

    const subTaskCheckboxOnChange = async (event) => {

        let value = event.target.checked;
        let subTaskId = event.target.value;

        let payload = {
            "completed": value
        };

        await axios.put(`${process.env.REACT_APP_BASE_URL}update-sub-task-status/${subTaskId}`, payload, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {

                if (res.status === 200) {

                    Toast.fire({
                        icon: "success",
                        title: "Task Updated Successfully"
                    });
                }
            })
            .catch((error) => {

                console.log(error);

                Toast.fire({
                    icon: "error",
                    title: "Something went wrong!, Please try again Later",
                });
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(() => {
        getAllAssignee();
    }, [])

    return (
        <Drawer open={open} anchor={'right'} sx={{
            '& .MuiDrawer-paper': {
                width: 600,
                backgroundColor: '#fff',
                color: '#333',
                padding: 0,
            },
        }} onClose={() => toggleDrawer()}>
            {!loading && tasks && tasks?.length !== 0 ? (
                <>
                    <div className="header_popup">
                        <div className="project_brief_links">
                            <ul>
                                {/* <li>
                                            <NavLink to="">
                                                Marketing Board
                                            </NavLink>
                                            {task.name}
                                        </li> */}
                                {/* <li className="active">
                                            <NavLink to="">
                                                Logo Provision
                                            </NavLink>
                                        </li> */}
                            </ul>
                        </div>
                        <div className="project_brief_closed">
                            <div className="popup_btn" onClick={() => toggleDrawer(false)}><CrossIcon /></div>
                        </div>
                    </div>
                    <div className="scroll_popup">
                        <div className="popup_content">
                            <div className="pop_title">{task.name}</div>
                            <div className="marketing_points">
                                <div>Assignee <span className="assigne">
                                    {/* <div className="assigne_name"></div> */}

                                    <li>{task.assignee ? task.assignee.name : 'No Assignee'}</li></span>

                                    {/* {task.assignee ? task.assignee.name : <CustomIconTitle text={'No assignee'} icon={<AssigneeIcon />} />}</span> */}
                                </div>
                                <div>Due Date <span className="date">
                                    {/* <div className="assigne_date">{CalendarIcon}</div> */}
                                    {formatMDY(task.due_on) || 'No due date'}</span></div>
                                        {/* {task.due_on ? formatMDY(task.due_on) : <CustomIconTitle text={'No due date'} icon={<DateIcon />} />}</span></div> */}
                                <div>Projects  <span className="status"><span className="icon risk">{DotValueIcon}</span>
                                    {task.projects && task.projects.length > 0 ?
                                        task.projects.map((item, index) => (
                                            <span key={index}>{item.name}</span>
                                        )) :
                                        <span>No Project</span>
                                    }
                                </span></div>
                                {/* <div>Dependencies  <span className="dependency">Add dependencies</span></div> */}
                            </div>
                            <div className="tags_marketing">
                                {/* <div className="tags_list">Production Tags
                                            <ul>
                                            {productionStatus && productionStatus.length > 0 ?
                                                productionStatus.map((item, index) => (
                                                    <li className="custom-red">{item.name}</li>
                                                )) :
                                                <span>No Production Status</span>
                                            }
                                        </ul>
                                        </div> */}
                                <div className="tags_list">Production Status
                                    <ul>
                                        {productionStatus && productionStatus.length > 0 ?
                                            productionStatus.map((item, index) => (
                                                <li className={`${item.name.replace(/\s+/g, '')}`}>{item.name}</li>
                                            )) :
                                            <span>--</span>
                                        }
                                    </ul>
                                </div>
                                <div className="tags_list">Task Status
                                    <ul>
                                        <li className={`${taskProgress?.name ? taskProgress.name.replace(/\s+/g, '') : ''}`}> {taskProgress?.name || '--'} </li>
                                    </ul>
                                </div>
                                <div className="tags_list">Priority
                                    <ul>
                                        <li className={`${taskPriority?.name}`}> {taskPriority?.name || '--'}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="marekting_descr">
                                <div class="pop_title">Description</div>
                                <p>{task?.notes || ''}</p>
                            </div>

                            {subtasks && subtasks?.length > 0 ? <div className="subtask-wrap">
                                <div className="pop_title">Subtask</div>
                                <ul className="subtask-main">
                                    {
                                        subtasks.length > 0 &&
                                        subtasks.map((item, index) => (
                                            <>
                                                <li className="subtask-inner">
                                                    <div className="title-wrap">
                                                        <Form.Check
                                                            className="check_input"
                                                            value={item?.gid}
                                                            type="checkbox"
                                                            defaultChecked={item?.completed}
                                                            onChange={subTaskCheckboxOnChange} // Handle changes
                                                        />
                                                        <div className="title">{item.name}</div>
                                                    </div>
                                                    <ul className="subtask-icons">
                                                        <li><RiEditBoxFill color="orange" className='cursor-pointer' size={20} onClick={() => openEditSubtaskPopup(item)} /></li>
                                                        <li><MdDelete className='cursor-pointer' onClick={() => handleSubtaskDelete(item)} color="red" size={20} /></li>
                                                        {/* <li><RiArrowRightSLine color="orange" className='cursor-pointer' size={20} onClick={() => handleEditClickOpen(item)} /></li> */}
                                                    </ul>

                                                </li>
                                            </>
                                        ))
                                    }
                                </ul>
                            </div> : ''}


                            <div class="list_fillter add__button add_subtask">
                                <div class="add_task" onClick={() => openAddSubtaskPopup()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18"><path d="M7.987 9.915H.8V8.041h7.187V.853h1.875v7.188h7.188v1.874H9.862v7.188H7.987V9.915z"></path></svg>
                                    Add subtask
                                </div>
                            </div>
                            <br />

                            <div className="show_comments">
                                {
                                    stories.length !== 0 ? stories.map((item, index) => {

                                        const nameArray = item.created_by?.name.split(' ');
                                        const initials = nameArray.map(word => word.charAt(0).toUpperCase()).join('');
                                        const date = new Date(item.created_at);
                                        const formattedDate = date.toLocaleDateString();

                                        let newText;
                                        let projectName;
                                        let assetId;
                                        const regex = /asset_id=(\d+)/;
                                        const regex2 = /https:\/\/app\.asana\.com\/0\/\d+\/list/;
                                        const regex3 = /https:\/\/app\.asana\.com\/0\/\d+\/\d+/;

                                        if (item.type == 'comment' && item.resource_subtype === 'comment_added') {
                                            const url = item.text.match(regex2);
                                            const url2 = item.text.match(regex3);

                                            task.projects !== undefined ? task.projects.map((item) => {
                                                projectName = item.name;
                                            }) : null

                                            newText = item.text.replace(url, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`)
                                                .replace(/\n/g, '<br/>').replace(url2, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`);
                                        }
                                        if (item.type == 'system' && item.resource_subtype === 'added_to_project') {
                                            const url = item.text.match(regex3);

                                            task.projects !== undefined ? task.projects.map((item) => {
                                                projectName = item.name;
                                            }) : null

                                            newText = item.text.replace(url, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`)
                                                .replace(/\n/g, '<br/>');
                                        }
                                        if (item.type == 'system' && item.resource_subtype === 'assigned' || item.resource_subtype === 'due_date_changed' || item.resource_subtype === 'section_changed') {
                                            const url = item.text.match(regex3);

                                            task.projects !== undefined ? task.projects.map((item) => {
                                                projectName = item.name;
                                            }) : null

                                            newText = item.text.replace(url, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`).replace(/\n/g, '<br/>');
                                        }
                                        if (item.type == 'system' && item.resource_subtype === 'attachment_added') {
                                            const match = item.text.match(regex);
                                            assetId = match ? match[1] : null;


                                        }
                                        if (item.type == 'system' && item.resource_subtype === 'name_changed' || item.resource_subtype === 'notes_changed') {
                                            const url = item.text.match(regex3);

                                            task.projects !== undefined ? task.projects.map((item) => {
                                                projectName = item.name;
                                            }) : null

                                            newText = item.text.replace(url, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`)
                                                .replace(/\n/g, '<br/>');

                                        }

                                        return (
                                            <div className="comment_list">
                                                <div key={index} className="comments_img">
                                                    {
                                                        item.type === 'system' && (item.resource_subtype === 'name_changed' || item.resource_subtype === 'notes_changed' || item.resource_subtype === 'comment_liked') ?
                                                            null :
                                                            item.type === 'system' && item.resource_subtype === 'marked_complete' ?
                                                                <span><TiTick color='black' size={30} /></span> :
                                                                !item.created_by.photo ?
                                                                    <span>{initials}</span> :
                                                                    <img
                                                                        className='comment_img'
                                                                        src={item.created_by.photo.image_27x27}
                                                                        alt='User Photo'
                                                                    />
                                                    }

                                                </div>
                                                <div className="comments_txt">
                                                    <div className="title">{item.created_by?.name} <span className="date">{formatDrawerDate(formattedDate)}</span></div>
                                                    {item.type == 'system' && item.resource_subtype === 'name_changed' || item.resource_subtype === 'notes_changed' ? null :
                                                        item.type == 'system' && item.resource_subtype === 'comment_liked' ? null
                                                            :
                                                            <p className='date-img'></p>}
                                                    {item.type == 'system' && item.resource_subtype === 'attachment_added' ?
                                                        attachments.map((item) => {
                                                            if (item.gid === assetId) {
                                                                const fileExtension = item.name?.substring(item.name.lastIndexOf('.') + 1)?.toLowerCase();
                                                                let icon;
                                                                if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'gif') {
                                                                    icon = <img src={item.view_url}
                                                                        className="rounded-md flex flex-shrink-0"
                                                                        alt={`${item.view_url}`} />;
                                                                } else if (fileExtension === 'pdf') {
                                                                    icon =
                                                                        <div
                                                                            className=' px-4 h-[80px] justify-center items-center flex gap-2 rounded-md border-1 border-gray-200 '>
                                                                            <BsFillFilePdfFill size={35}
                                                                                className='mr-2'
                                                                                color='red' />
                                                                            <p className='text-[11px] break-all text-[#6d6e6f] '>{item.name}</p>
                                                                        </div>;
                                                                } else if (fileExtension === 'mp4') {
                                                                    icon =
                                                                        <div
                                                                            className=' px-4 h-[80px] justify-center items-center flex gap-2 rounded-md border-1 border-gray-200 '>
                                                                            <BsFillPlayBtnFill size={35}
                                                                                className='mr-2'
                                                                                color='gray' />
                                                                            <p className='text-[11px] break-all text-[#6d6e6f] '>{item.name}</p>
                                                                        </div>;
                                                                    ;
                                                                } else if (fileExtension === 'zip') {
                                                                    icon = <div
                                                                        className=' px-4 h-[80px] justify-center items-center flex gap-2 rounded-md border-1 border-gray-200 '>
                                                                        <BsFileZipFill size={35}
                                                                            className='mr-2'
                                                                            color='gray' />
                                                                        <p className='text-[11px] break-all text-[#6d6e6f] '>{item.name}</p>
                                                                    </div>;
                                                                } else {
                                                                    icon = <div
                                                                        className=' px-4 h-[80px] justify-center items-center flex gap-2 rounded-md border-1 border-gray-200 '>
                                                                        <BsFileTextFill size={35}
                                                                            className='mr-2'
                                                                            color='blue' />
                                                                        <p className='text-[11px] break-all text-[#6d6e6f] '>{item.name}</p>
                                                                    </div>;
                                                                }
                                                                return (
                                                                    <Tooltip title={
                                                                        <React.Fragment>
                                                                            <p>{item.name}</p>

                                                                            Uploaded at {formattedDate}
                                                                        </React.Fragment>
                                                                    } followCursor>
                                                                        <div key={index}
                                                                            className=' cursor-pointer'>

                                                                            {icon}
                                                                        </div>
                                                                    </Tooltip>
                                                                )
                                                            }
                                                        })
                                                        :
                                                        item.type == 'system' && item.resource_subtype === 'name_changed' || item.resource_subtype === 'notes_changed' ?
                                                            <p className='para_txt'
                                                                dangerouslySetInnerHTML={{ __html: newText }}></p>
                                                            :
                                                            <p className='para_txt'
                                                                dangerouslySetInnerHTML={{ __html: newText }}></p>

                                                    }

                                                </div>
                                            </div>
                                        )

                                    }) : null
                                }
                            </div>
                        </div>
                        <div className="comment_main_box">
                            <div className="project_comments">
                                <div className="comments_logo">
                                    {/* <img src={LogoImg} alt="" /> */}

                                    {user?.user?.image ? <img src={user?.user?.image} alt="" /> : <div className="img_placeholder">{getFirstLetters(user?.user?.first_name || 'User')} </div>}
                                </div>
                                <div className="msg_box">

                                    <form onSubmit={(e) => handleSubmit(e)}>
                                        <textarea
                                            className="msg_box_comments"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            rows={1}
                                            placeholder='Add a comment'
                                        />
                                        <button type='submit' className="msg_box_btn">Send</button>
                                    </form>

                                </div>
                            </div>
                            {/* <div className="pro_collaborators">
                                        <div className="left_collaborators">
                                            <ul>
                                                <li>Collaborators</li>
                                                <li><img src={DummyImg} alt="Dummy Img" /></li>
                                                <li>{AddPersonIcon}</li>
                                                <li>{AddPersonIcon}</li>
                                                <li>{PlusIcon}</li>
                                            </ul>
                                        </div>
                                        <div className="right_collaborators">
                                            <ul>
                                                <li>{BellJoinIcon} Join</li>
                                            </ul>
                                        </div>
                                    </div> */}
                        </div>
                    </div>
                </>
            ) : (
                <div className='justify-center items-center flex h-[100%] w-[100%]'>
                    <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#F37A20"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            )}
            <Dialog
                open={showAddSubtaskPopup}
                TransitionComponent={Transition}
                keepMounted
                onClose={closeAddSubtaskPopup}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Create a SubTask"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={subTaskformik.handleSubmit} className='space-y-6 '>
                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <TextField
                                className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                id="title"
                                name="title"
                                label="Title"
                                value={subTaskformik.values.title}
                                onChange={subTaskformik.handleChange}
                                error={subTaskformik.touched.title && Boolean(subTaskformik.errors.title)}
                                helperText={subTaskformik.touched.title && subTaskformik.errors.title}
                            />
                        </div>
                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <FormControl className="custom-input" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                        <DatePicker
                                            fullWidth
                                            className='w-[100%]'
                                            name="due_date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="due_date"
                                            inputFormat="E MMM dd yyyy HH:MM:SS O"
                                            label="Due Date"
                                            value={subTaskformik.values.due_date}
                                            onChange={value => subTaskformik.setFieldValue('due_date', value)}
                                            error={subTaskformik.touched.due_date && Boolean(subTaskformik.errors.due_date)}

                                            disablePast
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Assignee</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    name="assignee"
                                    label="Assignee"
                                    id="assignee"
                                    value={subTaskformik.values.assignee}
                                    onChange={subTaskformik.handleChange}
                                    error={subTaskformik.touched.assignee && Boolean(subTaskformik.errors.assignee)}
                                    helperText={subTaskformik.touched.assignee && subTaskformik.errors.assignee}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    {allAssignee?.filter((element) => element.name !== 'Private User').map((item, index) => {
                                        // console.log(item);
                                        return (

                                            <MenuItem key={index}
                                                value={`${item.gid}`}>{item.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>

                        </div>
                        <div className='flex justify-end mt-3'>
                            <button
                                className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-2 text-white'
                                variant="contained" fullWidth type="submit" disabled={subtaskCreateLoading}>
                                {subtaskCreateLoading ? <Oval
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#ffffff"
                                    secondaryColor="#ffffff"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{ display: 'flex', justifyContent: 'center' }}
                                    wrapperClass=""
                                /> : "Create"}
                            </button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>

            <Dialog
                open={showEditSubtaskPopup}
                TransitionComponent={Transition}
                keepMounted
                onClose={closeEditSubtaskPopup}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Update a SubTask"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={updateSubTaskformik.handleSubmit} className='space-y-6 '>
                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <TextField
                                className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                id="title"
                                name="title"
                                label="Title"
                                value={updateSubTaskformik.values.title}
                                onChange={updateSubTaskformik.handleChange}
                                error={updateSubTaskformik.touched.title && Boolean(updateSubTaskformik.errors.title)}
                                helperText={updateSubTaskformik.touched.title && updateSubTaskformik.errors.title}
                            />
                        </div>
                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <FormControl className="custom-input" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            fullWidth
                                            className='w-[100%]'
                                            name="due_date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="due_date"
                                            inputFormat="E MMM dd yyyy HH:MM:SS O"
                                            label="Due Date"
                                            value={updateSubTaskformik.values.due_date}
                                            onChange={value => updateSubTaskformik.setFieldValue('due_date', value)}
                                            error={updateSubTaskformik.touched.due_date && Boolean(updateSubTaskformik.errors.due_date)}

                                            disablePast
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Assignee</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    name="assignee"
                                    label="Assignee"
                                    id="assignee"
                                    value={updateSubTaskformik.values.assignee}
                                    onChange={updateSubTaskformik.handleChange}
                                    error={updateSubTaskformik.touched.assignee && Boolean(updateSubTaskformik.errors.assignee)}
                                    helperText={updateSubTaskformik.touched.assignee && updateSubTaskformik.errors.assignee}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    {allAssignee?.filter((element) => element.name !== 'Private User').map((item, index) => {
                                        // console.log(item);
                                        return (

                                            <MenuItem key={index}
                                                value={`${item.gid}`}>{item.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>

                        </div>
                        <div className='flex justify-end mt-3'>
                            <button
                                className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-2 text-white'
                                variant="contained" fullWidth type="submit" disabled={subtaskUpdateLoading}>
                                {subtaskUpdateLoading ? <Oval
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#ffffff"
                                    secondaryColor="#ffffff"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{ display: 'flex', justifyContent: 'center' }}
                                    wrapperClass=""
                                /> : "Update"}
                            </button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </Drawer>




    );
}

export default DrawerCustom;