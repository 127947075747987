import React, {useState, useEffect, memo} from 'react';
import {useStateContext} from '../../contexts/ContextProvider';
import axios from 'axios'
import {MdDelete} from 'react-icons/md';
import Swal from 'sweetalert2';
import {Oval} from 'react-loader-spinner'
import {RiEditBoxFill} from 'react-icons/ri';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {
    TextField,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel
} from '@material-ui/core';
import DialogTitle from '@mui/material/DialogTitle';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {DateFormatWithLetters, DateAndTimestamp} from '../../utils/DateFormatWithSlash'
import Slide from '@mui/material/Slide';
import {S3Upload} from '../../utils/S3Upload'

window.Buffer = window.Buffer || require("buffer").Buffer;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const validationSchema = yup.object({
    // client: yup
    //     .string()
    //     .required('Client Name is required'),
    amount: yup
        .number()
        .required('Amount is required'),
    status: yup
        .string()
        .required('Status is required'),
    due_date: yup
        .date().required('Due Date is required'),
    pay_date: yup
        .date().required('Pay Date is required'),
    // link: yup
    //   .string()
    //   .required('Invoice Link is required'),
});

const InvoiceHistory = ({data, refresher, loader}) => {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState('btn1');
    const [editValues, setEditValues] = useState({});
    const {invoices, user, client} = useStateContext();
    const {role} = user.user;
    const [selectedFile, setSelectedFile] = useState([]);

    useEffect(() => {
        setLoading(loader);
    }, [loader])

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteMeeting(id)
            }
        })

    }

    // const getAllInvoiceByPid = (pid) => {
    //   if(user){
    //   let url;
    //   if(project_type.asana_project_id !== null){
    //     url = `${process.env.REACT_APP_BASE_URL}invoice?asana_project_id=${pid}`

    //   } else{
    //     url = `${process.env.REACT_APP_BASE_URL}invoice?manual_project_id=${pid}`
    //   }
    //   axios.get(url,{
    //     headers:{
    //       'Authorization':`Bearer ${user.token}`
    //     }
    //   }).then((response) => {
    //     const formattedData = response.data.data.map(item => {
    //       // console.log('item========',item)
    //       const due_date = new Date(item.due_date);
    //       const pay_date = new Date(item.pay_date);
    //       const formattedDueDate = due_date.toLocaleDateString();
    //       const formattedPayDate = pay_date.toLocaleDateString();
    //       return { ...item, due_date: formattedDueDate, pay_date: formattedPayDate };
    //   });

    //   setInvoices(formattedData)
    //   }).catch((err) => {
    //     console.log('err====', err);
    //   })
    //   // console.log(table);
    // }
    // }

    const handleClickOpen = (item) => {
        setEditValues(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const EditInvoice = async (file_uri = "") => {
        setLoading(true);
        const selectedClient = client.find(item => `${item.first_name} ${item.last_name}` === formik.values.client);
        const data = {
            //"client_id": selectedClient.id,
            "due_date": new Date(formik.values.due_date)?.toUTCString(),
            "pay_date": new Date(formik.values.pay_date)?.toUTCString(),
            "status": formik.values.status,
            "link": formik.values.link,
            "amount": formik.values.amount,
            //"file_path": file_uri || ""
        }

        if (file_uri) {
            data['file_path'] = file_uri;
        }

        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}invoice/${editValues.id}`, {...data, id: editValues.id}, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        refresher();
                        setLoading(false);
                        Swal.fire(
                            {
                                icon: 'success',
                                title: 'Invoice Updated successfully!',
                                showConfirmButton: false,
                                timer: 1000
                            }
                        )
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1000
                    })
                })
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const deleteMeeting = async (id) => {
        setLoading(true)
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}invoice/${id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        refresher();
                        // navigate('/projectInvoices')
                        // console.log('=========>',meeting);
                        setLoading(false);
                        Swal.fire(
                            'Deleted!',
                            'Invoice has been deleted.',
                            'success'
                        )
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1000
                    })
                })
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const handleUpload = async (file) => {
        if (file) {
            setLoading(true);
            try {
                const response = await S3Upload(file.type, file.name, file)
                if (response) {
                    console.log("RESPONSE URL IMAGE", response)
                    EditInvoice(response);
                }
            } catch (error) {
                setLoading(false)
                console.error("error", error)
            }
        } else {
            EditInvoice();
        }

    }

    const formik = useFormik({
        initialValues: {
            //client: editValues.client?.first_name + " " + editValues.client?.last_name,
            amount: editValues.amount,
            due_date: editValues.due_date,
            pay_date: editValues.pay_date,
            status: editValues.status === 0 ? 0 : 1,
            link: editValues.link

        },
        validationSchema: validationSchema,
        onSubmit: (values, {resetForm}) => {
            console.log('yes');
            handleClose()
            handleUpload()
            resetForm({values: ''})
        },
    });

    useEffect(() => {
        formik.setValues({
            client: editValues.client?.first_name + " " + editValues.client?.last_name || '',
            due_date: dayjs(editValues.due_date) || '',
            pay_date: dayjs(editValues.pay_date) || '',
            status: editValues.status === 0 ? 0 : 1,
            link: editValues.link || '',
            amount: editValues.amount || ''
        });
    }, [editValues]);

    // console.log('data=======',data);

    /*if (loading) {
        return (
            <div className=' justify-center items-center flex h-[80vh]'>
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#F37A20"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </div>

        )
    }*/

    const renderInvoices = (status) => {
        let isData = data.filter(el => el.status === status);
        if (isData.length) {
            return isData.map((item, index) => {
                console.log("INVOICES IN MAP", item)
                return (
                    <div key={index}>
                        <div className='flex justify-between items-center'>
                            <div className='flex gap-[50px]'>

                                <div className=' pr-[10px] pl-[10px] w-[110px]'>
                                    <p className='text-[18px] flex items-center lg:text-[18px] md:text-[12px] sm:text-[10px] xm:text-[8px] font-medium  text-[#2A303C]'>Invoice
                                        #{item.id}</p>
                                    {
                                        item?.link && <div className='flex items-center pr-[20px]'>
                                            <a target='_blank' href={item.link}>
                                                <p className='text-[#F47A1F] text-[12px] lg:text-[11px] md:text-[9px] sm:text-[8px] xm:text-[8px] font-medium'>View
                                                    invoice</p>
                                            </a>
                                        </div>
                                    }
                                </div>

                                <div className=' pr-[10px] pl-[10px] w-[110px]'>
                                    <p className='text-[#2A303C]  text-[14px] lg:text-[13px] md:text-[9px] sm:text-[8px] xm:text-[8px] font-medium'>Amount</p>
                                    <p className='text-[#2A303C] text-[13px] lg:text-[12px] md:text-[9px] sm:text-[8px] xm:text-[8px] font-medium mt-[2px] break-all'>{item.amount}</p>
                                </div>
                                {/*<p className='text-[#2A303C] flex items-center text-[16px] lg:text-[16px] md:text-[9px] sm:text-[8px]  xm:text-[8px] font-medium'>Amount</p>
                                <p className='text-[#505887] flex items-center text-[14px] lg:text-[14px] md:text-[9px] sm:text-[8px] xm:text-[8px] font-medium'>${item.amount}</p>*/}
                            </div>

                            <div className='flex justify-between items-center'>
                                <div className='flex gap-[5px] items-center'>
                                    {
                                        item?.file_path && <div className='flex items-center pr-[20px]'>
                                            <a target='_blank' href={item.file_path}>
                                                <p className='text-[#F47A1F] text-[12px] lg:text-[11px] md:text-[9px] sm:text-[8px] xm:text-[8px] font-medium'>invoice
                                                    File</p>
                                            </a>
                                        </div>
                                    }
                                    <div
                                        className='border-r-1 border-l-1 border-gray-100  pr-[10px] pl-[10px] w-[110px]'>
                                        <p className='text-[#2A303C]  text-[14px] lg:text-[13px] md:text-[9px] sm:text-[8px] xm:text-[8px] font-medium'>Due
                                            Date</p>
                                        <p className='text-[#505887] text-[13px] lg:text-[12px] md:text-[9px] sm:text-[8px] xm:text-[8px] font-medium mt-[2px] break-all '>{DateFormatWithLetters(new Date(item.due_date))}</p>
                                    </div>
                                    <div className='border-r-1 border-gray-100 pr-[10px] pl-[10px] w-[110px]'>
                                        <p className='text-[#2A303C]  text-[14px] lg:text-[13px] md:text-[9px] sm:text-[8px] xm:text-[8px] font-medium'>Pay
                                            Date</p>
                                        <p className='text-[#505887] text-[13px] lg:text-[12px] md:text-[9px] sm:text-[8px] xm:text-[8px] font-medium mt-[2px] break-all'>{DateFormatWithLetters(new Date(item.pay_date))}</p>
                                    </div>
                                    <div className=' pr-[10px] pl-[10px] w-[110px]'>
                                        <p className='text-[#2A303C]  text-[14px] lg:text-[13px] md:text-[9px] sm:text-[8px] xm:text-[8px] font-medium'>Status</p>
                                        <p className='text-[#069952] text-[13px] lg:text-[12px] md:text-[9px] sm:text-[8px] xm:text-[8px] font-medium mt-[2px] break-all'>{item.status === 0 ? 'Unpaid' : 'Paid'}</p>
                                    </div>
                                    {role !== 'client' ?
                                        <div>
                                            <p className='text-[#2A303C]  text-[14px] lg:text-[13px] md:text-[9px] sm:text-[8px] xm:text-[8px] font-medium'>Actions</p>
                                            <div className="flex gap-[5px]">
                                                <p
                                                    onClick={() => handleDelete(item.id)}
                                                    className='text-[#F47A1F] text-[13px] lg:text-[12px] md:text-[9px] sm:text-[8px] xm:text-[8px] font-medium mt-[2px] hover:cursor-pointer'>Delete</p>
                                                <p
                                                    onClick={() => handleClickOpen(item)}
                                                    className='text-[#505887] text-[13px] lg:text-[12px] md:text-[9px] sm:text-[8px] xm:text-[8px] font-medium mt-[2px] hover:cursor-pointer'>Edit</p>
                                                {/* <MdDelete className='cursor-pointer' onClick={() => handleDelete(item.id)} color="red" size={25} />
                        <RiEditBoxFill className='cursor-pointer' onClick={() => handleClickOpen(item)} color="orange" size={25} /> */}
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>


                        </div>
                        <hr className='w-[100%] bg-[#EFF1F5] mt-5 ml-[-5px]'/>
                    </div>

                )
            })
        }

        return <p className='text-center text-[13px] text-[#979797]'>No {status === 0 ? 'Unpaid' : 'Paid'} invoices</p>
    }


    return (
        <div className='relative h-[100%] whats-new'>
            <div className='invoice-task'>
                <p>Invoice History</p>
            </div>
            <div className=' flex justify-start px-[30px] gap-[40px] border-b-1 border-[#EDF0F4]'>
                <button onClick={() => setSelected('btn1')}
                        className={` mobile:text-[12px] small:text-[12px] pb-[4px] ${selected === 'btn1' ? 'selected whats-new-tab  ' : 'un-selected'}  `}>Paid
                </button>
                <button onClick={() => setSelected('btn2')}
                        className={` mobile:text-[12px] small:text-[12px] pb-[4px] ${selected === 'btn2' ? 'selected whats-new-tab ' : 'un-selected  '}  `}>Unpaid
                </button>
            </div>

            {
                loading ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }} className='mt-5'>
                        <Oval
                            visible={true}
                            height="20"
                            width="20"
                            color="#f37a20"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                        <small>Please wait while the content is loading</small>
                    </div> :
                    <div
                        className={`space-y-6 invoice-innerdiv p-[30px] overflow-auto h-[434px] mobile:h-[550px] small:h-[550px] w-[100%]  `}>


                        {
                            selected === 'btn1' ? invoices.length !== 0 ?
                                renderInvoices(1)
                                :
                                <div className='flex justify-center items-center p-4 bg-[white]'>
                                    <p className='text-center text-[13px] text-[#979797] font-bold'>No invoices
                                        found</p>
                                    {/*              <p className='text-center text-[11px] text-[#979797]'>No invoices</p>*/}
                                </div>

                                :
                                invoices.length !== 0 ?
                                    renderInvoices(0)
                                    :
                                    <div className='flex justify-center items-center p-4 bg-[white]'>
                                        <p className='text-center text-[13px] text-[#979797] font-bold'>No invoices
                                            found</p>
                                    </div>
                        }
                    </div>
            }
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Update Invoice"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit} className='space-y-6 '>
                        {/*<div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Client</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    name="client"
                                    label="Client"
                                    id="client"
                                    value={formik.values.client}
                                    onChange={formik.handleChange}
                                    error={formik.touched.client && Boolean(formik.errors.client)}
                                    helperText={formik.touched.client && formik.errors.client}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    {client?.map((item, index) => {
                                        return (

                                            <MenuItem key={index}
                                                      value={`${item.first_name} ${item.last_name}`}>{item.first_name} {item.last_name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>

                        </div>*/}

                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>

                            <FormControl className="custom-input" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                        <DatePicker
                                            fullWidth
                                            className='w-[100%]'
                                            name="pay_date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="pay_date"
                                            inputFormat="E MMM dd yyyy HH:MM:SS O"
                                            label="Pay Date"
                                            value={formik.values.pay_date}
                                            onChange={value => formik.setFieldValue('pay_date', value)}
                                            error={formik.touched.pay_date && Boolean(formik.errors.pay_date)}


                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                            <FormControl className="custom-input" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                        <DatePicker
                                            fullWidth
                                            className='w-[100%]'
                                            name="due_date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="due_date"
                                            inputFormat="E MMM dd yyyy HH:MM:SS O"
                                            label="Due Date"
                                            value={formik.values.due_date}
                                            onChange={value => formik.setFieldValue('due_date', value)}
                                            error={formik.touched.due_date && Boolean(formik.errors.due_date)}


                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>


                        </div>
                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <TextField
                                className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                id="amount"
                                name="amount"
                                label="Amount"
                                value={formik.values.amount}
                                onChange={formik.handleChange}
                                error={formik.touched.amount && Boolean(formik.errors.amount)}
                                helperText={formik.touched.amount && formik.errors.amount}
                            />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    name="status"
                                    label="Status"
                                    id="status"
                                    value={formik.values.status}
                                    onChange={formik.handleChange}
                                    error={formik.touched.status && Boolean(formik.errors.status)}
                                    helperText={formik.touched.status && formik.errors.status}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    <MenuItem value={1}>Paid</MenuItem>
                                    <MenuItem value={0}>Unpaid</MenuItem>
                                </Select>
                            </FormControl>

                        </div>
                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <TextField
                                className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                id="link"
                                name="link"
                                label="Invoice Link"
                                value={formik.values.link}
                                onChange={formik.handleChange}
                                error={formik.touched.link && Boolean(formik.errors.link)}
                                helperText={formik.touched.link && formik.errors.link}
                            />
                        </div>

                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <div className="file-upload-container">
                                <input
                                    type="file"
                                    onChange={handleFileInput}/>
                            </div>
                        </div>
                        <div className='flex justify-end mt-3'>
                            <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white'
                                    variant="contained" fullWidth type="submit">
                                Save
                            </button>
                        </div>
                    </form>
                </DialogContent>

            </Dialog>
        </div>
    )
}

export default memo(InvoiceHistory);