import React, { useState, useEffect } from 'react'
import { useStateContext } from '../../contexts/ContextProvider';
import { RiEditBoxFill } from 'react-icons/ri';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { MdDelete } from 'react-icons/md';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField } from '@material-ui/core';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Swal from 'sweetalert2';
import { Oval } from 'react-loader-spinner'
import { Modal, Table } from "react-bootstrap";
import { Loader, NoRecordFound, Toast } from '../../components/General';
import Spinner from 'react-bootstrap/Spinner';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const validationSchema = yup.object({
  points: yup
    .number()
    .required('This field is required!'),
});
const validationSchemaPoints = yup.object({
  title: yup
    .string()
    .matches(
      /^[A-Za-z ]+$/,
      "only alphabets are allowed")
    .min(5, 'title is too short!')
    .max(30, 'title is too big')
    .required('This field is required!'),
  points: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      "only a number is allowed")
    .required('This field is required!'),
});

const Activity = () => {
  const [open, setOpen] = useState(false);
  const [openPoints, setOpenPoints] = useState(false);
  const [loading, setLoading] = useState(false);
  const [benefits, setBenefits] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const formikPoints = useFormik({
    initialValues: {
      title: '',
      points: '',
    },
    validationSchema: validationSchemaPoints,
    onSubmit: (values, { resetForm }) => {
      // console.log("FORM VAKLUES", values)
      CreateBenefits();
      // resetForm({ values: '' });
      // handleClosePoints();
    },
  });

  const formik = useFormik({
    initialValues: {
      points: benefits.points,

    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      EditRewards()
    },
  });

  const handleClickOpen = (item) => {
    setBenefits(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setBenefits({});
  };

  const handleClickOpenPoints = () => {
    setOpenPoints(true);
  };
  
  const resetForm = () => {
    formik.setValues({
      points: benefits.points || ''
    });
  };

  const handleClosePoints = () => {

    formikPoints.resetForm();
    formik.resetForm();
    setErrorMessage(false);
    setOpenPoints(false);

  };
  const { activeMenu, user } = useStateContext();
  const [points, setPoints] = useState([]);

  const getAllBenefits = (loader = false) => {
    setLoading(loader);

    axios.get(`${process.env.REACT_APP_BASE_URL}get_benefit_rewards`, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    })
    .then((response) => {
      setPoints(response.data.data);
    })
    .catch((err) => {
      console.log('err====', err);
    })
    .finally(() => {
      setLoading(false);
    });

  }

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBenefits(id)
      }
    })

  }

  const deleteBenefits = async (id) => {
    
    await axios.delete(`${process.env.REACT_APP_BASE_URL}delete_benefit_reward/${id}`, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    })
    .then((res) => {
      if (res.status === 200) {
        getAllBenefits();
        
        Toast.fire({
          icon: "success",
          title: 'Benefits Reward Deleted Successfully.',
        });
      }
    })
    .catch((error) => {
      console.log(error);

      Toast.fire({
        icon: "error",
        title: error?.response?.data?.errors[0]?.message || "Something went wrong!, Please try again Later",
      });
    })
    .finally(() => {
      setLoading(false);
    });
  }

  const CreateBenefits = async () => {
    setBtnLoading(true);
    setErrorMessage(false);

    const data = {
      "title": formikPoints.values.title,
      "points": formikPoints.values.points
    }

    await axios.post(`${process.env.REACT_APP_BASE_URL}add_benefit_reward`, { ...data }, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    })
    .then((res) => {
      if (res.status === 200) {
        handleClosePoints();
        getAllBenefits();

        Toast.fire({
          icon: "success",
          title: 'Benefits Rewards Created successfully!',
        });
      }
    })
    .catch((error) => {
      setErrorMessage(error?.response?.data?.message)
    })
    .finally(() => {
      setBtnLoading(false);
    })
  };

  const EditRewards = async () => {
    setBtnLoading(true);

    const data = {
      "title": benefits.title,
      "points": formik.values.points
    }

    await axios.put(`${process.env.REACT_APP_BASE_URL}update_benefit_reward/${benefits.id}`, { ...data, id: benefits.id }, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    })
    .then((res) => {
      if (res.status === 200) {
        getAllBenefits();
        handleClose();
        Toast.fire({
          icon: "success",
          title: 'Benefits Rewards Updated Successfully!',
        });
      }
    })
    .catch((error) => {
    
      handleClose();
      console.log(error);

      Toast.fire({
        icon: "error",
        title: "Something went wrong!, Please try again Later",
      });
    })
    .finally(() => {
      setBtnLoading(false);
    });
  };
  
  useEffect(() => {
    getAllBenefits();
  }, []);

  useEffect(() => {
    formik.setValues({
      points: benefits.points || ''
    })
  }, [benefits])

  if (loading) {
    return <Loader />
  }


  return (

    <>
      <div className="download_tabs">

        <div className="list_fillter add__button">
          <div className="add_task" onClick={handleClickOpenPoints}>
            Add Benefits and Points
          </div>
        </div>

        <Table responsive>
          <thead>
            <tr>

              <th className="text-center">Title</th>
              <th className="text-center">Point</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {points && points.length > 0 ? points.map((item, index) => {

              return (
                <tr>
                  <td className="text-center">
                    {item.title}
                  </td>
                  <td className="text-center">
                    {item.points}
                  </td>

                  <td className="text-center">
                    <ul>

                      <li>
                        <RiEditBoxFill className='cursor-pointer' onClick={() => handleClickOpen(item)}
                          color="orange" size={20} />
                      </li>

                      <li>
                        <MdDelete className='cursor-pointer' onClick={() => handleDelete(item.id)} color="red"
                          size={20} />
                      </li>


                    </ul>
                  </td>
                </tr>
              )

            }) : (
              <NoRecordFound />
            )

            }
          </tbody>
        </Table>
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{`Update Reward Points - ${benefits.title}`}</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              className='w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%]'
              id="points"
              name="points"
              label="Total Points"
              value={formik.values.points}
              onChange={formik.handleChange}
              error={formik.touched.points && Boolean(formik.errors.points)}
              helperText={formik.touched.points && formik.errors.points}
            />
            <div className='flex justify-end mt-3' >
              {/* <div onClick={resetForm} className="bg-white w-[70px] rounded-md text-[16px] p-3 text-[#F37A20] border border-[#F37A20] mr-4">
                Reset
              </div> */}

              <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white' variant="contained" fullWidth type="submit" disabled={btnLoading}>
                {btnLoading ? <Oval
                    visible={true}
                    height="20"
                    width="20"
                    color="#ffffff"
                    secondaryColor="#ffffff"
                    ariaLabel="oval-loading"
                    wrapperStyle={{disply: 'flex', justifyContent: 'center'}}
                    wrapperClass=""
                /> : 'Save'}
              </button>
            </div>
          </form>
        </DialogContent>

      </Dialog>

      <Dialog
        open={openPoints}
        TransitionComponent={Transition}
        onClose={handleClosePoints}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Add Points"}</DialogTitle>
        <DialogContent>
          <form onSubmit={formikPoints.handleSubmit}>
            <div className='flex gap-8' >
              <TextField
                className='w-[700px]'
                id="title"
                name="title"
                label="Title"
                value={formikPoints.values.title}
                onChange={formikPoints.handleChange}
                error={formikPoints.touched.title && Boolean(formikPoints.errors.title)}
                helperText={formikPoints.touched.title && formikPoints.errors.title}
              />
            </div>

            <div className='flex gap-8' >
              {errorMessage ? <div style={{ color: 'red', marginTop: '7px' }}>{errorMessage}</div> : ''}
            </div>
            <br />

            <div className='flex gap-8' >
              <TextField
                className='w-[700px]'
                id="points"
                name="points"
                label="Points"
                value={formikPoints.values.points}
                onChange={formikPoints.handleChange}
                error={formikPoints.touched.points && Boolean(formikPoints.errors.points)}
                helperText={formikPoints.touched.points && formikPoints.errors.points}
              />
            </div>


            <div className='flex justify-end mt-3' >
              <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white' variant="contained" fullWidth type="submit" disabled={btnLoading}>
                {btnLoading ? <Oval
                    visible={true}
                    height="20"
                    width="20"
                    color="#ffffff"
                    secondaryColor="#ffffff"
                    ariaLabel="oval-loading"
                    wrapperStyle={{disply: 'flex', justifyContent: 'center'}}
                    wrapperClass=""
                /> : 'Save'}

                
              </button>
            </div>
          </form>
        </DialogContent>

      </Dialog>
    </>

  )
}

export default Activity;
