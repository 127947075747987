import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import { ClosedIcon, DotValueIcon, FileUploadIcon, InfoIcon } from "../../../app-constants";
import { Swiper, SwiperSlide } from 'swiper/react';
import DummyImg from "../../../img/Loyalty.svg";
import { useStateContext } from "../../../contexts/ContextProvider";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { TextField, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import { BsInfoCircle, BsInfoCircleFill } from 'react-icons/bs';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
// import { useFormik, Formik, Form, Field } from 'formik';
import * as yup from "yup";
import { NavLink, Link } from "react-router-dom";
import 'swiper/css';
import { Navigation } from 'swiper';
import 'swiper/css/navigation';
import axios from 'axios';
// import Swal from 'sweetalert2';
import Slide from "@mui/material/Slide";
import { S3Upload } from '../../../utils/S3Upload';
import ActivityCard from "./ActivityCard";
import { Loader, Toast } from "../../../components/General";
import Swal from 'sweetalert2';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import _ from "lodash";

function ClientRewardPoint() {

    const { user, userprofile, benefits, selectedProject, sessionProject, project, getAllBenefits } = useStateContext();


    const [loading, setLoading] = useState(false);

    const [userPoint, setUserPoint] = useState([]);
    const [activityReward, setActivityReward] = useState([]);
    const [benefitRewardList, setBenefitRewardList] = useState([]);


    const [values, setValues] = useState({ screenshot: null, link: '' });
    const [errors, setErrors] = useState({ screenshot: '', link: '' });
    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [file, setFile] = useState(null);

    const [pointId, setPointId] = useState(0);

    const [activityRewardLoading, setActivityRewardLoading] = useState(false);
    const [isClaimed, setIsClaimed] = useState(true);

    const [urlValidationMessage, setUrlValidationMessage] = useState('');
    // const [isSubmit, setIsSubmit] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow  classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: '#272b3e',
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#272b3e',
            color: 'rgb(255,255,255)',
            // maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #272b3e',
        },
    }));


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setErrors({ screenshot: '', link: '' });
        setDisableSubmit(false);
        setUrlValidationMessage("");
        handleFileRemove();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        validationSchema
            .validate(values, { abortEarly: false })
            .then(() => {
                // Validation successful, handle form submission
                handleUpload(selectedFile);
                handleClose();
            })
            .catch((validationErrors) => {
                // Validation failed, update errors state

                const newErrors = {};
                validationErrors.inner.forEach((error) => {
                    newErrors[error.path] = error.message;
                });


                console.log(validationErrors);

                setErrors(newErrors);
            });
    };

    // const handleChange = (event) => {
    //     const { name, value, files } = event.target;
    //     if (name === 'screenshot') {
    //         setValues({ ...values, screenshot: files[0] });
    //     } else {
    //         setValues({ ...values, [name]: value });
    //     }
    // };

    const handleFileChange = (e) => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile) {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                setFile(fileReader.result);
            };
            fileReader.readAsDataURL(uploadedFile);

            const { name, value, files } = event.target;
            if (name === 'screenshot') {
                setValues({ ...values, screenshot: files[0] });
            } else {
                setValues({ ...values, [name]: value });
            }

            setErrors({ link: errors.link, screenshot: '' });
        }
    };

    const handleFileRemove = (e) => {
        setFile(null);
        setErrors({ link: '', screenshot: '' });
        setValues({ screenshot: null, link: values.link });
    }

    const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)(:[0-9]{1,5})?(\/.*)?$/;

    const validationSchema = yup.object({
        screenshot: yup.mixed().required('Screenshot is required.'),
        // link: yup.string().optional(),
    });

    const createClaim = async (formData) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}activity-reward-claim`, formData, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                getAllPoints();

                Toast.fire({
                    icon: 'success',
                    title: 'Claimed Successfully',
                });

            }
        } catch (error) {

            Toast.fire({
                icon: 'success',
                title: error?.response?.data?.message || "Something went wrong, Please try again later "
            });
        }
    };

    const handleUpload = async () => {

        if (values.screenshot) {
            let file = values.screenshot
            try {

                Toast.fire({
                    icon: "info",
                    title: "Processing..."
                });

                const response = await S3Upload(file.type, file.name, file);
                if (response) {
                    const formData = new FormData();
                    formData.append('file_path', response);
                    formData.append('link', values.link);
                    formData.append('activity_reward_id', pointId);
                    formData.append('project_id', selectedProject);
                    await createClaim(formData);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
                // Handle error state or display error message to the user
            } finally {
                setLoading(false); // Always set loading state to false whether success or failure
            }
        }
    };

    const getUserPoints = () => {

        console.log('test get users----------------------------->', selectedProject);
        //get_user_points
        axios.get(`${process.env.REACT_APP_BASE_URL}get_user_points?project_id=${selectedProject}&client_id=${user?.user?.id}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {

            setUserPoint(response.data.data);
        }).catch((err) => {
            console.log('err====', err);
        })
    }



    const getAllPoints = (loader = false) => {
        if (user) {

            setActivityRewardLoading(loader);

            axios
                .get(`${process.env.REACT_APP_BASE_URL}activity-reward-list?project_id=${selectedProject}&client_id=${user?.user?.id}`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                })
                .then((response) => {

                    console.log(response.data.data);
                    setActivityReward(response.data.data);
                })
                .catch((error) => {
                    Swal.fire({
                        icon: 'error',
                        title: error?.response?.data?.message || 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 2000,
                    });
                })
                .finally(() => {
                    setActivityRewardLoading(false);
                });

        }
    };


    function validateUrl(url) {
        if (!url) {
            setDisableSubmit(false);
            setUrlValidationMessage("");
            return;
        }
    
        try {
            new URL(url);
            setDisableSubmit(false);
            setUrlValidationMessage("");
        } catch (e) {
            setDisableSubmit(true);
            setUrlValidationMessage("The URL you have entered is not valid.");
        }
    }
    

    const customUrlValidation = (e) => {

        const value = e.target.value;
        const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)(:[0-9]{1,5})?(\/.*)?$/;
    
        console.log("Value: " + value);

        validateUrl(value); 
    }


    const getBenefitRewardList = async () => {
        
        if (user) {
            setLoading(true);

            await axios
                .get(`${process.env.REACT_APP_BASE_URL}benefit-reward-list?project_id=${selectedProject}&client_id=${user?.user?.id}`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                })
                .then((response) => {
                    setBenefitRewardList(response.data.data);
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: error?.response?.data?.message || "Something went wrong, Please try again later "
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };


    const createBenefitClaim = async (id) => {

        const data = {
            "benefit_reward_id": id,
            "project_id": selectedProject
        }

        Toast.fire({
            icon: "info",
            title: "Processing..."
        });

        await axios.post(`${process.env.REACT_APP_BASE_URL}benefit-reward-claim`, { ...data }, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((res) => {

            if (res.status == 200) {
                getBenefitRewardList();

                Toast.fire({
                    icon: 'success',
                    title: 'Claimed Successfully',
                });
            }
        })
        .catch((error) => {

            Toast.fire({
                icon: 'success',
                title: error?.response?.data?.message || 'Something went wrong!',
            });
        })
    };

    useEffect(() => {
        getUserPoints();
        getAllPoints(true);
        getBenefitRewardList();
    }, [selectedProject])

    useEffect(() => {

        activityReward.map((item) => {
            if (item.claimed && item.status == 0) {
                setIsClaimed(false);
            }
        });
    
    }, [activityReward])


    return (
        <Container fluid className="p-0">
            <Row>
                <Col xxl={5}>
                    <div className="rewads_section">
                        <h6>Your Loyalty Points</h6>
                        <div className="loyalty_sec">
                            <div className="img">
                                <img src={DummyImg} alt="Loyalty" />
                            </div>
                            <div className="loyalty_txt">
                                {userPoint?.points ?? 0} <span>Points</span>
                            </div>
                        </div>
                        
                        <div className="loyalty_footer">
                            <div id="remaining_benefit_points"><span>{DotValueIcon}</span>{userPoint?.remaining_points} Points remaining for your next reward</div>
                            <div id="remaining_points"><span>{DotValueIcon}</span>{userPoint?.points} Points claimed</div>
                        </div>
                    </div>
                </Col>
                <Col xxl={7}>
                    <div className="rewads_section slider_section">
                        <h6>Earn Points and Unlock Exciting Rewards </h6>
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={6}
                            modules={[Navigation]}
                            
                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            }}
                            breakpoints={{
                                320: {
                                  slidesPerView: 1,
                                },
                                575: {
                                    slidesPerView: 1.5,
                                  },
                                666: {
                                  slidesPerView: 1.8,
                                },
                                768: {
                                  slidesPerView: 2,
                                },
                                1200: {
                                  slidesPerView: 5.5,
                                },
                                1400: {
                                  slidesPerView: 3.8,
                                },
                                1600: {
                                  slidesPerView: 5,
                                },
                    
                              }}
                        >
                            {
                                activityRewardLoading ? <Loader /> :
                                    activityReward?.map((item, index) => {
                                        return (

                                            <SwiperSlide key={`ar${index}`}>
                                                <ActivityCard
                                                    id={item.id}
                                                    claimed={item.claimed}
                                                    status={item.status}
                                                    title={item.title}
                                                    points={item.clientClaimedRewards ? item.clientClaimedRewards.points : item.points }
                                                    setPointId={setPointId}
                                                    handleClickOpen={handleClickOpen}
                                                    isClaimed={isClaimed}
                                                    item={item}
                                                />
                                            </SwiperSlide>

                                        )
                                    })
                            }
                        </Swiper>

                        <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xxl={12}>
                    <div className="main_style_box rewards_main rewards-top-margin">
                        <div className="project_title">Points Utilization Chart
                            <HtmlTooltip title={'From here, you can browse and claim any rewards available to you. Simply explore the options and select the reward that best suits your preferences.'}>
                            <span>{InfoIcon}</span>
                            </HtmlTooltip>
                        </div>
                        <div className="rewards_main_box">
                            {
                                loading ? <Loader /> :
                                    benefitRewardList?.map((item, index) => {
                                        // console.log(item);
                                        return (
                                            <div key={`br${index}`} className={item.claimed ? 'rewards_main_list' : (item.points > item.user_points ? 'rewards_main_list' : 'rewards_main_list claim')}>
                                                <div className="title">{item?.title}</div>
                                                <div className="points">{ item?.clientClaimedRewards ? item?.clientClaimedRewards?.points : item?.points } Points</div>
                                                <div className="butn">

                                                    {item.claimed ? item.status == 0 ? 'In Review' : 'Claimed' : (item.points > item.user_points ? `You need ${item.points - item.user_points} more Points` : <button onClick={() => createBenefitClaim(item.id)} > Claim</button>)}

                                                    {/* {item.claimed ? "Claimed" : (item.points > item.user_points ? `You need ${item.points - item.user_points} more Points` : <button onClick={() => createBenefitClaim(item.id)}>Claim</button>)} */}
                                                </div>
                                            </div>
                                        )
                                    })
                            }
                        </div>
                    </div>
                </Col>
            </Row>

            <Modal show={open} onHide={handleClose}
             aria-labelledby="contained-modal-title-vcenter"
             centered
            >
                <div className="modal_poopup">
                    <div className="colsed" onClick={handleClose}>
                        {ClosedIcon}
                    </div>
                    <h4>Claim Points</h4>
                    <p>Earn points by engaging in various activities, and unlock exciting rewards by claiming them through our easy-to-use rewards system!</p>

                    <form onSubmit={handleSubmit}>

                        {!file && (
                            <div className="file_upload_img">
                                <Form.Control
                                    type="file"
                                    name="screenshot"
                                    onChange={handleFileChange}

                                />
                                <div className="svg_icon">
                                    <div className="icons">{FileUploadIcon}</div>
                                    <div className="svg_icon_txt">Please upload the screenshot.</div>

                                </div>
                            </div>
                        )}
                        {file && (
                            <div className="uploaded_file_display">
                                <img src={file} alt="Uploaded Screenshot" className="uploaded_image" />
                                <div className="remove_img" onClick={handleFileRemove}>
                                    {ClosedIcon}
                                </div>
                            </div>
                        )}

                        {errors.screenshot && <div style={{ color: '#f44336', fontSize: '0.9rem' }}>{errors.screenshot}</div>}
                        <br />

                        <div className="reward_url">
                            <Form.Label>Enter URL</Form.Label>
                            <Form.Control type="text" className="custom_url" placeholder="https://www.example.com"  onChange={(e) => customUrlValidation(e)} />
                            {urlValidationMessage && <div style={{ color: '#f44336', fontSize: '0.9rem' }}>{urlValidationMessage}</div>}
                        </div>

                        <div className="btn_submit">
                            <Button type="submit" className="custom_btn" disabled={disableSubmit}>
                                Submit
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* <Dialog open={open} onClose={handleClose} aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{"Claim Points"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div
                            className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                            <div className="file-upload-container">
                                <div><small>Screenshot</small></div>
                                <input
                                    type="file"
                                    name="screenshot"
                                    onChange={handleChange}
                                />
                                {errors.screenshot &&
                                    <div style={{ color: '#f44336', fontSize: '0.75rem' }}>{errors.screenshot}</div>}
                            </div>
                        </div>

                        <div
                            className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                            <TextField
                                className="w-[350px] tab:w-[100%] mobile:w-[100%] small:w-[100%]"
                                id="link"
                                name="link"
                                label="URL"
                                value={values.link}
                                onChange={handleChange}
                                error={errors.link}
                                helperText={errors.link}
                            />
                        </div>

                        <div className="flex justify-end mt-3">
                            <button
                                className="bg-[#F37A20] w-[70px] rounded-md text-[12px] p-3 text-white"
                                type="submit"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog> */}
        </Container>
    )
}

export default ClientRewardPoint
