import React, {useEffect, useState} from 'react';
import BoardTopic from './BoardTopic';
import axios from 'axios';
import {useStateContext} from '../../contexts/ContextProvider';
import {Oval} from 'react-loader-spinner'
import {Form} from "react-bootstrap";
import {
    CalendarICon,
    CommentIcon,
    LikeIcon,
    PersonIcon,
    ThreeDots,
} from "../../app-constants";

const BoardTab = ({allAssignee, gid}) => {
    const [sections, setSections] = useState([])
    const {user, selectedProject} = useStateContext();
    const [count, setCount] = useState('')
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getAllSections();
    }, [selectedProject])

    const getAllSections = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_BASE_URL}get-asana-project-section/${selectedProject}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setSections(response.data.data)
            }
            setLoading(false);
        }).catch((err) => {
            console.log('err====', err);
            setLoading(false);
        })
    }

    const getcount = (c) => {
        setCount(c)
    }

    return (
        <>
            {loading ? (
                <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0 auto',
                    width: 'fit-content'
                }}
                className='mt-5'
            >
                <Oval
                    visible={true}
                    height={20}
                    width={20}
                    color="#f37a20"
                    ariaLabel="oval-loading"
                />
                <small>Please wait while the content is loading</small>
            </div>
            ) : (
                    // <div>
                    //     {sections.length !== 0 && sections.map((item, index) => (
                    //         <div className="board_view_list" key={index}>
                    //             <div className="task_title">
                    //                 <Form.Check
                    //                     className="list_input"
                    //                     type="checkbox"
                    //                 />
                    //                 Concept defined
                    //             </div>
                    //             <div className="task_content">
                    //                 <ul>
                    //                     <li className="Management">Project Management</li>
                    //                     <li className="Analyst">Business Analyst</li>
                    //                     <li className="Completed">Completed</li>
                    //                     <li className="Medium">Medium</li>
                    //                 </ul>
                    //             </div>
                    //             <div className="task_footer">
                    //                 <div className="task_left">
                    //                     <div className="img_role">{PersonIcon}</div>
                    //                     <div className="img_date">Calender</div>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     ))}
                    // </div>
                    <>
                        {
                            sections.length !== 0 && sections.map((item, index) => {
                                return (
                                    
                                    <div className="custom-col" key={index}>
                                        <BoardTopic
                                            getcount={getcount}
                                            sections={item.gid}
                                            sectionname={item.name}
                                            pid={gid}
                                            name={sections}
                                            allAssignee={allAssignee}
                                        />
                                    </div>


                                )
                            })
                        }
                    </>
                )
            }
        </>
    )
}

export default BoardTab;