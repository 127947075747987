import React, {useEffect, useState} from 'react';
import {useStateContext} from '../../../contexts/ContextProvider';
import {CircularProgressbar} from 'react-circular-progressbar';
import axios from "axios";
import TekPoints from "../../../img/Loyalty.svg"
const UserRewards = () => {

    const {user, userprofile, benefits, selectedProject, project} = useStateContext();
    const [data, setData] = useState([]);
    const [userPoint, setUserPoint] = useState([]);

    const getUserPoints = () => {
        //get_user_points
        axios.get(`${process.env.REACT_APP_BASE_URL}get_user_points?project_id=${selectedProject}&client_id=${user?.user?.id}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            // setData(response.data.data)
            setUserPoint(response.data.data);
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    useEffect(() => {
        getUserPoints()
    }, [selectedProject])


    return (
        
        <>
                <h6 className="points_claim" data-toggle="tooltip" data-placement="top" title="Tooltip on top">Your Loyalty Points </h6>
                <div class="loyalty_sec">
                    <div class="img">
                        <img src={TekPoints} alt="Loyalty" />
                    </div>
                    <div class="loyalty_txt">{userPoint?.points || 0 } <span>Points</span></div>
                </div> 
                <h6 className="points_claim"><span>{userPoint?.points || 0}</span> Points claimed</h6>
                <h6 className="points_claim"><span>{userPoint?.remaining_points || 0 }</span> Points remaining for your next reward</h6>
        </>
        
    );
}

export default UserRewards;
