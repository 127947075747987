import React, {useEffect, useState} from "react";
import {useStateContext} from "../contexts/ContextProvider";
import {Navigate} from "react-router-dom";
import axios from "axios";
import Drawer from '@mui/material/Drawer';
import {TextField} from "@material-ui/core";
import {ThreeDots} from 'react-loader-spinner'
import {Oval} from 'react-loader-spinner'
import DummyImg from "../img/DummyImg.png"

const Comments = ({stories, loading}) => {
    useEffect(() => {
        console.log(stories);
    })
    return (
        <>
            <div className="comments_box">

                {
                    stories && stories?.length > 0 ?
                        stories?.map(() => {
                            return (
                                <div className='main_status_section comments'>
                                    <div className="comment_status">
                                        <div className="image">
                                            <img src={DummyImg}
                                                 alt="alt"/>
                                        </div>
                                        <div className="content">
                                            <div className='task_name'>Jordan Brown created this task.</div>
                                            <div className='task_date'>6/22/2023</div>
                                            <p className='task_para'>Jordan Brown added to Test Project 2</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        : ''
                }


                {/*<div className='main_status_section comments'>
                    <div className="comment_status">
                        <div className="image">
                            <img src={DummyImg}
                                 alt="alt"/>
                        </div>
                        <div className="content">
                            <div className='task_name'>Jordan Brown created this task.</div>
                            <div className='task_date'>6/22/2023</div>
                            <p className='task_para'>Jordan Brown added to Test Project 2</p>
                        </div>
                    </div>
                </div>
                <div className='main_status_section comments'>
                    <div className="comment_status">
                        <div className="image">
                            <img src={DummyImg}
                                 alt="alt"/>
                        </div>
                        <div className="content">
                            <div className='task_name'>Jordan Brown created this task.</div>
                            <div className='task_date'>6/22/2023</div>
                            <p className='task_para'>Jordan Brown added to Test Project 2</p>
                        </div>
                    </div>
                </div>
                <div className='main_status_section comments'>
                    <div className="comment_status">
                        <div className="image">
                            <img src={DummyImg}
                                 alt="alt"/>
                        </div>
                        <div className="content">
                            <div className='task_name'>Jordan Brown created this task.</div>
                            <div className='task_date'>6/22/2023</div>
                            <p className='task_para'>Jordan Brown added to Test Project 2</p>
                        </div>
                    </div>
                </div>
                <div className='main_status_section comments'>
                    <div className="comment_status">
                        <div className="image">
                            <img src={DummyImg}
                                 alt="alt"/>
                        </div>
                        <div className="content">
                            <div className='task_name'>Jordan Brown created this task.</div>
                            <div className='task_date'>6/22/2023</div>
                            <p className='task_para'>Jordan Brown added to Test Project 2</p>
                        </div>
                    </div>
                </div>
                <div className='main_status_section comments'>
                    <div className="comment_status">
                        <div className="image">
                            <img src={DummyImg}
                                 alt="alt"/>
                        </div>
                        <div className="content">
                            <div className='task_name'>Jordan Brown created this task.</div>
                            <div className='task_date'>6/22/2023</div>
                            <p className='task_para'>Jordan Brown added to Test Project 2</p>
                        </div>
                    </div>
                </div>*/}
            </div>
            <div className="status_from_bg">
                <form className="status_from">
                    <div className="input_status">
                        <TextField
                            fullWidth
                            placeholder='Add a comment'
                        />
                    </div>
                    <button type='submit'>Comment</button>
                </form>
            </div>


            {/*{!loading ?*/}
            {/*    <div>*/}
            {/*        {stories && stories?.length > 0 ?*/}
            {/*            stories?.map((comment) => {*/}
            {/*                return (*/}
            {/*                    <>*/}
            {/*                        <div className='popup-header'>*/}
            {/*                            <div className="status_btn">*/}
            {/*                                Status Comments*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className='main_popup_section comments'>*/}
            {/*                            <div className="comment_popup">*/}
            {/*                                <div className="image">*/}
            {/*                                    <img src={comment?.created_by?.photo?.image_128x128}*/}
            {/*                                         alt={comment?.created_by?.name || '-'}*/}
            {/*                                         className="w-20 h-20 rounded-full"/>*/}
            {/*                                </div>*/}
            {/*                                <div className="content">*/}
            {/*                                    <div className='task_name'>{comment?.created_by?.name || 'N/A'}</div>*/}
            {/*                                    <div className='task_date'>{comment?.created_at || 'N/A'}</div>*/}
            {/*                                    <p className='task_para'>{comment?.text || 'N/A'}</p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}

            {/*                    </>)*/}
            {/*            }) :*/}
            {/*            <div className="no-comment-msg">No Comments Found</div>*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*    :*/}
            {/*    <div className='justify-center items-center flex h-[100%] w-[100%]'>*/}
            {/*        <ThreeDots*/}
            {/*            height="80"*/}
            {/*            width="80"*/}
            {/*            radius="9"*/}
            {/*            color="#F37A20"*/}
            {/*            ariaLabel="three-dots-loading"*/}
            {/*            wrapperStyle={{}}*/}
            {/*            wrapperClassName=""*/}
            {/*            visible={true}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*}*/}
        </>
    )
}

const DetailView = ({user, data, owner}) => {
    const [popupLoading, setPopupLoading] = useState(false);
    const [stories, setStories] = useState(false);
    const getStories = () => {
        setPopupLoading(true);
        if (user) {
            let url = `${process.env.REACT_APP_BASE_URL}get-post-task-stories/${data?.gid}`;
            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${user?.user.token}`
                }
            })
                .then((res) => {
                    setStories(res?.data?.data || []);
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    setPopupLoading(false);
                });
        }
    }

    useEffect(() => {
        console.log('=====data', data)
        if (data) {
            getStories()
        }

    }, [data])

    return (
        <div className="main-status_content">
            <div className="appboard-grid-project-txt">
                <div className="appboard_grid_project_img_box status_sec">
                    <div className="img_box">
                        <img
                            src={data?.author?.photo?.image_128x128 || DummyImg}
                            onError={(e) => {
                                e.target.src = DummyImg;
                            }}
                            alt={data?.author?.name || '-'}
                            className="img_sec"
                        />
                    </div>
                    <div>
                        <div className="img_title">
                            <h6>{data?.author?.name || 'N/A'} <span>Nov 25, 2023</span></h6>
                        </div>
                        <div className="appboard_grid_project_status">
                            <ul>
                                <li>Status :
                                    {data?.color == 'yellow' && <span className="warning">At Risk</span>}
                                    {data?.color == 'green' && <span className="success">On Track</span>}
                                    {
                                        data?.color == 'red' && <span className="danger">Off Track</span>
                                    }
                                    {
                                        data?.color == 'blue' && <span className="primary">On hold</span>
                                    }
                                    {
                                        data?.color == 'complete' && <span className="complete">Complete</span>
                                    }
                                </li>
                                <li>Owner : <span>{owner?.name || 'N/A'}</span></li>
                                <li>Dates : <span>{owner?.created_at || 'N/A'}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="appboard_grid_project_summary">
                    <div className="txt" dangerouslySetInnerHTML={{__html: data?.html_text ? data?.html_text.replace(/\n/g, '<br>') : ''}}/>
                </div>
                <div className="appboard_grid_project_button">
                    <Comments stories={stories} loading={popupLoading}/>
                </div>
            </div>

        </div>
    )
}

const StatusBox = ({user, data, owner, toggleDrawer, nextPage, setButtonLoading, buttonLoading, loadMore}) => {
    const [statusData, setStatusData] = useState(data[0]);
    const token = user?.user?.token

    const loadStatus = (id) => {
        getStatus(id);
    }

    const getStatus = (id) => {
        if (user?.user) {
            let url = `${process.env.REACT_APP_BASE_URL}get-project-status-by-statusId/${id}`;
            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((res) => {
                    if (res?.data?.data) {
                        setStatusData(res?.data?.data);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    setButtonLoading(false);
                });
        }
    }

    return (
        <div className="main-status_box">
            <div className="main-status_sidebar">
                <ul>
                    {data.map((dt) => (
                        <li onClick={() => loadStatus(dt?.gid)}>{dt?.title || 'N/A'}
                            {dt?.color == 'yellow' && <span className="warning">At Risk</span>}
                            {dt?.color == 'green' && <span className="success">On Track</span>}
                            {dt?.color == 'red' && <span className="danger">Off Track</span>}
                            {dt?.color == 'blue' && <span className="primary">On hold</span>}
                            {dt?.color == 'complete' && <span className="complete">Complete</span>}
                        </li>
                    ))
                    }
                </ul>

                {nextPage && (
                    <div>
                        {buttonLoading ? (
                            <div className="appboard_grid_project_button flex justify-center">
                                <button>
                                    <Oval
                                        visible={true}
                                        height="20"
                                        width="20"
                                        color="#ffffff"
                                        secondaryColor="#ffffff"
                                        ariaLabel="oval-loading"
                                        wrapperStyle={{display: 'flex', justifyContent: 'center'}}
                                        wrapperClass=""
                                    />
                                </button>
                            </div>
                        ) : (
                            <div className="appboard_grid_project_button flex justify-center" onClick={loadMore}>
                                <button>Load More</button>
                            </div>
                        )}
                    </div>
                )}
            </div>

            <DetailView user={user} data={statusData} owner={owner}/>
        </div>
    )
}

const Status = () => {
    const {user, project, sessionProject} = useStateContext();
    const {role} = user.user;
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [popupLoading, setPopupLoading] = useState(false);
    const [data, setData] = useState([]);
    const [owner, setOwner] = useState(false);
    const [open, setOpen] = useState(false);
    const [stories, setStories] = useState([]);
    const [nextPage, setNextPage] = useState("");
    const [offset, setOffset] = useState("");

    useEffect(() => {
        if (offset != nextPage) {
            setData([]);
        }
        getStatus();
    }, [project, offset]);

    const toggleDrawer = (id) => {
        getStories(id);
        setOpen(!open);
    };

    const getStories = (id) => {
        setPopupLoading(true);

        if (user) {
            let url = `${process.env.REACT_APP_BASE_URL}get-post-task-stories/${id}`;
            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    setStories(res?.data?.data || []);
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    setPopupLoading(false);
                });
        }
    }

    const getStatus = () => {
        !offset ? setLoading(true) : setButtonLoading(true);
        if (user && sessionProject) {
            let url = `${process.env.REACT_APP_BASE_URL}get-project-status/${sessionProject}?limit=10&offset=${offset}`;
            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res?.data?.data) {
                        const {status_updates, owner, next_page} = res?.data?.data;
                        setNextPage(next_page);
                        setData(prevData => [...prevData, ...status_updates]);
                        setOwner(owner);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                    setButtonLoading(false);
                });
        }
    }

    const loadMore = () => {
        if (nextPage) {
            setOffset(nextPage);
        }
    };

    return (
        <div className="appboard-grid">
            <div className={`appboard-sub-grid pt-4`}>
                <div className="main-top-banner">
                    <div className="main-title">
                        <h1>App <span>Status</span></h1>
                    </div>
                    <div className="main-btn-txt"></div>
                </div>
                {loading ? (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }} className='mt-5'>
                        <Oval
                            visible={true}
                            height="20"
                            width="20"
                            color="#f37a20"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                        <small>Please wait while the content is loading</small>
                    </div>
                ) : (
                    <div>
                        <StatusBox user={{user}} data={data} owner={owner} toggleDrawer={toggleDrawer}
                                   nextPage={nextPage} setButtonLoading={setButtonLoading} buttonLoading={buttonLoading} loadMore={loadMore}/>
                    </div>
                )}
            </div>
        </div>

    );
};

export default Status;
