import React, {useEffect, useState} from "react";
import {useStateContext} from "../contexts/ContextProvider";
import Chart from 'chart.js/auto';
import {Bar, Doughnut} from 'react-chartjs-2';
import axios from "axios";

const StatCard = ({label, count}) => {
    return (
        <div className="w-full md:w-1/4 p-4">
            <div className="bg-white border rounded-lg shadow-md p-6 flex flex-col justify-center items-center">
                <h2 className="text-lg font-semibold">{label}</h2>
                <p className="text-3xl font-bold mt-2">{count}</p>
            </div>
        </div>
    );
};

const BarChartCard = ({data}) => {
    return (
        <div className="graph_box">
            <div className="graph_box_item">
                <h2 className="text-[14px] font-semibold mb-4">Incomplete tasks by section</h2>
                <Bar data={data}/>
            </div>
        </div>
    );
};

const DoughnutChartCard = ({data}) => {
    return (
        <div className="graph_box">
            <div className="graph_box_item">
                <h2 className="text-[14px] font-semibold mb-4">Total tasks by completion status</h2>
                <Doughnut data={data}/>
                <div className="points_task">
                    {data?.labels?.map((label, index) => (
                        <div key={index} className="points_item">
              <span
                  className="inline-block w-3 h-3 rounded-full mr-2"
                  style={{backgroundColor: data?.datasets[0]?.backgroundColor[index]}}
              ></span>
                            <span>{label}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const AsanaDashboard = () => {

    const {user, table, project, sessionProject} = useStateContext();
    const {role} = user.user;
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [stats, setStats] = useState([
        {label: 'Complete Tasks', count: 0},
        {label: 'Incomplete Tasks', count: 0},
        {label: 'Milestones', count: 0},
        {label: 'Total Tasks', count: 0}
    ])
    const [taskData, setTaskData] = useState([]);

    const barChartData = {
        labels: data?.label,
        datasets: [
            {
                label: 'Tasks',
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(0, 255, 255, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(0, 255, 255, 1)'
                ],
                borderWidth: 1,
                data: data?.data,
            },
        ],
    };

    const doughnutChartData = {
        labels: ['Complete Tasks', 'In complete Tasks'],
        datasets: [
            {
                label: '# of Tasks',
                data: taskData,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    useEffect(() => {
        getDashboardCounts();
        getDashboard();
    }, [project])

    const getDashboardCounts = () => {
        setLoading(true);
        if (user) {
            let url = `${process.env.REACT_APP_BASE_URL}get-asana-dashboard-counts/${sessionProject}`;
            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    let payData = res?.data?.data
                    setStats([
                        {
                            label: 'Complete Tasks',
                            count: payData.data.num_completed_tasks,
                        },
                        {
                            label: 'Incomplete Tasks',
                            count: payData.data.num_incomplete_tasks,
                        },
                        {
                            label: 'Milestones',
                            count: payData.data.num_milestones,
                        },
                        {
                            label: 'Total Tasks',
                            count: payData.data.num_tasks,
                        }
                    ])

                    setTaskData([
                        payData?.data?.num_completed_tasks || 0,
                        payData?.data?.num_incomplete_tasks || 0,
                    ])
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    if (err.response.status == 401) {
                        localStorage.removeItem('tekrevol_user');
                        window.location.href = '/signin';
                    } else {
                        console.log(err);
                    }
                })
        }
        setLoading(false);
    }

    const getDashboard = () => {
        setLoading(true);
        if (user) {
            let url = `${process.env.REACT_APP_BASE_URL}asana-dashboard/${sessionProject}`;
            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    console.log('=========', res?.data?.data)
                    setData(res?.data?.data)
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    if (err.response.status == 401) {
                        localStorage.removeItem('tekrevol_user');
                        window.location.href = '/signin';
                    } else {
                        console.log(err);
                    }
                })
        }
        setLoading(false);
    }

    return (
        <div className="appboard-grid bg-[#f6f6f6]">
            <div className={`appboard-sub-grid pt-4 `}>
                <div className="main-top-banner">
                    <div className="main-title">
                        <h1>Asana <span> Dashboard</span></h1>
                    </div>
                    <div className="main-btn-txt"></div>
                </div>

                        <div className="flex">
                            {stats.map((stat, index) => (
                                <StatCard key={index} label={stat.label} count={stat.count}/>
                            ))}
                        </div>

                        <div className="container mx-auto">
                            <div className="graph_section">
                                <BarChartCard data={barChartData}/>
                                <DoughnutChartCard data={doughnutChartData}/>
                            </div>
                        </div>

            </div>
        </div>
    );
};

export default AsanaDashboard;
