function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export const DateFormatWithSlash = (timestamp) => {

    // let date = new Date(timestamp);
    // return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`


    const date = new Date(timestamp);

    // Get the month name
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const month = monthNames[date.getUTCMonth()];

    // Get the day of the month
    const day = date.getUTCDate();

    // Determine the suffix for the day
    const suffix = (day === 1 || day === 21 || day === 31) ? 'st' :
                   (day === 2 || day === 22) ? 'nd' :
                   (day === 3 || day === 23) ? 'rd' : 'th';

    // Get the year
    const year = date.getUTCFullYear();

    // Construct the formatted date string
    const formattedDate = `${month} ${day}${suffix}, ${year}`;
    return formattedDate;
}

export const DateFormatWithLetters = (timestamp) => {
    let date = new Date(timestamp)
    let month = date.toLocaleString('default', { month: 'long' });
    return `${month} ${date.getDate()}, ${date.getFullYear()}`
}

export const DateAndTimestamp =(timestamp) => {
    let date = new Date(timestamp)
    let month = date.toLocaleString('default', { month: 'long' });
    let time = formatAMPM(date)

    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}  | ${time}`
}


export const DateFormatWithTimeStamp = (timestamp) => {
    let date = new Date(timestamp)
    let month = date.toLocaleString('default', { month: 'long' });
    let time = formatAMPM(date)

    
    return `${date.getDate()} ${month}, ${time}`
}


const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    const strTime = `${hours}:${minutes} ${ampm}`;

    return strTime;
};