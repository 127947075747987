import React, {useEffect, useState} from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import {AddIcon} from "./utils/svg";
import {AttchedIcon, BriefIcon, ReferralsBoard} from "./app-constants";
import {NavLink} from "react-router-dom";
import {Form} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import * as yup from "yup";
import {useFormik} from "formik";
import axios from "axios";
import {Toast} from "./components/General";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import {Oval} from "react-loader-spinner";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {useStateContext} from "./contexts/ContextProvider";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import S3FileUpload from "react-s3";
import {getFileMimeType} from "./utils/Helper";
import Swal from "sweetalert2";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function AddButtonShortcut(props) {
    const {selectedProject, user} = useStateContext();
    const [loading, setLoading] = useState(false);
    const [Modalopen1, setModalOpen1] = React.useState(false);
    const [Modalopen2, setModalOpen2] = React.useState(false);
    const [allAssignee, setAllAssignee] = useState([]);
    const [sections, setSections] = useState([])

    const getAllSections = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_BASE_URL}get-asana-project-section/${selectedProject}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setSections(response.data.data)
            }
            setLoading(false);
        }).catch((err) => {
            console.log('err====', err);
            setLoading(false);
        })
    }

    const getAllAssignee = () => {
        if (user) {
            const ASANA_BEARER_TOKEN = "2/1204614194882962/1206884941289659:6993e942413ab55624207ae4379afb7f"
            axios.get(`${process.env.REACT_APP_ASANA_BASE_URL}users?workspace=146928382234467`, {
                headers: {
                    Authorization: `Bearer ${ASANA_BEARER_TOKEN}`,
                }
            })
                .then((res) => {
                    setAllAssignee(res.data.data)
                })
                .catch(err => {
                    console.log('err====', err);
                })
        }
    }

    useEffect(() => {
        getAllSections();
        getAllAssignee();
    }, [selectedProject])

    const handleCloseReferralModal = () => {
        setModalOpen1(false);
    };

    const handleOpenReferralModal = () => {
        setModalOpen1(true);
        setModalOpen2(false);
    };


    const handleCloseTaskModal = () => {
        setModalOpen2(false);
    };

    const handleOpenTaskModal = () => {
        setModalOpen2(true);
        setModalOpen1(false);
    };

    const validationReferralSchema = yup.object({
        name: yup.string().required('Name is required'),
        email: yup.string().email('Email must be a valid email').required('Email is required')
    });

    const formikReferral = useFormik({
        initialValues: {
            name: '',
            email: '',
        },
        validationSchema: validationReferralSchema,
        onSubmit: (values, { resetForm }) => {

            if (values) {
                let payload = {
                    client_id: user.user.id,
                    name: values.name,
                    email: values.email
                }
                submitReferralForm(payload,resetForm)

            }
        },
    });

    const submitReferralForm = async (payload,resetForm) => {
        setLoading(true);

        await axios.post(`${process.env.REACT_APP_BASE_URL}referal`, payload, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    Toast.fire({
                        icon: 'success',
                        title: 'Invitation sent successfully!',
                        showConfirmButton: false,
                        timer: 1000
                    })
                }
            })
            .catch((error) => {
                Toast.fire({
                    icon: 'error',
                    title: error?.response?.data?.errors[0]?.message || 'Something went wrong!',
                })
            })
            .finally(() => {
                handleCloseReferralModal()
                setLoading(false);
                resetForm({ values: '' })
            });
    }



    const validationTaskSchema = yup.object({
        title: yup
            .string()
            .min(2, 'Too Short!')
            .max(70, 'Too Long!')
            .required('Task Title is required'),
        due_date: yup.date().notRequired(),
        assignee: yup.string().notRequired()
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            due_date: null,
            assignee: '',
            section: '',

        },
        validationSchema: validationTaskSchema,
        onSubmit: (values, {resetForm}) => {
            CreateTask(resetForm)
        },
    });

    const CreateTask = (resetForm) => {
        setLoading(true)
        const data = {
            "projectId": selectedProject,
            "sectionId": formik.values.section,
            "taskName": formik.values.title,
            "assignee": formik.values.assignee || null,
            "due_on": formik.values.due_date || null
        };

        console.log('hyder--------------------->',data);
        axios.post(`${process.env.REACT_APP_BASE_URL}create-task`, data, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                resetForm({values: ''})
                setLoading(false)
                handleCloseTaskModal()
                Swal.fire({
                    icon: 'success',
                    title: 'Task Created successfully!',
                    showConfirmButton: false,
                    timer: 1000
                });
            })
            .catch((error) => {
                // setLoading(false);
                setLoading(false)
                handleCloseTaskModal()
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1000
                });
                console.log(error);
            });
    };

    return (
        <>
        <div className="floating_add_button">
        <div className="add_attachment_box">
            <div className="dot_edit">
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                        <AddIcon/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {/*<Dropdown.Item>{BriefIcon} Create project brief</Dropdown.Item>*/}
                        <Dropdown.Item onClick={()=>handleOpenTaskModal()}>{BriefIcon} Add Task</Dropdown.Item>
                        {/*<Dropdown.Item>{AttchedIcon}Add links & files</Dropdown.Item>*/}
                        <Dropdown.Item onClick={()=>handleOpenReferralModal()}>{ReferralsBoard}Refer a friend</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

        </div>
        </div>


            {/*Referral Modal*/}
            <Dialog
                open={Modalopen1}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseReferralModal}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Referrals Program"}</DialogTitle>
                <DialogContent>
                  <form onSubmit={formikReferral.handleSubmit} className="space-y-6 ">
                      <div className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                      <TextField
                          className="w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%] "
                          id="name"
                          name="name"
                          label="Referral Name"
                          value={formikReferral.values.name}
                          onChange={formikReferral.handleChange}
                          error={formikReferral.touched.name && Boolean(formikReferral.errors.name)}
                          helperText={formikReferral.touched.name && formikReferral.errors.name}
                      />
                      </div>
                      <div className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                      <TextField
                          className="w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%] "
                          id="name"
                          name="email"
                          type="email"
                          label="Referral Email"
                          value={formikReferral.values.email}
                          onChange={formikReferral.handleChange}
                          error={formikReferral.touched.email && Boolean(formikReferral.errors.email)}
                          helperText={formikReferral.touched.email && formikReferral.errors.email}
                      />

                      </div>


                      <div className="flex justify-end mt-3">
                          <button
                              className="bg-[#F37A20] w-[100px] rounded-md text-[16px] p-3 text-white"
                              variant="contained"
                              fullWidth
                              type="submit"
                              disabled={loading}
                          >
                              {loading ? <Oval
                                  visible={true}
                                  height="20"
                                  width="20"
                                  color="#ffffff"
                                  secondaryColor="#ffffff"
                                  ariaLabel="oval-loading"
                                  wrapperStyle={{disply: 'flex', justifyContent: 'center'}}
                                  wrapperClass=""
                              />:"Submit"}
                          </button>
                      </div>

            </form>
                </DialogContent>

            </Dialog>

            {/*Task Modal*/}

            <Dialog
                open={Modalopen2}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseTaskModal}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Create a Task"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit} className='space-y-6 '>
                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <TextField
                                className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                id="title"
                                name="title"
                                label="Title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                helperText={formik.touched.title && formik.errors.title}
                            />
                        </div>
                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <FormControl className="custom-input" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                        <DatePicker
                                            fullWidth
                                            className='w-[100%]'
                                            name="due_date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="due_date"
                                            inputFormat="E MMM dd yyyy HH:MM:SS O"
                                            label="Due Date"
                                            value={formik.values.due_date}
                                            onChange={value => formik.setFieldValue('due_date', value)}
                                            error={formik.touched.due_date && Boolean(formik.errors.due_date)}

                                            disablePast
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Section</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    name="section"
                                    label="Section"
                                    id="section"
                                    value={formik.values.section}
                                    onChange={formik.handleChange}
                                    error={formik.touched.section && Boolean(formik.errors.section)}
                                    helperText={formik.touched.section && formik.errors.section}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    {sections?.map((item, index) => {
                                        // console.log(item);
                                        return (

                                            <MenuItem key={index}
                                                      value={`${item.gid}`}>{item.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </div>

                            <div
                                className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Assignee</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    name="assignee"
                                    label="Assignee"
                                    id="assignee"
                                    value={formik.values.assignee}
                                    onChange={formik.handleChange}
                                    error={formik.touched.assignee && Boolean(formik.errors.assignee)}
                                    helperText={formik.touched.assignee && formik.errors.assignee}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    {allAssignee?.filter((element) => element.name !== 'Private User').map((item, index) => {
                                        // console.log(item);
                                        return (

                                            <MenuItem key={index}
                                                      value={`${item.gid}`}>{item.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            </div>


                        <div className='flex justify-end mt-3'>
                            <button
                                className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-2 text-white'
                                variant="contained" fullWidth type="submit" disabled={loading}>
                                {loading ?  <Oval
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#ffffff"
                                    secondaryColor="#ffffff"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{ display: 'flex', justifyContent: 'center' }}
                                    wrapperClass=""
                                /> : "Create" }
                            </button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
            </>
    );
}

export default AddButtonShortcut;