import React, {useEffect, useState} from "react";
import { Container, Row, Col } from 'react-bootstrap';
import BarChart from "../components/BarChart";
import ProjectBox from "../components/Projects/ProjectBox";
import WhatsNew from "../components/WhatsNew";
import UpComing from "../components/UpComing";
import ProjectTable from "../components/Projects/ProjectTable";
// import Rewards from "../components/Rewards";

import Rewards from "./new/components/Rewards";
import WhatsStatus from "../components/WhatsStatus";
// import StarCard from "../components/cards/starCard";
import {Footer} from "../components";
import trophie from '../data/trophie.svg'
import {intToString} from "../utils/PriceToString";
import {useStateContext} from "../contexts/ContextProvider";
import axios from "axios";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {BsPlus} from 'react-icons/bs'
import {RxCross2} from 'react-icons/rx'
import InvoiceView from "../components/Invoice/InvoiceView";
import Swal from 'sweetalert2';
import {NavLink} from "react-router-dom";
import { Toast } from '../components/General';
import { Oval } from 'react-loader-spinner';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'rgba(255, 255, 255, 0.8)',
    border: 'none',
    borderRadius: 2,
    boxShadow: 20,
    p: 4,
};

const feedbackstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'rgba(255, 255, 255, 0.8)',
    border: 'none',
    borderRadius: 2,
    boxShadow: 20,
    p: 4,
};

const Dashboard = () => {
    const {user, project, getDashboard, dashboardData, selectedProject, getAllManualProjects} = useStateContext();
    const {role} = user.user;
    ///const sessionProject = sessionStorage.getItem('session_project');
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [feedback, setFeedback] = React.useState(false);
    const handleOpenFeedback = () => setFeedback(true);
    const [asanaProject, setAsanaProject] = useState([]);
    const [loading, setLoading] = useState(false);
    const [promotionData, setPromotionData] = useState([]);
    const [score, setScore] = React.useState(null);
    const [projectProgress, setProjectProgress] = useState([]);
    const [totalTaskCount, setTotalTaskCount] = useState(0);
    const [completedTaskCount, setCompletedTaskCount] = useState(0);

    const [isSubmit, setIsSubmit] = useState(false);


    const [btnLoading, setBtnLoading] = useState(false);


    const getProjectProgress = async () => {

        await axios.get(`${process.env.REACT_APP_BASE_URL}dashboard-project-progress/${selectedProject}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((response) => {
            setProjectProgress(response.data?.data?.project_progress);
            setTotalTaskCount(response.data?.data?.total_task);
            setCompletedTaskCount(response.data?.data?.completed_task);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            console.log();
        });
    }

    const handleCloseFeedback = () => {
        setFeedback(false);
        setTimeout(() => {
            handleOpen();
        }, 3000);
    }

    const createFeedback = async () => {
        setBtnLoading(true);
        
        const data = {
            "feedback": score,
        }

     
        await axios.post(`${process.env.REACT_APP_BASE_URL}client-feedback`, {...data}, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((res) => {
            if (res.status === 200) {
               
                Swal.fire(
                    {
                        icon: 'success',
                        title: 'Thanks for your Feedback!',
                        showConfirmButton: false,
                        timer: 1000,
                    }
                )
                handleCloseFeedback();
            }
        })
        .catch((error) => {
            // setLoading(false);
            console.log(error);
            Swal.fire({
                icon: 'error',
                text: error.response.data.message.feedback,
                showConfirmButton: false,
                timer: 1000,
            })
            handleCloseFeedback();
        })
        .finally(() => {
            // setBtnLoading(false);
        });
        
    };

    useEffect(() => {
        if (selectedProject || project) {
            setLoading(true);
            if(role === 'client'){
                promotions();
            }
            getDashboard(selectedProject || project)



            setTimeout(() => {
                setLoading(false);
            }, 3000)

        }

        getAllManualProjects();
        getProjectProgress();

    }, [selectedProject])

    useEffect(() => {
        const isFirstVisit = sessionStorage.getItem('isFirstVisit');
        if (!isFirstVisit && role === 'client') {
            setTimeout(() => {
                // handleOpen();
                handleOpenFeedback();
            }, 3000);
            // Store a value in sessionStorage to mark the first visit
            sessionStorage.setItem('isFirstVisit', 'true');
        }
    }, []);

    const promotions = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}dashboard-promotion?client_id=${user?.user?.id}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            setPromotionData(response?.data?.data)
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    const formatDate = (date) => {
        const endDate = new Date(date);

        // Formatting the date
        return endDate.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        });
    }

    return (

<>
<div className="main_top_title">
                <div className="main_title"><h1>Dashboard <span>View</span></h1></div>
                <div className="main_btn_txt">
                    <ul>
                        <li>
                            <NavLink to="">
                                Dashboard
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="main_content_container">
                <Container fluid className="p-0">
                    <div className="mb-0">
                    {
                            loading ?
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    width: 'fit-content'
                                }}
                                className='mt-5'
                            >
                                <Oval
                                    visible={true}
                                    height={20}
                                    width={20}
                                    color="#f37a20"
                                    ariaLabel="oval-loading"
                                />
                                <small>Please wait while the content is loading</small>
                            </div> :      
                        <>
                       
                        <Row>
                            <Col xxl={role == "account manager" ? 12 :  9 } xl={7} lg={8}>
                                <Row>
                                    <Col xxl={12} lg={12}>
                                        <ProjectBox projectTask={projectProgress}
                                            UnpaidInvoice={dashboardData?.UnpaidInvoice}
                                            totalTaskCount={totalTaskCount}
                                            completedTaskCount={completedTaskCount}
                                            />
                                    </Col>
                                    <Col xxl={8} lg={12}>
                                        <BarChart projectProgress={projectProgress}/>
                                        <WhatsStatus/>
                                        <InvoiceView paidInvoice={dashboardData?.paidInvoice}/>
                                    </Col>
                                    <Col  xxl={4} lg={12} >
                                    
                                        <WhatsNew fileDocumentation={dashboardData?.file_documentation}/>
                                        <UpComing meeting={dashboardData?.meeting}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xxl={3} xl={5} lg={4}>
                                {
                                    role == 'client' ?(

                                        <div className=" dashboard-sub-grid-rewards">

                                            <Rewards benefitRewardList={dashboardData?.benefitRewardList}/>
                                        </div>
                                    ):null
                                }
                            </Col>
                        </Row>
                      
                        </>
                           
                        }
                        
                    </div>
                </Container>
            </div>







        

        <div className="d-none">
            <div className="appboard-sub-grid pt-4">
                {loading ?
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} className='mt-5'>
                            <Oval
                                visible={true}
                                height="20"
                                width="20"
                                color="#f37a20"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                            <small>Please wait while the content is loading</small>
                        </div> :
                        <>

                            <div className="main-top-banner">
                                <div className="main-title">
                                    <h1>Dashboard</h1>
                                </div>
                                <div className="main-btn-txt">
                                           <ul>
                        <li>
                            <NavLink to="">
                                Dashboard
                            </NavLink>
                        </li>
                
                    </ul>
                                </div>
                            </div>
                           {/*{role === 'client' && promotionData && Object.keys(promotionData).length > 0 &&*/}
                            {/*    <div className="promotions_box">*/}
                            {/*        <div className="promotions_txt">*/}
                            {/*            <h4>{promotionData?.title || 'Promotional'}</h4>*/}
                            {/*            <p>{promotionData?.description}</p>*/}
                            {/*            <NavLink to={`/promotions`}>Grab Your Deals Now!</NavLink>*/}
                            {/*            <div className="offer">Offer valid till: {formatDate(promotionData?.end_date) || ''}</div>*/}
                            {/*        </div>*/}
                            {/*        <div className="promotions_img">*/}
                            {/*            <div className="promotions_main">*/}
                            {/*                <img src={promotionData?.attachments && promotionData?.attachments[0]?.path} alt={promotionData?.title || 'Promotional'}/>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*}*/}
                            <div className="grid-dashboard">
                                <div className="dashboard-sub-grid">
                                    <div className="dashboard-project-box">
                                        <div className="dashboard-project-box-inner space-y-[18px]">
                                            {/* <ProjectBox /> */}
                                            <BarChart projectProgress={projectProgress}/>
                                            <WhatsStatus/>
                                            <InvoiceView paidInvoice={dashboardData?.paidInvoice}/>
                                            {/* <div className="m-5 grid grid-cols-12 gap-3">
                  <div className="h-[329px] col-span-6 mobile:col-span-12">
                    <WhatsNew />
                  </div>
                  <div className="h-[329px] col-span-6 mobile:col-span-12">
                    <UpComing />
                  </div>
                </div> */}
                                        </div>

                                        <div className="dashboard-sub-grid-whats-new">
                                            <div className="space-y-[20px]">
                                                <ProjectBox projectTask={projectProgress}
                                                            UnpaidInvoice={dashboardData?.UnpaidInvoice}
                                                            totalTaskCount={totalTaskCount}
                                                            completedTaskCount={completedTaskCount}
                                                            
                                                            />
                                                <WhatsNew fileDocumentation={dashboardData?.file_documentation}/>
                                                <UpComing meeting={dashboardData?.meeting}/>
                                            </div>
                                        </div>
                                        {/* <div className="col-span-12">
                <Footer />
              </div> */}
                                    </div>
                                </div>
                                {
                                    role == 'client' ?(

                                        <div className=" dashboard-sub-grid-rewards">

                                            <Rewards benefitRewardList={dashboardData?.benefitRewardList}/>
                                        </div>
                                    ):null
                                }
                                
                            </div>
                        </>
                }
            </div>
            {/*<Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              To sync with Asana, simply click on the Asana icon mentioned below. It will allow you to integrate your project with this ProjectTable
            </Typography>
            <div className="flex justify-center items-center" >
              <a href={`https://app.asana.com/-/oauth_authorize?client_id=${process.env.REACT_APP_ASANA_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ASANA_REDIRECT_URL}&response_type=code&state=thisIsARandomString&scope=default`}>
                <img src={require('../data/asana.png')} />
              </a>
               <div className="bg-[#F06A6A] rounded-full p-4 " >
                  <BsPlus className="cursor-pointer" size={30} color="white" />
              </div>
              <div onClick={handleClose} className="bg-[#0F1328] rounded-full cursor-pointer p-4 absolute right-[-30px] top-[-30px] " >
                <RxCross2 size={30} color="white" />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>*/}
            <Modal
                aria-labelledby="transition-modal-title-feedback"
                aria-describedby="transition-modal-description-feedback"
                open={feedback}
                onClose={handleCloseFeedback}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={feedback}>
                    <Box sx={feedbackstyle}>
                        <Typography id="transition-modal-description" sx={{mt: 2}}>
                            <p className="text-center text-[26px] font-medium ">How likely are you to recommend
                                Tekrevol
                                to a friend?</p>
                        </Typography>
                        <div className="pt-[20px]">
                            <div className="flex justify-center items-center gap-[20px] ">
                                <p className="text-[20px] text-[#131313] font-medium mb-0">Not Likely</p>
                                <div className="flex justify-around gap-[30px]">
                                    <input
                                        className={`score-uncheck`}
                                        type="checkbox"
                                        checked={score === 1}
                                        onChange={() => {setScore(1); setIsSubmit(true); } }
                                    />
                                    <input
                                        className={`score-uncheck`}
                                        type="checkbox"
                                        checked={score === 2}
                                        onChange={() => {setScore(2); setIsSubmit(true);} }
                                    />
                                    <input
                                        className={`score-uncheck`}
                                        type="checkbox"
                                        checked={score === 3}
                                        onChange={() => {setScore(3); setIsSubmit(true);} }
                                    />
                                    <input
                                        className={`score-uncheck`}
                                        type="checkbox"
                                        checked={score === 4}
                                        onChange={() => {setScore(4); setIsSubmit(true);} }
                                    />
                                    <input
                                        className={`score-uncheck`}
                                        type="checkbox"
                                        checked={score === 5}
                                        onChange={() => {setScore(5); setIsSubmit(true);} }
                                    />
                                </div>
                                <p className="text-[20px] text-[#131313] font-medium mb-0">Very Likely</p>

                            </div>
                            <div className="flex justify-center gap-[58px] pt-[20px]">
                                <p className="text-[#131313] text-[20px] font-medium ">1</p>
                                <p className="text-[#131313] text-[20px] font-medium ">2</p>
                                <p className="text-[#131313] text-[20px] font-medium ">3</p>
                                <p className="text-[#131313] text-[20px] font-medium ">4</p>
                                <p className="text-[#131313] text-[20px] font-medium ">5</p>
                            </div>
                            <div className="flex justify-center pt-[10px]">

                            {isSubmit && <button onClick={createFeedback} disabled={btnLoading}
                                        className="bg-[#F37A20] text-white text-[14px] font-medium p-2 px-4 rounded-md">   {btnLoading ? <Oval
                                            visible={true}
                                            height="20"
                                            width="20"
                                            color="#ffffff"
                                            secondaryColor="#ffffff"
                                            ariaLabel="oval-loading"
                                            wrapperStyle={{disply: 'flex', justifyContent: 'center'}}
                                            wrapperClass=""
                                        /> : 'Submit'}
                                </button>}
                                
                            </div>
                            <div onClick={handleCloseFeedback}
                                 className="bg-[#0F1328] rounded-full cursor-pointer p-4 absolute right-[-30px] top-[-30px] ">
                                <RxCross2 size={30} color="white"/>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
        </>
    );
};

export default Dashboard;
