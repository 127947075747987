import React, {useEffect, useState} from 'react';
import Rewards from '../components/Rewards';
import AppBoardTabs from '../components/Appboard/AppBoardTabs';
import OverviewTab from '../components/OverviewTab';
import {useStateContext} from '../contexts/ContextProvider';
import OverviewResource from '../components/OverviewResources';
import WhatsNew from '../components/WhatsNew'
import FilesTab from '../components/FilesTab';
import BoardTab from '../components/Appboard/BoardTab';
import Swal from 'sweetalert2';
import {useNavigate} from 'react-router-dom'
import MessageTab from '../components/Messagetab';
import ProjectProgress from '../components/charts/ProjectProgress';
import {useParams} from 'react-router-dom';
import {ThreeDots} from 'react-loader-spinner'
import axios from 'axios'
import {Oval} from 'react-loader-spinner'

const BoardView = () => {
    const {activeTab, user, handleTabClick, asanaToken, project, sessionProject, selectedProject} = useStateContext();
    const [asanaProject, setAsanaProject] = useState(null);
    const [allAssignee, setAllAssignee] = useState([]);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const {role} = user.user;

    useEffect(() => {
        getAsanaProject();
        getAllAssignee()
    }, [selectedProject])

    const getAsanaProject = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_BASE_URL}get-asana-project/${selectedProject}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setAsanaProject(response.data.data)
            }
            setLoading(false);
        }).catch((err) => {
            console.log('err====', err);
            setLoading(false);
        })
    }

    const getAllAssignee = () => {
        if (user) {
            const ASANA_BEARER_TOKEN = "2/1204614194882962/1206884941289659:6993e942413ab55624207ae4379afb7f"
            axios.get(`${process.env.REACT_APP_ASANA_BASE_URL}users?workspace=146928382234467`, {
                headers: {
                    Authorization: `Bearer ${ASANA_BEARER_TOKEN}`,
                }
            })
                .then((res) => {
                    setAllAssignee(res.data.data)
                })
                .catch(err => {
                    console.log('err====', err);
                })
        }
    }

    /*useEffect(() => {
        let isMounted = true;
        const ASANA_BEARER_TOKEN = "Bearer 2/1204614194882962/1206884941289659:6993e942413ab55624207ae4379afb7f"
        const getProjectById = () => {
            // asana-project?client_id=31
            axios.get(`${process.env.REACT_APP_ASANA_BASE_URL}projects/1201048070396335?opt_fields=archived,color,completed,created_at,current_status.author.name,current_status.author.resource_type,current_status.color,current_status.created_at,current_status.created_by,current_status.created_by.name,current_status.created_by.resource_type,current_status.modified_at,current_status.resource_type,current_status.text,current_status.title,current_status_update.resource_type,current_status_update.resource_subtype,current_status_update.title,due_on,due_date,followers.name,followers.resource_type,members.resource_type,members.name,members.photo,members.role,modified_at,name,notes,owner.name,owner.resource_type,permalink_url,resource_type,start_on`, {
                headers: {
                    Authorization: ASANA_BEARER_TOKEN,
                }
            }).then((response) => {
                console.log(response.data)
                if (isMounted) {
                    setProject(response.data.data);
                }

                // console.log(response.data.data);

            }).catch((err) => {
                // console.log('err====', err.response.status);
                if (err?.response?.status === 401) {
                    navigate(-1);
                }
            })


        }

        handleTabClick(0);
        getProjectById();
        return () => {
            isMounted = false;
        };
    }, [project])*/

    const handleDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        })

    }

    let start_date;
    let end_date;
    if (asanaProject?.created_at !== null) {
        start_date = new Date(asanaProject?.created_at);
        start_date = start_date.toLocaleDateString();
    }

    if (asanaProject?.due_date !== null) {
        end_date = new Date(asanaProject?.due_date);
        end_date = end_date.toLocaleDateString();
    }

    return (
        <div className="appboard-grid">
            <div className={`appboard-sub-grid pt-4 `}>

                <div className="main-top-banner">
                    <div className="main-title">
                        <h1>Board<span> View</span></h1>
                    </div>
                    <div className="main-btn-txt"></div>
                </div>
                {
                    loading ?
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} className='mt-5'>
                            <Oval
                                visible={true}
                                height="20"
                                width="20"
                                color="#f37a20"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                            <small>Please wait while the content is loading</small>
                        </div> :

                        <div className={activeTab === 1 ? 'active w-[100%] overflow-x-auto board-tab-scroll ' : ''}>
                            <BoardTab allAssignee={allAssignee} gid=""/>
                        </div>
                }
            </div>
        </div>
    )
};

export default BoardView;
