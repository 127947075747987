import React, {useEffect, useState} from "react";
import {useStateContext} from "../contexts/ContextProvider";
import axios from "axios";
import {Oval} from 'react-loader-spinner'
import {FaChevronUp, FaChevronDown} from 'react-icons/fa';
import Drawer from '@mui/material/Drawer';
import Slide from '@mui/material/Slide';
import {ThreeDots} from 'react-loader-spinner'
import Tooltip from '@mui/material/Tooltip';
import {BsFillFilePdfFill, BsFileTextFill, BsFillPlayBtnFill, BsFileZipFill} from 'react-icons/bs'
import {ImAttachment, ImExit} from 'react-icons/im'
import {TextField, MenuItem, Select, FormControl, InputLabel} from '@material-ui/core';
import {TiTick} from 'react-icons/ti'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const PriorityStatus = ({taskId}) => {
    return (
        <div className="text-center">N/A</div>
    )
}

const Tasks = ({section}) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [tasks, setTasks] = useState([])
    const [task, setTask] = useState([])
    const [loading, setLoading] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [stories, setStories] = useState([]);
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const {user, sessionProject, project} = useStateContext();

    useEffect(() => {
        getAllTasksBySectionId()
    }, [selectedProject]);

    const toggleDrawer = () => {
        setOpen(!open)
    };

    const toggleVisibility = () => {
        setIsExpanded(!isExpanded);
    };

    const getAllTasksBySectionId = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_BASE_URL}get-all-tasks/${section.gid}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setTasks(response.data.data)
            }
            setLoading(false);
        }).catch((err) => {
            console.log('err====', err);
            setLoading(false);
        })
    }

    const getTaskDetails = (gid) => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_BASE_URL}get-task-detail/${gid}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setTask(response.data.data)
            }
            setLoading(false);
        }).catch((err) => {
            console.log('err====', err);
            setLoading(false);
        })
    }

    const getTaskAttachment = (gid) => {
        axios.get(`${process.env.REACT_APP_BASE_URL}get-task-attachment/${gid}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setAttachments(response.data.data)
            }
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    const getTaskStories = (gid) => {
        axios.get(`${process.env.REACT_APP_BASE_URL}get-post-task-stories/${gid}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setStories(response.data.data)
            }
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    const PostTaskStory = (data) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}add-story`, data, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setStories([...stories, response.data.data]);
            }
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    /*const PostTaskStory = async (id, data) => {
        // setLoading(true);
        const ASANA_BEARER_TOKEN = "2/1204614194882962/1206884941289659:6993e942413ab55624207ae4379afb7f"
        try {
            await axios.post(`${process.env.REACT_APP_ASANA_BASE_URL}tasks/${id}/stories`, {data}, {
                headers: {
                    Authorization: `Bearer ${ASANA_BEARER_TOKEN}`,
                }

            })
                .then((res) => {
                    setStories([...stories, res.data.data]);
                    console.log(res);

                })
                .catch((error) => {
                    // setLoading(false);
                    console.log(error);

                })
        } catch (error) {
            // setLoading(false);
            console.log(error);
        }

    }*/

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            task_id: task?.gid,
            text: message
        }
        PostTaskStory(data)
        setMessage('');
    }

    const TaskDetails = (data) => {
        if (data) {
            toggleDrawer();
            setTask(data);
            getTaskDetails(data.gid);
            getTaskAttachment(data.gid);
            getTaskStories(data.gid);
        }
    }

    return (
        <>
            <div className="appboard-grid-project-txt-name">
                <div onClick={toggleVisibility} className="btn_click">
                    {isExpanded ? <FaChevronDown/> : <FaChevronUp/>}
                    <div className="name-title">{section.name}</div>
                </div>

            </div>

            {isExpanded && (
                tasks && tasks.map((task, index) => (
                    <ul key={index} className="appboard-grid-project-description-listing"
                        onClick={() => TaskDetails(task)}>
                        <li className="name_listing">{task?.name || ''}</li>
                        <li className="date_listing">{task?.due_on || ''}</li>
                        <li className="project_listing status"><span
                            className="success">{task?.completed ? 'Completed' : 'Incomplete'}</span></li>
                        <li className="task_listing"><PriorityStatus taskId={task?.gid}/></li>
                        <li className="comment_listing">{task?.notes || ''}</li>
                    </ul>
                ))
            )}

            <Drawer
                anchor='right'
                open={open}
                onClose={toggleDrawer}
            >
                <div className='popup_sidebar'>
                    {!loading && tasks.length !== 0 ?
                        <>
                            <div className='popup-header'>
                                <div className="status_btn">
                                    {task.completed ? 'Completed' : 'To Do'}
                                </div>
                                <div className='closed_btn'>
                                    <div onClick={toggleDrawer}>X</div>
                                </div>
                            </div>
                            <div className=' overflow-y-auto h-[74vh] board-tab-scroll'>
                                <div className='main_popup_section'>
                                    <div className='task_name'>{task.name}</div>

                                    <div className="task_list">
                                        <ul>
                                            <li>Assignee</li>
                                            <li>{task.assignee ? task.assignee.name : 'No Assignee'}</li>
                                        </ul>
                                        <ul>
                                            <li>Due Date</li>
                                            <li>{task.due_on || 'No due date'}</li>
                                        </ul>
                                        <ul>
                                            <li>Projects</li>
                                            <li>
                                                {task.projects && task.projects.length > 0 ?
                                                    task.projects.map((item, index) => (
                                                        <span key={index}>{item.name}</span>
                                                    )) :
                                                    <span>No Project</span>
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {attachments.length !== 0 && (
                                    <div className='file_box_main'>
                                        {attachments.map((item, index) => {
                                            const fileExtension = item.name?.substring(item.name.lastIndexOf('.') + 1)?.toLowerCase();
                                            let icon;
                                            if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'gif') {
                                                icon = <img src={item.view_url} alt={`${item.view_url}`}/>;
                                            } else if (fileExtension === 'pdf') {
                                                icon =
                                                    <div className='file_item'>
                                                        <BsFillFilePdfFill size={35} className='mr-2' color='red'/>
                                                        <p className='file_name'>{item.name}</p>
                                                    </div>;
                                            } else if (fileExtension === 'mp4') {
                                                icon =
                                                    <div
                                                        className='file_item'>
                                                        <BsFillPlayBtnFill size={35} className='mr-2'
                                                                           color='gray'/>
                                                        <p className='file_name'>{item.name}</p>
                                                    </div>;
                                                ;
                                            } else if (fileExtension === 'zip') {
                                                icon = <div
                                                    className='file_item'>
                                                    <BsFileZipFill size={35} className='mr-2' color='gray'/>
                                                    <p className='file_name'>{item.name}</p>
                                                </div>;
                                            } else {
                                                icon = <div
                                                    className='file_item'>
                                                    <BsFileTextFill size={35} className='mr-2' color='blue'/>
                                                    <p className='file_name'>{item.name}</p>
                                                </div>;
                                            }

                                            const date = new Date(item.created_at);
                                            const formattedDate = date.toLocaleDateString();
                                            return (
                                                <div key={index} className='file_box cursor-pointer'>
                                                    {icon}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                                }
                                <div className='comment_section'>
                                    {stories.length !== 0 ? stories.map((item, index) => {
                                        const nameArray = item.created_by?.name.split(' ');
                                        const initials = nameArray.map(word => word.charAt(0).toUpperCase()).join('');
                                        const date = new Date(item.created_at);
                                        const formattedDate = date.toLocaleDateString();

                                        let newText;
                                        let projectName;
                                        let assetId;
                                        const regex = /asset_id=(\d+)/;
                                        const regex2 = /https:\/\/app\.asana\.com\/0\/\d+\/list/;
                                        const regex3 = /https:\/\/app\.asana\.com\/0\/\d+\/\d+/;

                                        if (item.type == 'comment' && item.resource_subtype === 'comment_added') {
                                            const url = item.text.match(regex2);
                                            const url2 = item.text.match(regex3);

                                            task.projects !== undefined ? task.projects.map((item) => {
                                                projectName = item.name;
                                            }) : null

                                            newText = item.text.replace(url, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`)
                                                .replace(/\n/g, '<br/>').replace(url2, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`);
                                        }
                                        if (item.type == 'system' && item.resource_subtype === 'added_to_project') {
                                            const url = item.text.match(regex3);

                                            task.projects !== undefined ? task.projects.map((item) => {
                                                projectName = item.name;
                                            }) : null

                                            newText = item.text.replace(url, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`)
                                                .replace(/\n/g, '<br/>');
                                        }
                                        if (item.type == 'system' && item.resource_subtype === 'assigned' || item.resource_subtype === 'due_date_changed' || item.resource_subtype === 'section_changed') {
                                            const url = item.text.match(regex3);

                                            task.projects !== undefined ? task.projects.map((item) => {
                                                projectName = item.name;
                                            }) : null

                                            newText = item.text.replace(url, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`).replace(/\n/g, '<br/>');
                                        }
                                        if (item.type == 'system' && item.resource_subtype === 'attachment_added') {
                                            const match = item.text.match(regex);
                                            assetId = match ? match[1] : null;


                                        }
                                        if (item.type == 'system' && item.resource_subtype === 'name_changed' || item.resource_subtype === 'notes_changed') {
                                            const url = item.text.match(regex3);

                                            task.projects !== undefined ? task.projects.map((item) => {
                                                projectName = item.name;
                                            }) : null

                                            newText = item.text.replace(url, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`)
                                                .replace(/\n/g, '<br/>');


                                        }

                                        //   console.log(item.created_by.photo);


                                        return (

                                            <div key={index} className="comments_box">
                                                <div className="img_box">
                                                    {
                                                        item.type === 'system' && (item.resource_subtype === 'name_changed' || item.resource_subtype === 'notes_changed' || item.resource_subtype === 'comment_liked') ?
                                                            null :
                                                            item.type === 'system' && item.resource_subtype === 'marked_complete' ?
                                                                <span><TiTick color='black' size={30}/></span> :
                                                                !item.created_by.photo ?
                                                                    <span>{initials}</span> :
                                                                    <img
                                                                        className='comment_img'
                                                                        src={item.created_by.photo.image_27x27}
                                                                        alt='User Photo'
                                                                    />
                                                    }
                                                </div>
                                                <div className="img_txt">
                                                    {item.type == 'system' && item.resource_subtype === 'added_to_project' ?
                                                        <h5 className='name-img'>{item.created_by?.name} created this
                                                            task.</h5> :
                                                        item.type == 'system' && item.resource_subtype === 'comment_liked' ?
                                                            null
                                                            :
                                                            item.type == 'system' && item.resource_subtype === 'attachment_added' ?
                                                                <h5 className='name-img'>{item.created_by?.name}
                                                                    <span>
                                                                    <ImAttachment className='mt-1'
                                                                                  color='#F37A20'
                                                                                  size={15}/> attached
                                                                    </span>
                                                                </h5> :
                                                                item.type == 'system' && item.resource_subtype === 'name_changed' || item.resource_subtype === 'notes_changed' ? null
                                                                    :
                                                                    item.type == 'system' && item.resource_subtype === 'marked_complete' ?
                                                                        <h5 className='name-img'>{item.created_by?.name} marked
                                                                            this task complete</h5>
                                                                        :
                                                                        <h5 className='name-img'>{item.created_by?.name}</h5>
                                                    }
                                                    {item.type == 'system' && item.resource_subtype === 'name_changed' || item.resource_subtype === 'notes_changed' ? null :
                                                        item.type == 'system' && item.resource_subtype === 'comment_liked' ? null
                                                            :
                                                            <p className='date-img'>{formattedDate}</p>}
                                                    {item.type == 'system' && item.resource_subtype === 'attachment_added' ?
                                                        attachments.map((item) => {
                                                            if (item.gid === assetId) {
                                                                const fileExtension = item.name?.substring(item.name.lastIndexOf('.') + 1)?.toLowerCase();
                                                                let icon;
                                                                if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'gif') {
                                                                    icon = <img src={item.view_url}
                                                                                className="rounded-md flex flex-shrink-0"
                                                                                alt={`${item.view_url}`}/>;
                                                                } else if (fileExtension === 'pdf') {
                                                                    icon =
                                                                        <div
                                                                            className=' px-4 h-[80px] justify-center items-center flex gap-2 rounded-md border-1 border-gray-200 '>
                                                                            <BsFillFilePdfFill size={35}
                                                                                               className='mr-2'
                                                                                               color='red'/>
                                                                            <p className='text-[11px] break-all text-[#6d6e6f] '>{item.name}</p>
                                                                        </div>;
                                                                } else if (fileExtension === 'mp4') {
                                                                    icon =
                                                                        <div
                                                                            className=' px-4 h-[80px] justify-center items-center flex gap-2 rounded-md border-1 border-gray-200 '>
                                                                            <BsFillPlayBtnFill size={35}
                                                                                               className='mr-2'
                                                                                               color='gray'/>
                                                                            <p className='text-[11px] break-all text-[#6d6e6f] '>{item.name}</p>
                                                                        </div>;
                                                                    ;
                                                                } else if (fileExtension === 'zip') {
                                                                    icon = <div
                                                                        className=' px-4 h-[80px] justify-center items-center flex gap-2 rounded-md border-1 border-gray-200 '>
                                                                        <BsFileZipFill size={35} className='mr-2'
                                                                                       color='gray'/>
                                                                        <p className='text-[11px] break-all text-[#6d6e6f] '>{item.name}</p>
                                                                    </div>;
                                                                } else {
                                                                    icon = <div
                                                                        className=' px-4 h-[80px] justify-center items-center flex gap-2 rounded-md border-1 border-gray-200 '>
                                                                        <BsFileTextFill size={35} className='mr-2'
                                                                                        color='blue'/>
                                                                        <p className='text-[11px] break-all text-[#6d6e6f] '>{item.name}</p>
                                                                    </div>;
                                                                }
                                                                return (
                                                                    <Tooltip title={
                                                                        <React.Fragment>
                                                                            <p>{item.name}</p>

                                                                            Uploaded at {formattedDate}
                                                                        </React.Fragment>
                                                                    } followCursor>
                                                                        <div key={index}
                                                                             className=' cursor-pointer'>

                                                                            {icon}
                                                                        </div>
                                                                    </Tooltip>
                                                                )
                                                            }
                                                        })
                                                        :
                                                        item.type == 'system' && item.resource_subtype === 'name_changed' || item.resource_subtype === 'notes_changed' ?
                                                            <p className='para_txt'
                                                               dangerouslySetInnerHTML={{__html: newText}}></p>
                                                            :
                                                            <p className='para_txt'
                                                               dangerouslySetInnerHTML={{__html: newText}}></p>

                                                    }
                                                </div>
                                            </div>


                                        )
                                    }) : null
                                    }

                                </div>
                            </div>
                            {
                                user?.asana_token ?
                                    <form onSubmit={(e) => handleSubmit(e)} className="form_boxing">
                                <div className='bg-[#fafafa] px-5 py-4 bottom-0 w-[100%] '>
                                    <div className='flex gap-4  '>
                                        <div className='mt-2'>
                                                <span
                                                    className='bg-yellow-400 border-1 border-yellow-600 rounded-full flex justify-center items-center w-[36px] h-[36px]'>AA</span>
                                        </div>

                                        <div className=' w-[100%] bg-white  '>
                                            <TextField
                                                variant='outlined'
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                                rows={1}
                                                fullWidth
                                                multiline
                                                placeholder='Add a comment'
                                            />
                                        </div>
                                    </div>
                                    <div className='mt-5 ml-[55px]  flex justify-between items-center'>
                                        <div className='flex items-center gap-2'>
                                            <p className='text-[11px] mt-1 text-[#6d6e6f] '>Collaborators</p>
                                            {task?.followers !== null ? task?.followers?.map((item, index) => {

                                                const nameArray = item.name.split(' ');
                                                const initials = nameArray.map(word => word.charAt(0).toUpperCase()).join('');

                                                return (

                                                    item.photo !== null ?

                                                        <div key={index} className='w-[24px] h-[24px] rounded-full'>
                                                            <img className='rounded-full'
                                                                 src={item.photo?.image_21x21}
                                                                 alt={item.photo?.image_21x21}/>
                                                        </div>
                                                        :
                                                        <span key={index}
                                                              className='bg-yellow-400 border-1 border-yellow-600 text-[11px] rounded-full flex justify-center items-center w-[20px] h-[20px]'>{initials}</span>

                                                )
                                            }) : null}
                                        </div>
                                        <div
                                            className='bg-[#F47A1F] p-3 flex justify-center items-center rounded-md'>
                                            <button type='submit' className=' text-white text-[14px]'>Comment
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form> : <a href={`https://app.asana.com/-/oauth_authorize?client_id=${process.env.REACT_APP_ASANA_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ASANA_REDIRECT_URL}&response_type=code&scope=default&state=listview`} className="bg-[#F47A1F] p-3 flex justify-center items-center rounded-md">
                                    Login asana to add comment
                                    </a>
                            }

                        </>
                        :
                        <div className=' justify-center items-center flex h-[100%] w-[100%]'>
                            <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#F37A20"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    }
                </div>
            </Drawer>

        </>
    )
}

const ListView = () => {

    const {user, selectedProject} = useStateContext();
    const {role} = user.user;
    const [sections, setSections] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAllSections();
    }, [selectedProject])

    /*const getAllSections = () => {
        setLoading(true);
        const ASANA_BEARER_TOKEN = "Bearer 2/1204614194882962/1206884941289659:6993e942413ab55624207ae4379afb7f"
        axios.get(`${process.env.REACT_APP_ASANA_BASE_URL}projects/1204756445910735/sections`, {
            headers: {
                Authorization: ASANA_BEARER_TOKEN,
            }
        }).then((response) => {
            setSections(response.data.data);
            setLoading(false);
        }).catch((err) => {
            console.log('err====', err);
            setLoading(false);
        })
    }*/

    const getAllSections = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_BASE_URL}get-asana-project-section/${selectedProject}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setSections(response.data.data)
            }
            setLoading(false);
        }).catch((err) => {
            console.log('err====', err);
            setLoading(false);
        })
    }

    return (
        <div className="appboard-grid">
            <div className={`appboard-sub-grid pt-4 `}>

                <div className="main-top-banner">
                    <div className="main-title">
                        <h1>List<span> View</span></h1>
                    </div>
                    <div className="main-btn-txt"></div>
                </div>
                <div className="list-view-project-box appboard-overview-project-main">
                    <div className='appboard-grid-project-title'>
                        <ul className="appboard-grid-project-listing">
                            <li>Task Name</li>
                            <li>Due Date</li>
                            <li className="text-center">Task Status</li>
                            <li className="text-center">Priority</li>
                            <li className="text-center">Comment</li>
                        </ul>
                    </div>
                    <div className='appboard-grid-project-txt p-0'>
                        {
                            loading ?
                                <div className="mt-2">
                                    <Oval
                                        visible={true}
                                        height="20"
                                        width="20"
                                        color="#f37a20"
                                        ariaLabel="oval-loading"
                                        wrapperStyle={{display: 'flex', justifyContent: 'center'}}
                                        wrapperClass=""
                                    />
                                    <small className="flex justify-center">Please wait while the content is
                                        loading</small>
                                </div> :
                                <div className="appboard-grid-lsiting-boxing">
                                    {
                                        sections && sections.map((section) => {
                                            return (
                                                <Tasks section={section}/>
                                            )
                                        })
                                    }
                                </div>
                        }
                    </div>

                </div>


            </div>
        </div>
    );
};

export default ListView;
