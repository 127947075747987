import React, { useEffect, useState } from "react";

import { useFormik } from 'formik';
import axios from 'axios';
import * as yup from 'yup';
import { TextField } from '@material-ui/core';
import { NavLink } from "react-router-dom";
// import BannerImg from "../../img/ReferralsView.jpg"
import BannerImg from "../../img/ReferralsView_updated.png"
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { CheckListIcon, StarsIcon } from "../../app-constants";
import { useStateContext } from "../../contexts/ContextProvider";
import { Toast, NoRecordFound, Loader } from "../../components/General";
import Spinner from 'react-bootstrap/Spinner';
import { Table } from "react-bootstrap";
import { LocalShippingSharp } from "@mui/icons-material";


const ClientPage = ({ user }) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const getReferrals = async (loader = false) => {

        setLoading(loader);

        await axios.get(`${process.env.REACT_APP_BASE_URL}referal`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((response) => {
            setData(response?.data?.data)
        })
        .catch((err) => {
            console.log('err====', err);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        getReferrals(true);
    }, [])

    const validationSchema = yup.object({
        name: yup.string().required('Name is required'),
        email: yup.string().email('Email must be a valid email').required('Email is required')
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {

            if (values) {
                let payload = {
                    client_id: user.user.id,
                    name: values.name,
                    email: values.email
                }
                submitForm(payload, resetForm)
            }
        },
    });

    const submitForm = async (payload, resetForm) => {
        setBtnLoading(true);

        await axios.post(`${process.env.REACT_APP_BASE_URL}referal`, payload, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((res) => {
            if (res.status === 200) {
                getReferrals();

                Toast.fire({
                    icon: 'success',
                    title: 'Invitation sent successfully!',
                    showConfirmButton: false,
                    timer: 1000
                })

                resetForm({ values: '' })
            }
        })
        .catch((error) => {
            Toast.fire({
                icon: 'error',
                title: error?.response?.data?.errors[0]?.message || 'Something went wrong!',
            })
        })
        .finally(() => {
            setBtnLoading(false);
        });
    }

    return (
        <>
            <div className="main_content_container">
                <Container fluid className="p-0">
                    <Row>
                        <Col xxl={12}>
                            <div className="banner_img">
                                <img src={BannerImg} alt="BannerImg" />
                                <h2>Refer a Friend,<br />
                                    Earn Rewards!</h2>
                            </div>
                            <div className="points_banner">
                                <div className="points_txt">
                                    <div className="points_sec">
                                        <h4>Unlock Limitless Potential with Our Referral Program!</h4>
                                        <p>Refer a friend, colleague, or business partner to our platform, and they'll discover the same comprehensive suite of tools that have revolutionized your workflow. Simply fill out the form below with your referral's Full Name and Email Address, and we'll take care of the rest.</p>
                                    </div>
                                </div>
                                <div className="points_from">
                                    <form onSubmit={formik.handleSubmit}>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Referral Name</Form.Label>

                                            <Form.Control
                                                type="text"
                                                placeholder="Name"
                                                name="name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                                isInvalid={formik.touched.name && !!formik.errors.name}
                                            />


                                            <Form.Control.Feedback type="invalid">
                                                {formik.touched.name && formik.errors.name}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Referral Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Email"
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                isInvalid={formik.touched.email && !!formik.errors.email}
                                            />


                                            <Form.Control.Feedback type="invalid">
                                                {formik.touched.email && formik.errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <div>
                                            <button type="submit" className="btn_submit" >
                                                <div className="btn_submit">

                                                    {btnLoading ? <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    /> : ''}

                                                    <span>
                                                        Submit
                                                    </span>


                                                </div>
                                            </button>

                                        </div>

                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {
                loading ? <Loader /> : (
                    <div className="download_tabs">
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th className="text-center">Referral Name</th>
                                    <th className="text-center">Referral Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.length > 0 ?
                                    data.map((item, index) => {

                                        return (
                                            <tr>
                                                <td className="text-center">{item.name || 'N/A'}</td>
                                                <td className="text-center">{item.email}</td>
                                            </tr>
                                        )

                                    })
                                    : (
                                        <NoRecordFound />
                                    )
                                }
                            </tbody>
                        </Table>
                    </div>
                )
            }


        </>

    )
}


export default ClientPage;
