import React, { useMemo } from 'react';
import { Link } from 'react-router-dom'
import { useStateContext } from '../../contexts/ContextProvider';
import Progress from '../charts/Progress';
import ProjectProgress from '../charts/ProjectProgress';
import Rectangle89 from '../../data/Rectangle89.png'
import { Oval } from 'react-loader-spinner'
import { DateFormatWithTimeStamp } from '../../utils/DateFormatWithSlash'
const AppProject = () => {

    const { table, loading, getAllProjects } = useStateContext();
    const mostRecentProject = table.length > 0 ? table : null;
    console.log(mostRecentProject);
    const goON = () => {
        getAllProjects()
    }

    if (!mostRecentProject) {
        return (
            <div className='flex justify-center items-center h-[100px]' >
                <p className='text-[13px] text-[#344054] font-semibold '>Sync with Asana to view all the Projects & Latest Updates</p></div>
        )
    }

    return (
        <>
            {mostRecentProject &&
                <div className='container-app'  >
                    {
                        mostRecentProject.map(project => {
                            console.log(project);
                            return (
                                <div className='pr-4 pl-[31px] pt-4' >
                                    <div key={project?.gid} className='flex justify-between cursor-pointer'>
                                        <div className=''>
                                            <div className='flex items-start' >
                                                <Link to={`/appboard/project/${project?.gid}`}>
                                                    <div style={{ backgroundColor: `${project.color && project.color !== 'none' ? project.color?.substring(project.color.lastIndexOf('-') + 1) : 'green'}` }} className=' rounded-lg w-[40px] mobile:w-[35px] mobile:h-[35px] small:w-[35px] small:h-[35px] h-[40px] flex justify-center items-center flex-shrink-0 ' >
                                                        <p className='text-white font-bold text-[20px] md:text-[18px] sm:text-[16px] xm:text-[14px]  text-center' >{project.name.charAt(0)}</p>
                                                    </div>
                                                </Link>
                                                {project &&
                                                    <div>
                                                        <div className='pl-4' >
                                                            <p className='text-[#505887] text-[16px] md:text-[14px] sm:text-[12px] xm:text-[10px] font-semibold ' >{project?.name}</p>
                                                            <p
                                                                className='text-[#979797] w-[110vh] md:w-[90vh] sm:w-[70vh] xm:w-[50vh] text-[14px] md:text-[12px] sm:text-[10px] xm:text-[8px] font-medium mt-1'
                                                                dangerouslySetInnerHTML={{
                                                                    __html: project.html_notes == '<body></body>' ? (
                                                                        `
                                                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus minus accusantium sed. Cum quod, inventore porro facilis labore pariatur quia deleniti necessitatibus doloremque assumenda aliquid expedita temporibus laboriosam ipsam dolores.
                                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur laudantium sint ipsa, aliquam ullam obcaecati atque sunt quia, repellendus odio deleniti, minima veritatis iste placeat? Molestiae dignissimos magnam repellendus iure.        
                                                                    `
                                                                    ) : (project.html_notes)
                                                                }}
                                                            >
                                                            </p>
                                                        </div>
                                                        <div className='pl-4 mt-[29px] flex gap-[60px]'>
                                                            <div>
                                                                <p className='text-[#505887] text-[16px]  md:text-[14px] sm:text-[12px] xm:text-[10px] font-semibold '>Project Category</p>
                                                                <p className='text-[#505887] text-[14px]  md:text-[12px] sm:text-[10px] xm:text-[9px] font-medium mt-2'>App</p>
                                                            </div>
                                                            <div className='pl-5'>
                                                                <p className='text-[#505887] text-[16px] md:text-[14px] sm:text-[12px] xm:text-[10px] font-semibold '>Project Started</p>
                                                                <p className='text-[#718EBF] text-[14px] md:text-[12px] sm:text-[10px] xm:text-[9px] font-medium	 mt-2'>
                                                                    {DateFormatWithTimeStamp(project?.created_at)}
                                                                </p>
                                                            </div>
                                                            <div className='pl-5'>
                                                                <p className='text-[#505887] text-[16px] md:text-[14px] sm:text-[12px] xm:text-[10px] font-semibold '>Project Status</p>
                                                                <span className="text-[#F47A1F] text-[14px] md:text-[12px] sm:text-[10px] xm:text-[9px] p-1 text-left bg-[#FFF9F0] rounded-sm">Beta</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className=" bg-[white] border-1 border-[#EDF0F4] m-3 rounded-t-lg p-2 h-[232px] w-[246px] md:h-[200px] md:w-[212px]  sm:h-[180px] sm:w-[200px] xm:h-[170px] xm:w-[180px]">
                                            <span className="text-[#F47A1F] text-[14px] md:text-[12px] sm:text-[10px] xm:text-[9px]  text-left bg-[#FFF9F0] p-1 rounded-sm font-semibold">Project Progress</span>
                                            <div className='m-auto flex justify-center items-center flex-shrink-0 py-6 w-[60%]'>
                                                <ProjectProgress projectData={project?.task?.data} />
                                                {/* <Progress value={0} /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='border-[#EAEAEA] border-1 my-2 w-[99%]' />
                                </div>
                            )
                        })
                    }
                    <div className='flex justify-end p-3 pr-8' >
                        {!loading ?
                            <button className='text-[14px] md:text-[12px] text-[#6d6e6f] font-semibold' onClick={goON} >Show more</button>
                            : <Oval
                                height="40"
                                width="40"
                                radius="9"
                                color="#F37A20"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />}
                    </div>
                </div>

            }
        </>
    )
}

export default AppProject;