import React from 'react';
import Drawer from "@mui/material/Drawer";
import {AssigneeIcon, CrossIcon, DateIcon} from "../../utils/svg";
import {formatMDY} from "../../utils/Helper";
import {formatDrawerDate} from "../../utils/Helper";
import {DotValueIcon} from "../../app-constants";
import {TiTick} from "react-icons/ti";
import {BsFileTextFill, BsFileZipFill, BsFillFilePdfFill, BsFillPlayBtnFill} from "react-icons/bs";
import Tooltip from "@mui/material/Tooltip";
import LogoImg from "../../img/DummyLogo.png";
import {ThreeDots} from "react-loader-spinner";
import CustomIconTitle from "./components/CustomIconTitle";
import {useStateContext} from "../../contexts/ContextProvider";
import TaskAttachments from "../../components/Tasks/TaskAttachments";
import {convertToRequiredDateFormat} from '../../utils/Helper'

function DrawerGrowthBoard({
    loading,
    tasks = [],
    open,
    toggleDrawer,
    handleSubmit,
    setMessage,
    message,
    description,
    stories = [],
    task,
    productionStatus = [],
    taskProgress,
    attachments = [],
    taskPriority
}) {
    const { user, client, project, selectedProject } = useStateContext();
    const { role } = user.user;

    return (
        <Drawer
            open={open}
            anchor={'right'}
            sx={{
                '& .MuiDrawer-paper': {
                    width: 600,
                    backgroundColor: '#fff',
                    color: '#333',
                    padding: 0,
                },
            }}
            onClose={() => toggleDrawer(false)}
        >
            {!loading && tasks.length !== 0 ? (
                <>
                    <div className="header_popup">
                        <div className="project_brief_links">
                            <ul>
                                {/* Add necessary links or other header items */}
                            </ul>
                        </div>
                        <div className="project_brief_closed">
                            <div className="popup_btn" onClick={() => toggleDrawer(false)}><CrossIcon /></div>
                        </div>
                    </div>
                    <div className="scroll_popup growth-popup">
                        <div className="popup_content">
                            <div className="pop_title mb-5">{task.name}</div>
                            <div className="marketing_points">
                                <div className="tags_marketing">
                                    Status
                                    <div className="tags_list">
                                        <ul>
                                                {role === 'client' && task.status === 0 && (
                                                <li className="task_list request">
                                                    <span>Request</span>
                                                </li>
                                                )}
                                                {role === 'client' && task.status === 1 && (
                                                    <li className="task_list review"><span >Request In Review</span></li>
                                                )}
                                                {role !== 'client' && task.status === 1 && (
                                                    <li className="task_list request-received"><span>Request Received</span></li>
                                                )}
                                                {task.status === 2 && (
                                                    <li className="task_list task-progress"><span>Task in Progress</span></li>
                                                )}
                                                {task.status === 3 && (
                                                    <li className="task_list success"><span>Task Completed</span></li>
                                                )}
                                        </ul>
                                    </div>
                                    {/* <div className="tags_list">Priority
                                        <ul>
                                            <li className={`${taskPriority?.name}`}> {taskPriority?.name || '--'}</li>
                                        </ul>
                                    </div> */}
                                </div>
                                <div>Date Started: <span className="date">
                                    {formatMDY(task.start_date) || 'No due date'}
                                    {/* {task.start_date ? (
                                        <CustomIconTitle text={convertToRequiredDateFormat(task.start_date)} icon={<DateIcon />} />
                                    ) : (
                                        <CustomIconTitle text={'No date added'} icon={<DateIcon />} />
                                    )} */}
                                </span></div>
                                <div>Deadline: <span className="date">
                                    {formatMDY(task.end_date) || 'No due date'}
                                    {/* {task.end_date ? (
                                        <CustomIconTitle text={convertToRequiredDateFormat(task.end_date)} icon={<DateIcon />} />
                                    ) : (
                                        <CustomIconTitle text={'No date added'} icon={<DateIcon />} />
                                    )} */}
                                </span></div>
                            </div>
                            
                            <div className="marekting_descr">
                                <div className="pop_title">Description</div>
                                <p>{task?.description ? task.description : 'Description not found'}</p>
                            </div>
                            {task && task.name && (
                                <TaskAttachments task={task} />
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <div className="loading_spinner">
                    <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        visible={true}
                    />
                </div>
            )}
        </Drawer>
    );
}

export default DrawerGrowthBoard;
