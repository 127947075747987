import React from 'react'
import { Oval } from 'react-loader-spinner';
import Swal from 'sweetalert2';


export const Loader = () => (
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }} className='m-10'>
        <Oval
            visible={true}
            height="20"
            width="20"
            color="#F37A20"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
        />
    </div>
);

export const NoRecordFound = () => (
    <tr style={{ textAlign: "center" }}>
        <td colSpan="100%">No record found.</td>
    </tr>

);


export const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});