import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import { useStateContext } from '../contexts/ContextProvider'
import { DateFormatWithSlash } from '../utils/DateFormatWithSlash';
import { getDifferenceFromCurrent } from '../utils/GetTimeStampDifference'
import Avatar5 from '../data/Avatar5.png'
import like from '../data/link.svg'
import DateIcon from '../data/latest-update-date-icon.svg'
import profile from '../data/projectImg1.png'
import message from '../data/message.svg'
import MessageList from './MessageList';
import { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/navigation";
import { ThreeDots } from 'react-loader-spinner'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import { RxCross2 } from 'react-icons/rx'
import DummyImg from "../img/DummyImg.png"

import ReactReadMoreReadLess from 'react-read-more-read-less'
import { Loader } from './General';
import { Col } from 'react-bootstrap';

import {formatMDY} from "../utils/Helper";

const FILE_FORMATS = ['jpg', 'jpeg', 'gif', 'png', 'jpg', 'jpeg', 'gif', 'png']

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'rgba(255, 255, 255, 0.8)',
    border: 'none',
    borderRadius: 2,
    boxShadow: 20,
    p: 4,
};

const WhatsStatus = ({ dt }) => {
    const [selected, setSelected] = useState('btn1');
    const { user, files, table, asanaToken, project, selectedProject } = useStateContext();
    const swiper = useSwiper();
    const [open, setOpen] = useState({ isOpen: false, des: '' });
    // const recentPreject = useMemo(() => orderByRecent(table), [table])
    const [recentPreject, setRecentProject] = useState(null);
    const [projectauthor, setProjectauthor] = useState(null)

    const [statuses, setStatuses] = useState(null);
    const [loading, setLoading] = useState(false)

    const [data, setData] = useState(false);
    const [owner, setOwner] = useState(false);

    const handleClose = () => {
        setOpen({ isopen: false, des: '' })
    }

    useEffect(() => {
        getStatus();
    }, [project])

    const getStatus = async () => {
        if (user && selectedProject) {
            setLoading(true);

            let url = `${process.env.REACT_APP_BASE_URL}get-project-status/${selectedProject}?limit=1`;
            await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res?.data?.data) {
                        // console.log('res', res.data.data)
                        setData(res?.data?.data?.status_updates);
                        setOwner(res?.data?.data?.owner);
                        setLoading(false);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    if (err.response.status == 401) {
                        localStorage.removeItem('tekrevol_user');
                        window.location.href = '/signin';
                    } else {
                        console.log(err);
                    }
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }


    useEffect(() => {
        if (recentPreject) {
            //setLoading(true)
            //setProjectauthor(recentPreject?.current_status?.author)
            /*axios
                .get(`${process.env.REACT_APP_ASANA_BASE_URL}projects/${recentPreject.gid}/project_statuses?opt_fields=created_at,html_text,title,author.name,author.photo,text,modified_at`, {
                    headers: {
                        Authorization: `Bearer ${asanaToken}`,
                    },
                })
                .then((res => {
                    console.log("DATA INSTATUS UPDATE", res.data)
                    setLoading(false)
                    setStatuses(res.data.data)
                }))
                .catch((err) => {
                    console.log("ERR", err)
                    setLoading(false)
                })*/
        }
    }, [recentPreject])

    useEffect(() => {
        // console.log("AUTHOR", projectauthor)
    }, [projectauthor])

    useEffect(() => {
        const projectsSortedByDate = table.sort((elA, elB) => {
            let elA_Date = new Date(elA.created_at)
            let elB_Date = new Date(elB.created_at)

            return Number(elB_Date) - Number(elA_Date)
        })


        // console.log(projectsSortedByDate[0]);
        const hasCurrentStatus = projectsSortedByDate.find(el => el.current_status !== null)
        // console.log("CURRENT STATUSSES", { hasCurrentStatus, table })

        setRecentProject(hasCurrentStatus)

        return () => {
            setRecentProject(null)
        }
    })
    // console.log(statuses)

    const timeAgo = (date) => {
        if (date) {
            const timeAgo = ((new Date() - new Date(date)) / 1000 / 60);
            const formattedTime = timeAgo < 60 ? 'just now' : timeAgo < 60 * 60 ? `${Math.floor(timeAgo / 60)} minutes ago` : timeAgo < 60 * 60 * 24 ? `${Math.floor(timeAgo / 60 / 60)} hours ago` : `${Math.floor(timeAgo / 60 / 60 / 24)} days ago`;
            return formattedTime;
        }
    }

    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const contentRef = useRef(null);


    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };
    

    useEffect(() => {
        if (contentRef.current) {
          const isContentOverflowing = contentRef.current.scrollHeight > contentRef.current.clientHeight;
          setIsOverflowing(isContentOverflowing);
        }
      }, [dt]);

    const HtmlContent = ({ htmlString }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
    };


    // const HtmlContent = ({ htmlString }) => {
    //     return (
    //         <div dangerouslySetInnerHTML={{ __html: htmlString }} />
    //     );
    // };




    return (
        <>

            <div className="appboard-overview-project-main project-update">
                <div className="appboard-grid-project-title">
                    Latest Update
                    {/* <span><img src={DateIcon} /> 5 July 2024</span> */}
                    </div>
                <div className="appboard-grid-project-txt">
                    {loading ? <Loader /> : 
                    (<div className="dashboard-latest-update">
                        <h5>Summary</h5>
                        <div className="update-slider">
                            <Swiper
                                spaceBetween={0}
                                slidesPerView={1}
                                navigation={{
                                    nextEl: '.review-swiper-button-next',
                                    prevEl: '.review-swiper-button-prev'
                                }}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false
                                }}
                                loop={true}
                                modules={[Navigation, Autoplay]}
                                className='mySwiper'
                            >
                                {
                                    data && data.map((dt) => {
                                        return (
                                            <SwiperSlide>
                                                <div className="date">Update of {dt?.created_at ? formatMDY(dt.created_at) : ''}</div>

                                                <div className="txt"> <HtmlContent htmlString={dt?.html_text || 'N/A'} /></div>
                                                {/* <div className={`txt ${isExpanded ? 'expanded' : 'collapsed'}`}>
                                                    <HtmlContent htmlString={dt?.html_text || 'N/A'} />

                                                    {isOverflowing && (
                                                        <span className='show-btn' onClick={toggleExpand}>
                                                            {isExpanded ? 'Show Less' : 'Show More'}
                                                        </span>
                                                    )}

                                                {dt?.html_text && dt.html_text !== 'N/A' && (
                                                    <span className='show-btn' onClick={toggleExpand}>
                                                    {isExpanded ? 'Show Less' : 'Show More'}
                                                    </span>
                                                )}
                                                </div> */}
                                                
                                                <div className="client-info">
                                                    <div className="img-info">
                                                        <img
                                                            src={dt?.author?.photo?.image_128x128 || DummyImg}
                                                            onError={(e) => { e.target.src = DummyImg; }}
                                                            alt={dt?.author?.name || '-'}
                                                        />
                                                    </div>
                                                    <div className="txt-info">
                                                        {dt?.author?.name || ''} <span>{dt.created_at ? formatMDY(dt.created_at) : ''}</span>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                                {/*<SwiperSlide>
                           <div className="date">Update of 12/8/2023</div>
                            <div className="txt">Printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. show more</div>
                           <div className="client-info">
                               <div className="img-info">
                                   <img src={DumyImg} alt="title-here"/>
                               </div>
                               <div className="txt-info">
                                   Hira Abid Khan <span>30 minute ago</span>
                               </div>
                           </div>
                       </SwiperSlide>
                       <SwiperSlide>
                           <div className="date">Update of 15/8/2023</div>
                           <div className="txt">Printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. show more</div>
                           <div className="client-info">
                               <div className="img-info">
                                   <img src={DumyImg} alt="title-here"/>
                               </div>
                               <div className="txt-info">
                                   Adeen Ali <span>60 minute ago</span>
                               </div>
                           </div>
                       </SwiperSlide>*/}

                            </Swiper>
                            <div className="review-swiper-button-next">
                                <div className="btn_swiper">
                                    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.57107 10.4683L1.64209 5.53931L6.57107 0.610329" stroke="#F47A1F" />
                                    </svg>
                                </div>
                            </div>
                            <div className="review-swiper-button-prev">
                                <div className="btn_swiper">
                                    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.57107 10.4683L1.64209 5.53931L6.57107 0.610329" stroke="#F47A1F" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>)
                }

                </div>
            </div>

            {/*<div className='whats-new relative py-[20px] sm:py-[15px] xm:py-[12px] min-h-[228px] sm:min-h-[200px] xm:min-h-[202px]' >*/}
            {/*    <div className='px-[25px] md:px-[20px] sm:px-[20px] xm:px-[20px]  '>*/}
            {/*        <p className='text-[#131313] pl-[5px] text-[20px] md:text-[18px] sm:text-[16px] xm:text-[14px] font-semibold'>Latest Update</p>*/}
            {/*        {*/}
            {/*            loading ? (*/}
            {/*                <div className=' justify-center items-center flex w-[100%]' >*/}
            {/*                    <ThreeDots*/}
            {/*                        height="80"*/}
            {/*                        width="80"*/}
            {/*                        radius="9"*/}
            {/*                        color="#F37A20"*/}
            {/*                        ariaLabel="three-dots-loading"*/}
            {/*                        wrapperStyle={{}}*/}
            {/*                        wrapperClassName=""*/}
            {/*                        visible={true}*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            ) : (*/}
            {/*                <Swiper navigation={{*/}
            {/*                    nextEl: '.review-swiper-button-next',*/}
            {/*                    prevEl: '.review-swiper-button-prev',*/}
            {/*                }} modules={[Navigation]} className='mySwiper'  >*/}
            {/*                    {statuses && statuses.length !== 0 ? statuses.map((item, index) => {*/}
            {/*                        return (*/}
            {/*                            <SwiperSlide key={index} >*/}
            {/*                                <div className='mt-3'>*/}
            {/*                                    <div className='relative bg-[#FFFFFF] border-1 border-[#EDF0F4] rounded-md p-[13px] px-[25px]'>*/}
            {/*                                        {*/}
            {/*                                            <>*/}
            {/*                                                <div className='flex justify-between pb-[5px]' >*/}
            {/*                                                    <p className='text-[#F47A1F] font-medium text-[20px] md:text-[18px] sm:text-[16px] xm:text-[14px]	'>{item.title}</p>*/}
            {/*                                                    <p className='text-[#505887] font-medium text-[12px] md:text-[10px] sm:text-[8px] xm:text-[7px] leading-[25px]'>Updated of {' '}*/}
            {/*                                                        {DateFormatWithSlash(item.modified_at)}*/}
            {/*                                                    </p>*/}
            {/*                                                </div>*/}
            {/*                                                <div className='h-[35px] overflow-auto' >*/}
            {/*                                                    {item.text.length > 40 ? (<p className='text-[#344054] lg:text-[13px] md:text-[12px] sm:text-[10px] xm:text-[8px]'>*/}
            {/*                                                        /!* {<ReactReadMoreReadLess*/}
            {/*                                                            charLimit={50}*/}
            {/*                                                            readMoreText={"Read more ▼"}*/}
            {/*                                                            readLessText={"Read less ▲"}*/}
            {/*                                                            readMoreStyle={{ color: '#F5822C', cursor: 'pointer' }}*/}
            {/*                                                            readLessStyle={{ color: '#7ACB4A', cursor: 'pointer' }}*/}
            {/*                                                        > *!/*/}
            {/*                                                        {item.text.substring(0, 40 - 3)}*/}
            {/*                                                        <span*/}
            {/*                                                            className={`text-[${open.isOpen ? '#7ACB4A' : '#F5822C'}] cursor-pointer`}*/}
            {/*                                                            onClick={() => setOpen({ isOpen: true, des: item.text })}*/}
            {/*                                                        > ...{open.isOpen ? 'Read less ▲' : 'Read more ▼'}</span>*/}
            {/*                                                    </p>) : (*/}
            {/*                                                        <p className='text-[#344054] lg:text-[13px] md:text-[12px] sm:text-[10px] xm:text-[8px]'>*/}
            {/*                                                            {item.text}*/}
            {/*                                                        </p>*/}
            {/*                                                    )*/}
            {/*                                                    }*/}
            {/*                                                    /!* </ReactReadMoreReadLess>} *!/*/}
            {/*                                                </div>*/}
            {/*                                            </>*/}
            {/*                                        }*/}
            {/*                                        {*/}
            {/*                                            <div className='flex items-center mt-[14px] pl-[5px]'>*/}
            {/*                                                <div className='grow flex items-center gap-[5px]'>*/}
            {/*                                                    <div className={`w-[28px] h-[28px] rounded-full bg-[#58A182] flex justify-center items-center`}>*/}
            {/*                                                        {item.author?.photo ?*/}
            {/*                                                            <div className=' rounded-full w-[50px] h-[50px] md:w-[45px] md:h-[45px] sm:w-[40px] sm:h-[40px] xm:w-[35px] xm:h-[35px] mb-1  flex justify-center items-center flex-shrink-0 ' >*/}
            {/*                                                                <img className='rounded-full' src={item.author?.photo?.image_36x36} alt={item.author?.photo?.image_36x36} />*/}
            {/*                                                            </div>*/}
            {/*                                                            :*/}
            {/*                                                            <p className='text-center text-[white] font-medium text-[14px] md:text-[12px] sm:text-[10px] xm:text-[8px]'>{*/}
            {/*                                                                item.author?.name.charAt(0)*/}
            {/*                                                            }</p>*/}
            {/*                                                        }*/}
            {/*                                                    </div>*/}
            {/*                                                    <div className='ml-2'>*/}
            {/*                                                        <p className='text-[#F37A20] font-medium text-[12px] md:text-[10px] sm:text-[8px] xm:text-[7px]'>{item.author?.name}</p>*/}
            {/*                                                        <p className='text-[#505887] font-medium text-[9px] md:text-[8px] sm:text-[7px] xm:text-[6px]'>{getDifferenceFromCurrent(item.modified_at)} ago.</p>*/}

            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                                /!* <div className='pr-3'>*/}
            {/*                            <img src={like} />*/}
            {/*                        </div> *!/*/}
            {/*                                            </div>*/}
            {/*                                        }*/}

            {/*                                    </div>*/}
            {/*                                    <div className='mt-[-2px] bg-[#FFFFFF] border-1 border-[#EDF0F4] w-[97%] m-auto h-[10px] rounded-b-md'></div>*/}

            {/*                                </div>*/}
            {/*                            </SwiperSlide>*/}
            {/*                        )*/}
            {/*                    }) : <div className='flex justify-center items-center h-[100px]' >*/}
            {/*                        <p className='text-[13px] text-[#344054] font-semibold '>Sync with Asana to view all the latest updates (forthcoming module)</p></div>}*/}
            {/*                </Swiper>*/}

            {/*            )*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*    <div onClick={() => swiper.slideNext()} className="swiper-button-next cursor-pointer icon-arrow-long-right review-swiper-button-next">*/}

            {/*    </div>*/}
            {/*    <div onClick={() => swiper.slidePrev()} className="swiper-button-prev cursor-pointer icon-arrow-long-left review-swiper-button-prev">*/}

            {/*    </div>*/}
            {/*    /!* <div className='p-5 flex items-center'>*/}
            {/*        <img src={message} />*/}
            {/*        <p className='ml-2 text-[#3F6AC4] lg:text-[14px] md:text-[12px] font-semibold'>Send message to members</p>*/}
            {/*    </div> *!/*/}
            {/*    /!* {*/}
            {/*        statuses && <MessageList statuses={statuses} author={projectauthor} />*/}

            {/*    } *!/*/}

            {/*    <Modal*/}
            {/*        aria-labelledby="transition-modal-title"*/}
            {/*        aria-describedby="transition-modal-description"*/}
            {/*        open={open.isOpen}*/}
            {/*        onClose={handleClose}*/}
            {/*        closeAfterTransition*/}
            {/*        slots={{ backdrop: Backdrop }}*/}
            {/*        slotProps={{*/}
            {/*            backdrop: {*/}
            {/*                timeout: 500,*/}
            {/*            },*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <Fade in={open.isOpen}>*/}
            {/*            <Box sx={style}>*/}
            {/*                <Typography id="transition-modal-description" sx={{ mt: 2 }}>*/}
            {/*                    <div style={{ whiteSpace: "pre-line" }}>*/}
            {/*                        {open.des}*/}
            {/*                    </div>*/}
            {/*                </Typography>*/}
            {/*                <div onClick={() => setOpen({ isOpen: false, des: null })} className="bg-[#F37A20] rounded-full cursor-pointer p-4 absolute right-[-30px] top-[-30px] " >*/}
            {/*                    <RxCross2 size={30} color="white" />*/}
            {/*                </div>*/}
            {/*            </Box>*/}
            {/*        </Fade>*/}
            {/*    </Modal>*/}
            {/*</div>*/}
        </>
    )
}

const orderByRecent = (data, fetchData) => {
    console.log("DATA", data)
    const sorted = data.sort((elA, elB) => {
        let elA_Date = new Date(elA.created_at)
        let elB_Date = new Date(elB.created_at)

        return Number(elB_Date) - Number(elA_Date)
    })

    const hasCurrentStatus = sorted.find(el => el.current_status !== null)
    if (hasCurrentStatus == undefined) {
        fetchData()
    }

    // console.log("HAS CURRENT", hasCurrentStatus)
    return hasCurrentStatus
}

export default WhatsStatus;