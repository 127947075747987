const S3_BUCKET = 'tek-crm';
const REGION = 'us-east-1';
const ACCESS_KEY = 'AKIAUPLSLQUZCEBB22HJ';
const SECRET_ACCESS_KEY = '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';

export const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
}