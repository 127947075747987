import React, {useEffect, useState} from "react";
import {useStateContext} from "../contexts/ContextProvider";
import {Navigate} from "react-router-dom";
import axios from "axios";
import {BsFillFilePdfFill, BsFileTextFill, BsFillPlayBtnFill, BsFileZipFill} from 'react-icons/bs'
import {Oval} from 'react-loader-spinner'

const AttachmentCard = ({attachments}) => {
    useEffect(() => {
        console.log('=====', attachments);
    }, []);

    function bytesToMB(bytes) {
        if (bytes === 0) return '0 MB';

        const megabytes = bytes / (1024 * 1024);
        return megabytes.toFixed(2) + ' MB';
    }

    return (
        <div className="project_files">
            {attachments?.map((attachment, index) => {
                const fileExtension = attachment.name?.substring(attachment.name.lastIndexOf('.') + 1)?.toLowerCase();
                let icon;

                if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'gif') {
                    icon = (
                        <div className='img_file'>
                            <img
                                src={attachment.view_url} alt={attachment.name}
                            />
                        </div>
                    );
                } else if (fileExtension === 'pdf') {
                    icon = (
                        <div className='img_file'>
                            <BsFillFilePdfFill size={35} color='red'/>
                        </div>
                    );
                } else if (fileExtension === 'mp4') {
                    icon = (
                        <div className='img_file'>
                            <BsFillPlayBtnFill size={35} color='gray'/>
                        </div>
                    );
                } else if (fileExtension === 'zip') {
                    icon = (
                        <div className='img_file'>
                            <BsFileZipFill size={35} color='gray'/>
                        </div>
                    );
                } else {
                    icon = (
                        <div className='img_file'>
                            <BsFileTextFill size={35} color='blue'/>
                        </div>
                    );
                }

                return (

                    <div key={index} className="project_file_list">
                        <div className="project_files_img">
                            {icon}
                        </div>
                        <div className="project_files_txt">
                            <ul className="top_content">
                                <li>{attachment?.name && (attachment.name.length > 50 ? `${attachment.name.substring(0, 50)}...` : attachment.name)}
                                    <span>{attachment?.created_at}</span></li>
                                <li>{attachment?.size ? bytesToMB(attachment.size) : ''} <span><a
                                    href={attachment?.download_url} target="_blank" download>Download</a></span></li>
                            </ul>
                        </div>
                    </div>

                );
            })}
        </div>
    );
};

const Files = () => {
    const { user, project, sessionProject } = useStateContext();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [nextPage, setNextPage] = useState("");
    const [offset, setOffset] = useState("");

    useEffect(() => {
        if(offset != nextPage){
            setData([]);
        }
        getAttachments();
    }, [project, offset]);

    const getAttachments = () => {
        setLoading(true);
        if (user) {
            let url = `${process.env.REACT_APP_BASE_URL}get-asana-files/${sessionProject}?limit=5&offset=${offset}`;
            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    setNextPage(res?.data?.data?.next_page);
                    // Append new data to existing data
                    setData(prevData => [...prevData, ...res.data?.data?.data]);
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        localStorage.removeItem('tekrevol_user');
                        window.location.href = '/signin';
                    } else {
                        console.log(err);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const loadMore = () => {
        if (nextPage) {
            setOffset(nextPage);
        }
    };

    return (
        <div className="appboard-grid bg-[#f6f6f6]">
            <div className={`appboard-sub-grid pt-4 `}>
                   <div className="main-top-banner">
                    <div className="main-title">
                        <h1>App <span> Files</span></h1>
                    </div>
                    <div className="main-btn-txt"></div>
                </div>
                <div className="rounded-t-[15px] mb-[62px] md:mb-[30px]">
                    {
                        !loading && data.length <= 0 &&
                        <div className=" bg-[white] pt-[20px] pl-[31px] pb-[23px] h-[100%] xm:pb-[15px] xm:pt-[15px]">
                            No attachment exist
                        </div>
                    }
                    {
                        loading ?
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }} className='mt-5'>
                                <Oval
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#f37a20"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                                <small>Please wait while the content is loading</small>
                            </div> :
                            data && data.length > 0 && <AttachmentCard attachments={data} />
                    }
                </div>
                {
                    nextPage && <div className="appboard_grid_project_button flex justify-center p-5"
                        onClick={loadMore}>
                        <button>Load More</button>
                    </div>
                }
            </div>
        </div>
    );
};

export default Files;
