import React, { useEffect, useState } from "react";
import { useFormik } from 'formik';
import axios from 'axios';
import { Loader } from "../General";
import { Table } from "react-bootstrap";
import { Toast, NoRecordFound } from "../../components/General";


const ManagerPage = ({ user }) => {
    const [loading, setLoading] = useState();
    const [data, setData] = useState();

    useEffect(() => {
        getReferrals();
    }, [])

    const getReferrals = async () => {

        setLoading(true);

        await axios.get(`${process.env.REACT_APP_BASE_URL}referal`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            setData(response?.data?.data)
        })
            .catch((err) => {
                console.log('err====', err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <div className="download_tabs">
                <Table responsive>
                    <thead>
                        <tr>

                            <th className="text-center">Name</th>
                            <th className="text-center">Email</th>
                            <th className="text-center">Reffered By</th>
                            <th className="text-center">Reffered By Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.length > 0 ?
                            data.map((item, index) => {

                                return (
                                    <tr>

                                        <td className="text-center">{item.name || 'N/A'}</td>
                                        <td className="text-center">{item.email}</td>
                                        <td className="text-center">{`${item?.user?.first_name} ${item?.user?.last_name}`}</td>
                                        <td className="text-center">{item?.user?.email}</td>
                                    </tr>
                                )

                            })
                            : (
                                <NoRecordFound />
                            )
                        }
                    </tbody>
                </Table>
            </div>
        </>
    );
}

export default ManagerPage;
