import React, { useState, useEffect } from 'react';
import { Modal, Table } from "react-bootstrap";
import DOC from "../../../img/files/DOC_01.svg";
import IMG01 from "../../../img/img1.jpeg";
import { DeletedIcon, DownloadIcon, LinksIcon, ViewIcon } from "../../../app-constants";
import PDF from "../../../img/files/PDF_01.svg";
import IMG02 from "../../../img/img2.jpeg";
import JPG from "../../../img/files/JPG_01.jpg";
import IMG03 from "../../../img/img3.jpeg";
import XLS from "../../../img/files/XLS_01.svg";
import IMG04 from "../../../img/img4.jpeg";
import ModalView from "./ModalView";
import axios from 'axios';
import { useStateContext } from "../../../contexts/ContextProvider";
import link from '../../../data/link1.svg';
import { MdDelete } from 'react-icons/md';
import { RiEditBoxFill } from 'react-icons/ri';
import Swal from 'sweetalert2';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useFormik } from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { TextField, MenuItem, InputLabel, FormControl, Select } from '@material-ui/core';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Slide from '@mui/material/Slide';
import { Loader, NoRecordFound } from '../../../components/General';
import {getOrdinalSuffix} from "../../../utils/Helper";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const validationSchema = yup.object({
    title: yup.string().required("Meeting Title is required"),
    agenda: yup.string().min(6, "Too Short!").max(70, "Too Long!").required("Meeting Agenda is required"),
    time: yup.date().required("Meeting Time is required"),
    date: yup.string().required("Meeting Date is required"),
    project_type: yup.string().required("Project Type is required"),
    client_id: yup.string().required("Client Name is required"),
    status: yup.string().required("Meeting Status is required"),
});

function UpComingClient({ meeting, loader }) {

    const { user, client, table, getAllMeetings, selectedProject } = useStateContext();
    const { role } = user.user;
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [editValues, setEditValues] = useState(false);

    const [users, setUsers] = useState([]);


    const formik = useFormik({
        initialValues: {
            title: "",
            agenda: "",
            client_id: "",
            date: null,
            time: null,
            project_type: "",
            status: "",
            meeting_link: "",
        },
        validationSchema: validationSchema,
        enableReinitialize: true, // This allows the form to reinitialize with new values
        onSubmit: (values, { resetForm }) => {
            handleClose();
            EditMeeting();
            resetForm();
        },
    });

    // function convertToPST(utcDateString) {

    //     // Create a Date object from the UTC date string
    //     const utcDate = new Date(utcDateString);

    //     // Convert to Pakistan Standard Time (GMT+5)
    //     const pstDate = new Date(utcDate);
    //     pstDate.setHours(pstDate.getHours()); // Add 5 hours for Pakistan time

    //     // Format the date and time
    //     const options = {
    //         day: '2-digit',
    //         month: '2-digit',
    //         year: 'numeric',
    //         hour: '2-digit',
    //         minute: '2-digit',
    //         hour12: true
    //     };
    //     const pstFormattedDateTime = pstDate.toLocaleString('en-US', options);

    //     // Return the formatted PST date and time
    //     return pstFormattedDateTime;
    // }

    function convertToPST(dateString) {
        // Create a Date object from the ISO 8601 formatted string
        const date = new Date(dateString);
      
        // Get the month (0-indexed) and add 1 for human-readable format (1-12)
        const month = date.getMonth() + 1;
        const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
      
        // Get day of the month (1-31)
        const day = date.getDate();
      
        // Get hours (0-23) with 12-hour format and leading zero for single digits
        const hours = date.getHours() % 12 || 12;
        const hoursFormatted = hours.toString().padStart(2, '0');
      
        // Get minutes (0-59) with leading zero for single digits
        const minutes = date.getMinutes().toString().padStart(2, '0');
      
        // Get AM/PM indicator
        const amPm = date.getHours() >= 12 ? 'PM' : 'AM';
      
        // Format the desired output
        return `${monthName} ${day}${getOrdinalSuffix(day)} ${date.getFullYear()}, ${hoursFormatted}:${minutes} ${amPm}`;
    }
  

    useEffect(() => {
        setLoading(loader);
    }, [loader])

    useEffect(() => {
        getAllUsers();
    }, [])

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteMeeting(id)
            }
        })
    }

    const getAllUsers = () => {
        setLoading(true);
        // axios.get(`${process.env.REACT_APP_BASE_URL}get_clients_by_user/${user.user.id}`,{
        axios.get(`${process.env.REACT_APP_BASE_URL}get_clients_by_project/${selectedProject}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((response) => {
            setUsers(response.data.data);
        })
        .catch((err) => {
            console.log('err====', err);
        })
        .finally(() => {
            setLoading(false);
        })
        // console.log(table);
    }

    const handleClickOpen = (item) => {
        setEditValues(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const EditMeeting = async () => {
        // setLoading(true);
        let date = new Date(formik.values?.date).toISOString().split("T")[0];
        const time = new Date(formik.values?.time).toISOString().split("T")[1];

        const data = {
            "name": formik.values.title,
            "description": formik.values.agenda,
            "date_time": date + " " + time,
            "user_id": user.user.id,
            "project_type": editValues.project_type,
            "status": formik.values.status === 'Done' ? 1 : 0,
            "meeting_link": formik.values.meeting_link,

            "priority": 0,
        }

        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}meeting/${editValues.id}`, { ...data, id: editValues.id }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        getAllMeetings(selectedProject);
                        setLoading(false);
                        Swal.fire(
                            {
                                icon: 'success',
                                title: 'Meeting Updated successfully!',
                                showConfirmButton: false,
                                timer: 1000
                            }
                        )
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1000
                    })
                })
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };


    const deleteMeeting = async (id) => {
        setLoading(true)
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}meeting/${id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        getAllMeetings(selectedProject);
                        setLoading(false);
                        Swal.fire(
                            'Deleted!',
                            'Meeting has been deleted.',
                            'success'
                        )
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1000
                    })
                })
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }


    useEffect(() => {
        formik.setValues({
            // agenda: editValues.name || '',
            // date: dayjs(editValues.date_time) || '',
            // time: dayjs(editValues.date_time) || '',
            // client: editValues?.client?.first_name + " " + editValues?.client?.last_name || '',
            // client_id: editValues?.client_id,
            // status: editValues?.status === 0 ? 'To be held' : 'Done',
            // desc: editValues?.description || '',
            // meeting_link: editValues?.meeting_link,
            // recording_link: editValues?.recording_link,


            title: editValues?.name,
            agenda: editValues?.description,
            client_id: editValues?.client_id,
            date: dayjs(editValues.date_time) || '',
            time: dayjs(editValues.date_time) || '',
            project_type: editValues?.project_type,
            status: editValues?.status === 0 ? 'To be held' : 'Done',
            meeting_link: editValues?.meeting_link,
        });
    }, [editValues]);

    const [show, setShow] = useState(false);

    //const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if(loading){
        return <Loader />
    }

    return (
        <>
            <div className="meeting_tabs m-3">
                <Table responsive>
                    <thead>
                        <tr>
                            <th className="text-center">
                                Project Manager
                            </th>
                            <th className="text-center">Date & Time</th>
                            <th className="text-center">Meeting Title</th>
                            <th className="text-center">Meeting Link</th>
                            <th className="text-center">Recording Link</th>
                            <th className="text-center">Meeting Status</th>
                            {/* <th className="text-center">Recording</th> */}
                            {role !== 'client' ?
                                <th className="text-center">Action</th>
                                : null
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {meeting && meeting?.length > 0 ?
                            meeting?.map((item, index) => {
                                let project_name;
                                if (item?.manual_project) {
                                    project_name = item.manual_project.name;
                                } else {
                                    // console.log(item);
                                    const selectedProject = table.find(asana => `${asana.gid}` === item.asana_project_id);
                                    if (selectedProject) {
                                        project_name = selectedProject.name;
                                    }

                                }

                                return (
                                    <tr key={index}>
                                        <td className="text-center">
                                            {/* {project_name} */}

                                            {item?.user?.first_name} {item?.user?.last_name}
                                        </td>
                                        <td className="text-center">
                                            {convertToPST(item.date_time)}
                                        </td>
                                        <td className="text-center">
                                            {item.name}
                                        </td>
                                        <td className="text-center link_icon">
                                            {
                                                item?.meeting_link ?
                                                    <a target="_blank" href={item.meeting_link}>
                                                        {LinksIcon}
                                                    </a> :
                                                    <p className='mb-0'>N/A</p>
                                            }
                                        </td>
                                        <td className="text-center link_icon">
                                            {
                                                item?.recording_link ?
                                                    <a target="_blank" href={item.recording_link}>
                                                        {LinksIcon}
                                                    </a> :
                                                    <p className='mb-0'>N/A</p>
                                            }
                                        </td>
                                        <td>
                                            <div className="status_info done">
                                                {item.status === 0 ? 'To Be Held' : 'Done'}
                                            </div>

                                        </td>

                                        {/* <td className="text-center">
                                            {
                                                item?.recording_link ?
                                                    <a target="_blank" href={item?.recording_link}>
                                                        {LinksIcon}
                                                    </a> :
                                                    <p>N/A</p>
                                            }
                                        </td> */}

                                        {role !== 'client' ?
                                            <td className="text-center">
                                                <div className="btn_flex">
                                                    <RiEditBoxFill className='cursor-pointer' onClick={() => handleClickOpen(item)} color="orange" size={28} />
                                                    <MdDelete className='cursor-pointer' onClick={() => handleDelete(item.id)} color="red" size={28} />
                                                </div>
                                            </td>
                                            : null
                                        }
                                    </tr>
                                )
                            })
                            : <NoRecordFound />
                        }
                    </tbody>
                </Table>
            </div>
            {/* <Modal show={show} onHide={handleClose}>
                <ModalView />
            </Modal> */}

            <Dialog
                open={open}
                TransitionComponent={Transition}
                // keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Update a Meeting"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit} className='space-y-6 '>

                        <TextField
                            className="w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%] "
                            id="title"
                            name="title"
                            label="Meeting Title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            helperText={formik.touched.title && formik.errors.title}
                            InputLabelProps={{ shrink: true }}
                        />

                        <TextField
                            className='w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%] '
                            id="agenda"
                            name="agenda"
                            label="Meeting Agenda"
                            value={formik.values.agenda}
                            onChange={formik.handleChange}
                            error={formik.touched.agenda && Boolean(formik.errors.agenda)}
                            helperText={formik.touched.agenda && formik.errors.agenda}
                            InputLabelProps={{ shrink: true }}
                        />



                        <div className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                            <FormControl fullWidth error={formik.touched.client && Boolean(formik.errors.client)}>
                                <InputLabel id="demo-simple-select-label">Client</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    name="client_id"
                                    label="Client"
                                    id="client_id"
                                    value={formik.values.client_id} // Ensure it's a single value
                                    helperText={formik.touched.client_id && formik.errors.client_id}
                                    onChange={(event) => {
                                        formik.setFieldValue('client_id', event.target.value);
                                    }}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                >
                                    {users?.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.first_name} {item.last_name}
                                        </MenuItem>
                                    ))}
                                </Select>

                                {formik.touched.client_id && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.client_id}</div>}

                                {/* <FormHelperText>{formik.touched.client && formik.errors.client}</FormHelperText> */}
                            </FormControl>
                        </div>

                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <FormControl className="custom-input" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DateTimePicker"]}>
                                        <DatePicker
                                            name="date"
                                            id="date"
                                            inputFormat="E MMM dd yyyy "
                                            label="Meeting Date"
                                            value={formik.values.date}
                                            onChange={(value) => formik.setFieldValue("date", value)}
                                        />
                                        {formik.touched.date && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.date}</div>}

                                        <TimePicker
                                            fullWidth
                                            className="w-[100%]"
                                            name="time"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="time"
                                            // inputFormat="E MMM dd yyyy HH:MM:SS O"
                                            label="Meeting Time"
                                            value={formik.values.time}
                                            onChange={(value) => formik.setFieldValue("time", value)}
                                            error={formik.touched.time && Boolean(formik.errors.time)}
                                        />
                                        {formik.touched.time && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.time}</div>}

                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                        </div>
                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>

                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Meeting Status</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    name="status"
                                    label="Meeting Status"
                                    id="status"
                                    value={formik.values.status}
                                    onChange={formik.handleChange}
                                    error={formik.touched.status && Boolean(formik.errors.status)}
                                    helperText={formik.touched.status && formik.errors.status}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    <MenuItem value="To be held">To Be Held</MenuItem>
                                    <MenuItem value="Done">Done</MenuItem>
                                </Select>
                            </FormControl>

                        </div>
                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>

                            <TextField
                                className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                id="meeting_link"
                                name="meeting_link"
                                label="Meeting Link"
                                value={formik.values.meeting_link}
                                onChange={formik.handleChange}
                                error={formik.touched.meeting_link && Boolean(formik.errors.meeting_link)}
                                helperText={formik.touched.meeting_link && formik.errors.meeting_link}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        {/* <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>

                            <TextField
                                className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                id="recording_link"
                                name="recording_link"
                                label="Recording Link"
                                value={formik.values.recording_link}
                                onChange={formik.handleChange}
                                error={formik.touched.recording_link && Boolean(formik.errors.recording_link)}
                                helperText={formik.touched.recording_link && formik.errors.recording_link}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div> */}

                        <div className='flex justify-end mt-3'>
                            <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white'
                                variant="contained" fullWidth type="submit">
                                Save
                            </button>
                        </div>
                    </form>
                </DialogContent>

            </Dialog>
        </>

    );
}

export default UpComingClient;