import React, { createContext, useContext, useState, useEffect } from 'react';


import axiosInstance from '../axios/axiosInstance';
import axios from 'axios';

const StateContext = createContext();

const initialState = {
    chat: false,
    cart: false,
    userProfile: false,
    notification: false,
};


export const ContextProvider = ({ children }) => {

    const sessionProject = sessionStorage.getItem('session_project');

    const [activeMenu, setActiveMenu] = useState(true);
    const [tag, setTag] = useState('');
    const [screenSize, setScreenSize] = useState(undefined);
    const [isOpen, setIsOpen] = useState(true)
    const [meeting, setMeeting] = useState([])
    const [benefits, setBenefits] = useState([])
    const [invoices, setInvoices] = useState([])
    const [client, setClient] = useState([]);
    const [project, setProject] = useState('');
    const [userprofile, setUserprofile] = useState(null)
    const [table, setTable] = useState([])

    const [user, setUser] = useState(() => {
        const storedUser = localStorage.getItem("tekrevol_user");
        return storedUser ? JSON.parse(storedUser) : null;
    });

    const [role, setRole] = useState('');
    const [loading, setLoading] = useState(false);
   

    const [manualProjects, setManualProjects] = useState([]);

    const [selectedProject, setSelectedProject] = useState(() => {
        if (sessionProject) {
            return sessionProject;
        }

        return null;
    });

    const [dashboardData, setDashboardData] = useState('');
    const [sessionProjectDetail, setSessionProjectDetail] = useState([]);
    const [projectProgress, setProjectProgress] = useState('');
    const [count, setCount] = useState({});


    const hamBurger = () => {

        setIsOpen(!isOpen)
        if (isOpen) {
            setTag('fixed left-0  z-50  duration-700')
        } else {
            setTag('fixed left-[-100%] duration-700 ')
        }

    }

    const getNotificationCount = async () => {
        if(user){

            await axios.get(`${process.env.REACT_APP_BASE_URL}get_notification_count/2`, {
            // axios.get(`${process.env.REACT_APP_BASE_URL}get_notification_count/${user.user.id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {
                setCount(response.data.data);
            }).catch((err) => {
                console.log('err====', err);
            })
        }
    };


    const getDashboard = async (project_id) => {
        try {
            const response = await axios
                .get(`${process.env.REACT_APP_BASE_URL}client_dashboard?project_id=${project_id}`, {
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    }
                });

            setDashboardData(response?.data?.data);
        } catch (error) {
            console.error("Dashboard ERROR", error)
        }
    }

    const getSessionProjectDetails = () => {
        if (user) {
            let url = `${process.env.REACT_APP_BASE_URL}manual-project/${sessionProject}`;
            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    setSessionProjectDetail(res?.data?.data)
                })
                .catch(err => {

                    // console.log(err);
                    if (err?.response?.status == 401) {
                        localStorage.removeItem('tekrevol_user');
                        window.location.href = '/signin';
                    } else {
                        console.log(err);
                    }
                })
        }
    }

    // const getprojectProgress = async (project_id) => {
    //     try {
    //         const response = await axios
    //             .get(`${process.env.REACT_APP_BASE_URL}get-project-progress/${project_id}`, {
    //                 headers: {
    //                     'Authorization': `Bearer ${user.token}`
    //                 }
    //             })
    //             setProjectProgress(response?.data);
    //     } catch (error) {
    //         console.error("Dashboard ERROR", error)
    //     }
    // }

    const getAllManualProjects = async () => {
        let url;

        if (user) {

            if (user.user.role === 'admin') {
                url = `manual-project?all=1`
            } else if (user.user.role === 'client') {
                url = `manual-project?project_by_client=${user.user.id}`
            } else {
                url = `manual-project?business_dev=${user.user.id}`
            }

            await axiosInstance.get(url)
                .then((response) => {
                    if (response?.data?.length > 0) {
                        setManualProjects(response?.data);

                        if (user && !sessionProject) {
                            setSelectedProject(response?.data[0].id);

                            // console.log("SetSessionProject");
                            // console.log(response?.data[0].id);

                            sessionStorage.setItem('session_project', response?.data[0].id);
                        }
                    }
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                })       
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const setDefaultProject = () => {
        let url;
        const sessionProject = sessionStorage.getItem('session_project');
        if (user && !sessionProject) {
            if (user.user.role === 'admin') {
                url = `${process.env.REACT_APP_BASE_URL}manual-project?all=1`
            } else if (user.user.role === 'client') {
                url = `${process.env.REACT_APP_BASE_URL}manual-project?project_by_client=${user.user.id}`
            } else {
                url = `${process.env.REACT_APP_BASE_URL}manual-project?business_dev=${user.user.id}`
            }
            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res?.data?.data.length > 0) {
                        sessionStorage.setItem('session_project', res?.data?.data[0].id);
                        setProject(res?.data?.data[0].id);
                    }
                })
                .catch(err => {
                    if (err.response.status == 401) {
                        localStorage.removeItem('tekrevol_user');
                        window.location.href = '/signin';
                    } else {
                        console.log(err);
                        //return Toast(error.response.data.message, false);
                    }
                    //console.log('err====', err);
                })
        }
    }

    useEffect(() => {
        setRole(user?.user?.role);

        getUserProfile();
    }, [user]);

    useEffect(() => {

        // const storedUser = localStorage.getItem("tekrevol_user");
        // if (storedUser) {
        //     setUser(JSON.parse(storedUser));
        // }

        getAllManualProjects();
        getSessionProjectDetails();

        const handleSideBar = () => {
            if (window.innerWidth < 965) {

                setTag('fixed left-[-100%] duration-700 z-50')
                setActiveMenu(true);
                setScreenSize(true);
            } else {
                setTag('left-0 duration-700 ')
                // setActiveMenu(false);
                setScreenSize(false);
            }
        }
        // setIsOpen(false)

        handleSideBar();
        window.addEventListener("resize", handleSideBar);
        
        return () => {
            window.removeEventListener("resize", handleSideBar);
        };
    }, []);


    const getAllMeetings = async (projectId) => {
        if (user) {
            let url = `${process.env.REACT_APP_BASE_URL}meeting?manual_project_id=${projectId}`;
            /*if (user.user.role === 'client') {
                url = `${process.env.REACT_APP_BASE_URL}meeting?meeting_by_client=${user.user.id}&project_id=${projectId}`

            } else {
                url = `${process.env.REACT_APP_BASE_URL}meeting?user_id=${user.user.id}&project_id=${projectId}`
            }*/
            await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {
                if (response && response.data && response.data.data) {
                    // console.log('==============>>>', response.data.data)
                    setMeeting(response.data.data);
                }

                /*const formattedData = response.data.data.map(item => {
                    const date = new Date(item.date_time);
                    const formattedDate = date.toLocaleDateString() + ' | ' + date.toLocaleTimeString([], {
                        hour: 'numeric',
                        minute: 'numeric'
                    });
                    return {...item, date: formattedDate};
                });
                const sortByNewest = formattedData.sort((x, y) => {
                    return new Date(x.created_at) < new Date(y.created_at) ? 1 : -1
                })
                setMeeting(sortByNewest);*/
            }).catch((err) => {
                if (err.response && err.response.status === 401) {
                    localStorage.removeItem('tekrevol_user');
                    window.location.href = '/signin';
                } else if (err.response) {
                    console.log(err.response);
                    // Handle other status codes if needed
                } else {
                    console.log('Error', err.message);
                    //return Toast(err.message, false);
                }
                console.log('err====', err);
            });
            // console.log(table);
        }
    }

    const getAllBenefits = () => {
        if (user) {
            axios.get(`${process.env.REACT_APP_BASE_URL}benefit-reward-list?project_id=${sessionProject}&client_id=${user?.user?.id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {

                const Points = response.data.data.map(item => {
                    console.log('===========>>>>', item)
                    let GetPercentage;
                    if (item?.user_points <= item.points) {
                        GetPercentage = Math.round((item?.user_points / item.points) * 100)
                    } else {
                        GetPercentage = 100;
                    }

                    // console.log(GetPercentage);
                    return {...item, percentage: GetPercentage};
                });
                setBenefits(Points);
                const obtain_percentage = (item?.user_points / 30000) * 100

            }).catch((err) => {
                console.log('err====', err);
            })
        }

    }

    
    const getUserProfile = async () => {
        if (user) {
            let uid = user?.user?.id
            const storedUser = JSON.parse(localStorage.getItem("tekrevol_user"));

            try {
                const response = await axios
                    .get(`${process.env.REACT_APP_BASE_URL}get_user/${uid}`, {
                        headers: {
                            'Authorization': `Bearer ${user.token}`
                        }
                    })

                setUserprofile(response?.data?.data);
            } catch (error) {
                console.error("USER PROFILE ERROR", error)
            }
        }
    }

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <StateContext.Provider value={{
            user,
            setUser,
            role,
            manualProjects,
            selectedProject,
            getAllBenefits,
            setSelectedProject,
            activeMenu,
            setActiveMenu,
            getAllMeetings,
            tag,
            meeting,
            hamBurger,
            setIsOpen,
            screenSize,
            setScreenSize,
            getDashboard,
            getAllManualProjects,
            getSessionProjectDetails,
            sessionProjectDetail, 
            setSessionProjectDetail,
            benefits,
            setBenefits,
            invoices,
            setInvoices,
            setClient,
            project,
            setProject,
            userprofile,
            getUserProfile,
            setUserprofile,
            dashboardData,
            setDashboardData,
            setTable,
            table,
            count,
            getNotificationCount
            // projectProgress, 
            // setProjectProgress,
            // getprojectProgress

        }}>
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);