import React, {useEffect, useState} from 'react';
import {useStateContext} from '../contexts/ContextProvider';
import axios from "axios";
import {DebounceInput} from "react-debounce-input";

const DownloadContentTabs = (props) => {
    const {user, activeDownloadTab, handleDownloadTabClick} = useStateContext();

    return (
        <div className="appboard-tabs-header">
                    <div className="appboard-tabs-nav">
                        {props.data && props.data.map((dt, index) => {
                                return (
                                    <h5 onClick={() => handleDownloadTabClick(dt.id)}  className={`${activeDownloadTab === props.type[dt.type] ? 'active' : ''}`}>
                                        {dt.type}
                                    </h5>
                                )
                            })
                        }
                    </div>
                    <div className="appboard-tabs-search">
                        <DebounceInput
                            value={props?.searchValue}
                            className="form-control"
                            placeholder="Search"
                            minLength={1}
                            debounceTimeout={700}
                            onChange={(e) => {
                                props?.setSearchValue(e.target.value.trim())
                            }}/>
                    </div>
                </div>
    )
}

export default DownloadContentTabs;