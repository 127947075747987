import React, {useState, useEffect} from 'react';
import medal1 from '../../data/medal1.svg'
import {ReactSVG} from 'react-svg';
import axios from 'axios'
import medal2 from '../../data/medal2.svg'
import {useStateContext} from '../../contexts/ContextProvider';
import {CircularProgressbarWithChildren} from 'react-circular-progressbar';
import {MdDone} from 'react-icons/md';
import {intToString} from '../../utils/PriceToString'
import Swal from 'sweetalert2';
import {TooltipComponent} from '@syncfusion/ej2-react-popups';


const RewardsSection = () => {

    const {benefits, getAllBenefits, user, sessionProject} = useStateContext();
    const [showDiv, setShowDiv] = useState(null);
    const [loading, setLoading] = useState(false);


    const createClaim = async (id) => {
        setLoading(true);
        const data = {
            "benefit_reward_id": id,
            "project_id": sessionProject
        }

        // console.log('data======',data);
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}benefit-reward-claim`, {...data}, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        getAllBenefits();
                        setLoading(false);
                        Swal.fire({
                            icon: 'success',
                            title: 'Successfully claimed!',
                            showConfirmButton: false,
                            timer: 2000
                        });
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    Swal.fire({
                        icon: 'error',
                        title: error?.response?.data?.message || 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 2000
                    });
                })
        } catch (error) {
            setLoading(false);
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: error?.response?.data?.message || 'Something went wrong!',
                showConfirmButton: false,
                timer: 2000
            });
        }
    };

    useEffect(() => {
        getAllBenefits()
        // console.log(benefits)
    }, [])
    // console.log(benefits);

    return (
        <div className='landing-div h-auto'>
            <div
                className='lg:max-h-[500px] md:max-h-[472px] sm:max-h-[424px] xm:max-h-[385px] overflow-y-auto px-[16px] space-y-[13px] milestone-bar'>
                {benefits.length !== 0 ? benefits.map((item, index) => {
                    return (
                        <div key={index}
                             className={` xm:flex xm:items-center  p-[23px] xm:p-[15px] border-1  ${item.percentage === 100 ? 'border-[#F47A1F]' : 'border-[#EDF0F4]'}  rounded-t-[15px] `}>
                            <TooltipComponent style={{width: '100%', cursor: 'pointer'}}
                                              title='Accumulate points for each reward, unlocking exclusive services'
                                              position="BottomCenter">
                                <div className='flex justify-between w-[100%] xm:gap-[5px]  '>
                                    <div className='flex gap-[20px] xm:gap-[10px] items-center'>
                                        <div
                                            className='w-[30px] h-[30px] md:w-[28px] md:h-[28px] sm:w-[25px] sm:h-[25px] xm:w-[22px] xm:h-[22px] flex flex-shrink-0 '>
                                            <CircularProgressbarWithChildren
                                                value={item.percentage}
                                                background={true}
                                                strokeWidth={15}
                                                styles={{
                                                    // Customize the root svg element
                                                    root: {},
                                                    // Customize the path, i.e. the "completed progress"
                                                    path: {
                                                        // Path color
                                                        stroke: `#F37A20`,
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Customize transition animation
                                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                                        // Rotate the path
                                                    },
                                                    // Customize the circle behind the path, i.e. the "total progress"
                                                    trail: {
                                                        // Trail color
                                                        stroke: '#FFE5D1',
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Rotate the trail
                                                    },
                                                    // Customize the text

                                                    // Customize background - only used when the `background` prop is true
                                                    background: {
                                                        fill: 'transparent',
                                                    },
                                                }}
                                            >
                                                {item.percentage === 100 ?
                                                    <MdDone color='#F47A1F' size={15}/>
                                                    : null}
                                            </CircularProgressbarWithChildren>
                                        </div>
                                        <div className='flex items-center'>
                                            <div>
                                                <p className={` ${item.percentage === 100 ? 'text-[#F47A1F]' : 'text-[#2A303C]'}  text-[20px] lg:text-[16px] sm:text-[12px] xm:text-[10px] md:text-[14px] mobile:text-[16px] small:text-[16px] font-bold `}>{item.title.length > 15 ? item.title.slice(0, 15) + "..." : item.title}</p>
                                                {item.percentage !== 100 ?
                                                    <p className='text-[#505887] text-[12px] md:text-[9px] sm:text-[7px] xm:text-[6px] font-normal '>{user.user.points < item.points ? item.points - user.user.points : '0'} More
                                                        Points Remaining</p>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end items-center'>
                                        {item.percentage === 100 ?

                                            !loading && !item.claimed ?
                                                <button onClick={() => createClaim(item.id)}
                                                        className='cursor-pointer bg-[#F37A20] rounded-[11px] flex justify-center items-center min-w-[69px] min-h-[31px] md:min-w-[60px] md:min-h-[22px] sm:min-w-[51px] sm:min-h-[20px] xm:min-w-[49px] xm:min-h-[18px] text-white md:text-[12px] text-[14px] sm:text-[11px] xm:text-[10px] '>
                                                    Claim
                                                </button> : !item?.status ?
                                                <button disabled
                                                        className=' bg-[#D9D9D9] rounded-[11px] flex justify-center items-center min-w-[69px] min-h-[31px] md:min-w-[60px] md:min-h-[22px] sm:min-w-[51px] sm:min-h-[20px] xm:min-w-[49px] xm:min-h-[18px] text-black md:text-[12px] text-[14px]  sm:text-[11px] xm:text-[10px]'>
                                                    Claimed
                                                </button>
                                                :
                                                <button disabled
                                                        className=' bg-[#D9D9D9] rounded-[11px] flex justify-center items-center min-w-[71px] min-h-[30px] md:min-w-[65px] md:min-h-[24px] sm:min-w-[62px] sm:min-h-[24px] xm:min-w-[56px] xm:min-h-[20px] text-black md:text-[12px] text-[13px]  sm:text-[11px] xm:text-[10px]'>
                                                    Approved
                                                </button>

                                            :
                                            <p className='text-[#0F1328] text-[20px] md:text-[18px]  sm:text-[16px] xm:text-[14px] font-semibold  '>{intToString(item.points)}</p>}
                                    </div>
                                </div>
                            </TooltipComponent>

                            {/* {showDiv === index &&
            <div className='flex justify-center' >
                <div className='items-center text-center' >
                <p className='text-white text-[20px] md:text-[18px] sm:text-[16px] xm:text-[14px] font-semibold' >{item.points}</p>
                <p className='text-white text-[14px] lg:text-[12px] md:text-[10px] sm:text-[8px] xm:text-[7px] font-normal ' >{ user.user.points < item.points ?  item.points - user.user.points : '0' } More Points Remaining for this reward</p>
            </div>
            </div>
        } */}
                        </div>
                    )
                }) : null}
            </div>

        </div>
    )
}

export default RewardsSection;