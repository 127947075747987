import React, { useState, useEffect } from 'react';
import {
    AddPersonIcon, ArrowIcon, AttchedIcon,
    BellJoinIcon, ClosedIcon,
    DotValueIcon,
    DropDownArrowIcon,
    LikeIcon, LinksIcon,
    PlusIcon,
    SendMsgIcon, ThreeDots
} from "../../app-constants";
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import DummyImg from "../../img/img1.jpeg";
import LogoImg from "../../img/DummyLogo.png";
import Form from "react-bootstrap/Form";
import { useStateContext } from "../../contexts/ContextProvider";
import axiosInstance from '../../axios/axiosInstance';

import { Oval } from 'react-loader-spinner'
import { Loader, Toast } from "../../components/General";
import axios from "axios";
import { formatDrawerDate, convertToPST  } from "../../utils/Helper";
import StatusComment from "./StatusComment";


function StatusView(props) {
    const { user, selectedProject } = useStateContext();
    const { role } = user.user;

    const [nextPage, setNextPage] = useState("");
    const [offset, setOffset] = useState("");


    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isStatus, setIsStatus] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [owner, setOwner] = useState([]);
    const [statusUpdates, setStatusUpdates] = useState([]);

    function formatDate(isoDateString) {
        const date = new Date(isoDateString);
    
        // Options for formatting
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
    
        // Convert date to the desired format
        const formattedDate = date.toLocaleDateString('en-GB', options).replace(',', '');
    
        return formattedDate;
    }

    const toggleActive = () => {
        setIsActive(current => !current);
    };
    const StatusActive = () => {
        setIsStatus(current => !current);
    };
    const EditActive = (value) => {
        if (value === 1) {
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }
    };

    const getProjectStatus = async () => {

        if (selectedProject) {
            setLoading(true);

            await axiosInstance.get(`get-project-status/${selectedProject}`)
                .then((response) => {

                    setNextPage(response?.data?.next_page);
                    setOwner(response?.data?.owner);

                    // setStatusUpdates([...statusUpdates, ...response?.data?.status_updates]);
                    setStatusUpdates(response?.data?.status_updates);


                }).catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const getMoreProjectStatus = async () => {

        if (selectedProject) {
            setLoading(true);

            await axiosInstance.get(`get-project-status/${selectedProject}?offset=${offset}`)
                .then((response) => {

                    setNextPage(response?.data?.next_page);
                    setOwner(response?.data?.owner);

                    setStatusUpdates([...statusUpdates, ...response?.data?.status_updates]);
                    // setStatusUpdates(response?.data?.status_updates);


                }).catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        setNextPage("");
        setOffset("");
        setStatusUpdates([]);
        getProjectStatus();
    }, [selectedProject]);

    useEffect(() => {
        // setStatusUpdates([]);
        getMoreProjectStatus();
    }, [offset]);

    const capitalizeString = (str) => {

        if (str) {
            return str.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
        }

        return '';
    };


    const loadMore = () => {
        if (nextPage) {
            setOffset(nextPage);
        }
    };


    const HtmlContent = ({ htmlString }) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: htmlString }} />
        );
    };

    const statusTypes = {
        'on_track': 'on-track',
        'complete': 'on-track',
        'off_track': 'risk',
        'at_track': 'risk',
        'at_risk': 'risk',
        'on_hold': 'hold'
    };

    

    const handleSubmit = (item) => {

        const data = {
            task_id: item?.gid,
            text: comment,
            client_id: user.user.id
        }

        console.log(data);

        // PostTaskStory(data);
        // setMessage('');
    }

    const PostTaskStory = (data) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}add-story`, data, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setStories([...stories, response.data.data]);
            }
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    // if(loading){
    //     return <Loader />
    // }

    return (
        <>
            <div className="main_top_title">
                <div className="main_title"><h1>Status <span>View</span></h1></div>
                <div className="main_btn_txt">
                    {/* <ul>
                        <li>
                            <NavLink to="">
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="">
                                Appboard
                            </NavLink>
                        </li>
                        <li className="active">
                            <NavLink to="" >
                                Status
                            </NavLink>
                        </li>
                    </ul> */}
                </div>
            </div>

            {/* {role === 'account manager' ?
                <div className={isEdit ? 'list_fillter active' : 'list_fillter'}>
                    <div className="add_status" onClick={toggleActive}>
                        Set status {DropDownArrowIcon}
                        <ul className={isActive ? 'active' : ''}>
                            <li className="on-track" onClick={() => EditActive(1)}>
                                <span>{DotValueIcon}On track</span>
                            </li>
                            <li className="risk">
                                <span>{DotValueIcon}At risk</span>
                            </li>
                            <li className="off-track">
                                <span>{DotValueIcon}Off track</span>
                            </li>
                            <li className="hold">
                                <span>{DotValueIcon}On hold</span>
                            </li>
                            <li className="complete">
                                <span>{DotValueIcon}Complete</span>
                            </li>
                        </ul>
                    </div>

                </div>
                : ''} */}

            <div className="main_content_container">
                <Container fluid className="p-0">
                    <Row>
                        <Col xxl={8} xl={12} lg={12}>

                            {
                                !nextPage && loading ? <Loader /> :
                                    statusUpdates && statusUpdates.length > 0 ?
                                        statusUpdates.map((item, index) => {
                                            return (

                                                <div className={`main_style_box status_update ${statusTypes[item?.status_type]}`}>
                                                    <div class="project_title">{item?.title}
                                                        <ul className="project_icons">
                                                            {/*<li>{LikeIcon}</li>*/}
                                                            {/*{role === 'account manager' ?*/}
                                                            {/*    <>*/}
                                                            {/*        /!*<li>{LinksIcon}</li>*!/*/}
                                                            {/*        <li>{ThreeDots}</li>*/}
                                                            {/*    </>*/}
                                                            {/*    : ''}*/}
                                                        </ul>
                                                    </div>

                                                    <div className="project_txt">
                                                        <div className="info_user">
                                                            <div className="info_img">
                                                                <img src={DummyImg} alt="DummyImg" />
                                                            </div>
                                                            <div className="info_title">
                                                                {item?.author?.name}
                                                            </div>
                                                            <div className="info_date">{item?.title}</div>
                                                            {/* <div className="info_date">{formatDate(item?.created_at)}</div> */}
                                                        </div>
                                                        <div className="status_points">
                                                            <div>Status <span class={`status ${statusTypes[item?.status_type]}`}>{DotValueIcon}
                                                                {capitalizeString(item?.status_type)}
                                                            </span></div>
                                                            <div>Owner <span class="name">{owner?.name}</span></div>
                                                            <div>Dates <span class="value">{formatDate(item?.created_at)}</span></div>
                                                        </div>
                                                        <div className="status_summary">
                                                            <h4>Summary</h4>

                                                            <HtmlContent htmlString={item?.html_text} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="project_comments">
                                                        <div className="comments_logo">
                                                            <img src={LogoImg} alt="" />
                                                        </div>
                                                        <div className="msg_box">
                                                            <Form.Control as="textarea" className="msg_box_comments" placeholder="Reply to message..."
                                                                value={comment}
                                                                onChange={(e) => setComment(e.target.value)}
                                                            />
                                                            <button className="msg_box_btn mt-2" onClick={ () => handleSubmit(item) }>Send</button>
                                                        </div>
                                                    </div> */}


                                                    <div className="popup_content app-status">
                                                        <StatusComment item={item} />
                                                    </div>

                                                </div>

                                            )
                                        })
                                        : ''
                            }

                            {
                                nextPage && loading ? <Loader /> : ''
                            }

                            {
                                nextPage && <div className="appboard_grid_project_button flex justify-center p-5"
                                    onClick={() => loadMore()}>
                                    <button>Load More</button>
                                </div>
                            }

                            <div className={isEdit ? 'set_status_main_box' : 'set_status_main_box active'}>
                                <div className="main_style_box status_update hold">
                                    <div class="project_title">Status Update - Feb 28
                                        <ul className="project_icons">
                                            <li onClick={() => EditActive(2)}>{ArrowIcon}Back to Status</li>
                                        </ul>
                                    </div>

                                    <div className="project_txt">

                                        Test data
                                        <div className="status_points">
                                            <div onClick={StatusActive}>Status <span class="status hold last">{DotValueIcon} On Hold {DropDownArrowIcon}</span>
                                                <ul className={isStatus ? 'status_value active' : 'status_value'}>
                                                    <li className="on-track">
                                                        <span>{DotValueIcon}On track</span>
                                                    </li>
                                                    <li className="risk">
                                                        <span>{DotValueIcon}At risk</span>
                                                    </li>
                                                    <li className="off-track">
                                                        <span>{DotValueIcon}Off track</span>
                                                    </li>
                                                    <li className="hold">
                                                        <span>{DotValueIcon}On hold</span>
                                                    </li>
                                                    <li className="complete">
                                                        <span>{DotValueIcon}Complete</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>Owner <span class="name">Hira Abid Khan</span></div>
                                            <div>Dates <span class="value">Jun 1, 2023</span></div>
                                        </div>
                                        <div className="status_file_attached">
                                            <div className="status_file_item">{AttchedIcon}Add Attachment</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="main_style_box status_update">
                                    <div class="project_title">Summary
                                        <div className="project_btns">
                                            {ClosedIcon}
                                        </div>
                                    </div>
                                    <div className="project_txt">
                                        <Form.Control as="textarea" className="edit_status_area" placeholder="Continue writing here..." />
                                    </div>
                                </div>
                                <div className="main_style_box status_update">
                                    <div class="project_title">Completed
                                        <div className="project_btns">
                                            {ClosedIcon}
                                        </div>
                                    </div>
                                    <div className="project_txt">
                                        <Form.Control as="textarea" className="edit_status_area" placeholder="Continue writing here..." />
                                    </div>
                                </div>
                                <div className="main_style_box status_update">
                                    <div class="project_title">Blocker
                                        <div className="project_btns">
                                            {ClosedIcon}
                                        </div>
                                    </div>
                                    <div className="project_txt">
                                        <Form.Control as="textarea" className="edit_status_area" placeholder="Continue writing here..." />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        {/*<Col xxl={4} xl={4} lg={4}>*/}
                        {/*    <div className={isEdit ? 'set_status_main_box' : 'set_status_main_box active'}>*/}
                        {/*        <div className="main_style_box status_update">*/}
                        {/*            asdasdsds*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default StatusView;


// <div className="filter_task">
// {SendMsgIcon} Send message to members
// </div>