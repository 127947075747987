import React, { useState } from 'react';
import PDFIMG from "../../../img/files/pdf.svg";
import JPGIMG from "../../../img/files/jpg.svg";
import DOCIMG from "../../../img/files/doc.svg";
import XLSIMG from "../../../img/files/xls.svg";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Loader, Toast, NoRecordFound } from "../../../components/General";

function BrandAssets(props) {


    const downloadFile = async (url) => {

        // console.log(url);

        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const urlObject = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = urlObject;

            // Extract file name from response headers or use a default name
            const contentDisposition = response.headers.get('content-disposition');
            let fileName = 'downloaded_file';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                if (fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
            }

            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(urlObject);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };


    // async function download(item) {

    //     console.log(item.file_path);

    //     fetch(item.file_path)
    //         .then(response => response.blob())
    //         .then(blob => {
    //             const link = document.createElement("a");
    //             const url = URL.createObjectURL(blob);
    //             const filename = "report-sheet.xlsx";

    //             link.href = url;

    //             console.log(url);

    //             link.setAttribute("download", filename);

    //             document.body.appendChild(link);
    //             link.click();

    //             // Clean up the URL and remove the link
    //             URL.revokeObjectURL(url);
    //             link.remove();
    //         })
    //         .catch(error => {
    //             console.error("Error downloading the file:", error);
    //         });
    // }

    return (
        <Container fluid>
            <div className='downloadable-layout'>
            <Row>
                <Col xxl={6}>
                    <div className="project_title_new">{props?.title}</div>
                </Col>
            </Row>
            <Row>

                <Col xxl={12} xl={12} lg={12}>
                    <div class="filing-wrap">
                {props.data && props.data.length > 0 ?
                    props.data.map((item, index) => {

                        return (
                                <div className="filing_section">
                                    <div className="files_box">
                                        <div className="file_icon">
                                            <img src={PDFIMG} alt="PDFIMG" />
                                        </div>
                                        <div className="file_content">
                                            <div className="name_file">
                                                <div className="title">{item?.file_name}</div>
                                                <span className="download" onClick={() => downloadFile(item.file_path)}>Download</span>
                                            </div>
                                            <div className="txt_file">
                                                {item?.created_at} - <span>{item?.owner}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        )
                    })
                    : <NoRecordFound />
                }
                    </div>

                </Col>

            </Row>
            </div>
        </Container>

    );
}

export default BrandAssets;