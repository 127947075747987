import React from 'react';
import ClientTable from './components/Client/ClientTable';
import { NavLink } from 'react-router-dom';
import { Container,Row, Col } from 'react-bootstrap';

function ClientManagement() {
    return (
        <>
            <div className="main_top_title">
                <div className="main_title"><h1>Our <span>Clients</span></h1></div>
                <div className="main_btn_txt">
                    {/* <ul>
                        <li>
                            <NavLink to="">
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="">
                                Appboard
                            </NavLink>
                        </li>
                        <li className="active">
                            <NavLink to="" >
                                Client
                            </NavLink>
                        </li>
                    </ul> */}
                </div>
            </div>
            <Container fluid className="p-0">
                <Row>
                    <Col xxl={12}>
                        <div className="main_style_box">
                            <ClientTable />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ClientManagement
