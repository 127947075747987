import React, { useState,useEffect } from 'react';
import { ScheduleComponent, ViewsDirective, ViewDirective, Day, Week, WorkWeek, Month, Agenda, Inject, Resize, DragAndDrop } from '@syncfusion/ej2-react-schedule';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { useStateContext } from '../contexts/ContextProvider';
import { scheduleData } from '../data/dummy';
import { Header } from '../components';
import axios from 'axios'

// eslint-disable-next-line react/destructuring-assignment
const PropertyPane = (props) => <div className="mt-5">{props.children}</div>;

const Scheduler = () => {
  const [scheduleObj, setScheduleObj] = useState();

  const change = (args) => {
    scheduleObj.selectedDate = args.value;
    scheduleObj.dataBind();
  };

  const onDragStart = (arg) => {
    // eslint-disable-next-line no-param-reassign
    arg.navigation.enable = true;
  };

  const [meetings, setMeetings] = useState([]);
  const {user} = useStateContext();


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}meeting?all=1`,{
      headers:{
        'Authorization':`Bearer ${user.token}`
      }
    })
      .then((response) => {
        const data = response.data.data.map((meeting) => {
          return {
            Id: meeting.id,
            Subject: meeting.name,
            StartTime: new Date(meeting.date_time),
            IsAllDay: true,
            Description: meeting.description
          };
        });
        setMeetings(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="mx-4 bg-white rounded-3xl">
      {/* <Header category="App" title="Calendar" /> */}
      <h1 className=" meeting-text mb-8">
          CRM{" "}
          <span style={{ fontWeight: "700" }} className="text-[#F37A20]">
            Calendar
          </span>
        </h1>
      <ScheduleComponent
        height="650px"
        ref={(schedule) => setScheduleObj(schedule)}
        selectedDate={new Date(2021, 0, 10)}
        eventSettings={{ dataSource: meetings }}
        dragStart={onDragStart}
      >
        <ViewsDirective>
          { ['Day', 'Week', 'WorkWeek', 'Month', 'Agenda'].map((item) => <ViewDirective key={item} option={item} />)}
        </ViewsDirective>
        <Inject services={[Day, Week, WorkWeek, Month, Agenda, Resize, DragAndDrop]} />
      </ScheduleComponent>
      <PropertyPane>
        <table
          style={{ width: '100%', background: 'white' }}
        >
          <tbody>
            <tr style={{ height: '50px' }}>
              <td style={{ width: '100%' }}>
                <DatePickerComponent
                  value={new Date(2021, 0, 10)}
                  showClearButton={false}
                  placeholder="Current Date"
                  floatLabelType="Always"
                  change={change}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </PropertyPane>
    </div>
  );
};

export default Scheduler;
