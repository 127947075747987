import React, { useState, useEffect } from 'react'
import { useStateContext } from '../contexts/ContextProvider';
import { RiEditBoxFill } from 'react-icons/ri';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField } from '@material-ui/core';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Swal from 'sweetalert2';
import { Oval } from 'react-loader-spinner'
import { Link } from 'react-router-dom';

import { Modal, Table } from "react-bootstrap";
import { DownloadIcon, ViewIcon, DeletedIcon } from '../app-constants';
import IMG01 from "../img/img1.jpeg";
import IMG02 from "../img/img1.jpeg";
import { Loader, NoRecordFound } from '../components/General';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const validationSchema = yup.object({
  points: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      "only a number is allowed")
    .required('This Field is Required!'),
});

const RewardsClientTable = () => {
  const [client, SetClient] = useState(null);
  const { activeMenu, user, project, selectedProject } = useStateContext();
  const [points, setPoints] = useState(0);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);


  const handleShow = () => setShow(true);


  const formik = useFormik({
    initialValues: {
      points: points,

    },
    validationSchema: validationSchema,
    onSubmit: () => {
      UpdatePoints(client)
      handleClose()
    },
  });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (item) => {
    setPoints(item.points);
    SetClient(item)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const UpdatePoints = async (item) => {
    console.log(item);
    setLoading(true);
    const data = {
      "email": item.email,
      "points": formik.values.points,
    }
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}update_user/${item.id}`, { ...data, id: item.id }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
        .then((res) => {
          if (res.status === 200) {
            getAllUsers();
            setLoading(false);
            Swal.fire(
              {
                icon: 'success',
                title: 'Points Updated successfully!',
                showConfirmButton: false,
                timer: 1000
              }

            )
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1000
          })
        })
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getAllUsers = () => {
    setLoading(true);
    // axios.get(`${process.env.REACT_APP_BASE_URL}get_clients_by_user/${user.user.id}`,{
    axios.get(`${process.env.REACT_APP_BASE_URL}get_clients_by_project/${selectedProject}`, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    }).then((response) => {
      setUsers(response.data.data);
      setLoading(false);
    }).catch((err) => {
      console.log('err====', err);
    })
    // console.log(table);
  }
  useEffect(() => {
    getAllUsers();
  }, [selectedProject]);

  useEffect(() => {

    formik.setValues({
      points: points,
    })
  }, [points])

  /*if (loading) {
    return (
      <div className=' justify-center items-center flex h-[80vh]' >
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#F37A20"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>

    )
  }*/


  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="download_tabs">
        <Table responsive>
          <thead>
            <tr>

              <th className="text-center">Client Name</th>
              <th className="text-center">Client Email</th>
              <th className="text-center">Company Name</th>
            </tr>
          </thead>
          <tbody>


            {users && users.length > 0 ? users.map((item, index) => {

              return (
                <tr>

                  <td className="text-center">
                    <Link to={`/rewards/client/${item.id}`} state={{ item: item }} className='link-pri'>

                      {item?.first_name}
                    </Link>
                  </td>

                  <td className="text-center">{item?.email}</td>
                  <td className="text-center">{item?.company_name}</td>
                </tr>
              )

            }) : (
              <NoRecordFound />
            )

            }


          </tbody>
        </Table>
      </div>

    </>

  );

  // return (
  //   <table className="width-100">
  //     <thead>
  //       <tr>
  //         <th>Client Name</th>
  //         <th>Client Email</th>
  //         <th>Company Name</th>
  //       </tr>
  //     </thead>
  //     <tbody>
  //       {users.length !== 0 ?
  //         users.map((item, index) => {
  //           return (
  //             <tr key={index}>
  //               <Link to={`/rewards/client/${item.id}`} state={{ item: item }}>
  //                 <td>{item.first_name}</td>
  //               </Link>
  //               <td>{item.email}</td>
  //               <td>{item.company_name}</td>

  //               {/*<td className="p-3 text-sm  text-gray-700">
  //                             <p className=" text-[#505887]  text-[14px] lg:text-[12px] md:text-[12px] sm:text-[11px] xm:text-[10px] font-medium  ">{item.points}</p>
  //                           </td>

  //                           <td className="p-3 ">
  //                             <div className=" flex gap-2 ">
  //                               <RiEditBoxFill className='cursor-pointer' onClick={() => handleClickOpen(item)}
  //                                              color="orange" size={20}/>
  //                             </div>

  //                           </td>*/}
  //             </tr>
  //           )
  //         })
  //         :
  //         <div className='flex justify-center items-center p-4 bg-[white]'>
  //           <p className='text-center text-[13px] text-[#979797] font-bold'>No clients found</p>
  //         </div>
  //       }
  //     </tbody>
  //   </table>
  // )
}

export default RewardsClientTable;
