import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Loader, Toast } from "../../../components/General";
import axios from 'axios';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Oval } from 'react-loader-spinner';


function ChangePassword({ handleClose }) {
    const { user, userprofile, getUserProfile, setUserprofile } = useStateContext();

    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({
        old_password: yup.string()
            .required("Current password is required"),
        password: yup.string()
            .required("Password is required")
            .max(20, "Password should be maximun of 20 characters")
            .min(8, "Password must be atleast of 8 characters"),
        confirm_password: yup.string()
            .required("Confirm password is required")
            .oneOf([yup.ref("password")], "Password do not matched"),
    });

    const formik = useFormik({
        initialValues: {
            old_password: '',
            password: '',
            confirm_password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            // handleSubmit(values);
            updatePassword(values);
        },
    });


    const updatePassword = async (data) => {

        setLoading(true);

        console.log(data);

        await axios.put(`${process.env.REACT_APP_BASE_URL}change_password`, data, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data);
                    setLoading(false);
                    handleClose();

                    Toast.fire({
                        icon: 'success',
                        title: 'Password Updated Successfully!',
                    })

                    // logout()
                }
            })
            .catch((error) => {
                if (error?.response?.data?.message) {
                    Toast.fire({
                        icon: 'error',
                        title: error?.response?.data?.message || "Something went wrong, Please try again later.",
                    })
                }

            })
            .finally(() => {
                setLoading(false);
            });

    };

    return (
        <div className="change_pass  main_popup">

            <div className="main_title">Change <span>Password</span></div>
            <form onSubmit={formik.handleSubmit}>

                <Form.Group controlId="old_password">
                    <Form.Label>Current Password</Form.Label>

                    <Form.Control type="text" placeholder="Current Password"
                        name="old_password"
                        label="Password"
                        value={formik.values.old_password || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched.old_password && Boolean(formik.errors.old_password)}
                    />

                    <Form.Control.Feedback type="invalid">
                        {formik.touched.old_password && formik.errors.old_password}
                    </Form.Control.Feedback>
                </Form.Group>
                <br />

                <Form.Group controlId="password">
                    <Form.Label>New Password</Form.Label>

                    <Form.Control type="text" placeholder="New Password"
                        name="password"
                        label="Password"
                        value={formik.values.password || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched.password && Boolean(formik.errors.password)}
                    />

                    <Form.Control.Feedback type="invalid">
                        {formik.touched.password && formik.errors.password}
                    </Form.Control.Feedback>
                </Form.Group>
                <br />

                <Form.Group controlId="confirm_password">
                    <Form.Label>Confirm Password</Form.Label>

                    <Form.Control type="text" placeholder="Confirm Password"
                        name="confirm_password"
                        label="Confirm Password"
                        value={formik.values.confirm_password || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                    />

                    <Form.Control.Feedback type="invalid">
                        {formik.touched.confirm_password && formik.errors.confirm_password}
                    </Form.Control.Feedback>
                </Form.Group>
                <br />

                <div className="profile_button">
                    <button type="submit">
                        <div className="btn_profile" >

                            { loading ? <Oval
                                visible={true}
                                height="20"
                                width="20"
                                color="#ffffff"
                                secondaryColor="#ffffff"
                                ariaLabel="oval-loading"
                                wrapperStyle={{ display: 'flex', justifyContent: 'center' }}
                                wrapperClass=""
                            /> : "Save"}

                        </div>
                    </button>

                    <div className="btn_profile cancel" onClick={handleClose}>
                        Cancel
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ChangePassword;