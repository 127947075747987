import React from 'react'

function TermAndConditions() {
  return (

    <>
        <div className="terms-title">
            <div class="main_title"><h1>Term and <span>Conditions</span></h1></div>
        </div>
        <section className='terms-page'>
        
            <p>Welcome to Revol Base using our CRM product (&quot;Product&quot;), which is currently in its beta phase, you agree to the following terms and conditions:</p>
            <ol>
                <li>
                    <h3>Beta Status</h3>
                    <p>The Product is in beta testing and is provided to you for evaluation purposes. As such, it is still under development and may contain bugs, errors, or other issues. The features and functionality of the Product are subject to change based on feedback and further development.</p>
                </li>
                <li>
                    <h3>Development and Enhancements</h3>
                    <p>Ongoing Improvements: We are continuously working to improve the Product. Expect regular updates, enhancements, and changes as we incorporate feedback and refine its features.</p>
                </li>
                <li>
                    <h3>Feedback and Support</h3>
                    <p><strong>Feedback:</strong> We encourage you to provide feedback on your experience with the Product. Your insights are valuable and will help us in making necessary improvements.</p>
                </li>
                <li>
                    <h3>Changes to Terms</h3>
                    <p>We may update these terms as needed. Any changes will be effective when posted on our website. Continued use of the Product indicates acceptance of the updated terms.</p>
                </li>
                <li>
                    <h3>Contact Us</h3>
                    <p>For questions or feedback, please contact us at <a href="mailto:pmo@tekrevol.com">pmo@tekrevol.com</a></p>
                </li>
            </ol>
        </section>
    </>
  )
}

export default TermAndConditions