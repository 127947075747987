import React, { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Table from '../Table';
import {RiDownloadCloudFill} from 'react-icons/ri'
import {AiOutlineLink} from 'react-icons/ai'
import axios from 'axios'
import { useStateContext } from '../../contexts/ContextProvider';

const ClientDetails=()=>{
    const[userDetail,setUserDetail] = useState();
    const {user} = useStateContext();
    const[meeting,setMeeting] = useState([]);
    const[attachments,setAttachments] = useState([]);
    const params = useParams();
    // console.log(params);
    const getClientDetails=()=>{
        axios.get(`${process.env.REACT_APP_BASE_URL}get_user/${params.id}`,{
            headers:{
                'Authorization':`Bearer ${user.token}`
              }
        }).then((response)=>{
          setUserDetail(response.data.data);
        }).catch((err)=>{
          console.log('err====',err);
        })
        // console.log(table);
      }

      const getClientMeeting=()=>{
        axios.get(`${process.env.REACT_APP_BASE_URL}meeting?meeting_by_client=${params.id}`,{
            headers:{
                'Authorization':`Bearer ${user.token}`
              }
        }).then((response)=>{
            setMeeting(response.data.data);
        }).catch((err)=>{
          console.log('err====',err);
        })
        // console.log(table);
      }

      const getClientAttachments=()=>{
        axios.get(`${process.env.REACT_APP_BASE_URL}downloadable-content?content_by_client=${params.id}`,{
            headers:{
                'Authorization':`Bearer ${user.token}`
              }
        }).then((response)=>{
            setAttachments(response.data.data);
        }).catch((err)=>{
          console.log('err====',err);
        })
        // console.log(table);
      }

      useEffect(() => {
        getClientDetails();
        getClientMeeting();
        getClientAttachments();
      }, []);

      console.log(attachments);

    return(
        <div className='w-[80%] mx-auto ' >
            <div className='mb-6' >
            <h1 className=" meeting-text">
          Clients{" "}
          <span style={{ fontWeight: "700" }} className="text-[#F37A20]">
            Details
          </span>
        </h1>
            </div>
            <div className='grid grid-cols-2 xm:grid-cols-1 tab:grid-cols-1 mobile:grid-cols-1 small:grid-cols-1 mx-auto gap-8' >
                <div className='bg-white rounded-md ' >
                    <div className='flex' >
                    <div className='bg-[#ffd9bd] p-12  rounded-r-full' >
                        <p className='text-[24px] sm:text-[14px] xm:text-[20px] mobile:text-[10px] small:text-[10px]  md:text-[15px]   lg:text-[20px] font-bold  text-orange-500 ' >Client Name</p>
                    </div>
                    <div className='p-12 pl-16' >
                        <p className='text-[24px] sm:text-[14px] xm:text-[20px] mobile:text-[10px] small:text-[10px]  md:text-[15px] lg:text-[20px] font-semibold '>{userDetail?.first_name} {userDetail?.last_name}</p>
                    </div>
                    </div>
                </div>
                <div className='bg-white rounded-md ' >
                    <div className='flex' >
                    <div className='bg-[#ffd9bd] p-12  rounded-r-full' >
                        <p className='text-[24px] sm:text-[14px] xm:text-[20px] mobile:text-[10px] small:text-[10px]  md:text-[15px] lg:text-[20px] font-bold text-orange-500 ' >Email</p>
                    </div>
                    <div className='p-12' >
                        <p className='text-[24px] sm:text-[14px] xm:text-[20px] mobile:text-[10px] small:text-[10px]  md:text-[15px] lg:text-[20px] font-semibold '>{userDetail?.email}</p>
                    </div>
                    </div>
                </div>
                <div className='bg-white rounded-md ' >
                    <div className='flex' >
                    <div className='bg-[#ffd9bd] p-12  rounded-r-full' >
                        <p className='text-[24px] sm:text-[14px] xm:text-[20px] mobile:text-[10px] small:text-[10px]  md:text-[15px] lg:text-[20px] font-bold text-orange-500 ' >Phone No.</p>
                    </div>
                    <div className='p-12' >
                        <p className='text-[24px] sm:text-[14px] xm:text-[20px] mobile:text-[10px] small:text-[10px]  md:text-[15px] lg:text-[20px] font-semibold '>{userDetail?.phone}</p>
                    </div>
                    </div>
                </div>
                <div className='bg-white rounded-md ' >
                    <div className='flex' >
                    <div className='bg-[#ffd9bd] p-12  rounded-r-full' >
                        <p className='text-[24px] sm:text-[14px] xm:text-[20px] mobile:text-[10px] small:text-[10px]  md:text-[15px] lg:text-[20px] font-bold text-orange-500 ' >Company Name</p>
                    </div>
                    <div className='p-12' >
                        <p className='text-[24px] sm:text-[14px] xm:text-[20px] mobile:text-[10px] small:text-[10px]  md:text-[15px] lg:text-[20px] font-semibold '>{userDetail?.company_name}</p>
                    </div>
                    </div>
                </div>
            </div>
            <div>
                <Table id={params.id} />
            </div>
            <div className='grid grid-cols-2 xm:grid-cols-1 tab:grid-cols-1 mobile:grid-cols-1 small:grid-cols-1 mx-auto gap-8 mt-8' >
                <div className='bg-white rounded-md p-5 ' >
                    <p className='text-[24px] sm:text-[18px] xm:text-[20px] mobile:text-[16px] small:text-[14px]  md:text-[18px] lg:text-[20px] font-bold ' >Client Files</p>
                    <ul className='mt-4 h-[200px] overflow-y-auto' >
                        {attachments.length !== 0 ? attachments.map((item,index)=>{
                            return(
                            <div key={index}  className='flex justify-between pb-4 pt-4 pr-[20px] border-b-1 border-gray-300' >
                            <li className='text-[18px] font-semibold' >{item.file_name}</li>
                            <li><RiDownloadCloudFill size={24}/></li>
                            </div>

                        )}):<div className='flex justify-center items-center' >
                        <p>No Files Here...</p></div>}


                    </ul>
                </div>
                <div className='bg-white rounded-md p-5 ' >
                    <p className='text-[24px] sm:text-[18px] xm:text-[20px] mobile:text-[16px] small:text-[14px]  md:text-[18px] lg:text-[20px] font-bold ' >Next Meeting</p>
                    <ul className='mt-4 h-[200px] overflow-y-auto' >
                        {meeting && meeting?.length!==0 ? meeting?.previous?.map((item,index)=>{
                            return(

                        <div key={index} className='flex justify-between pb-4 pt-4 pr-[20px] border-b-1 border-gray-300' >
                        <li className='text-[18px] font-semibold' >{item.name}</li>
                        <li>
                            <a target='_blank' href='https://www.meet.google.com/ono-jqse-kug'  className='cursor-pointer' >
                            <AiOutlineLink   size={24}/></a></li>

                        </div>

                            )
                        }):<div className='flex justify-center items-center' >
                            <p>No Upcomming Meetings</p></div>}
                    </ul>
                </div>

            </div>
        </div>
    )
}

export default ClientDetails;