import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import Rewards from '../../components/Rewards';
import AppBoardTabs from '../../components/Appboard/AppBoardTabs';
import OverviewTab from '../../components/OverviewTab';
import {useStateContext} from '../../contexts/ContextProvider';
import OverviewResource from '../../components/OverviewResources';
import WhatsNew from '../../components/WhatsNew'
import FilesTab from '../../components/FilesTab';
import BoardTab from '../../components/Appboard/BoardTab';
import Swal from 'sweetalert2';
import {useNavigate} from 'react-router-dom'
import MessageTab from '../../components/Messagetab';
import ProjectProgress from '../../components/charts/ProjectProgress';
import {useParams} from 'react-router-dom';
import axios from 'axios'
import {Oval} from 'react-loader-spinner'


import {
    CalendarICon,
    CommentIcon,
    FillterIcon,
    HideIcon, LikeIcon,
    PersonIcon,
    PlusIcon,
    SortIcon,
    ThreeDots
} from "../../app-constants";


import {Container,Row,Col} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import DummyImg1 from "../../img/img1.jpeg";
import DummyImg2 from "../../img/img2.jpeg";
import DummyImg3 from "../../img/img2.jpeg";
import DummyImg4 from "../../img/img4.jpeg";


const BoardViewComp = () => {
    const {activeTab, user, handleTabClick, asanaToken, project, sessionProject, selectedProject} = useStateContext();
    const [asanaProject, setAsanaProject] = useState(null);
    const [allAssignee, setAllAssignee] = useState([]);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const {role} = user.user;

    useEffect(() => {
        getAsanaProject();
        getAllAssignee()
    }, [selectedProject])

    const getAsanaProject = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_BASE_URL}get-asana-project/${selectedProject}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setAsanaProject(response.data.data)
            }
            setLoading(false);
        }).catch((err) => {
            console.log('err====', err);
            setLoading(false);
        })
    }

    const getAllAssignee = () => {
        if (user) {
            const ASANA_BEARER_TOKEN = "2/1204614194882962/1206884941289659:6993e942413ab55624207ae4379afb7f"
            axios.get(`${process.env.REACT_APP_ASANA_BASE_URL}users?workspace=146928382234467`, {
                headers: {
                    Authorization: `Bearer ${ASANA_BEARER_TOKEN}`,
                }
            })
                .then((res) => {
                    setAllAssignee(res.data.data)
                })
                .catch(err => {
                    console.log('err====', err);
                })
        }
    }

    const handleDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        })

    }

    let start_date;
    let end_date;
    if (asanaProject?.created_at !== null) {
        start_date = new Date(asanaProject?.created_at);
        start_date = start_date.toLocaleDateString();
    }

    if (asanaProject?.due_date !== null) {
        end_date = new Date(asanaProject?.due_date);
        end_date = end_date.toLocaleDateString();
    }

    return (
        <>
            <div className="main_top_title">
                <div className="main_title"><h1>Card <span>View</span></h1></div>
                <div className="main_btn_txt">
                    {/* <ul>
                        <li>
                            <NavLink to="">
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="">
                                Appboard
                            </NavLink>
                        </li>
                        <li className="active">
                            <NavLink to="" >
                                Board
                            </NavLink>
                        </li>
                    </ul> */}
                </div>
            </div>

            {/* {
                role != 'client' && (
                    
                    <div className="list_fillter">
                        <div className="add_task">
                            {PlusIcon}Add task
                        </div>
                        <div className="filter_task">
                            {FillterIcon}Filter
                        </div>
                        <div className="sort_task">
                            {SortIcon} Sort
                        </div>
                        <div className="hide_task">
                            {HideIcon} Hide
                        </div>
                    </div>
                )
            } */}

            
            <div className="main_content_container">
                <Container fluid className="p-0">
                    <div className="mb-0 custom-row">
                    {
                            loading ?
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    width: 'fit-content'
                                }}
                                className='mt-5'
                            >
                                <Oval
                                    visible={true}
                                    height={20}
                                    width={20}
                                    color="#f37a20"
                                    ariaLabel="oval-loading"
                                />
                                <small>Please wait while the content is loading</small>
                            </div> :      
                        <>
                            <BoardTab allAssignee={allAssignee} gid=""/>
                        </>
                           
                        }
                        
                    </div>
                </Container>
            </div>
          </>
    );

}


export default BoardViewComp;