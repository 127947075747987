import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../contexts/ContextProvider';
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import reactangle from '../../data/rectangle.svg'
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner'




const renderChart = (percentage, loading) => {
    if (loading) return <div className='flex justify-center' >
        <ThreeDots
            width="10"
            radius="1"
            color="#F47A1F"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
        />
    </div>
    return <CircularProgressbar
        value={percentage}
        background={true}
        strokeWidth={5}
        text={percentage + '%'}
        styles={{
            // Customize the root svg element
            root: {},
            // Customize the path, i.e. the "completed progress"
            path: {
                // Path color
                stroke: `#F47A1F`,
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
                // Customize transition animation
                transition: 'stroke-dashoffset 0.5s ease 0s',
                // Rotate the path
            },
            // Customize the circle behind the path, i.e. the "total progress"
            trail: {
                // Trail color
                stroke: '#F47A1F',
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
                // Rotate the trail
            },
            // Customize the text
            text: {
                // Text color
                fill: '#2A303C',
                // Text size
                fontSize: '14px',
                fontWeight: 'bold'
            },
            // Customize background - only used when the `background` prop is true
            background: {
                fill: '#FAFAFA',
            },
        }}
    />
}

function MarketingProjectProgress({ projectData }) {
    const [percentage, setPercentage] = useState(0);
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(false)
        let ratio = Math.ceil((projectData.complete_task / projectData.task_count || 0) * 100) ?? 0;
        setPercentage(ratio)
    }, [])

    return (
        <div>
            {
                renderChart(percentage, loading)
            }
        </div>
    )
}

export default MarketingProjectProgress

