import React, { useState } from 'react'
import WhiteLogo from '../data/tekLogo.svg'
import WhiteLogoCRM from '../data/white-logo-crm.svg'
import axios from 'axios';
import { ThreeDots, TailSpin } from 'react-loader-spinner'
import { useFormik } from 'formik';
import * as yup from 'yup';
import email from '../data/signin-email.png'
import Swal from 'sweetalert2';
import { Toast } from "../components/General";
const validationSchema = yup.object({
  email: yup.string().email("Invalid Email Format").required("Email is required"),
});

const ForgotPassword = () => {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      ForgotPassword();

    },
  });

  const [loading, setLoading] = useState(false);

  const ForgotPassword = async () => {

    setLoading(true);
    const data = {
      "email": formik.values.email,
      // "redirect_url": "http://localhost:3008/reset"
      "redirect_url": `${process.env.REACT_APP_URL}reset`
    }
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}forgot_password`, { ...data })
        .then((res) => {
          if (res.status === 200) {
            console.log("RESULT IN FP", res.data);
            setLoading(false);
            // handleClose();
            Swal.fire({
              icon: 'success',
              title: 'Email has been sent, Kindly check your mailbox!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              }
            })

          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("ERROR inFP", error);
          let msg = typeof (error.response.data.errors[0].message) == 'string' ? error.response.data.errors[0].message : error.response.data.errors[0].message.email
          Toast.fire({
            icon: 'error',
            title: msg,
          })
        })
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };


  return (
    <div className="signinform min-h-screen">
      <div className='grid grid-cols-7' >

        <div className='signin-form'  >
          <div className='signin-form-div' >
            <div className='mb-[80px]' >
              <img src={WhiteLogoCRM} alt="WhiteLogo" />
            </div>
            <h1 className='signin-form-div-title forgot-after ' >Forgot Password</h1>
            <div className='flex justify-center items-center' >
              <form onSubmit={formik.handleSubmit} className='pt-[20px] space-y-4 custom-input' >
                {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}

                <div className='settings-email-div' >
                  <label className='text-white text-[14px] font-medium' >Email</label><br />
                  <div className='relative pt-[7px] ' >
                    <div className="settings-email-div-img mt-2">
                      <img src={email} className="pl-2" alt="searchIcon" />
                    </div>
                    <input
                      id='email'
                      name='email'
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      className='input-email'
                      placeholder='doe@gmail.com' />
                  </div>
                  {formik.errors.email && <span className="error-message">{formik.errors.email}</span>}

                </div>
                {!loading ?
                  <button type='submit' className='signin-form-div-save' >Send Email</button>
                  : <button className='signin-form-div-save justify-center flex items-center' >
                    <TailSpin
                      height="30"
                      width="30"
                      color="#ffffff"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </button>
                }
              </form>
            </div>
              <a href="/signin" className='back-btn'>Back</a>

          </div>

        </div>
        <div className='col-span-4 left ' >
          <div className='flex justify-center items-center h-full ' >
            {/* <img width={350} src={WhiteLogo} alt='WhiteLogo' /> */}
          </div>
        </div>

      </div>
    </div>
  )
}

export default ForgotPassword;