import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import axios from "axios";
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { Oval } from 'react-loader-spinner'
import { NavLink } from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner'
import Tooltip from '@mui/material/Tooltip';
import { BsFillFilePdfFill, BsFileTextFill, BsFillPlayBtnFill, BsFileZipFill } from 'react-icons/bs'
import { ImAttachment, ImExit } from 'react-icons/im'
import { TextField, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import { TiTick } from 'react-icons/ti'
import Slide from '@mui/material/Slide';
import Drawer from '@mui/material/Drawer';
import LogoImg from "../../img/DummyLogo.png";
import DummyImg from "../../img/img1.jpeg";
import Swal from 'sweetalert2'
import {
    AddPersonIcon, BellJoinIcon,
    CommentIcon,
    CalendarIcon,
    DotValueIcon,
    ClosedIcon,
    DropDownArrowIcon,
    FillterIcon,
    HideIcon,
    PlusIcon,
    SortIcon
} from "../../app-constants";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {useFormik} from 'formik';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import * as yup from 'yup';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {convertDateFormat} from '../../utils/Helper'
import { formatMDY } from '../../utils/Helper'
import DrawerCustom from "./DrawerCustom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    title: yup
        .string()
        .min(2, 'Too Short!')
        .max(70, 'Too Long!')
        .required('Task Title is required'),
    due_date: yup.date().notRequired(),
    assignee: yup.string().notRequired()
});


const Tasks = ({ section,allAssignee }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [tasks, setTasks] = useState([]);
    const [task, setTask] = useState([])
    const [loading, setLoading] = useState(false);
    const [taskCreateLoading, setTaskCreateLoading] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [stories, setStories] = useState([]);
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const { user, selectedProject } = useStateContext();
    const [activeId, setActiveId] = useState(1);
    const [drawerLoading, setDrawerLoading] = useState(false);
    const [taskProgress, setTaskProgress] = useState({});
    const [taskPriority, setTaskPriority] = useState({});
    const [productionStatus, setProductionStatus] = useState({});
    const [isActive, setIsActive] = useState(false);
    const role = user?.user?.role;
    const [Modalopen, setModalOpen] = React.useState(false);
    
    const toggleActive = () => {
        setIsActive(current => !current);
    };
    const DoneActive = () => {
        setIsActive(false);
    };
    const toggleActiveClass = (id) => {
        setActiveId(current => current === id ? null : id);
    };
    useEffect(() => {
        getAllTasksBySectionId()
    }, [selectedProject]);

    const toggleDrawer = (data) => {
        setOpen(!open)
        if(data){

            getTaskDetails(data.gid);
            getTaskAttachment(data.gid);
            getTaskStories(data.gid);
        }
        setMessage('')
    };

    const toggleVisibility = () => {
        setIsExpanded(!isExpanded);
    };

    const getAllTasksBySectionId = () => {
        setLoading(true);

        axios.get(`${process.env.REACT_APP_BASE_URL}get-all-tasks/${section.gid}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response?.data?.data;
            if (data) {
                setTasks(response.data.data);
            }

            console.log(response?.data?.data);
        })
        .catch((err) => {
            console.log("error here");
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const getTaskDetails = (gid) => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_BASE_URL}get-task-detail/${gid}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                // setTaskPriority(response.data.data.custom_fields[2].enum_value)
                // setTaskProgress(response.data.data.custom_fields[1].enum_value)
                // setProductionStatus(response.data.data.custom_fields[0].multi_enum_values);
                setTask(response.data.data)

                const customFields = response.data.data.custom_fields;

                // Check if customFields exists and has the expected length before accessing
                if (customFields && customFields.length > 2) {
                    setTaskPriority(customFields[2]?.enum_value || null);
                    setTaskProgress(customFields[1]?.enum_value || null);
                    setProductionStatus(customFields[0]?.multi_enum_values || []);
                } else {
                    // Handle the case where custom_fields doesn't exist or doesn't have the expected length
                    setTaskPriority(null);
                    setTaskProgress(null);
                    setProductionStatus([]);
                }


                console.log('hydre----', response.data.data)
            }
            setLoading(false);
        }).catch((err) => {
            console.log('err====', err);
            setLoading(false);
        })
    }

    const getTaskAttachment = (gid) => {
        axios.get(`${process.env.REACT_APP_BASE_URL}get-task-attachment/${gid}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setAttachments(response.data.data)
            }
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    const getTaskStories = (gid) => {
        axios.get(`${process.env.REACT_APP_BASE_URL}get-post-task-stories/${gid}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setStories(response.data.data)
            }
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    const PostTaskStory = (data) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}add-story`, data, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setStories([...stories, response.data.data]);
            }
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            task_id: task?.gid,
            text: message
        }
        PostTaskStory(data)
        setMessage('');
    }

    const TaskDetails = (data) => {
        if (data) {
            setIsActive(null);
            setIsActive(true);

            getTaskDetails(data.gid);
            getTaskAttachment(data.gid);
            getTaskStories(data.gid);
        }
    }

    // const TaskDetails = (id) => {
    //     setIsActive(null);
    //     setIsActive(true);
    //     getTaskDetails(id);
    //     getTaskAttachment(id);
    //     getTaskStories(id);
    // }
    const handleOverlayClick = () => {
        setIsActive(null);
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        }
    });

    const handleCheckboxChange = async (event) => {

        Toast.fire({
            icon: "info",
            title: "Processing..."
        });

        let value = event.target.checked;
        let milestoneId = event.target.value;
        // let milestoneId = 1207088794399866;

        let payload = {
            "completed": value
        };

        await axios.put(`${process.env.REACT_APP_BASE_URL}update-milestone/${milestoneId}`, payload, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {

                if (res.status === 200) {
                    setLoading(false);

                    Toast.fire({
                        icon: "success",
                        title: "Task Updated successfully"
                    });

                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);

                Toast.fire({
                    icon: "error",
                    title: 'Something went wrong!',
                });
            })
    };

    const handleClickOpen = (gid) => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const formik = useFormik({
        initialValues: {
            title: '',
            due_date: null,
            assignee: '',

        },
        validationSchema: validationSchema,
        onSubmit: (values, {resetForm}) => {
            CreateTask(resetForm)

        },
    });

    const CreateTask = (resetForm) => {
        setTaskCreateLoading(true)
        const data = {
            "projectId": selectedProject,
            "sectionId": section.gid,
            "taskName": formik.values.title,
            "assignee": formik.values.assignee || null,
            "due_on": formik.values.due_date || null
        };

        console.log('hyder--------------------->',data);
        axios.post(`${process.env.REACT_APP_BASE_URL}create-task`, data, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                resetForm({values: ''})
                setTaskCreateLoading(false)
                handleClose()
                getAllTasksBySectionId();
                Swal.fire({
                    icon: 'success',
                    title: 'Task Created successfully!',
                    showConfirmButton: false,
                    timer: 1000
                });
            })
            .catch((error) => {
                // setLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1000
                });
                setTaskCreateLoading(false)
                handleClose()
                console.log(error);
            });

    };
    

    return (
        <>

            <div className="main_style_box project_listing_accordion ">
                <ul onClick={() => toggleActiveClass(1)} className={activeId === 1 ? 'main_header active' : 'main_header'}>

                    <li>{DropDownArrowIcon} {section.name}</li>
                </ul>

                {
                    tasks && tasks?.length > 0 ? (
                        <>
                            {tasks.map((task, index) => (

                                <>
                                    <div
                                        className={activeId === 1 ? 'project_content_accordion listing_class active' : 'project_content_accordion listing_class'}>

                                        <ul className="main_listing">
                                            <li>
                                                <Form.Check
                                                    className="list_input"
                                                    value={task?.gid}
                                                    type="checkbox"
                                                    defaultChecked={task?.completed}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <h4 onClick={() => toggleDrawer(task)}>{task?.name || ''}</h4>
                                                {/* <span>41 {CommentIcon}</span> */}
                                            </li>
                                            <li>
                                                <div className="date">{task?.due_on ? formatMDY(task?.due_on) : '-'}</div>
                                            </li>
                                            <li>
                                                {/* <div className="pro_list">
                                                {task?.custom_fields?.[0]?.multi_enum_values?.length > 0 ? (
                                                    task.custom_fields[0].multi_enum_values.map((item, index) => (
                                                        <div className={`${item.name.replace(/\s+/g, '')}`} key={index}>{item.name}</div>
                                                    ))
                                                ) : (
                                                    <span>-</span>
                                                )}
                                            </div> */}

                                                <div className="pro_list">
                                                    {task?.custom_fields?.[0]?.multi_enum_values?.length > 0 ? (
                                                        <>
                                                            <div
                                                                className={`${task?.custom_fields?.[0]?.multi_enum_values?.[0].name.replace(/\s+/g, '')}`}>
                                                                {truncateString(task?.custom_fields?.[0]?.multi_enum_values?.[0].name, 6)}
                                                            </div>
                                                            {
                                                                task?.custom_fields?.[0]?.multi_enum_values?.length - 1 !== 0 && (
                                                                    <div className="list_point extra">
                                                                        +{task?.custom_fields?.[0]?.multi_enum_values?.length - 1}
                                                                    </div>
                                                                )
                                                            }
                                                        </>


                                                    ) : (
                                                        <span>-</span>
                                                    )}
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    className={`task_list ${task?.custom_fields?.[1]?.enum_value?.name.replace(/\s+/g, '')}`}>
                                                    {task?.custom_fields?.[1]?.enum_value?.name || '-'}
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    className={`status_list ${task?.custom_fields?.[2]?.enum_value?.name.replace(/\s+/g, '') || ''}`}>
                                                    {task?.custom_fields?.[2]?.enum_value?.name || '-'}
                                                </div>
                                            </li>
                                        </ul>

                                    </div>


                                </>

                            ))}
                            {
                                role !== 'client' &&
                            <div className={activeId === 1 ? 'project_content_accordion listing_class active' : 'project_content_accordion listing_class'}>

                                <ul className="main_listing">
                                    <li onClick={() => handleClickOpen(section)}>
                                        <div className="add_task">
                                            <svg

                                                xmlns="http://www.w3.org/2000/svg"
                                                width="25"
                                                height="26"
                                                fill="none"
                                                viewBox="0 0 30 31"
                                            >
                                                <mask
                                                    id="mask0_19_3197"
                                                    style={{maskType: "alpha"}}
                                                    width="25"
                                                    height="26"
                                                    x="0"
                                                    y="0"
                                                    maskUnits="userSpaceOnUse"
                                                >
                                                    <path fill="#D9D9D9" d="M0.008 0.672H30.008V30.672H0.008z"></path>
                                                </mask>
                                                <g   mask="url(#mask0_19_3197)">
                                                    <path
                                                        width="25"
                                                        height="26"
                                                        fill="#2A303C"
                                                        d="M14.07 16.61H6.884v-1.875h7.188V7.547h1.874v7.188h7.188v1.875h-7.188v7.187h-1.874V16.61z"
                                                    ></path>
                                                </g>
                                            </svg>

                                        </div>
                                        <h4>Add task</h4>
                                    </li>
                                </ul>

                            </div>}
                        </>

                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '0 auto',
                                width: 'fit-content'
                            }}
                            className='mt-5'
                        >
                            <Oval
                                visible={true}
                                height={20}
                                width={20}
                                color="#f37a20"
                                ariaLabel="oval-loading"
                            />
                            <small>Please wait while the content is loading</small>
                        </div>
                    )
                }

                <DrawerCustom
                    loading={drawerLoading}
                    message={message}
                    attachments={attachments}
                    handleSubmit={handleSubmit}
                    open={open}
                    productionStatus={productionStatus}
                    setMessage={setMessage}
                    stories={stories}
                    task={task}
                    taskPriority={taskPriority}
                    taskProgress={taskProgress}
                    tasks={tasks}
                    toggleDrawer={toggleDrawer}
                />

                {/*<div className={isActive ? 'side_bar_popup active' : 'side_bar_popup'}>*/}
                {/*    {!loading && tasks.length !== 0 ? (*/}
                {/*        <>*/}
                {/*            <div className="header_popup">*/}
                {/*                <div className="project_brief_links">*/}
                {/*                    <ul>*/}
                {/*                        /!* <li>*/}
                {/*                            <NavLink to="">*/}
                {/*                                Marketing Board*/}
                {/*                            </NavLink>*/}
                {/*                        </li>*/}
                {/*                        <li className="active">*/}
                {/*                            <NavLink to="">*/}
                {/*                                Logo Provision*/}
                {/*                            </NavLink>*/}
                {/*                        </li> *!/*/}
                {/*                    </ul>*/}
                {/*                </div>*/}
                {/*                <div className="project_brief_closed">*/}
                {/*                    <div className="popup_btn" onClick={DoneActive}>x</div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="scroll_popup">*/}
                {/*                <div className="popup_content">*/}
                {/*                    <div className="pop_title">{task.name}</div>*/}
                {/*                    <div className="marketing_points">*/}
                {/*                        <div>Assignee <span className="assigne">*/}
                {/*                            /!* <div className="assigne_name">ss</div> *!/*/}
                {/*                            <li>{task.assignee ? task.assignee.name : 'No Assignee'}</li></span>*/}
                {/*                        </div>*/}
                {/*                        <div>Due Date <span className="date">*/}
                {/*                            /!* <div className="assigne_date">{CalendarIcon}</div> *!/*/}
                {/*                            {formatMDY(task.due_on) || 'No due date'}</span></div>*/}
                {/*                        <div>Projects  <span className="status"><span className="icon risk">{DotValueIcon}</span>*/}
                {/*                            {task.projects && task.projects.length > 0 ?*/}
                {/*                                task.projects.map((item, index) => (*/}
                {/*                                    <span key={index}>{item.name}</span>*/}
                {/*                                )) :*/}
                {/*                                <span>No Project</span>*/}
                {/*                            }*/}
                {/*                            </span></div>*/}
                {/*                        <div>Dependencies  <span className="dependency">Add dependencies</span></div>*/}
                {/*                    </div>*/}
                {/*                    <div className="tags_marketing">*/}
                {/*                        /!* <div className="tags_list">Production Tags*/}
                {/*                            <ul>*/}
                {/*                            {productionStatus && productionStatus.length > 0 ?*/}
                {/*                                productionStatus.map((item, index) => (*/}
                {/*                                    <li className="custom-red">{item.name}</li>*/}
                {/*                                )) :*/}
                {/*                                <span>No Production Status</span>*/}
                {/*                            }*/}
                {/*                        </ul>*/}
                {/*                        </div> *!/*/}
                {/*                        <div className="tags_list">Production Status*/}
                {/*                            <ul>*/}
                {/*                                {productionStatus && productionStatus.length > 0 ?*/}
                {/*                                    productionStatus.map((item, index) => (*/}
                {/*                                        <li className={`${item.name.replace(/\s+/g, '')}`}>{item.name}</li>*/}
                {/*                                    )) :*/}
                {/*                                    <span>No Production Status</span>*/}
                {/*                                }*/}
                {/*                            </ul>*/}
                {/*                        </div>*/}
                {/*                        <div className="tags_list">Task Status*/}
                {/*                            <ul>*/}
                {/*                                <li className={`${taskProgress?.name ? taskProgress.name.replace(/\s+/g, '') : ''}`}> {taskProgress?.name} </li>*/}
                {/*                            </ul>*/}
                {/*                        </div>*/}
                {/*                        <div className="tags_list">Priority*/}
                {/*                            <ul>*/}
                {/*                                <li className={`${taskPriority?.name}`}> {taskPriority?.name}</li>*/}
                {/*                            </ul>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="marekting_descr">*/}
                {/*                        <div class="pop_title">Description</div>*/}
                {/*                        <p>{task.notes}</p>*/}
                {/*                    </div>*/}


                {/*                    <div className="show_comments">*/}
                {/*                        {*/}
                {/*                            stories.length !== 0 ? stories.map((item, index) => {*/}

                {/*                                const nameArray = item.created_by?.name.split(' ');*/}
                {/*                                const initials = nameArray.map(word => word.charAt(0).toUpperCase()).join('');*/}
                {/*                                const date = new Date(item.created_at);*/}
                {/*                                const formattedDate = date.toLocaleDateString();*/}

                {/*                                let newText;*/}
                {/*                                let projectName;*/}
                {/*                                let assetId;*/}
                {/*                                const regex = /asset_id=(\d+)/;*/}
                {/*                                const regex2 = /https:\/\/app\.asana\.com\/0\/\d+\/list/;*/}
                {/*                                const regex3 = /https:\/\/app\.asana\.com\/0\/\d+\/\d+/;*/}

                {/*                                if (item.type == 'comment' && item.resource_subtype === 'comment_added') {*/}
                {/*                                    const url = item.text.match(regex2);*/}
                {/*                                    const url2 = item.text.match(regex3);*/}

                {/*                                    task.projects !== undefined ? task.projects.map((item) => {*/}
                {/*                                        projectName = item.name;*/}
                {/*                                    }) : null*/}

                {/*                                    newText = item.text.replace(url, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`)*/}
                {/*                                        .replace(/\n/g, '<br/>').replace(url2, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`);*/}
                {/*                                }*/}
                {/*                                if (item.type == 'system' && item.resource_subtype === 'added_to_project') {*/}
                {/*                                    const url = item.text.match(regex3);*/}

                {/*                                    task.projects !== undefined ? task.projects.map((item) => {*/}
                {/*                                        projectName = item.name;*/}
                {/*                                    }) : null*/}

                {/*                                    newText = item.text.replace(url, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`)*/}
                {/*                                        .replace(/\n/g, '<br/>');*/}
                {/*                                }*/}
                {/*                                if (item.type == 'system' && item.resource_subtype === 'assigned' || item.resource_subtype === 'due_date_changed' || item.resource_subtype === 'section_changed') {*/}
                {/*                                    const url = item.text.match(regex3);*/}

                {/*                                    task.projects !== undefined ? task.projects.map((item) => {*/}
                {/*                                        projectName = item.name;*/}
                {/*                                    }) : null*/}

                {/*                                    newText = item.text.replace(url, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`).replace(/\n/g, '<br/>');*/}
                {/*                                }*/}
                {/*                                if (item.type == 'system' && item.resource_subtype === 'attachment_added') {*/}
                {/*                                    const match = item.text.match(regex);*/}
                {/*                                    assetId = match ? match[1] : null;*/}


                {/*                                }*/}
                {/*                                if (item.type == 'system' && item.resource_subtype === 'name_changed' || item.resource_subtype === 'notes_changed') {*/}
                {/*                                    const url = item.text.match(regex3);*/}

                {/*                                    task.projects !== undefined ? task.projects.map((item) => {*/}
                {/*                                        projectName = item.name;*/}
                {/*                                    }) : null*/}

                {/*                                    newText = item.text.replace(url, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`)*/}
                {/*                                        .replace(/\n/g, '<br/>');*/}

                {/*                                }*/}

                {/*                                return (*/}
                {/*                                    <div className="comment_list">*/}
                {/*                                        <div key={index} className="comments_img">*/}
                {/*                                            {*/}
                {/*                                                item.type === 'system' && (item.resource_subtype === 'name_changed' || item.resource_subtype === 'notes_changed' || item.resource_subtype === 'comment_liked') ?*/}
                {/*                                                    null :*/}
                {/*                                                    item.type === 'system' && item.resource_subtype === 'marked_complete' ?*/}
                {/*                                                        <span><TiTick color='black' size={30} /></span> :*/}
                {/*                                                        !item.created_by.photo ?*/}
                {/*                                                            <span>{initials}</span> :*/}
                {/*                                                            <img*/}
                {/*                                                                className='comment_img'*/}
                {/*                                                                src={item.created_by.photo.image_27x27}*/}
                {/*                                                                alt='User Photo'*/}
                {/*                                                            />*/}
                {/*                                            }*/}

                {/*                                        </div>*/}
                {/*                                        <div className="comments_txt">*/}
                {/*                                            <div className="title">{item.created_by?.name} <span className="date">{formattedDate}</span></div>*/}
                {/*                                            {item.type == 'system' && item.resource_subtype === 'name_changed' || item.resource_subtype === 'notes_changed' ? null :*/}
                {/*                                                item.type == 'system' && item.resource_subtype === 'comment_liked' ? null*/}
                {/*                                                    :*/}
                {/*                                                    <p className='date-img'></p>}*/}
                {/*                                            {item.type == 'system' && item.resource_subtype === 'attachment_added' ?*/}
                {/*                                                attachments.map((item) => {*/}
                {/*                                                    if (item.gid === assetId) {*/}
                {/*                                                        const fileExtension = item.name?.substring(item.name.lastIndexOf('.') + 1)?.toLowerCase();*/}
                {/*                                                        let icon;*/}
                {/*                                                        if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'gif') {*/}
                {/*                                                            icon = <img src={item.view_url}*/}
                {/*                                                                className="rounded-md flex flex-shrink-0"*/}
                {/*                                                                alt={`${item.view_url}`} />;*/}
                {/*                                                        } else if (fileExtension === 'pdf') {*/}
                {/*                                                            icon =*/}
                {/*                                                                <div*/}
                {/*                                                                    className=' px-4 h-[80px] justify-center items-center flex gap-2 rounded-md border-1 border-gray-200 '>*/}
                {/*                                                                    <BsFillFilePdfFill size={35}*/}
                {/*                                                                        className='mr-2'*/}
                {/*                                                                        color='red' />*/}
                {/*                                                                    <p className='text-[11px] break-all text-[#6d6e6f] '>{item.name}</p>*/}
                {/*                                                                </div>;*/}
                {/*                                                        } else if (fileExtension === 'mp4') {*/}
                {/*                                                            icon =*/}
                {/*                                                                <div*/}
                {/*                                                                    className=' px-4 h-[80px] justify-center items-center flex gap-2 rounded-md border-1 border-gray-200 '>*/}
                {/*                                                                    <BsFillPlayBtnFill size={35}*/}
                {/*                                                                        className='mr-2'*/}
                {/*                                                                        color='gray' />*/}
                {/*                                                                    <p className='text-[11px] break-all text-[#6d6e6f] '>{item.name}</p>*/}
                {/*                                                                </div>;*/}
                {/*                                                            ;*/}
                {/*                                                        } else if (fileExtension === 'zip') {*/}
                {/*                                                            icon = <div*/}
                {/*                                                                className=' px-4 h-[80px] justify-center items-center flex gap-2 rounded-md border-1 border-gray-200 '>*/}
                {/*                                                                <BsFileZipFill size={35}*/}
                {/*                                                                    className='mr-2'*/}
                {/*                                                                    color='gray' />*/}
                {/*                                                                <p className='text-[11px] break-all text-[#6d6e6f] '>{item.name}</p>*/}
                {/*                                                            </div>;*/}
                {/*                                                        } else {*/}
                {/*                                                            icon = <div*/}
                {/*                                                                className=' px-4 h-[80px] justify-center items-center flex gap-2 rounded-md border-1 border-gray-200 '>*/}
                {/*                                                                <BsFileTextFill size={35}*/}
                {/*                                                                    className='mr-2'*/}
                {/*                                                                    color='blue' />*/}
                {/*                                                                <p className='text-[11px] break-all text-[#6d6e6f] '>{item.name}</p>*/}
                {/*                                                            </div>;*/}
                {/*                                                        }*/}
                {/*                                                        return (*/}
                {/*                                                            <Tooltip title={*/}
                {/*                                                                <React.Fragment>*/}
                {/*                                                                    <p>{item.name}</p>*/}

                {/*                                                                    Uploaded at {formattedDate}*/}
                {/*                                                                </React.Fragment>*/}
                {/*                                                            } followCursor>*/}
                {/*                                                                <div key={index}*/}
                {/*                                                                    className=' cursor-pointer'>*/}

                {/*                                                                    {icon}*/}
                {/*                                                                </div>*/}
                {/*                                                            </Tooltip>*/}
                {/*                                                        )*/}
                {/*                                                    }*/}
                {/*                                                })*/}
                {/*                                                :*/}
                {/*                                                item.type == 'system' && item.resource_subtype === 'name_changed' || item.resource_subtype === 'notes_changed' ?*/}
                {/*                                                    <p className='para_txt'*/}
                {/*                                                        dangerouslySetInnerHTML={{ __html: newText }}></p>*/}
                {/*                                                    :*/}
                {/*                                                    <p className='para_txt'*/}
                {/*                                                        dangerouslySetInnerHTML={{ __html: newText }}></p>*/}

                {/*                                            }*/}

                {/*                                        </div>*/}
                {/*                                    </div>*/}
                {/*                                )*/}

                {/*                            }) : null*/}
                {/*                        }*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div className="comment_main_box">*/}
                {/*                    <div className="project_comments">*/}
                {/*                        <div className="comments_logo">*/}
                {/*                            <img src={LogoImg} alt=""/>*/}
                {/*                        </div>*/}
                {/*                        <div className="msg_box">*/}

                {/*                            <form onSubmit={(e) => handleSubmit(e)}>*/}
                {/*                                <textarea*/}
                {/*                                    className="msg_box_comments"*/}
                {/*                                    value={message}*/}
                {/*                                    onChange={(e) => setMessage(e.target.value)}*/}
                {/*                                    rows={1}*/}
                {/*                                    placeholder="Ask your Question or post an update..."*/}
                {/*                                /> */}
                {/*                                <button type='submit' className="msg_box_btn">Send</button>*/}
                {/*                            </form> */}
                {/*                            */}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    /!* <div className="pro_collaborators">*/}
                {/*                        <div className="left_collaborators">*/}
                {/*                            <ul>*/}
                {/*                                <li>Collaborators</li>*/}
                {/*                                <li><img src={DummyImg} alt="Dummy Img" /></li>*/}
                {/*                                <li>{AddPersonIcon}</li>*/}
                {/*                                <li>{AddPersonIcon}</li>*/}
                {/*                                <li>{PlusIcon}</li>*/}
                {/*                            </ul>*/}
                {/*                        </div>*/}
                {/*                        <div className="right_collaborators">*/}
                {/*                            <ul>*/}
                {/*                                <li>{BellJoinIcon} Join</li>*/}
                {/*                            </ul>*/}
                {/*                        </div>*/}
                {/*                    </div> *!/*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </>*/}
                {/*    ) : (*/}
                {/*        <div className='justify-center items-center flex h-[100%] w-[100%]'>*/}
                {/*            <ThreeDots*/}
                {/*                height="80"*/}
                {/*                width="80"*/}
                {/*                radius="9"*/}
                {/*                color="#F37A20"*/}
                {/*                ariaLabel="three-dots-loading"*/}
                {/*                wrapperStyle={{}}*/}
                {/*                wrapperClassName=""*/}
                {/*                visible={true}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</div>*/}
                {/*{isActive && <div className="overlay" onClick={handleOverlayClick}></div>}*/}

                <Dialog
                    open={Modalopen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Create a Task"}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={formik.handleSubmit} className='space-y-6 '>
                            <div
                                className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <TextField
                                    className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                    id="title"
                                    name="title"
                                    label="Title"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    error={formik.touched.title && Boolean(formik.errors.title)}
                                    helperText={formik.touched.title && formik.errors.title}
                                />
                            </div>
                            <div
                                className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <FormControl className="custom-input" fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                                            <DatePicker
                                                fullWidth
                                                className='w-[100%]'
                                                name="due_date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                id="due_date"
                                                inputFormat="E MMM dd yyyy HH:MM:SS O"
                                                label="Due Date"
                                                value={formik.values.due_date}
                                                onChange={value => formik.setFieldValue('due_date', value)}
                                                error={formik.touched.due_date && Boolean(formik.errors.due_date)}

                                                disablePast
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Assignee</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="assignee"
                                        label="Assignee"
                                        id="assignee"
                                        value={formik.values.assignee}
                                        onChange={formik.handleChange}
                                        error={formik.touched.assignee && Boolean(formik.errors.assignee)}
                                        helperText={formik.touched.assignee && formik.errors.assignee}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        {allAssignee?.filter((element) => element.name !== 'Private User').map((item, index) => {
                                            // console.log(item);
                                            return (

                                                <MenuItem key={index}
                                                          value={`${item.gid}`}>{item.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>

                            </div>
                            <div className='flex justify-end mt-3'>
                                <button
                                    className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-2 text-white'
                                    variant="contained" fullWidth type="submit" disabled={taskCreateLoading}>
                                    {taskCreateLoading ?  <Oval
                                        visible={true}
                                        height="20"
                                        width="20"
                                        color="#ffffff"
                                        secondaryColor="#ffffff"
                                        ariaLabel="oval-loading"
                                        wrapperStyle={{ display: 'flex', justifyContent: 'center' }}
                                        wrapperClass=""
                                    /> : "Create" }
                                </button>
                            </div>
                        </form>
                    </DialogContent>

                </Dialog>

            </div>
        </>
    )
}


function MarketingListView(props) {

    const { user, table, selectedProject } = useStateContext();
    const { role } = user.user;
    const [sections, setSections] = useState([])
    const [loading, setLoading] = useState(false);
    const [allAssignee, setAllAssignee] = useState([]);


    useEffect(() => {
        getAllSections();
        getAllAssignee();
    }, [selectedProject])

    const getAllAssignee = () => {
        if (user) {
            const ASANA_BEARER_TOKEN = "2/1204614194882962/1206884941289659:6993e942413ab55624207ae4379afb7f"
            axios.get(`${process.env.REACT_APP_ASANA_BASE_URL}users?workspace=146928382234467`, {
                headers: {
                    Authorization: `Bearer ${ASANA_BEARER_TOKEN}`,
                }
            })
                .then((res) => {
                    setAllAssignee(res.data.data)
                })
                .catch(err => {
                    console.log('err====', err);
                })
        }
    }

    const getAllSections = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_BASE_URL}get-asana-project-marketing-section/${selectedProject}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setSections(response.data.data)
            }
            setLoading(false);
        }).catch((err) => {
            console.log('err====', err);
            setLoading(false);
        })
    }


    const [activeId, setActiveId] = useState(1);
    const toggleActiveClass = (id) => {
        setActiveId(current => current === id ? null : id);
    };
    return (
        <>
            <div className="main_top_title">
                <div className="main_title"><h1>List <span>View</span></h1></div>
                <div className="main_btn_txt">
                    <ul>
                        <li>
                            <NavLink to="">
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="">
                                Appboard
                            </NavLink>
                        </li>
                        <li className="active">
                            <NavLink to="" >
                                List
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            {/* {
                role !== 'client' && (
                    <div className="list_fillter">
                        <div className="add_task">
                            {PlusIcon}Add task
                        </div>
                        <div className="filter_task">
                            {FillterIcon}Filter
                        </div>
                        <div className="sort_task">
                            {SortIcon} Sort
                        </div>
                        <div className="hide_task">
                            {HideIcon} Hide
                        </div>
                    </div>
                )
            } */}
            <div className="main_content_container">
                <Container fluid className="p-0">
                    <Row>
                        <Col>
                            {/* <div className="main_style_box project_listing listing_class">
                                <ul className="main_header">
                                    <li>Task name</li>
                                    <li>Due Date</li>
                                    <li>Production</li>
                                    <li>Task Progress</li>
                                    <li>Priority</li>
                                </ul>
                            </div> */}
                            {
                                loading ?
                                    <div className="mt-2">
                                        <Oval
                                            visible={true}
                                            height="20"
                                            width="20"
                                            color="#f37a20"
                                            ariaLabel="oval-loading"
                                            wrapperStyle={{ display: 'flex', justifyContent: 'center' }}
                                            wrapperClass=""
                                        />
                                        <small className="flex justify-center">Please wait while the content is
                                            loading</small>
                                    </div> :
                                    <div>
                                        {
                                            sections && sections.map((section) => {
                                                return (
                                                    <Tasks section={section} allAssignee={allAssignee} />
                                                )
                                            })
                                        }
                                    </div>
                            }

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default MarketingListView;