import { useLocation, Navigate, Outlet } from "react-router-dom"

const RequireAuth = () => {
    const token = localStorage.getItem('tekrevol_user');
    const location = useLocation()

    return (
        token
            ? <Outlet />
            : <Navigate to="/signin" state={{ from: location }} replace />
    )
}
export default RequireAuth