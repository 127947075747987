import React, { useEffect, useState } from 'react';
import projectImg1 from '../data/projectImg1.png'
import axios from 'axios'


const OverviewResource=({project})=>{

    // const[resources,setResources] = useState([]);

    let members;
    console.log(project);

    if (project) {
        ({ members } = project);
      }
      
    
    return(
        <div className='' >
            <p className='text-[16px] md:text-[14px] sm:text-[12px] xm:text-[10px] text-[#505887]  font-semibold ' >Resources</p>
        <div className='flex gap-[20px] md:gap-[15px] sm:gap-[12px] xm:gap-[10px] pt-[20px] md:pt-[15px] sm:pt-[10px] xm:pt-[10px]' >

                {members.length !== 0 ? members.map((item,index)=>{
                    const nameArray = item.name.split(' ');
                    const initials = nameArray.map(word => word.charAt(0).toUpperCase()).join('');
                    console.log(item);
                    return(
                        <div className='min-w-[85px] md:min-w-[75px] sm:min-w-[65px] xm:min-w-[55px]' key={index} >
                            <div className='pt-[10px]' >
                            {!item.photo ?
                <div style={{backgroundColor:`${ project.color && project.color !== 'none' ?  project.color?.substring(project.color.lastIndexOf('-') + 1) : 'green'}`}}  className=' rounded-md w-[60px] md:w-[55px] md:h-[55px] sm:w-[45px] sm:h-[45px] xm:w-[40px] xm:h-[40px] mb-1 h-[60px] flex justify-center items-center flex-shrink-0 ' >
                
                <span className='text-white font-bold text-[18px] md:text-[16px] sm:text-[14px] xm:text-[12px] text-center' >{initials}</span> 
                
                </div>
                : 
                <div className=' rounded-md w-[60px] md:w-[55px] md:h-[55px] sm:w-[45px] sm:h-[45px] xm:w-[40px] xm:h-[40px] mb-1 h-[60px] flex justify-center items-center flex-shrink-0 ' >
                <img className='rounded-md' src={item.photo?.image_60x60} alt={item.photo?.image_60x60} />
                </div>
                }
                </div>
                    <p className='text-[13px] md:text-[11px] sm:text-[9px] xm:text-[8px] break-words text-[#505887] font-medium '  >{item.name}</p>
                    <p className='text-[9px] md:text-[9px] sm:text-[8px] xm:text-[7px] text-[#718EBF] font-medium ' >{ item.role ? item.role : item.resource_type}</p>
                </div>

                    )
                }):
                <div>
                    <div className='rounded-lg mb-3' >
                    <img src={projectImg1} className='rounded-lg w-[67px] h-[67px] md:w-[50px] md:h-[50px] sm:w-[40px] sm:h-[40px] xm:w-[35px] xm:h-[35px]  ' alt="projectImg1" />
                    </div>
                    <p className='text-[13px] md:text-[11px] sm:text-[9px] xm:text-[8px] text-[#505887] font-medium '  >John Doe</p>
                    <p className='text-[9px]  md:text-[8px] sm:text-[7px] xm:text-[6px] text-[#718EBF] font-medium ' >Project Manager</p>
                </div>}
                

            </div>
            </div>
    )
}

export default OverviewResource;