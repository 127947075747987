import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

function DashboardNew() {
  const [loading, setLoading] = useState(false)
  return <>
        <div className="main_top_title">
                <div className="main_title"><h1>Dashboard <span>View</span></h1></div>
                <div className="main_btn_txt">
                    <ul>
                        <li>
                            <NavLink to="">
                                Dashboard
                            </NavLink>
                        </li>
                       
                    </ul>
                </div>
            </div>
            <div className="main_content_container">
                <Container fluid className="p-0">
                    <div className="mb-0 custom-row">
                    {
                            loading ?
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    width: 'fit-content'
                                }}
                                className='mt-5'
                            >
                                <Oval
                                    visible={true}
                                    height={20}
                                    width={20}
                                    color="#f37a20"
                                    ariaLabel="oval-loading"
                                />
                                <small>Please wait while the content is loading</small>
                            </div> :      
                        <>
                       
                        <Col lg={8}>
                        <div className='main_style_box'>
                                            <div className="project_title">Heading</div>
                                      
                                        </div>
                        </Col>
                      
                        </>
                           
                        }
                        
                    </div>
                </Container>
            </div>
           
  
  </>;
}

export default DashboardNew;
