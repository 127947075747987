import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import imageBroken from "../img/NoImages.png";
import { NoRecordFound } from "./General";
import { ClockIcon } from "../utils/svg";
import { convertToRequiredDateFormat } from "../utils/Helper";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/navigation";
import { NavLink } from "react-router-dom";

import { MenuItem, InputLabel, FormControl, Select } from '@material-ui/core';
import Blog from "../img/demo/downloadable-demo/articles/blog.png"
import WriterImg from "../img/demo/downloadable-demo/articles/writer.jfif"

SwiperCore.use([Navigation, Pagination]);

// Utility function to chunk the projects array
const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
};


function BlogsView(props) {

    const portfolioMain = props.data;

    // const portfolioMain = [
    //     { imgSrc: Blog, title: "App Cost Calculator: Mobile App Development Cost Estimate in UK", tag: "Technology", Writer: "Tracey Wilson", date: "August 20, 2024", writeImage: WriterImg, link: "https://www.tekrevol.com/blogs" },
    //     { imgSrc: Blog, title: "App Cost Calculator: Mobile App Development Cost Estimate in UK", tag: "Technology", Writer: "Tracey Wilson", date: "August 20, 2024", writeImage: WriterImg, link: "https://www.tekrevol.com/blogs" },
    //     { imgSrc: Blog, title: "App Cost Calculator: Mobile App Development Cost Estimate in UK", tag: "Technology", Writer: "Tracey Wilson", date: "August 20, 2024", writeImage: WriterImg, link: "https://www.tekrevol.com/blogs" },
    //     { imgSrc: Blog, title: "App Cost Calculator: Mobile App Development Cost Estimate in UK", tag: "Technology", Writer: "Tracey Wilson", date: "August 20, 2024", writeImage: WriterImg, link: "https://www.tekrevol.com/blogs" },
    //     { imgSrc: Blog, title: "App Cost Calculator: Mobile App Development Cost Estimate in UK", tag: "Technology", Writer: "Tracey Wilson", date: "August 20, 2024", writeImage: WriterImg, link: "https://www.tekrevol.com/blogs" },
    //     { imgSrc: Blog, title: "App Cost Calculator: Mobile App Development Cost Estimate in UK", tag: "Technology", Writer: "Tracey Wilson", date: "August 20, 2024", writeImage: WriterImg, link: "https://www.tekrevol.com/blogs" },
    //     { imgSrc: Blog, title: "App Cost Calculator: Mobile App Development Cost Estimate in UK", tag: "Technology", Writer: "Tracey Wilson", date: "August 20, 2024", writeImage: WriterImg, link: "https://www.tekrevol.com/blogs" },
    //     { imgSrc: Blog, title: "App Cost Calculator: Mobile App Development Cost Estimate in UK", tag: "Technology", Writer: "Tracey Wilson", date: "August 20, 2024", writeImage: WriterImg, link: "https://www.tekrevol.com/blogs" },
    //     { imgSrc: Blog, title: "App Cost Calculator: Mobile App Development Cost Estimate in UK", tag: "Technology", Writer: "Tracey Wilson", date: "August 20, 2024", writeImage: WriterImg, link: "https://www.tekrevol.com/blogs" },
    //     { imgSrc: Blog, title: "App Cost Calculator: Mobile App Development Cost Estimate in UK", tag: "Technology", Writer: "Tracey Wilson", date: "August 20, 2024", writeImage: WriterImg, link: "https://www.tekrevol.com/blogs" },
    //     { imgSrc: Blog, title: "App Cost Calculator: Mobile App Development Cost Estimate in UK", tag: "Technology", Writer: "Tracey Wilson", date: "August 20, 2024", writeImage: WriterImg, link: "https://www.tekrevol.com/blogs" },
    //     { imgSrc: Blog, title: "App Cost Calculator: Mobile App Development Cost Estimate in UK", tag: "Technology", Writer: "Tracey Wilson", date: "August 20, 2024", writeImage: WriterImg, link: "https://www.tekrevol.com/blogs" },

    //     // Add more projects as needed
    // ];

    const chunkedProjects = chunkArray(portfolioMain, 8);


    const [show, setShow] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleOpenDialog = (item) => {
        setSelectedItem(item);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedItem(null);
    };

    const HtmlContent = ({ htmlString }) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: htmlString }} />
        );
    };


    const swiperRef = useRef(null);

    const [paginationText, setPaginationText] = useState('');

    useEffect(() => {
        const swiper = swiperRef.current.swiper;

        const updatePagination = () => {
            if (swiper) {
                const activeIndex = swiper.activeIndex;
                const totalSlides = chunkedProjects.length; // Total chunks, not total slides
                const slidesPerPage = 8;

                const currentChunkIndex = activeIndex;
                const currentPageStart = currentChunkIndex * slidesPerPage + 1;
                const currentPageEnd = Math.min(currentPageStart + slidesPerPage - 1, portfolioMain.length);
                const totalItems = portfolioMain.length;

                const paginationText = `${currentPageStart}-${currentPageEnd} of ${totalItems}`;
                setPaginationText(paginationText);
            }
        };

        // Initial update
        updatePagination();

        // Update on slide change
        swiper.on('slideChange', updatePagination);

        // Clean up event listener on component unmount
        return () => swiper.off('slideChange', updatePagination);
    }, []);


    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
    const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(true);

    useEffect(() => {
        const swiperInstance = swiperRef.current.swiper;

        const handleSlideChange = () => {
            setIsNextButtonDisabled(swiperInstance.isEnd);
            setIsPrevButtonDisabled(swiperInstance.isBeginning);
        };

        swiperInstance.on('slideChange', handleSlideChange);

        // Initial state setup
        setIsPrevButtonDisabled(swiperInstance.isBeginning);

        return () => {
            swiperInstance.off('slideChange', handleSlideChange);
        };
    }, []);

    const handleNextButtonClick = () => {
        swiperRef.current.swiper.slideNext();
    };

    const handlePrevButtonClick = () => {
        swiperRef.current.swiper.slidePrev();
    };

    // Material UI Select states
    const [selectedProject1, setSelectedProject1] = useState('all'); // Default value set to 'all'
    const [selectedProject2, setSelectedProject2] = useState('all2'); // Default value set to 'all2'

    const handleChangeOption1 = (e) => {
        sessionStorage.setItem('session_project1', e.target.value);
        setSelectedProject1(e.target.value);
    };



    return (
        <Container fluid>
            {/* <Row>
                <Col xxl={6}>
                    <div className="project_title_new">{props?.title}</div>
                </Col>
            </Row> */}

            <Row>

                <div className="downloadable-layout">

                    <div className="downloadable-title-wrap">
                        <div className="project_title_new">{props?.title}</div>

                        {/* <Select
                                fullWidth
                                labelId="demo-simple-select-label"
                                name="article_project_id_1"
                                label="Project Projects"
                                id="article_project_id"
                                value={selectedProject1}
                                onChange={handleChangeOption1}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                <MenuItem key="10" value="all">
                                    All
                                </MenuItem>
                                <MenuItem key="20" value="upcomming">
                                    Upcoming Events
                                </MenuItem>
                                <MenuItem key="30" value="pastevent">
                                    Past Events
                                </MenuItem>
                            </Select> */}
                    </div>


                    {/* {selectedValue && <p>You selected: {selectedValue}</p>} */}

                    <div className="articles-inner">
                        <Swiper
                            ref={swiperRef}
                            spaceBetween={30}
                            slidesPerView={1}
                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev'
                            }}
                            pagination={{ el: '.custom-pagination', clickable: true }}
                            onSwiper={(swiper) => { swiper.pagination.render(); }}
                            autoplay={false}
                            loop={false}
                            modules={[Navigation, Autoplay, Pagination]}
                            className='mySwiper'
                        >
                            {chunkedProjects.map((chunk, chunkIndex) => (
                                <SwiperSlide key={chunkIndex}>
                                    <div className="swiper-slide-content row">
                                        {chunk.map((portfolio, index) => (
                                            <Col xxl={3} xl={6}>

                                                <div className="blogs-inner" key={index}>
                                                    <div className="blogs-img">
                                                        <Link to={'https://www.tekrevol.com/blogs'} style={{ cursor: 'Pointer' }}>
                                                            <img src={portfolio?.file_path} alt="Img01" />
                                                        </Link>
                                                    </div>
                                                    <div className="blogs-content">
                                                        <span className='tag'>{portfolio?.industry_name}</span>
                                                        <Link to={'https://www.tekrevol.com/blogs'} style={{ cursor: 'Pointer' }} className="title">
                                                            <h4>{portfolio?.file_name}</h4>
                                                        </Link>
                                                        <div className="author-wrap">
                                                            <div className="author">
                                                                <img src={portfolio?.creator_image} alt="" />
                                                                {portfolio?.creator_name}
                                                            </div>
                                                            <div className="date">{portfolio?.created_at}</div>
                                                        </div>

                                                    </div>
                                                </div>




                                            </Col>
                                        ))}
                                    </div>
                                </SwiperSlide>
                            ))}

                        </Swiper>

                        

                        { chunkedProjects?.length > 0 ? <div className="downloadable-button-wrap downloadable-pagination-wrap">
                            <div className="custom-pagination"> {paginationText}</div>
                            <div
                                className={`downloadable-swiper-button-prev downloadable-swiper-prev downloadable-swiper-button ${isPrevButtonDisabled ? 'swiper-button-disabled' : ''}`}
                                onClick={handlePrevButtonClick}
                            >
                                <div className="btn_swiper">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 25 24"
                                    >
                                        <path
                                            fill="#F37A20"
                                            fillRule="evenodd"
                                            d="M20.2 11.387l-.083-.094-5-5a1 1 0 00-1.498 1.32l.084.094L16.995 11H5.41a1 1 0 00-.117 1.993L5.41 13h11.585l-3.292 3.293a1 1 0 00-.084 1.32l.084.094a1 1 0 001.32.083l.094-.083 5-5a1 1 0 00.083-1.32z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                            <div
                                className={`downloadable-swiper-button-next downloadable-swiper-next downloadable-swiper-button ${isNextButtonDisabled ? 'swiper-button-disabled' : ''}`}
                                onClick={handleNextButtonClick}
                            >
                                <div className="btn_swiper">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 25 24"
                                    >
                                        <path
                                            fill="#F37A20"
                                            fillRule="evenodd"
                                            d="M20.2 11.387l-.083-.094-5-5a1 1 0 00-1.498 1.32l.084.094L16.995 11H5.41a1 1 0 00-.117 1.993L5.41 13h11.585l-3.292 3.293a1 1 0 00-.084 1.32l.084.094a1 1 0 001.32.083l.094-.083 5-5a1 1 0 00.083-1.32z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                            </div> : 'No record found.'
                        }

                    </div>
                </div>



                {/* {props.data && props.data.length > 0 ?
                    props.data.map((item, index) => {

                        return (
                            <Col xxl={3} xl={6}>
                                <div className="blogs_section" style={props?.event ? { height: "350px" } : undefined}>
                                    <div className="blogs_img">
                                        <Link to={'https://www.tekrevol.com/blogs'} style={{cursor:'Pointer'}}>
                                            <img src={item.file_path} alt="Img01" onError={(event) => {
                                                event.target.src = imageBroken; // Replace with your dummy image path
                                            }} />
                                        </Link>

                                    </div>
                                    <div className="blogs_content">
                                        <h4>{item.file_name}</h4>


                                        <div className="txt">{item?.description}</div>

                                        <div className="date"><ClockIcon/>{convertToRequiredDateFormat(item?.created_at)}</div>
                                        
                                    </div>
                                </div>
                            </Col>
                        )

                    })
                    : <NoRecordFound />
                } */}

            </Row>



            <Modal show={show} onHide={handleClose} className="blog_modals">

                {selectedItem && (
                    <>
                        <Modal.Title>{selectedItem.file_name}</Modal.Title>

                        <Modal.Body>
                            <HtmlContent htmlString={selectedItem?.description?.replace(/\n/g, '<br>')} />
                            {/*<p>{selectedItem.description}</p>*/}
                        </Modal.Body>
                    </>
                )}

            </Modal>

        </Container>
    );
}

export default BlogsView;