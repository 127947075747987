import React, { useState } from 'react';
import { Loader, Toast } from "../components/General";
import { DownloadIcon, DeletedIcon, ViewIcon } from "../app-constants";

const DownloadButtonDemo = ({ url, fileName }) => {
    const [loader, setLoader] = useState(false);

    const downloadFile = async () => {
        try {
            // setLoader(true);
            const response = await fetch(url);
            if (!response.ok) throw new Error('Network response was not ok');
            const blob = await response.blob();
            const urlObject = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = urlObject;

            // Extract file name from response headers or use a default name
            const contentDisposition = response.headers.get('content-disposition');
            let fileNameToUse = 'downloaded_file';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                if (fileNameMatch.length > 1) {
                    fileNameToUse = fileNameMatch[1];
                }
            } else if (fileName) {
                fileNameToUse = fileName;
            }

            a.download = fileNameToUse;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(urlObject);
            setLoader(false);
        } catch (error) {
            setLoader(false);
            Toast.fire({
                icon: 'error',
                title: `Error downloading file:${error}` || 'Something went wrong!',
            });
            console.error('Error downloading file:', error);
        }
    };

    return (
        <>
            <li onClick={downloadFile} style={{ cursor: "pointer" }}>
                {loader ? <Loader /> : DownloadIcon}
            </li>
            {/* <li style={{ cursor: "pointer" }}>
                {loader ? <Loader /> : ViewIcon}
            </li>
            <li style={{ cursor: "pointer" }}>
                {loader ? <Loader /> : DeletedIcon}
            </li> */}
        </>
    );
};

export default DownloadButtonDemo;
