import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FillterIcon } from "../../app-constants";
import ColumnChart from "./Chart";
import PieChart from "./PieCharts";
import ProjectDesc from "./ProjectDesc";
import { useStateContext } from "../../contexts/ContextProvider";
import React, { useEffect, useState } from "react";
import axiosInstance from '../../axios/axiosInstance';
import { Oval } from 'react-loader-spinner';
import { Loader } from "../../components/General";


function OverviewBoard() {

    const { selectedProject, getAllManualProjects } = useStateContext();
    const [loading, setLoading] = useState(false);

    const [taskData, setTaskData] = useState({
        "completed_task": 0,
        "incompleted_task": 0,
        "total_task": 0,
    });

    const [stats, setStats] = useState([]);

    const getDashboardCounts = async () => {

        if (selectedProject) {
            setLoading(true);

            await axiosInstance.get(`get-asana-dashboard-counts/${selectedProject}`)
                .then((response) => {

                    let payData = response?.data

                    setStats([
                        {
                            label: 'Complete Tasks',
                            count: payData.num_completed_tasks,
                        },
                        {
                            label: 'Incomplete Tasks',
                            count: payData.num_incomplete_tasks,
                        },
                        {
                            label: 'Milestones',
                            count: payData.num_milestones,
                        },
                        {
                            label: 'Total Tasks',
                            count: payData.num_tasks,
                        },
                    ])

                    setTaskData({
                        "total_task": payData?.num_tasks,
                        "completed_task": payData?.num_completed_tasks,
                        "incompleted_task": payData?.num_incomplete_tasks,
                    })
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }


    useEffect(() => {

        getDashboardCounts();
        getAllManualProjects();

    }, [selectedProject]);


    return (
        <>
            <div className="main_top_title">

                <div className="main_title"><h1>Overview <span>
                    {/* {sessionProjectDetail?.name} */}
                </span></h1></div>
                <div className="main_btn_txt">
                    {/* <ul>
                        <li>
                            <NavLink to="">
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="">
                                Appboard
                            </NavLink>
                        </li>
                        <li className="active">
                            <NavLink to="" >
                                Overview
                            </NavLink>
                        </li>
                    </ul> */}
                </div>
            </div>
            <div className="main_content_container">
                <Container fluid className="p-0">
                    <Row>
                        {
                            loading ? <Loader /> :
                                stats.map((stat, index) =>
                                (
                                    <Col key={index}>
                                        <div className='main_style_box dash'>
                                            <h4>{stat?.label}</h4>
                                            <div className="dash_numbers">{stat?.count}</div>
                                            {/* <div className="dash_fiilter">{FillterIcon}1 Filter</div> */}
                                        </div>
                                    </Col>
                                )
                                )
                        }
                    </Row>
                    <Row className="dash_graph">
                        <Col xxl={6} xl={12}>
                            <div className='main_style_box graph'>
                                <h4>Incomplete tasks by section</h4>
                                <div className="">
                                    <ColumnChart />
                                </div>
                                {/* <div className="dash_fiilter">{FillterIcon}2 Filters</div> */}
                            </div>
                        </Col>
                        <Col xxl={6} xl={12}>
                            <div className='main_style_box graph'>
                                <h4>Total tasks by completion status</h4>
                                <div className="">
                                    {
                                        loading ? <Loader /> : <PieChart taskData={taskData} />
                                    }

                                </div>
                                {/* <div className="dash_fiilter">{FillterIcon}1 Filters</div> */}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ProjectDesc />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default OverviewBoard;