import ResourcesImg from "../../img/KeyResources.png";
import { AttchedIcon, BriefIcon, DropDownArrowIcon, PlusIcon, ThreeDots } from "../../app-constants";
import {Link, NavLink} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Img01 from "../../img/referrals.jpg";
import { useStateContext } from "../../contexts/ContextProvider";
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Loader, Toast } from "../../components/General";
import Dropdown from 'react-bootstrap/Dropdown';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Oval } from "react-loader-spinner";
import Slide from "@mui/material/Slide";
import { S3Upload } from "../../utils/S3Upload";
import {AddIcon, FileIcon} from "../../utils/svg";

const validationSchema = yup.object({
    project_brief: yup
        .string()
        .min(6, 'Too Short!')
        .max(255, 'Too Long!')
        .required('Description is required'),
});


const validationAttachmentSchema = yup.object({
    file_name: yup
        .string()
        .required('File Name is required'),
    link: yup.string().when("attachment", (attachment, schema) => {
        return attachment[0] == 1 ? schema.notRequired() : schema.required('link is required')
    }
    ),
    file: yup.string().when("attachment", (attachment, schema) => {
        return attachment[0] == 0 ? schema.notRequired() : schema.required('file is required')
    }
    ),
    attachment: yup.number().required(),
});


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});



function KeyResources() {
    const { user } = useStateContext();


    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isAddImg, setIsAddImg] = useState(false);
    const [isFile, setIsFile] = useState(false);

    const [projectBrief, setProjectBrief] = useState([]);
    const [isView, setIsView] = useState(false);

    const [projectBriefEdit, setProjectBriefEdit] = useState(false);
    const [briefId, setBriefId] = useState(null);
    const [projectId, setProjectId] = useState(null);

    const { selectedProject, sessionProjectDetail, getSessionProjectDetails } = useStateContext();

    const [selectedProjectAsanaId, setSelectedProjectAsanaId] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [selectedFile, setSelectedFile] = useState(false);
    const fileInputRef = useRef(null);
    const [attachmentDetail, setAttachmentDetail] = useState([]);





    const formik = useFormik({
        initialValues: {
            project_brief: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            updateProjectDetail(values);
            resetForm();
        },
    });


    const formikAttachment = useFormik({
        initialValues: {
            file_name: '',
            link: '',
            attachment: 0,
            file: null,
        },
        validationSchema: validationAttachmentSchema,
        onSubmit: (values, { resetForm }) => {
            handleUpload(selectedFile, values, resetForm);
            // resetForm();
        },
    });


    const handleUpload = async (file, values, resetForm) => {

        setBtnLoader(true);

        try {
            if (formikAttachment.values.attachment == 0) {
                await updateProjectAttachmentDetail(null, values, resetForm);
            } else {
                const response = await S3Upload(file.type, file.name, file)
                if (response) {
                    await updateProjectAttachmentDetail(response, values, resetForm);
                }
            }

        } catch (error) {
            setBtnLoader(false)
            console.error("error handle upload", error)
        }
    }

    const handleFileInput = (e) => {
        formikAttachment.setFieldValue('file', e.target.files[0])
        setSelectedFile(e.target.files[0]);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        formik.resetForm({ values: '' });
        setSelectedFile(false);
        if (fileInputRef?.current?.value) {
            fileInputRef.current.value = null;
        }

        setOpen(false);
    };

    useEffect(() => {
        getSessionProjectDetails();
        getProjectBrief();
        // getSelectedProjectAsanaId();
    }, [selectedProject]);

    useEffect(() => {
        getProjectAttachmentDetail();
    }, [sessionProjectDetail]);


  


    // const getSelectedProjectAsanaId = async () => {
    //     if (selectedProject) {

    //         setLoading(true);

    //         let url = `${process.env.REACT_APP_BASE_URL}get-selected-project/${selectedProject}`;

    //         await axios.get(url, {
    //             headers: {
    //                 'Authorization': `Bearer ${user.token}`
    //             }
    //         })
    //         .then((res) => {
    //             if (res?.status) {
    //                 setSelectedProjectAsanaId(res?.data?.data?.asana_project_id);
    //             }
    //         })
    //         .catch(err => {
    //             setIsDone(false);
    //         })
    //         .finally(() => {

    //             console.log(selectedProjectAsanaId);
    //             setLoading(false);
    //         });
    //     }
    // }

    const getProjectBrief = async () => {
        if (selectedProject) {

            setLoading(true);

            let url = `${process.env.REACT_APP_BASE_URL}get-project-brief/${selectedProject}`;

            await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res?.status) {
                        setProjectBrief(res?.data.data);
                        // setIsDone(true);
                    }
                })
                .catch(err => {

                    setIsDone(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const updateProjectDetail = async (values) => {

        if (briefId) {

            setIsActive(false);
            Toast.fire({
                icon: "info",
                title: "Processing..."
            });

            let payload = {
                'text': values.project_brief,
            }

            await axios
                .put(
                    `${process.env.REACT_APP_BASE_URL}update-project-brief/${briefId}`,
                    payload,
                    {
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                        },
                    }
                )
                .then((res) => {

                    if (res.status == 200) {
                        // setIsDone(true);

                        Toast.fire({
                            icon: "success",
                            title: "Project Brief Updated Successfully",
                        });

                        getProjectBrief();
                    }
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: 'Something went wrong!',
                    });
                })
                .finally(() => {
                    setLoading(false);
                });

            return;
        }

        let payload = {
            'text': values.project_brief,
            'project_id': sessionProjectDetail.asana_project_id,
        }

        setIsActive(false);
        Toast.fire({
            icon: "info",
            title: "Processing..."
        });

        await axios
            .post(
                `${process.env.REACT_APP_BASE_URL}add-project-brief`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                }
            )
            .then((res) => {

                if (res.status == 200) {
                    Toast.fire({
                        icon: "success",
                        title: "Project Brief Added Successfully",
                    });

                    getProjectBrief();
                }
            })
            .catch(() => {
                Toast.fire({
                    icon: "error",
                    title: "Something went wrong Please try again later.",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const updateProjectAttachmentDetail = async (file, values, resetForm) => {
        if (selectedProject) {
            setIsActive(false);
            let payload = {
                'resource_subtype': 'external',
                'parent': sessionProjectDetail.asana_project_id,
                'name': values.file_name,
                'url': values.attachment === 0 ? values.link : file,
            }

            await axios
                .post(
                    `${process.env.REACT_APP_BASE_URL}insert-key-resource-attachment`,
                    payload,
                    {
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                        },
                    }
                )
                .then((res) => {

                    if (res.status == 200) {
                        // setIsDone(true);
                        getProjectAttachmentDetail();
                        Toast.fire({
                            icon: "success",
                            title: "Project Attachments added Successfully",
                        });

                    }
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: 'Something went wrong!',
                    });
                })
                .finally(() => {
                    handleClose()
                    resetForm({ values: '' })
                    setBtnLoader(false)
                });

            return;
        } else {
            setBtnLoader(false)
            Toast.fire({
                icon: "error",
                title: 'Project Id not found!',
            });
        }

    };

    const getProjectAttachmentDetail = async () => {

        if (sessionProjectDetail?.asana_project_id) {

            const options = {
                method: 'GET',
                headers: {
                    accept: 'application/json',
                    authorization: user.user.asana_token
                }
            };

            await axios
                .get(`https://app.asana.com/api/1.0/attachments?parent=${sessionProjectDetail.asana_project_id}&opt_fields=name,view_url`, options)
                .then((res) => {

                    // console.log("getProjectAttachmentDetail");
                    // console.log(res.data.data);
                    setAttachmentDetail(res.data.data);

                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {

                });
        }
    };


    const toggleActive = (value) => {
        console.log("log")
        // setIsActive(current => !current);
        setIsActive(true);

        setProjectBriefEdit(true);
        formik.setFieldValue('project_brief', value?.text);
    };


    const editProjectBrief = (value) => {

        // setIsActive(current => !current);
        setIsActive(true);

        setProjectBriefEdit(true);
        setBriefId(value?.gid);
        formik.setFieldValue('project_brief', value?.text);
    };

    const closeBrief = () => {
        formik.resetForm()
        setIsActive(false);
        setProjectBriefEdit(false);

    }

    const DoneActive = () => {
        setIsDone(current => !current);
        setIsActive(current => !current);
        setIsEdit(false);
    };

    const EditActive = () => {
        setIsEdit(current => !current);
    };

    const FileEditActive = () => {
        setIsFile(current => !current);
    };

    const DeleteActive = () => {
        setIsDone(current => !current);
        setIsEdit(false);
    };

    const AddImg = () => {
        setIsAddImg(true);
    };

    const toggleImg = () => {
        setIsAddImg(true);
    };

    const DeleteImg = () => {
        setIsAddImg(false);
    };

    const viewProjectBrief = () => {
        setIsActive(true);
    };

    const deleteProjectBrief = (gid) => {

        Swal.fire({
            title: "Are you sure you want to Delete?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#F37A20",
            cancelButtonColor: "#0F1328",
            confirmButtonText: "Yes, Delete!",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteProjectBriefRecord(gid);
            }
        });
    };

    const deleteProjectAttachment = (gid) => {

        Swal.fire({
            title: "Are you sure you want to Delete?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#F37A20",
            cancelButtonColor: "#0F1328",
            confirmButtonText: "Yes, Delete!",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteProjectAttachmentRecord(gid);
            }
        });
    };

    const deleteProjectBriefRecord = (gid) => {
        if (gid) {

            Toast.fire({
                icon: "info",
                title: "Processing..."
            });

            let url = `${process.env.REACT_APP_BASE_URL}delete-project-brief/${gid}`;

            axios.delete(url, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res?.status) {
                        Toast.fire({
                            icon: "success",
                            title: "Project Brief Deleted Successfully."
                        });
                         setBriefId(null)
                        getProjectBrief();
                    }
                })
                .catch(err => {
                    setLoading(false);

                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong, Please try again later. "
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }


    const deleteProjectAttachmentRecord = (gid) => {
        if (gid) {

            Toast.fire({
                icon: "info",
                title: "Processing..."
            });

            let url = `${process.env.REACT_APP_ASANA_BASE_URL}attachments/${gid}`;

            axios.delete(url, {
                headers: {
                    'Authorization': `${user?.user?.asana_token}`
                }
            })
                .then((res) => {
                    if (res?.status) {
                        Toast.fire({
                            icon: "success",
                            title: "Project Attachment Deleted Successfully."
                        });

                        getProjectAttachmentDetail();
                    }
                })
                .catch(err => {
                    setLoading(false);

                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong, Please try again later. "
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const HtmlContent = ({ htmlString }) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: htmlString }} />
        );
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            {
                projectBrief && Object.keys(projectBrief).length > 0 ?
                    (
                        <>
                            <div className={'key_brife active'}>
                                <div className="key_brife_img">
                                    <h5>{sessionProjectDetail?.name} - project brief </h5>
                                </div>
                                <div className="key_brife_content">
                                    <p>
                                        <HtmlContent htmlString={projectBrief?.html_text} />
                                    </p>
                                </div>
                                <div className="dot_edit">
                                    <Dropdown >
                                        <Dropdown.Toggle id="dropdown-basic" >
                                            {ThreeDots}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => editProjectBrief(projectBrief)}>Edit</Dropdown.Item>
                                            <Dropdown.Item onClick={() => deleteProjectBrief(projectBrief?.gid)}>Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {/*<div onClick={EditActive}>{ThreeDots}</div>*/}
                                    {/*<ul className={isEdit ? 'active' : ''}>*/}
                                    {/*    <li onClick={() => editProjectBrief(projectBrief)}>Edit</li>*/}
                                    {/*    <li onClick={() => deleteProjectBrief(projectBrief?.gid)} >Delete</li>*/}
                                    {/*</ul>*/}
                                </div>
                            </div>
                            <div className="attachment_container">
                                <div className="add_attachment_box" onClick={handleClickOpen}>
                                    <AddIcon/>
                                </div>
                                {attachmentDetail.length ? attachmentDetail.map((item)=>{
                                    return <div className="attachment_box" key={item?.gid}>
                                        <Link to={item?.view_url} target="_blank" className="attachment_link">
                                        <FileIcon/>
                                        <div className="attachment_title">
                                            {item.name}
                                        </div>
                                        </Link>

                                        <div className="dot_edit">
                                            <Dropdown >
                                                <Dropdown.Toggle id="dropdown-basic" >
                                                    {ThreeDots}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => deleteProjectAttachment(item?.gid)}>Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            {/*<div onClick={EditActive}>{ThreeDots}</div>*/}
                                            {/*<ul className={isEdit ? 'active' : ''}>*/}
                                            {/*    <li onClick={() => editProjectBrief(projectBrief)}>Edit</li>*/}
                                            {/*    <li onClick={() => deleteProjectBrief(projectBrief?.gid)} >Delete</li>*/}
                                            {/*</ul>*/}
                                        </div>
                                    </div>
                                }) : null}

                            </div>

                        </>


                    ) : attachmentDetail && Object.keys(attachmentDetail).length > 0 ?
                    (

                            <div className="attachment_container">
                                <div className="add_attachment_box">
                                    <div className="dot_edit">
                                    <Dropdown >
                                        <Dropdown.Toggle id="dropdown-basic" >
                                            <AddIcon/>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => toggleActive()}>{BriefIcon}  Create project brief</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleClickOpen()}>{AttchedIcon}Add links & files</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </div>

                                </div>
                                {attachmentDetail.length ? attachmentDetail.map((item)=>{
                                    return <div className="attachment_box" key={item?.gid}>
                                        <Link to={item?.view_url} target="_blank" className="attachment_link">
                                            <FileIcon/>
                                            <div className="attachment_title">
                                                {item.name}
                                            </div>
                                        </Link>

                                        <div className="dot_edit">
                                            <Dropdown >
                                                <Dropdown.Toggle id="dropdown-basic" >
                                                    {ThreeDots}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => deleteProjectAttachment(item?.gid)}>Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            {/*<div onClick={EditActive}>{ThreeDots}</div>*/}
                                            {/*<ul className={isEdit ? 'active' : ''}>*/}
                                            {/*    <li onClick={() => editProjectBrief(projectBrief)}>Edit</li>*/}
                                            {/*    <li onClick={() => deleteProjectBrief(projectBrief?.gid)} >Delete</li>*/}
                                            {/*</ul>*/}
                                        </div>
                                    </div>
                                }) : null}

                            </div>



                    ):(

                        <div className="key_resources">
                            <div className="key_imges">
                                <div className="key_imges">
                                    <img src={ResourcesImg} alt="KeyResources" />
                                </div>
                            </div>
                            <div className="key_content">
                                <p>Align your team around a shared vision with a project brief and supporting resources.</p>
                                <ul className="key_main_btn">
                                    <li onClick={toggleActive} className={isActive ? 'active' : ''} >{BriefIcon}  Create project brief</li>
                                    <li onClick={handleClickOpen}>{AttchedIcon}Add links & files</li>
                                </ul>
                            </div>
                        </div>
                    )
            }


            <form onSubmit={formik.handleSubmit}>
                <div className={isActive ? 'side_bar_popup active' : 'side_bar_popup'}>
                    <div className="header_popup project_brief">
                        <div className="project_brief_links">
                            <ul>
                                <li>
                                    <NavLink to="">
                                        {sessionProjectDetail?.name}
                                    </NavLink>
                                </li>
                                <li className="active">
                                    <NavLink to="">
                                        Project Brief
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="project_brief_closed">
                            <button type="button" onClick={() => closeBrief()} className="popup_btn cancel" >Cancel</button>
                            <button type="submit" className="popup_btn done" >Done</button>
                        </div>
                    </div>
                    <div className="popup_content">
                        <div className="pop_title">{sessionProjectDetail?.name}  - Project Brief</div>
                        <div className="msg_box">

                            <Form.Control
                                as="textarea"
                                className="msg_area"
                                id="project_brief"
                                name="project_brief"
                                value={formik.values.project_brief}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.project_brief && !!formik.errors.project_brief}
                            />


                            <Form.Control.Feedback type="invalid">
                                {formik.touched.project_brief && formik.errors.project_brief}
                            </Form.Control.Feedback>
                        </div>
                    </div>
                </div>
            </form>


            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Add Attachment"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={formikAttachment.handleSubmit} className='space-y-6 '>

                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <TextField
                                className='w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                id="file_name"
                                name="file_name"
                                label="File Name"
                                value={formikAttachment.values.file_name}
                                onChange={formikAttachment.handleChange}
                                error={formikAttachment.touched.file_name && Boolean(formikAttachment.errors.file_name)}
                                helperText={formikAttachment.touched.file_name && formikAttachment.errors.file_name}
                            />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Attachment Type</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    name="attachment"
                                    label="Attachment Type"
                                    id="attachment"
                                    value={formikAttachment.values.attachment}
                                    onChange={formikAttachment.handleChange}
                                    error={formikAttachment.touched.attachment && Boolean(formikAttachment.errors.attachment)}
                                    helperText={formikAttachment.touched.attachment && formikAttachment.errors.attachment}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    <MenuItem value={0}>Link</MenuItem>
                                    <MenuItem value={1}>File</MenuItem>
                                </Select>

                                {formikAttachment.touched.attachment && <div style={{ color: 'red', marginTop: '7px' }}>{formikAttachment.errors.attachment}</div>}
                            </FormControl>
                        </div>
                        {!formikAttachment.values.attachment ?
                            <div
                                className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <TextField
                                    className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                    id="link"
                                    name="link"
                                    label="Link"
                                    value={formikAttachment.values.link}
                                    onChange={formikAttachment.handleChange}
                                    error={formikAttachment.errors.link && Boolean(formikAttachment.errors.link)}
                                    helperText={formikAttachment.errors.link && formikAttachment.errors.link}
                                />
                            </div> : null

                        }

                        {formikAttachment.values.attachment ?
                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <div className="file-upload-container">
                                    <input type="file" name="file" ref={fileInputRef} onChange={handleFileInput} />
                                    {formikAttachment.errors.file && <div style={{ color: 'red', marginTop: '7px' }}>{formikAttachment.errors.file}</div>}
                                </div>


                            </div> : null
                        }



                        <div className='flex justify-end mt-3'>
                            <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white'
                                variant="contained" fullWidth type="submit" disabled={btnLoader}>
                                {btnLoader ? <Oval
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#ffffff"
                                    secondaryColor="#ffffff"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{ disply: 'flex', justifyContent: 'center' }}
                                    wrapperClass=""
                                /> : 'Save'}
                            </button>
                        </div>
                    </form>
                </DialogContent>

            </Dialog>

        </>
    );

}

export default KeyResources;


{/* {file && (
               
        <div  className="img_added active" onClick={FileEditActive}>
            <img src={file} alt="Img01"/>
            <div className={isFile ? 'hover_arrow active' : 'hover_arrow'}>
                {DropDownArrowIcon}
            </div>

            <ul className={isFile ? 'active' : ''}>
                <li>Download</li>
                <li onClick={handleFileRemove}>Delete</li>
            </ul>
        </div> )} */}



// useEffect(() => {
//     setLoading(true);
//     getSessionProjectDetails();
//     setTimeout(() => {
//         setLoading(false);
//     }, 3000);
// }, [project]);

// useEffect(() => {
//     setLoading(true);
//     getProjectBrief();
//     setTimeout(() => {

//         console.log("Test");
//         console.log(projectBrief);

//         formik.setFieldValue('project_brief', projectBrief.title)
//     }, 1000);

//     setTimeout(() => {
//         setLoading(false);
//     }, 3000);
// }, [project]);

// useEffect(() => {
//     setLoading(true);
//     getProjectBrief();

//     getSessionProjectDetails();

//     // formik.setFieldValue('project_brief', "projectBrief.title")


//     setTimeout(() => {
//         setLoading(false);
//     }, 3000);
// }, []);