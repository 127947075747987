import React from 'react';
import Rectangle89 from '../data/Rectangle89.png'
import note from '../data/note.png'
import { useStateContext } from '../contexts/ContextProvider'
import Table from './Table';

const OverviewTab = ({ project }) => {
    const { table, loading, getAllProjects } = useStateContext();
    console.log("TABLE", table)
    const mostRecentProject = table && table?.length > 0 ? table?.filter(el => el?.gid == project?.gid) : null;
    return (
        <div className='' >
            <div className=' ' >
                <div className='flex gap-[15px]' >
                    <div style={{ backgroundColor: `${project.color && project.color !== 'none' ? project.color?.substring(project.color.lastIndexOf('-') + 1) : 'green'}` }} className=' rounded-lg w-[40px] md:w-[35px] md:h-[35px] sm:w-[30px] sm:h-[30px] xm:w-[25px] xm:h-[25px] h-[40px] flex justify-center items-center flex-shrink-0 ' >
                        <p className='text-white font-bold text-[20px] md:text-[18px] sm:text-[16px] xm:text-[14px] text-center' >{project.name.charAt(0)}</p>
                    </div>
                    <div className='' >
                        <p className='text-[#979797] break-all text-[14px] md:text-[12px] sm:text-[10px] xm:text-[9px] font-normal' >
                            {
                                project.name
                            }
                        </p>
                        {project.current_status?.text &&
                            <p className='text-[#979797] break-all text-[14px] md:text-[12px] sm:text-[10px] xm:text-[9px] font-normal' >
                                {project.current_status.text}
                            </p>
                        }
                        <br />
                        <p
                            className='text-[#979797]  text-[14px] md:text-[12px] sm:text-[10px] xm:text-[9px] break-all font-normal'
                            dangerouslySetInnerHTML={{
                                __html: project.html_notes == '<body></body>' ? (
                                    `
                                                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus minus accusantium sed. Cum quod, inventore porro facilis labore pariatur quia deleniti necessitatibus doloremque assumenda aliquid expedita temporibus laboriosam ipsam dolores.
                                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur laudantium sint ipsa, aliquam ullam obcaecati atque sunt quia, repellendus odio deleniti, minima veritatis iste placeat? Molestiae dignissimos magnam repellendus iure.        
                                                                    `
                                ) : (mostRecentProject[0].html_notes)
                            }}
                        >
                        </p>

                        {/* <br />
                        <p className='text-[#979797]  text-[14px]  md:text-[12px] sm:text-[10px] xm:text-[9px] break-all font-normal ' >
                            We collaborate with entrepreneurs and industry leaders to emphasize the importance of digital transformation. We regularly invite technopreneurs and change leaders to talk about how technology played a crucial role</p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OverviewTab;