import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const PieChart = ({ taskData }) => {
    const { CanvasJSChart } = CanvasJSReact;

    const options = {
        animationEnabled: true,

        legend: {
            cursor: "pointer",
            verticalAlign: "bottom", // Align vertically center
            horizontalAlign: "center" // Align horizontally to the right side of the chart
        },
        subtitles: [{
            text: taskData?.total_task,
            verticalAlign: "center",
            fontSize: 70,
            dockInsidePlotArea: true,
            fontColor: "#505887"

        }],
        data: [{
            type: "doughnut",
            innerRadius: "75%",
            showInLegend: true, // Enable legend display
            legendText: "{name}", // Set what text the legend should show
          //indexLabelPlacement: "inside", // Optional: to display labels outside the doughnut
            //indexLabel: "{name}: {y}%", // Optional: display labels with percentage
            yValueFormatString: "###,###'%'",
            dataPoints: [
                { name: "Incomplete", y: taskData?.incompleted_task, color: "#FFD1AF" },
                { name: "Completed", y: taskData?.completed_task, color: "#F37A20" },
                // { name: "Neutral", y: 15, color: "#84ECDEFF" },
                // { name: "Dissatisfied", y: 5, color: "#E3A0AEFF" },
                // { name: "Very Dissatisfied", y: 5, color: "#F79646" }
            ]
        }]
    };

    return <CanvasJSChart options={options} />;
};

export default PieChart;
