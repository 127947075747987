import React,{useState,useEffect} from 'react'
import { useStateContext } from '../contexts/ContextProvider';
import {MdDelete} from 'react-icons/md';
import {RiEditBoxFill} from 'react-icons/ri';
import axios from 'axios';
import Swal from 'sweetalert2';
import {Link} from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner'

const  UserTable=()=> {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("tekrevol_user");
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const {activeMenu } = useStateContext();
  const[users,setUsers] = useState([]);
  const[loading,setLoading] = useState(false);

  const getAllUsers=()=>{
    axios.get(`${process.env.REACT_APP_BASE_URL}get_users_only`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }).then((response)=>{
        setUsers(response.data.data);
    }).catch((err)=>{
      console.log('err====',err);
    })
    // console.log(table);
  }
  const deleteUser = async(id) => {
    setLoading(true)
    try {
        await axios.delete(`${process.env.REACT_APP_BASE_URL}delete_user/${id}`)
            .then((res) => {
                if (res.status === 200) {
                  getAllUsers();
                  setLoading(false);
                  Swal.fire(
                    'Deleted!',
                    'User has been deleted.',
                    'success'
                  )
                }
            })
            .catch((error) => {
              setLoading(false);
                console.log(error);
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong!',
                  showConfirmButton: false,
                  timer: 1000
                })
            })
    } catch (error) {
      setLoading(false);
        console.log(error);
    }
}
  const handleDelete=(id)=>{
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          deleteUser(id)
        }
      })

  }

  useEffect(() => {
    getAllUsers();
  }, []);

  if(loading){
    return(
      <div className=' justify-center items-center flex h-[80vh]' >
  <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#F37A20"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
      />
      </div>

    )
  }

  return (
    <div className={ ` relative w-[97%] lg:w-[96%] md:w-[96%] mobile:w-[89%] small:w-[91%]  ${ activeMenu ? ' p-0   h-[284px] table-div mx-4 bg-[#FFFFFF] mt-8 lg:mt-6 md:mt-6 ml-5':' mx-4 bg-[#FFFFFF] mt-10  mt-8 lg:mt-6 md:mt-6  table-div '}`} >
    <div className='project2-task' >
            <p>All Managers</p>
      </div>

    <div className={` w-[100%] h-[427px]   overflow-x-auto overflow-y-hidden  bg-[#FAFAFA] table-div1 `}>
      <table className={ ` w-[1100px] lg:w-[1177px] md:w-[840px] block  `}>
        <thead className="bg-gray-50s table w-[98%] table-fixed">
        <tr>
          <th className="  p-3 text-[16px] lg:text-[13px] md:text-[13px] text-[#505887]  text-left"><p className='' >First Name</p></th>
          <th className="  p-3 text-[16px] lg:text-[13px] md:text-[13px] text-[#505887] text-left"><p className=' ' >Last Name</p></th>
          {/*<th className="  p-3 text-[16px] lg:text-[13px] md:text-[13px] text-[#505887] text-left"><p className=' ' >Designation</p></th>*/}
          <th className="  p-3 text-[16px] lg:text-[13px] md:text-[13px] text-[#505887] text-left"><p className=' ' >Email</p></th>
          <th className="  p-3 text-[16px] lg:text-[13px] md:text-[13px] text-[#505887] text-left"><p className=' ' >Phone Number</p></th>
          {/*<th className="  p-3 text-[16px] lg:text-[13px] md:text-[13px] text-[#505887] text-left"><p className=' ' >Role</p></th>*/}
          <th className="  p-3 text-[16px] lg:text-[13px] md:text-[13px] text-[#505887] text-left"><p className='' >Actions</p></th>
        </tr>
        </thead>
        <div className={`bg-white table-body w-[100%]  `} >
        <div className='h-[370px] relative milestone-bar overflow-y-auto' >
        <tbody className="divide-y absolute  w-[100%]  divide-gray-100">
          {users.length !==0 ?
          users.map((item,index)=>{
            return(
              <tr key={index} className="bg-white w-[100%] hover:bg-[#FAFAFA]  table table-fixed  ">
          <td className="p-3 text-sm  text-gray-700">
            <p className=" text-[#505887]  text-[14px] lg:text-[12px] md:text-[12px] font-medium  ">{item.first_name}</p>
          </td>
          <td className="p-3 text-sm  text-gray-700">
            <p className=" text-[#505887]  text-[14px] lg:text-[12px] md:text-[12px] font-medium  ">{item.last_name}</p>
          </td>
          {/*<td className="p-3 text-sm  text-gray-700">
            <p className=" text-[#505887]  text-[14px] lg:text-[12px] md:text-[12px] font-medium  ">{item.designation}</p>
          </td>*/}
          <td className="p-3 text-sm  text-gray-700">
            <p className=" text-[#505887]  text-[14px] lg:text-[12px] md:text-[12px] font-medium  ">{item.email}</p>
          </td>
          <td className="p-3 text-sm  text-gray-700">
            <p className=" text-[#505887]  text-[14px] lg:text-[12px] md:text-[12px] font-medium  ">{item.phone}</p>
          </td>

          {/*<td className="p-3 text-[14px] lg:text-[12px] md:text-[12px] text-[#F78F21]"><p className={`font-medium  `} >{item.role}</p></td>*/}
          <td className="p-3 ">
          <div className=" flex gap-2 " >
                            <MdDelete className='cursor-pointer' onClick={()=>handleDelete(item.user_id)} color="red" size={20} />
                            <Link to={`/user/edituser/${item.user_id}`} state={{ item: item} } >
                        <RiEditBoxFill color="orange" size={20} />
                        </Link>
                        </div>
            </td>
        </tr>
            )
          })
          :
          null
          }
        </tbody>
        </div>
        </div>
      </table>
    </div>
  </div>
  )
}

export default UserTable;
