import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import IMG01 from "../../../img/casesutdy/img_01.png"
import IMG02 from "../../../img/casesutdy/img_02.png"
import IMG03 from "../../../img/casesutdy/img_03.png"
import IMG04 from "../../../img/casesutdy/img_04.png"
import IMG05 from "../../../img/casesutdy/img_05.png"
import IMG06 from "../../../img/casesutdy/img_06.png"
import IMG07 from "../../../img/casesutdy/img_07.png"
import IMG08 from "../../../img/casesutdy/img_08.png"

import Portfolio from "../../../img/demo/downloadable-demo/articles/portfolio.png"

import { NavLink } from "react-router-dom";
import { NoRecordFound } from "../../../components/General";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/navigation";

import { MenuItem, InputLabel, FormControl, Select } from '@material-ui/core';

SwiperCore.use([Navigation, Pagination]);

// Utility function to chunk the projects array
const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
};

const portfolioMain = [
    { imgSrc: Portfolio, title: "AMJ Workplace Wellness", link: "https://www.tekrevol.com/projects" },
    { imgSrc: Portfolio, title: "AMJ Workplace Wellness", link: "https://www.tekrevol.com/projects" },
    { imgSrc: Portfolio, title: "AMJ Workplace Wellness", link: "https://www.tekrevol.com/projects" },
    { imgSrc: Portfolio, title: "AMJ Workplace Wellness", link: "https://www.tekrevol.com/projects" },
    { imgSrc: Portfolio, title: "AMJ Workplace Wellness", link: "https://www.tekrevol.com/projects" },
    { imgSrc: Portfolio, title: "AMJ Workplace Wellness", link: "https://www.tekrevol.com/projects" },
    { imgSrc: Portfolio, title: "AMJ Workplace Wellness", link: "https://www.tekrevol.com/projects" },
    { imgSrc: Portfolio, title: "AMJ Workplace Wellness", link: "https://www.tekrevol.com/projects" },
    { imgSrc: Portfolio, title: "AMJ Workplace Wellness", link: "https://www.tekrevol.com/projects" },
    { imgSrc: Portfolio, title: "AMJ Workplace Wellness", link: "https://www.tekrevol.com/projects" },
    { imgSrc: Portfolio, title: "AMJ Workplace Wellness", link: "https://www.tekrevol.com/projects" },
    { imgSrc: Portfolio, title: "AMJ Workplace Wellness", link: "https://www.tekrevol.com/projects" },
    // Add more projects as needed
];



const chunkedProjects = chunkArray(portfolioMain, 8);


function PortfolioView(props) {

    const swiperRef = useRef(null);

    const [paginationText, setPaginationText] = useState('');

    useEffect(() => {
        const swiper = swiperRef.current.swiper;
        
        const updatePagination = () => {
            if (swiper) {
                const activeIndex = swiper.activeIndex;
                const totalSlides = chunkedProjects.length; // Total chunks, not total slides
                const slidesPerPage = 8;
                
                const currentChunkIndex = activeIndex;
                const currentPageStart = currentChunkIndex * slidesPerPage + 1;
                const currentPageEnd = Math.min(currentPageStart + slidesPerPage - 1, portfolioMain.length);
                const totalItems = portfolioMain.length;
                
                const paginationText = `${currentPageStart}-${currentPageEnd} of ${totalItems}`;
                setPaginationText(paginationText);
            }
        };

        // Initial update
        updatePagination();

        // Update on slide change
        swiper.on('slideChange', updatePagination);

        // Clean up event listener on component unmount
        return () => swiper.off('slideChange', updatePagination);
    }, []);


    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
    const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(true);

    useEffect(() => {
        const swiperInstance = swiperRef.current.swiper;

        const handleSlideChange = () => {
            setIsNextButtonDisabled(swiperInstance.isEnd);
            setIsPrevButtonDisabled(swiperInstance.isBeginning);
        };

        swiperInstance.on('slideChange', handleSlideChange);

        // Initial state setup
        setIsPrevButtonDisabled(swiperInstance.isBeginning);

        return () => {
            swiperInstance.off('slideChange', handleSlideChange);
        };
    }, []);

    const handleNextButtonClick = () => {
        swiperRef.current.swiper.slideNext();
    };

    const handlePrevButtonClick = () => {
        swiperRef.current.swiper.slidePrev();
    };

    // Material UI Select states
    const [selectedProject1, setSelectedProject1] = useState('all'); // Default value set to 'all'
    const [selectedProject2, setSelectedProject2] = useState('all2'); // Default value set to 'all2'

    const handleChangeOption1 = (e) => {
        sessionStorage.setItem('session_project1', e.target.value);
        setSelectedProject1(e.target.value);
    };



    return (
        <Container fluid>
            {/* <Row>
                <Col xxl={6}>
                    <div className="project_title_new">{props?.title}</div>
                </Col>
            </Row> */}
            <Row>

            <div className="downloadable-layout">
                        
                        <div className="downloadable-title-wrap">
                            <div className="project_title_new">Portfolio</div>
                            
                            <Select
                                fullWidth
                                labelId="demo-simple-select-label"
                                name="article_project_id_1"
                                label="Project Projects"
                                id="article_project_id"
                                value={selectedProject1}
                                onChange={handleChangeOption1}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                <MenuItem key="10" value="all">
                                    Select Industry
                                </MenuItem>
                                <MenuItem key="20" value="upcomming">
                                    Upcoming Events
                                </MenuItem>
                                <MenuItem key="30" value="pastevent">
                                    Past Events
                                </MenuItem>
                            </Select>
                        </div>
                        {/* {selectedValue && <p>You selected: {selectedValue}</p>} */}
                        
                        <div className="articles-inner">
            <Swiper
                ref={swiperRef}
                spaceBetween={30}
                slidesPerView={1}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }}
                pagination={{ el: '.custom-pagination', clickable: true }}
                onSwiper={(swiper) => { swiper.pagination.render(); }}
                autoplay={false}
                loop={false}
                modules={[Navigation, Autoplay, Pagination]}
                className='mySwiper'
            >
                         {chunkedProjects.map((chunk, chunkIndex) => (
                            <SwiperSlide key={chunkIndex}>
                                <div className="swiper-slide-content row">
                                    {chunk.map((portfolio, index) => (
                                        <Col xxl={3} xl={6} className="custom-col-xxxl-4">
                                        <div className="protfolio_sec" key={index}>
                                            <div className="protfolio_img">
                                                <img src={portfolio.imgSrc} alt={`IMG0${index + 1}`} />
                                            </div>
                                            <h3>{portfolio.title}</h3>
                                            <NavLink to={portfolio.link}>View Full Case Study</NavLink>
                                        </div>
                                        
                                        </Col>
                                    ))}
                                </div>
                            </SwiperSlide>
                        ))}
                            
            </Swiper>
            <div className="downloadable-button-wrap downloadable-pagination-wrap">
            <div className="custom-pagination">{paginationText}</div>
                <div
                    className={`downloadable-swiper-button-prev downloadable-swiper-prev downloadable-swiper-button ${isPrevButtonDisabled ? 'swiper-button-disabled' : ''}`}
                    onClick={handlePrevButtonClick}
                >
                    <div className="btn_swiper">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            fill="none"
                            viewBox="0 0 25 24"
                        >
                            <path
                                fill="#F37A20"
                                fillRule="evenodd"
                                d="M20.2 11.387l-.083-.094-5-5a1 1 0 00-1.498 1.32l.084.094L16.995 11H5.41a1 1 0 00-.117 1.993L5.41 13h11.585l-3.292 3.293a1 1 0 00-.084 1.32l.084.094a1 1 0 001.32.083l.094-.083 5-5a1 1 0 00.083-1.32z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </div>
                </div>
                <div
                    className={`downloadable-swiper-button-next downloadable-swiper-next downloadable-swiper-button ${isNextButtonDisabled ? 'swiper-button-disabled' : ''}`}
                    onClick={handleNextButtonClick}
                >
                    <div className="btn_swiper">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            fill="none"
                            viewBox="0 0 25 24"
                        >
                            <path
                                fill="#F37A20"
                                fillRule="evenodd"
                                d="M20.2 11.387l-.083-.094-5-5a1 1 0 00-1.498 1.32l.084.094L16.995 11H5.41a1 1 0 00-.117 1.993L5.41 13h11.585l-3.292 3.293a1 1 0 00-.084 1.32l.084.094a1 1 0 001.32.083l.094-.083 5-5a1 1 0 00.083-1.32z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
                    </div>

                {/* {props.data && props.data.length > 0 ?
                    props.data.map((item, index) => {

                        return (
                            <Col xxl={3} xl={4}>
                                <div className="protfolio_sec">
                                    <div className="protfolio_img">
                                        <img src={item?.file_path} alt="IMG01" />
                                    </div>
                                    <h3>{item?.file_name}</h3>
                                    <NavLink to="https://www.tekrevol.com/projects">View Full Case Study</NavLink>
                                </div>
                            </Col>
                        )
                    })
                    : <NoRecordFound />
                } */}

            </Row>
        </Container>
    );
}

export default PortfolioView;