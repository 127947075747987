import React, { useState, useEffect } from 'react'
import { useStateContext } from '../../contexts/ContextProvider';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link, useLocation } from 'react-router-dom';
import { MenuItem, InputLabel, FormControl, Select } from '@material-ui/core';
import { Oval } from 'react-loader-spinner'
import { Table } from "react-bootstrap";
import { Loader, NoRecordFound, Toast } from '../General';

const BenefitRewardTable = ({ item }) => {
    const { activeMenu, user, selectedProject, project } = useStateContext();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleClaim = async (value, id) => {
        const data = {
            "status": value,
        }
        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}benefit-reward-claim/${id}`, { ...data, id: id }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        getAllClaim();

                        Toast.fire({
                            icon: 'success',
                            title: res?.data?.message || '',
                        });
                    }
                })
                .catch((error) => {
                    //   console.log(error);
                    if (error.response.status === 404 && !error.response.data.success) {

                        Toast.fire({
                            icon: 'error',
                            title: 'Client points is insufficient!' || '',
                        });

                        
                    } else {

                        Toast.fire({
                            icon: 'error',
                            title: 'Something went wrong!' || '',
                        });
                    }
                })
        } catch (error) {
            console.log(error);
        }
    };

    const getAllClaim = () => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_BASE_URL}benefit-reward-claim?client_id=${item.id}&project_id=${selectedProject}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            setUsers(response.data.data);
            setLoading(false)
        }).catch((err) => {
            console.log('err====', err);
        })
        // console.log(table);
    }
    useEffect(() => {
        getAllClaim();
    }, [selectedProject]);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="download_tabs">
            <Table responsive>
                <thead>
                    <tr>
                        <th className="text-center">Client Name</th>
                        <th className="text-center">Client Email</th>
                        <th className="text-center">Total Points</th>
                        <th className="text-center">Reward Name</th>
                        <th className="text-center">Reward Points</th>
                        <th className="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    { users && users.length > 0 ?
                        users.map((item, index) => {

                            return (

                                <tr key={index}>

                                    <td className='text-center'>{item.client?.first_name} {item.client?.last_name}</td>
                                    <td className='text-center'>{item.client?.email}</td>
                                    <td className='text-center'>{item.client?.points}</td>
                                    <td className='text-center'>{item.benefit_reward?.title}</td>
                                    <td className='text-center'>{ item.clientClaimedRewards ? item.clientClaimedRewards?.points : item.points }</td>

                                    <td className='text-center'>
                                        <FormControl fullWidth>
                                            {/* <InputLabel id="demo-simple-select-label"></InputLabel> */}
                                            <Select
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                name="Request"
                                                label="Request"
                                                disabled={item.status === 1 ? true : false}
                                                id="Request"
                                                displayEmpty
                                                value={item.status}
                                                key={item.id}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                onChange={(e) => handleClaim(e.target.value, item.id)}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "left"
                                                    },
                                                    getContentAnchorEl: null
                                                }}
                                            >
                                                <MenuItem value={0}>Claimed</MenuItem>
                                                <MenuItem value={1}>Approved</MenuItem>
                                                <MenuItem value={2}>Reject</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </td>

                                </tr>

                            )
                        }) : <NoRecordFound />

                    }
                </tbody>
            </Table>

        </div>

    )
}

export default BenefitRewardTable;
