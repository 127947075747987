import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from "react-router-dom";
import { Col, Container, Row, Form, Modal } from "react-bootstrap";
import IMG from "../../img/img1.jpeg";

import ModalView from "./components/ModalView";
import ChangePassword from "./components/ChangePassword";
import { useStateContext } from '../../contexts/ContextProvider';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axios from 'axios';
import { S3Upload } from '../../utils/S3Upload';
import { Loader, Toast } from "../../components/General";
import { useNavigate } from 'react-router-dom';
import { FileUploadIcon, FileUploadIconTwo } from "../../app-constants";
import { Oval } from 'react-loader-spinner';

function SettingView(props) {
    const { user, userprofile, getUserProfile, setUserprofile } = useStateContext();

    const [isUpdateProfile, setIsUpdateProfile] = useState(false);

    const navigate = useNavigate();
    const validationSchema = yup.object({
        first_name: yup
            .string()
            .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed')
            .required('First name is required')
            .max(20, "First name should be maximun of 20 characters")
            .min(4, "First name must be atleast of 4 characters"),
        last_name: yup
            .string()
            .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed')
            .required('Last name is required')
            .max(20, "Last name should be maximun of 20 characters")
            .min(4, "Last name must be atleast of 4 characters"),
        email: yup.string().email("Invalid Email Format").required("Email is Required"),
        phone: yup.string().required('Phone number is required'),
        countryCode: yup.string().required('Country code is required'),
    });

    // Example country codes
    const countryCodes = [
        { code: '+1', name: 'United States' },
        { code: '+44', name: 'United Kingdom' },
        { code: '+91', name: 'India' },
        { code: '+92', name: 'Pakistan' },
        // Add more countries as needed
    ];


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(false);

    const [image, setImage] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const [values, setValues] = useState({ screenshot: null });
    const [isImageEdit, setIsImageEdit] = useState(false);


    const [setting, setSetting] = useState({ meeting: false, invoice: false });

    const fileInputRef = useRef(null);

    // setSetting(prevSetting => ({
    //     ...prevSetting,
    //     meeting: true
    // }));
    // console.log(setting.meeting);

    const formik = useFormik({
        initialValues: {
            first_name: userprofile?.first_name,
            last_name: userprofile?.last_name,
            email: userprofile?.email,
            phone: userprofile?.phone || '',
            countryCode: userprofile?.country_code || '+1',
            bio: userprofile?.bio || '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {

            // console.log(values);

            handleSubmit(values);
        },
    });

    useEffect(() => {
        formik.setValues({
            first_name: userprofile?.first_name,
            last_name: userprofile?.last_name,
            email: userprofile?.email,
            phone: userprofile?.phone || '',
            countryCode: userprofile?.country_code || '+1',
            bio: userprofile?.bio || '',
        });

    }, [userprofile]);


    const handleSubmit = async (data) => {

        setIsUpdateProfile(true);

        // console.log(data);

        // Toast.fire({
        //     icon: "info",
        //     title: "Processing..."
        // });

        setTimeout(async function () {

            const response = image ? await S3Upload(image.type, image.name, image) : '';

            let payload = isImageEdit ? { ...data, image: response } : { ...data };

            await axios.put(`${process.env.REACT_APP_BASE_URL}update_user/${user.user.id}`, payload, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            .then((res) => {
                if (res.status === 200) {
                    setUserprofile(res?.data?.data);

                    Toast.fire({
                        icon: "success",
                        title: "User Updated Successfully.!!"
                    });
                }
            })
            .catch((error) => {

                Toast.fire({
                    icon: "error",
                    title: "Something went wrong, Please try again later."
                });
            })
            .finally(() => {
                setIsUpdateProfile(false);
            });
        }, 3000);
    }

    const handleImageUpload = (e) => {

        const file = e.target.files[0];

        if (file) {
            const fileType = file.type;
            if (!fileType.startsWith('image/jpeg') && !fileType.startsWith('image/png') && !fileType.startsWith('image/jpg')) {
                // Display error toast for invalid file type
                Toast.fire({
                    icon: "error",
                    title: " Only JPEG, PNG ,JPG files are allowed. Please choose a valid file type."
                });
                return;
            }

            setImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageURL(reader.result);
            };
            reader.readAsDataURL(file);

            const { name, value, files } = event.target;
            setValues({ screenshot: files[0] });

            setIsImageEdit(true);
        }
    };

    if (props === "props") {
        setShow(true)
    }

    const handleImageRemove = () => {
        setImage(null);
        setImageURL(null);
        setIsImageEdit(true);
    };

    useEffect(() => {
        getUserProfile();
        getUserSetting();
    }, []);


    const getUserSetting = async () => {

        if (user) {

            await axios.get(`${process.env.REACT_APP_BASE_URL}get_user/${user.user.id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {

                let invoice = response.data.data.invoice == 1 ? true : false;
                let meeting = response.data.data.meeting == 1 ? true : false;

                console.log(invoice);

                setSetting({ meeting: meeting, invoice: invoice });

            }).catch((err) => {
                console.log('err====', err);
            })

            console.log(setting);
        }

    }

    useEffect(() => {
        formik.setFieldValue('first_name', userprofile?.first_name);
        formik.setFieldValue('last_name', userprofile?.last_name);
        formik.setFieldValue('email', userprofile?.email);
        formik.setFieldValue('phone', userprofile?.phone);
        setImageURL(userprofile?.image);

    }, [userprofile])

    const handleOnChange = async (e) => {

        const type = e.target.value;
        const status = e.target.checked ? 1 : 0;

        let payload = {
            'type': type,
            'status': status,
        };

        const obj = {
            ...setting
        }

        obj[type] = e.target.checked;
        setSetting(obj);

        await axios.put(`${process.env.REACT_APP_BASE_URL}update_user_notification/${user.user.id}`, payload, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                if (res.status === 200) {

                    // Toast.fire({
                    //     icon: "success",
                    //     title: "Setting Updated Successfully.!!"
                    // });
                }
            })
            .catch((error) => {

                Toast.fire({
                    icon: "error",
                    title: "Something went wrong, Please try again later."
                });
            })
            .finally(() => {
                // setIsUpdateProfile(false);
            });
    };


    //active Tab
    const [activeTab, setActiveTab] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    // Ensure handleTabClick is only declared once
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    // Ensure handleOnChange is only declared once
    // const handleOnSearchChange = (event) => { // Renamed to handleOnSearchChange
    //   setSearchTerm(event.target.value);
    // };



    return (
        <>
            <div className="main_top_title">
                <div className="main_title"><h1>Profile <span>Setting</span></h1></div>
                <div className="main_btn_txt">
                    <ul>
                        <li>
                            <NavLink to="">
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="">
                                Appboard
                            </NavLink>
                        </li>
                        <li className="active">
                            <NavLink to="" >
                                Setting
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>


            <div>
                <ul className="downloadable_tabs">
                    <li onClick={() => handleTabClick(1)} className={activeTab === 1 ? 'active' : ''}>
                        Profile
                    </li>
                    <li onClick={() => handleTabClick(2)} className={activeTab === 2 ? 'active' : ''}>
                        Notifications
                    </li>
                    <li>
                        {/* <input
                        type="search"
                        value={searchTerm}
                        placeholder="Search Files"
                        onChange={handleOnSearchChange} // Updated to handleOnSearchChange
                    /> */}
                    </li>
                </ul>
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <div className="downloadable_content bg-unset">
                        {activeTab === 1 &&
                            <>
                                <div className="main_content_container profile_main_container bg-white">
                                    <Container fluid className="p-0">
                                        <Row>
                                            <Col md={12} lg={12}>
                                                <form onSubmit={formik.handleSubmit}>
                                                    <div className="main_style_box profile_main_box">
                                                        <div className="main_title">Profile Settings</div>
                                                        <div className="profile_img">
                                                            <Form.Control type="file" ref={fileInputRef} name="screenshot" accept=".jpg, .jpeg, .png" className="file_img" onChange={handleImageUpload} />
                                                            {imageURL ? (
                                                                <div className="profile_img_inner">
                                                                    <img src={imageURL} alt="Uploaded" />
                                                                    <div onClick={handleImageRemove} className="closed_img">X</div>
                                                                </div>
                                                            ) : (
                                                                <div className="profile_img_inner">
                                                                    {FileUploadIconTwo}
                                                                </div>
                                                            )}

                                                            <div className="profile_button">

                                                                <button type="submit">
                                                                    <div className="btn_profile" onClick={handleButtonClick}>
                                                                        Upload Photo
                                                                    </div>
                                                                </button>
                                                                <div className="btn_profile cancel" onClick={handleImageRemove}>
                                                                    Remove Photo
                                                                </div>
                                                            </div>

                                                        </div>


                                                        <div className="profile_box">
                                                            <Form.Group controlId="first_name" className="profile_inputs">
                                                                <Form.Label>First Name</Form.Label>
                                                                <Form.Control type="text" className="input_profile" placeholder="First name"

                                                                    name="first_name"
                                                                    label="First Name"
                                                                    value={formik.values.first_name || ''}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                                                />

                                                                <Form.Control.Feedback type="invalid">
                                                                    {formik.touched.first_name && formik.errors.first_name}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group controlId="first_name" className="profile_inputs">
                                                                <Form.Label>Last Name</Form.Label>
                                                                <Form.Control type="text" className="input_profile" placeholder="Last name"

                                                                    name="last_name"
                                                                    label="Last Name"
                                                                    value={formik.values.last_name || ''}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                                                />

                                                                <Form.Control.Feedback type="invalid">
                                                                    {formik.touched.last_name && formik.errors.last_name}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>

                                                        <div className="profile_box">
                                                            <Form.Group controlId="email" className="profile_inputs">
                                                                <Form.Label>Email</Form.Label>
                                                                <Form.Control type="text" className="input_profile" placeholder="example@tekrevol.com"
                                                                    name="email"
                                                                    label="Email"
                                                                    value={formik.values.email || ''}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={formik.touched.email && Boolean(formik.errors.email)}
                                                                    readOnly={true}

                                                                />

                                                                <Form.Control.Feedback type="invalid">
                                                                    {formik.touched.email && formik.errors.email}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group controlId="phone" className="profile_inputs phone_inputs">
                                                                <Form.Label>Phone Number</Form.Label>
                                                                <div className="d-flex">
                                                                    <Form.Control
                                                                        as="select"
                                                                        name="countryCode"
                                                                        className="form-control me-2"
                                                                        value={formik.values.countryCode}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        isInvalid={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
                                                                    >
                                                                        {countryCodes.map((country) => (
                                                                            <option key={country.code} value={country.code}>
                                                                                {country.code}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Control>
                                                                    <Form.Control
                                                                        type="tel"
                                                                        name="phone"
                                                                        className="form-control"
                                                                        placeholder="Please enter your phone number"
                                                                        value={formik.values.phone}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        isInvalid={formik.touched.phone && Boolean(formik.errors.phone)}
                                                                    />
                                                                </div>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {formik.touched.phone && formik.errors.phone}
                                                                </Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {formik.touched.countryCode && formik.errors.countryCode}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                        </div>
                                                        <div className="profile_box">

                                                            <Form.Group controlId="boi" className="profile_inputs">
                                                                <Form.Label>Bio</Form.Label>
                                                                <div className="d-flex">

                                                                    <Form.Control
                                                                        as="textarea"
                                                                        name="bio"
                                                                        className="form-control"
                                                                        placeholder="Write your Bio here e.g your hobbies, interests ETC"
                                                                        value={formik.values.bio}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        isInvalid={formik.touched.bio && Boolean(formik.errors.bio)}
                                                                    />
                                                                </div>

                                                            </Form.Group>

                                                            {/* <Form.Control type="email" name="email" value={formik.values.email || ''} placeholder="Enter email" className="input_profile" /> */}
                                                        </div>


                                                        <div className="profile_button">
                                                            <button type="submit" disabled={isUpdateProfile}>
                                                                <div className="btn_profile" >

                                                                    {isUpdateProfile ? <Oval
                                                                        visible={true}
                                                                        height="20"
                                                                        width="20"
                                                                        color="#ffffff"
                                                                        secondaryColor="#ffffff"
                                                                        ariaLabel="oval-loading"
                                                                        wrapperStyle={{ display: 'flex', justifyContent: 'center' }}
                                                                        wrapperClass=""
                                                                    /> : "Update Profile"}

                                                                </div>
                                                            </button>

                                                            {/*<div className="btn_profile cancel" onClick={() => {*/}
                                                            {/*    console.log('back')*/}
                                                            {/*    navigate(-1)*/}
                                                            {/*}} >*/}
                                                            {/*    Cancel*/}
                                                            {/*</div>*/}
                                                            {/* <div className="btn_profile cancel" onClick={handleShow}>
                                                        Change Password
                                                    </div> */}
                                                            <div className="btn_profile cancel">
                                                                Reset
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <div className="main_content_container pass_main_container bg-white mt-4">
                                    <Container fluid className="p-0">
                                        <Row>
                                            <Col md={12} lg={6} xl={5} xxl={4}>
                                                <div className="main_style_box profile_main_box">
                                                    <ChangePassword handleClose={handleClose} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>

                            </>


                        }
                        {activeTab === 2 &&

                            <div className="main_content_container">
                                <Container fluid className="p-0">
                                    <Row>
                                        <Col md={12} lg={12}>
                                            <div className="main_style_box profile_main_box profile_notification_box">
                                                <div className="main_title">Notification Setting</div>

                                                {/* <h4>Notification Setting </h4> */}
                                                <div className="profile_notification">
                                                    <Form>
                                                        <div className='top-wrap'>
                                                            <div className="content">
                                                                <span className='title'>Invoice</span>
                                                                <span>Enable Notification for Invoice.</span>
                                                            </div>
                                                            <Form.Check // prettier-ignore
                                                                type="switch"
                                                                id="invoice"
                                                                label=""
                                                                onChange={handleOnChange}
                                                                value="invoice"
                                                                checked={setting.invoice}
                                                            />
                                                        </div>

                                                        <div className='top-wrap'>
                                                            <div className="content">
                                                                <span className='title'>Meeting</span>
                                                                <span>Enable Notification for Invoice.</span>
                                                            </div>
                                                            <Form.Check // prettier-ignore
                                                                type="switch"
                                                                id="meeting"
                                                                label=""
                                                                onChange={handleOnChange}
                                                                value="meeting"
                                                                checked={setting.meeting}
                                                            />
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>

                        }
                    </div>
                )}
            </div>





            <Modal show={show} onHide={handleClose} size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <ChangePassword handleClose={handleClose} />
            </Modal>
        </>
    );
}

export default SettingView;