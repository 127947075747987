import React from 'react';
import UserTable from '../components/UserTable';
import Rewards from '../components/Rewards';
import { useStateContext } from '../contexts/ContextProvider';
import {MdCreateNewFolder} from 'react-icons/md';
import { Link } from "react-router-dom";

const Users=()=>{
  const{user} = useStateContext();
  const{role} = user.user;
    return(
        <div className='meetingboard-grid' >
            <div className={`meetingboard-grid-sub h-[80vh] ${role !== 'client' ? 'col-span-12 ' :''} `} >
                <div>
                <div className="flex justify-between pt-[5px] mobile:inline small:inline  " >
            <h1 className=" meeting-text">
          Account{" "}
          <span style={{ fontWeight: "700" }} className="text-[#F37A20]">
            Managers
          </span>
        </h1>
        {role !== 'client' ?
        <div className="pr-[33px] pt-[10px] mobile:ml-[18px] small:ml-[18px] " >
        <Link to="createuser" >
        <button className="p-[8px] text-white flex bg-[#F37A20] uppercase rounded-md text-[14px]  ">
          <MdCreateNewFolder color="white" size={20}/>Create User</button>
        </Link>
      </div>
      :null}
        </div>
            </div>
            <UserTable/>
            </div>
            {role === 'client' ?
            <div className="meetingboard-grid-rewards">
        <Rewards />
      </div>
      :null}
        </div>
    )
}

export default Users;