import React, {useState, useEffect} from 'react';
import medal1 from '../../../data/medal1.svg'
import {ReactSVG} from 'react-svg';
import axios from 'axios'
import medal2 from '../../../data/medal2.svg'
import {useStateContext} from '../../../contexts/ContextProvider';
import {CircularProgressbarWithChildren} from 'react-circular-progressbar';
import {MdDone} from 'react-icons/md';
import {intToString} from '../../../utils/PriceToString'
import Swal from 'sweetalert2';
import {TooltipComponent} from '@syncfusion/ej2-react-popups';
import TekPoints from "../../../img/Loyalty.svg"


const RewardsSection = () => {

    const {benefits, getAllBenefits, user, sessionProject} = useStateContext();
    const [showDiv, setShowDiv] = useState(null);
    const [loading, setLoading] = useState(false);
    

    const createClaim = async (id) => {
        setLoading(true);
        const data = {
            "benefit_reward_id": id,
            "project_id": sessionProject
        }

        // console.log('data======',data);
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}benefit-reward-claim`, {...data}, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        getAllBenefits();
                        setLoading(false);
                        Swal.fire({
                            icon: 'success',
                            title: 'Successfully claimed!',
                            showConfirmButton: false,
                            timer: 2000
                        });
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    Swal.fire({
                        icon: 'error',
                        title: error?.response?.data?.message || 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 2000
                    });
                })
        } catch (error) {
            setLoading(false);
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: error?.response?.data?.message || 'Something went wrong!',
                showConfirmButton: false,
                timer: 2000
            });
        }
    };

    useEffect(() => {
        getAllBenefits()
        console.log('hyder test------------------------->',benefits);
    }, [])
    // console.log(benefits);


    function formatPoints(points) {
        if (points === undefined || points === null) {
            return ''; // Handle case where points is undefined or null
        }
    
        // Convert points to a number if it's a string
        points = parseFloat(points);
    
        // Check if points is a number and within the three-digit range
        if (!isNaN(points) && points >= 0 && points < 1000) {
            return points.toString(); // Return points as string for numbers less than 1000
        } else if (!isNaN(points) && points >= 1000 && points < 1000000) {
            // Convert to thousands (k) format
            return (points / 1000).toFixed(0) + 'k'; // Round to nearest integer
        } else if (!isNaN(points) && points >= 1000000) {
            // Convert to millions (m) format
            return (points / 1000000).toFixed(0) + 'm'; // Round to nearest integer
        } else {
            return ''; // Handle any other cases where points doesn't fit the expected range
        }
    }

    return (
        <>
            {benefits.length !== 0 ? benefits.map((item, index) => {
                return (
                    <div className="reward_box" key={index}>
                        <div className="icons">
                            <img src={TekPoints} alt="TekPoints" />
                        </div>
                        <div className="txt">
                        {item?.title} <span>{item.claimed ? item.status == 0 ? 'In Review' : 'Claimed' : (item.points > item.user_points ? `You need ${item.points - item.user_points} more Points` : <button onClick={() => createBenefitClaim(item.id)}>Claim</button>)}</span>
                        </div>
                        {/* <div className="points">{item?.points}</div> */}
                        <div className="points">{formatPoints(item?.points)}</div>
                    </div>
                );
            }) : null}
        </>
    );
}

export default RewardsSection;