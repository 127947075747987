import React from 'react';
import { useStateContext } from '../../contexts/ContextProvider';

const AppBoardTabs = () => {

    const { activeTab, handleTabClick, user } = useStateContext();
    const { role } = user.user;

    return (

        <div className=' justify-between flex overflow-x-auto app-board-tab'>
            <div className=' tab-header gap-10' >
                <div className={activeTab === 0 ? 'active active-indicator' : ''} onClick={() => handleTabClick(0)}>
                    <p className='text-[16px] mb-[19px] xm:mb-[12px] md:text-[14px] sm:text-[14px] xm:text-[12px]' >Overview</p>
                </div>

                <div className={activeTab === 1 ? 'active active-indicator' : ''} onClick={() => handleTabClick(1)}>
                    <p className='text-[16px] mb-[19px] xm:mb-[12px] md:text-[14px] sm:text-[14px] xm:text-[12px]'>Board</p>
                </div>


            </div>
            {/* { role === 'client' && activeTab === 1 ?
                        <div className='boardSection-div cursor-pointer mobile:ml-[40px] tab:ml-[40px] small:ml-[40px] mobile:mt-[-5px] tab:mt-[-5px] small:mt-[-5px] ' >
                            <p className='mobile:w-[150px] small:w-[150px] tab:w-[150px] ' >+ Add New Section</p>
                        </div>
                        :null} */}
        </div>
    )
}

export default AppBoardTabs;