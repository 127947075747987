import React, {useState, useEffect} from 'react'
import WhiteLogo from '../data/tekLogo.svg'
import axios from 'axios';
import {ThreeDots} from 'react-loader-spinner'
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Swal from 'sweetalert2';
import {Toast} from "../components/General";

const validationSchema = yup.object({
    password: yup.string()
        .required("Password is required")
        .max(20, "Password should be maximun of 20 characters")
        .min(8, "Password must be atleast of 8 characters"),
    confirm_password: yup.string()
        .required("Confirm password is required")
        .oneOf([yup.ref("password")], "Password do not matched")
});

const ResetPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {id} = useParams();

    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, {resetForm}) => {
            ResetPassword();
            resetForm({values: ''})

        },
    });

    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');

    const ResetPassword = async () => {

        if (email !== '' && code !== '') {

            setLoading(true);
            const data = {
                "password": formik.values.password,
                "password_confirmation": formik.values.confirm_password,
                //"email":email,
                //"type":1
            }

            try {
                await axios.put(`${process.env.REACT_APP_BASE_URL}reset_password/${id}`, {...data})
                    .then((res) => {
                        if (res.status === 200) {
                            console.log(res.data);
                            setLoading(false);
                            // handleClose();
                            Toast.fire({
                                icon: 'success',
                                title: 'Password Reset successfully, Kindly Login!',
                            })
                            // Swal.fire(
                            //   {
                            //     icon: 'success',
                            //     title: 'Password Reset successfully, Kindly Login!',
                            //     showConfirmButton: false,
                            //     timer: 1000
                            //   }
                            // )
                            navigate('/signin')

                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        console.log(error);
                        let msg = typeof (error.response.data.errors[0].message) == 'string' ? error.response.data.errors[0].message : error.response.data.errors[0].message.email

                        Toast.fire({
                            icon: 'error',
                            title: msg || 'Something went wrong!',
                        })
                    })
            } catch (error) {
                setLoading(false);
                console.log(error);
            }
        }
    };

    useEffect(() => {
        console.log('params', id);
        if (id) {
            setToken(id);
        }
        const params = new URLSearchParams(location.search);
        const email = params.get('email');
        const token = params.get('token');
        setEmail(email);
        setCode(token)
        // console.log('params==========',code);
        // console.log('params==========',token);

    }, []);

    return (
        <div className="signinform min-h-screen">
            <div className='grid grid-cols-7'>

                <div className='signin-form'>
                    <div className='signin-form-div'>
                        <div className='mb-[80px]'>
                            <img src={WhiteLogo} alt="WhiteLogo"/>
                        </div>
                        <h1 className='signin-form-div-title forgot-after '>Reset Password</h1>
                        <div className='flex justify-center items-center'>
                            <form onSubmit={formik.handleSubmit} className='pt-[20px] space-y-4 custom-input'>
                                {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}

                                <div className='settings-email-div'>
                                    <label className='text-white text-[14px] font-medium'>Password</label><br/>
                                    <div className='relative pt-[7px] '>
                                        <input
                                            id='password'
                                            name='password'
                                            type='password'
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            className='input-password'
                                            placeholder='***********'/>
                                    </div>
                                    {formik.errors.password &&
                                    <span className="error-message">{formik.errors.password}</span>}

                                </div>
                                <div className='mt-[19px]'>
                                    <label className='text-white text-[14px] font-medium'>Re-Password</label><br/>
                                    <div className='pt-[7px]'>
                                        <input
                                            id='confirm_password'
                                            name='confirm_password'
                                            value={formik.values.confirm_password}
                                            onChange={formik.handleChange}
                                            type='password'
                                            className='input-password '
                                            placeholder='***********'/>
                                        <br/>{formik.errors.confirm_password &&
                                    <span className="error-message">{formik.errors.confirm_password}</span>}
                                    </div>
                                </div>
                                {!loading ?
                                    <button type='submit' className='signin-form-div-save'>Save</button>
                                    : <div className=' justify-center items-center flex h-[100%] w-[100%]'>
                                        <ThreeDots
                                            height="60"
                                            width="60"
                                            radius="9"
                                            color="#F37A20"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                }
                            </form>
                        </div>

                    </div>

                </div>
                <div className='col-span-4 left '>
                    <div className='flex justify-center items-center h-full '>
                        {/* <img width={350} src={WhiteLogo} alt='WhiteLogo' /> */}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ResetPassword;