import React, {useEffect, useState} from 'react';
import {useStateContext} from '../contexts/ContextProvider';
import Swal from 'sweetalert2';
import axios from 'axios'
import {Oval} from 'react-loader-spinner'
import {useFormik} from 'formik';
import * as yup from 'yup';
import {TextField} from '@material-ui/core';
import ReferralImg from "../../src/img/referrals.jpg"

const validationSchema = yup.object({
    name: yup.string().required('name is required'),
    email: yup.string().required('email is required')
});

const ClientPage = ({user}) => {
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, {resetForm}) => {
            if (values) {
                let payload = {
                    client_id: user.user.id,
                    name: values.name,
                    email: values.email
                }
                submitForm(payload)
                resetForm({values: ''})
                handleClose()
            }
        },
    });

    const submitForm = async (payload) => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BASE_URL}referal`, payload, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    Swal.fire(
                        {
                            icon: 'success',
                            title: 'Invitation sent successfully!',
                            showConfirmButton: false,
                            timer: 1000
                        }
                    )
                }
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: error?.response?.data?.errors[0]?.message || 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 5000
                })
            })
    }

    return (
        <div className="appboard-overview-project-main">
            {/*<div className="appboard-grid-project-title">Referral Program</div>*/}
            <div className="appboard-grid-project-txt">
                <div className="referral_section">
                    <div className="referral_img">
                        <img src={ReferralImg}
                             alt={'Referral-Image'}/>
                    </div>
                    <div className="referral_content">
                        <h3>Unlock Limitless Potential with Our Referral Program!</h3>
                        <p>Refer a friend, colleague, or business partner to our platform, and they'll discover the same
                            comprehensive suite of tools that have revolutionized your workflow.
                            Simply fill out the form below with your referral's Full Name and Email Address, and we'll take
                            care of the rest.</p>
                    </div>
                    <div className="referral_froms">
                        <form onSubmit={formik.handleSubmit}>
                            <TextField
                                id="name"
                                name="name"
                                label="Referral Name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                            <TextField
                                id="email"
                                name="email"
                                label="Referral Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <span className="submit_btn">
                            {loading ? <button><Oval
                                        visible={true}
                                        height="20"
                                        width="20"
                                        color="#ffffff"
                                        secondaryColor="#ffffff"
                                        ariaLabel="oval-loading"
                                        wrapperStyle={{disply: 'flex', justifyContent: 'center'}}
                                        wrapperClass=""
                                    /></button> :
                                    <button>Submit</button>
                            }</span>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}

const UserPage = ({user}) => {
    const [loading, setLoading] = useState();
    const [data, setData] = useState();

    useEffect(() => {
        getReferrals();
    }, [])

    const getReferrals = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}referal`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            console.log(response?.data?.data)
            setData(response?.data?.data)
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    return (
        <div class="list-view-project-box appboard-overview-project-main">
            <div class="appboard-grid-project-title">
                <ul class="appboard-grid-project-listing refferral-class">
                    <li>Name</li>
                    <li>Email</li>
                    <li>Reffered By</li>
                    <li>Reffered By Email</li>
                    {/*<li>Action</li>*/}
                </ul>
            </div>
            <div class="appboard-grid-project-txt p-0">
                <div class="appboard-grid-lsiting-boxing">
                    {data && data.length > 0 && data?.map((dt) => {
                            return (
                                <ul class="appboard-grid-project-description-listing refferral-class">
                                    <li class="name_listing">{dt.name || 'N/A'}</li>
                                    <li class="name_listing">{dt.email}</li>
                                    <li class="name_listing">{`${dt?.user?.first_name} ${dt?.user?.last_name}`}</li>
                                    <li class="name_listing">{dt?.user?.email}</li>
                                    {/*<li class="project_listing">
                                        popup
                                    </li>*/}
                                </ul>
                            )
                        })
                    }
                </div>
            </div>

        </div>
    )
}

const Referrals = () => {
    const {project, user} = useStateContext();
    const role = user?.user?.role;

    return (
        <div className="appboard-grid">
            <div className={`appboard-sub-grid pt-4`}>
                <div className="main-top-banner">
                    <div className="main-title">
                        <h1>Referrals<span> Program</span></h1>
                    </div>
                </div>
                        {role !== 'client' ? <UserPage user={user}/> : <ClientPage user={user}/>}

            </div>
        </div>
    )
};

export default Referrals;
