import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { Container, Row, Col, Table, Modal } from "react-bootstrap";
import MarketingCollateral from "./components/MarketingCollateral";
import MarketingCollateralDemo from "./components/MarketingCollateralDemo";
import ArticlesView from "./components/ArticlesView";
import ArticlesViewDemo from "./components/ArticlesViewDemo";
import BrandAssets from "./components/BrandAssets";
import PortfolioView from "./components/PortfolioView";
import PortfolioViewDemo from "./components/PortfolioViewDemo";
import axios from 'axios';
import { useStateContext } from '../../contexts/ContextProvider';
import debounce from 'lodash/debounce';
import { Loader, Toast } from '../../components/General';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Swal from 'sweetalert2';
import S3FileUpload from 'react-s3';
import { S3Upload } from '../../utils/S3Upload'
import { ThreeDots, Oval } from 'react-loader-spinner'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import {
    TextField,
    TextareaAutosize,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel
} from '@material-ui/core';

import dayjs from 'dayjs';
import {useLocation, useNavigate} from "react-router";
import BlogsView from "../../components/BlogsView";
import BlogsViewDemo from "../../components/BlogsViewDemo";

window.Buffer = window.Buffer || require("buffer").Buffer;

function DownloadableContentDemo() {
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="down" ref={ref} {...props} />;
    });

    const location = useLocation();
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [btnLoader, setBtnLoader] = useState(false);
    const validationSchema = yup.object({
        file_type: yup
            .string()
            .required('File Type is required'),
        // client: yup
        //   .string()
        //   .required('Client is required'),
        file_name: yup
            .string()
            .required('File Name is required'),
        link: yup
            .string(),
        description: yup
            .string(),
        industry_id: yup
            .string(),
        event_date: yup
            .string()
    });


    const {
        user,
        role,
        selectedProject
    } = useStateContext();

    const [activeTab, setActiveTab] = useState(1);
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');


    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const isWhatsNewEndpoint = location.pathname.endsWith('whats-new');
        if(isWhatsNewEndpoint){
            navigate('/downloadable-view')
        }

        setSearchTerm('');
    };

    const getAllFiles = async (keyword = "") => {

        setLoading(true);

        await axios.get(`${process.env.REACT_APP_BASE_URL}downloadable-content?file_type=${activeTab}&keyword=${keyword}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            setFiles(response.data.data);

        }).catch((err) => {
            console.log('err====', err);
        })
            .finally(() => {
                setLoading(false);
            })
    }

    const debouncedFetchData = debounce((searchTerm) => {
        getAllFiles(searchTerm);
    }, 700); // 500ms debounce time

    const handleOnChange = async (e) => {
        const searchTerm = e.target.value;

        setSearchTerm(searchTerm);
        debouncedFetchData(searchTerm);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        getAllFiles();
    }, [activeTab]);

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const handleUpload = async (file,resetForm) => {

        setBtnLoader(true);

        try {
            const response = await S3Upload(file.type, file.name, file)
            if (response) {
                console.log("RESPONSE URL IMAGE", response)
                UploadContent(response,resetForm);
            }
        } catch (error) {
            setBtnLoader(false)
            console.error("error", error)
        }
    }


    const UploadContent = async (file_uri,resetForm) => {
        setBtnLoader(true);
        //const selectedClient = users.find(item => `${item.first_name} ${item.last_name}` === formik.values.client);

        const data = {
            "file_type": formik.values.file_type,
            //"client_id": selectedClient.id,
            "business_dev": user.user.id,
            "file_name": formik.values.file_name,
            "file_path": file_uri,
            "link": formik.values.link ? formik.values.link : '',
            "description": formik.values.description ? formik.values.description : '',
            "industry_id": formik.values.industry_id ? formik.values.industry_id : '',
            "event_date": formik.values.event_date ? formik.values.event_date : '',
        }

        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}downloadable-content`, { ...data }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            .then((res) => {
                if (res.status === 200) {
                    getAllFiles();

                    Toast.fire({
                        icon: 'success',
                        title: 'File Uploaded successfully!',
                    })
                }
            })
            .catch((error) => {
                console.log(error);

                Toast.fire({
                    icon: 'success',
                    title: 'Something went wrong, Please try again later!',
                })
            })
            .finally(() => {
                setBtnLoader(false);
                handleClose()
                resetForm({ values: '' })
            })
        } catch (error) {
            setBtnLoader(false);

            console.log(error);
        }
    };

    const formik = useFormik({
        initialValues: {
            file_type: null,
            client: null,
            file_name: '',
            file_path: '',

        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {

            // console.log(values);

            handleUpload(selectedFile,resetForm)

        },
    });

    const downloadableContentType = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}downloadable-content-type`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setData(response.data.data)
                setActiveDownloadTab(data[0]?.id);
            }
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    const getIndustries = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}get_industry`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setIndustries(response.data.data)
            }
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    useEffect(() => {
        const isWhatsNewEndpoint = location.pathname.endsWith('whats-new');
        if(isWhatsNewEndpoint){
            setActiveTab(7)
        }

        downloadableContentType()
        getIndustries()
    }, [])



    // material UI Select
    const [selectedSorting, setSelectedSorting] = useState('sort');
    const handleChangeSorting = (e) => {
        sessionStorage.setItem('session_sorting', e.target.value);
        setSelectedSorting(e.target.value);
    };


    return (
        <>
            <div className="main_top_title">
                <div className="main_title"><h1>Downloadable <span>Content</span></h1></div>
                <div className="main_btn_txt">
                    {role !== "client" ? (
                        <div className="main-btn-txt">
                            <button className="btn-banner" onClick={handleClickOpen}>Upload New Upload</button>
                        </div>
                    ) : null}
                </div>

            </div>
            <div className="main_content_container downloadable-page">
                <Container fluid className="p-0">
                    <Row>
                        <Col xxl={12}>
                            <div className="main_style_box">
                                <ul className="downloadable_tabs downloadable-search-tabs">
                                    <li onClick={() => handleTabClick(1)} className={activeTab == 1 ? "active" : ''}>Marketing Collaterals</li>
                                    <li onClick={() => handleTabClick(2)} className={activeTab == 2 ? "active" : ''}>Articles</li>
                                    <li onClick={() => handleTabClick(3)} className={activeTab == 3 ? "active" : ''}>Blogs</li>
                                    <li onClick={() => handleTabClick(4)} className={activeTab == 4 ? "active" : ''}>Brand Assets</li>
                                    <li onClick={() => handleTabClick(5)} className={activeTab == 5 ? "active" : ''}>Documentation</li>
                                    <li onClick={() => handleTabClick(6)} className={activeTab == 6 ? "active" : ''} >Portfolio</li>
                                    <li onClick={() => handleTabClick(7)} className={activeTab == 7 ? "active" : ''}>What’s New</li>
                                    <li>
                                        <ul className='downloadable-search'>
                                            {/* <li className='sort'>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="20"
                                                    fill="none"
                                                    viewBox="0 0 16 20"
                                                    >
                                                        <path
                                                            fill="#2A303C"
                                                            d="M4.36 10.681V3.097L1.406 6.05.293 4.952 5.141.104l4.847 4.848L8.875 6.05 5.922 3.097v7.584H4.359zm6.7 9.215l-4.848-4.848 1.114-1.098 2.953 2.953V9.32h1.562v7.584l2.953-2.953 1.114 1.098-4.848 4.848z"
                                                        ></path>

                                                </svg>

                                                <Select
                                                    fullWidth
                                                    labelId="demo-simple-select-label"
                                                    name="article_sorting"
                                                    label="Project Projects"
                                                    id="article_sorting"
                                                    value={selectedSorting}
                                                    onChange={handleChangeSorting}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null
                                                    }}
                                                >
                                                    <MenuItem key="81" value="sort">
                                                        Sort
                                                    </MenuItem>
                                                    <MenuItem key="81" value="name">
                                                        Name
                                                    </MenuItem>
                                                    <MenuItem key="82" value="title">
                                                        Title
                                                    </MenuItem>
                                                    <MenuItem key="83" value="date">
                                                        Date Modified
                                                    </MenuItem>
                                                </Select>
                                            </li> */}
                                            <li className='search'>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="19"
                                                    height="19"
                                                    fill="none"
                                                    viewBox="0 0 19 19"
                                                    >
                                                    <path
                                                        fill="#505887"
                                                        stroke="#505887"
                                                        d="M17.901 17.197l-2.843-2.843a7.76 7.76 0 002.011-5.21c0-4.301-3.5-7.801-7.801-7.801-4.302 0-7.801 3.5-7.801 7.801 0 4.302 3.5 7.801 7.8 7.801a7.76 7.76 0 005.21-2.01l2.844 2.842a.41.41 0 00.58 0 .41.41 0 000-.58zm-8.633-1.073a6.988 6.988 0 01-6.98-6.98 6.988 6.988 0 016.98-6.98 6.988 6.988 0 016.98 6.98 6.988 6.988 0 01-6.98 6.98z"
                                                    ></path>
                                                </svg>
                                                <input type="search" value={searchTerm} placeholder='Search Files' onChange={handleOnChange} />
                                            </li>
                                        </ul>
                                    </li>

                                </ul>
                                
                                {loading ? <Loader /> : (
                                    <div className="downloadable_content">
                                        {activeTab === 1 &&
                                            <MarketingCollateralDemo data={files} title="Marketing Collaterals" />
                                        }
                                        {activeTab === 2 &&
                                            <BlogsViewDemo title="Articles" data={files} event={false}/>
                                        }
                                        {activeTab === 3 &&
                                            <BlogsViewDemo title="Blogs" data={files} event={false}/>
                                        }
                                        {activeTab === 4 &&
                                            <BrandAssets title="Brand Assets" data={files} />
                                        }
                                        {activeTab === 5 &&
                                            <BrandAssets title="Documentation" data={files} />
                                        }
                                        {activeTab === 6 &&
                                            <PortfolioViewDemo title="Portfolio" data={files} />
                                        }
                                        {activeTab === 7 &&
                                            <ArticlesViewDemo title="What’s New" data={files} event={true}/>
                                        }

                                    </div>
                                )}

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


            <Dialog
                open={open}
                // TransitionComponent={Transition}
                // keepMounted
                onClose={handleClose}
            >
                <DialogTitle>{"Upload New File"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit} className='space-y-6 '>
                        <div
                            className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">File Type</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    name="file_type"
                                    label="File Type"
                                    id="file_type"
                                    value={formik.values.file_type}
                                    onChange={formik.handleChange}
                                    error={formik.touched.file_type && Boolean(formik.errors.file_type)}
                                    helperText={formik.touched.file_type && formik.errors.file_type}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    {
                                        data && data?.map((dt, index) => {
                                            return (
                                                <MenuItem value={dt.id}>{dt.type}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                                {formik.touched.file_type && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.file_type}</div>}
                            </FormControl>
                        </div>

                        {formik.values.file_type === data[6]?.id && (
                            <div
                                className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                                <FormControl fullWidth>
                                    <InputLabel id="industry">Select Industry</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="industry"
                                        name="industry_id"
                                        label="Select Industry"
                                        id="industry_id"
                                        value={formik.values.industry_id}
                                        onChange={formik.handleChange}
                                        error={formik.touched.industry_id && Boolean(formik.errors.industry_id)}
                                        helperText={formik.touched.industry_id && formik.errors.industry_id}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem value="">Select Industry</MenuItem>
                                        {
                                            industries && industries?.map((dt, index) => {
                                                return (
                                                    <MenuItem value={index + 1}>{dt}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <TextField
                                className='w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                id="file_name"
                                name="file_name"
                                label="File Name"
                                value={formik.values.file_name}
                                onChange={formik.handleChange}
                                error={formik.touched.file_name && Boolean(formik.errors.file_name)}
                                helperText={formik.touched.file_name && formik.errors.file_name}
                            />
                            <div className="file-upload-container">
                                <input
                                    required
                                    type="file"
                                    onChange={handleFileInput} />
                            </div>
                        </div>

                        {formik.values.file_type === data[5]?.id && (
                            <div
                                className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <TextField
                                    className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                    id="link"
                                    name="link"
                                    label="URL / Case Study Link"
                                    value={formik.values.link}
                                    onChange={formik.handleChange}
                                    error={formik.touched.link && Boolean(formik.errors.link)}
                                    helperText={formik.touched.link && formik.errors.link}
                                />
                            </div>
                        )}

                        {[data[1]?.id, data[2]?.id].includes(formik.values.file_type) && (
                            <div
                                className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <TextareaAutosize
                                    style={{ borderBottom: '1px solid #00000070' }}
                                    className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%] MuiInputBase-input MuiInput-input'
                                    aria-label="minimum height"
                                    minRows={3}
                                    placeholder="Enter Description"
                                    id="description"
                                    name="description"
                                    label="Description"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    error={formik.touched.description && Boolean(formik.errors.description)}
                                    helperText={formik.touched.description && formik.errors.description}
                                />
                            </div>
                        )}

                        {formik.values.file_type === data[6]?.id && (
                            <FormControl className="custom-input" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                        <DatePicker
                                            fullWidth
                                            className='w-[100%]'
                                            name="event_date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="pay_date"
                                            inputFormat="E MMM dd yyyy HH:MM:SS O"
                                            label="Event Date"
                                            value={formik.values.event_date}
                                            onChange={value => formik.setFieldValue('event_date', value)}
                                            error={formik.touched.event_date && Boolean(formik.errors.event_date)}
                                            minDate={dayjs()}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                        )}
                        <div className='flex justify-end mt-3'>
                            <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white'
                                variant="contained" fullWidth type="submit" disabled={btnLoader}>
                                {btnLoader ? <Oval
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#ffffff"
                                    secondaryColor="#ffffff"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{disply: 'flex', justifyContent: 'center'}}
                                    wrapperClass=""
                                /> : 'Save'}
                            </button>
                        </div>
                    </form>
                </DialogContent>

            </Dialog>
        </>
    );
}

export default DownloadableContentDemo;