import React, {useState, useMemo, useEffect} from "react";
import Rewards from "../components/Rewards";
import UpComingTable from "../components/UpComingTable";
import googleIcon from "../data/google-meet-icon.png";
import {useStateContext} from "../contexts/ContextProvider";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {useFormik} from "formik";
import * as yup from "yup";
import {
    TextField,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
} from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Slide from "@mui/material/Slide";
import axios from "axios";
import Swal from "sweetalert2";
import {ThreeDots} from "react-loader-spinner";
import {Footer} from "../components";
import UpComing from "../components/UpComing";
import dayjs from 'dayjs';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    agenda: yup
        .string()
        .min(6, "Too Short!")
        .max(70, "Too Long!")
        .required("Meeting Agenda is required"),
    time: yup.date().required("Meeting Time is required"),
    title: yup.string().required("Meeting Time is required"),
    project_type: yup.string().required("Project Type is required"),
    desc: yup
        .string()
        .min(6, "Too Short!")
        .max(70, "Too Long!")
        .required("Meeting Link is required")
    //client: yup.string().required("Client Name is required"),
    //project: yup.string().required("Project Name is required"),
    // status: yup.string().required("Meeting Status is required"),
    // record: yup
    //   .string()
    //   .min(6, "Too Short!")
    //   .max(70, "Too Long!")
    //   .optional("Recording link is required"),
    // .required('Recording link is required'),
});

const MeetingBoard = () => {
    const {
        meeting,
        user,
        client,
        table,
        manualProjects,
        getAllMeetings,
        setClient,
        getAllManualProjects,
        sessionProject,
        project
    } = useStateContext();
    const {role} = user.user;
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [currentMeetings, setCurrentMeetings] = useState([]);
    const [selected, setSelected] = useState("btn2");
    const [projectState, setProjectState] = useState("manual");
    const [projectType, setProjectType] = useState([]);
    const [currentdate, setCurrentdate] = useState(new Date());
    const nextMonthDate = useMemo(
        () => getNextMonthDate(currentdate),
        [currentdate]
    );

    useEffect(() => {
        setLoading(true);
        getAllMeetings(sessionProject)
    }, [project])

    const getNextMonthMeeting = (currentDate) => {
        setCurrentMeetings(meeting.upcomming)
    };

    const getPreviousMonthMeeting = (currentDate) => {
        setCurrentMeetings(meeting.previous)
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formik = useFormik({
        initialValues: {
            agenda: "",
            time: null,
            project_type: "",
            status: "",
            desc: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values, {resetForm}) => {
            handleClose();
            createMeeting();
            resetForm({values: ""});
        },
    });

    const getProjectType = () => {
        if (user) {
            axios
                .get(`${process.env.REACT_APP_BASE_URL}project-type`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                })
                .then((res) => {
                    setProjectType(res.data.data);
                })
                .catch((err) => {
                    console.log("err====", err);
                });
        }
    };

    const createMeeting = async () => {
        setLoading(true);
        let date = new Date(formik.values?.date).toISOString().split("T")[0];
        const time = new Date(formik.values?.time).toISOString().split("T")[1];

        const createPostData = {
            user_id: user.user.id,
            manual_project_id: parseInt(sessionProject),//projectState === "manual" ? selectedProject.id : null,
            name: formik.values.title,
            description: formik?.values?.agenda,
            meeting_link: formik?.values?.desc,
            project_type: parseInt(sessionProject),
            priority: 0,
            date_time: date + " " + time,
            status: formik.values.status,
        };

        try {
            await axios
                .post(
                    `${process.env.REACT_APP_BASE_URL}meeting`,
                    {...createPostData},
                    {
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                        },
                    }
                )
                .then((res) => {
                    if (res.status === 200) {
                        getAllMeetings(sessionProject);
                        setLoading(false);
                        Swal.fire({
                            icon: "success",
                            title: "Meeting Created successfully!",
                            showConfirmButton: false,
                            timer: 1000,
                        });
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log("ERROR IN MEET", error);
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        showConfirmButton: false,
                        timer: 1000,
                    });
                });
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    useEffect(() => {
        setLoading(true);
        getProjectType();
        getAllManualProjects();
    }, []);

    useEffect(() => {
        if (selected !== "btn1") {
            getNextMonthMeeting(currentdate);
        } else {
            getPreviousMonthMeeting(currentdate);
        }
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }, [selected, meeting]);

    return (
        <div className={`appboard-grid`}>
            <div className="appboard-sub-grid pt-4">
                <div className="main-top-banner">
                    <div className="main-title">
                        <h1>Meeting  <span> Board</span></h1>
                    </div>
                    {role !== "client" ? (
                        <div className="main-btn-txt">
                            <button className="btn-banner"  onClick={handleClickOpen}>Schedule a Meeting</button>
                        </div>
                    ) : null}
                </div>
                <div className={`${role === "client" ? "meeting_box" : ""}`}>
                    <div className="meeeting_item">
                        <div className="appboard-overview-project-main">
                            <div className="appboard-grid-project-title">All Projects Meetings</div>
                            <div className="appboard-grid-project-txt">
                                <div className="tab_btns">
                                    <button
                                        onClick={() => setSelected("btn2")}
                                        className={`${
                                            selected === "btn2"
                                                ? "active"
                                                : ""
                                        }`}
                                    >
                                        Upcoming
                                    </button>
                                    <button
                                        onClick={() => setSelected("btn1")}
                                        className={`${
                                            selected === "btn1"
                                                ? "active"
                                                : ""
                                        }`}
                                    >
                                        Previous
                                    </button>
                                </div>
                                <UpComingTable meeting={currentMeetings} loader={loading}/>
                            </div>
                        </div>
                    </div>
                    <div className="meeeting_item">
                        {role === "client" ? (
                            <div className="meetingboard-grid-rewards pt-5">
                                <Rewards/>
                            </div>
                        ) : null}
                    </div>
                </div>


                {/* <div className="meetingboard-grid-project">
          {meeting.length !== 0
            ? meeting.map((item, index) => {
              return (
                <div key={index} className="flex flex-wrap ">
                  <div className="meetingboard-grid-project-div"></div>
                  <div>
                    <p className="meetingboard-grid-project-div-title">
                      {item.name}
                    </p>
                    <div className="flex flex-wrap">
                      <img
                        width={35}
                        height={35}
                        src={googleIcon}
                        alt="googleIcon"
                      />
                      <p className="meetingboard-grid-project-div-link ">
                        <a target="_blank" href="https://www.meet.google.com/ono-jqse-kug">meet.google.com/ono-jqse-kug</a>
                      </p>
                    </div>
                    <p className="meetingboard-grid-project-div-date">
                      {item.date}
                    </p>
                  </div>
                </div>
              );
            })
            : null}
        </div> */}


                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Schedule a Meeting"}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={formik.handleSubmit} className="space-y-6 ">
                            <TextField
                                className="w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%] "
                                id="title"
                                name="title"
                                label="Meeting Title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                helperText={formik.touched.title && formik.errors.title}
                            />
                            <TextField
                                className="w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%] "
                                id="agenda"
                                name="agenda"
                                label="Meeting Agenda"
                                value={formik.values.agenda}
                                onChange={formik.handleChange}
                                error={formik.touched.agenda && Boolean(formik.errors.agenda)}
                                helperText={formik.touched.agenda && formik.errors.agenda}
                            />
                            <FormControl className="custom-input" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DateTimePicker"]}>
                                        <DatePicker
                                            name="date"
                                            id="date"
                                            inputFormat="E MMM dd yyyy "
                                            label="Meeting Date"
                                            value={formik.values.date}
                                            onChange={(value) => formik.setFieldValue("date", value)}
                                            // error={formik.touched.date && Boolean(formik.errors.date)}
                                            // disablePast
                                        />
                                        {/* <TimePicker
                      name="time"
                      id="time"
                      inputFormat="E MMM dd yyyy "
                      label="Meeting Time"
                      value={formik.values.time}
                      onChange={(value) => formik.setFieldValue("time", value)}
                      error={formik.touched.time && Boolean(formik.errors.time)}
                      disablePast
                    /> */}
                                        <TimePicker
                                            fullWidth
                                            className="w-[100%]"
                                            name="time"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="time"
                                            inputFormat="E MMM dd yyyy HH:MM:SS O"
                                            label="Meeting Time"
                                            value={formik.values.time}
                                            onChange={(value) => formik.setFieldValue("time", value)}
                                            error={formik.touched.time && Boolean(formik.errors.time)}
                                            // disablePast
                                        />

                                        {/* <DatePicker
                        fullWidth
                        className='w-[100%]'
                        name="time"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        id="time"
                        inputFormat="E MMM dd yyyy HH:MM:SS O"
                        label="Meeting Time"
                        value={formik.values.time}
                        onChange={value => formik.setFieldValue('time', value)}
                        error={formik.touched.time && Boolean(formik.errors.time)}
                        disablePast
                      /> */}
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                            {/*<FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Client</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  name="client"
                  label="Client"
                  id="client"
                  value={formik.values.client}
                  onChange={formik.handleChange}
                  error={formik.touched.client && Boolean(formik.errors.client)}
                  helperText={formik.touched.client && formik.errors.client}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  // MenuProps={{
                  //   autoFocus: false,
                  //   disableAutoFocusItem: true,
                  //   disableEnforceFocus: true,
                  //   disableAutoFocus: true
                  // }}
                >
                  {client?.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={`${item.first_name} ${item.last_name}`}
                      >
                        {item.first_name} {item.last_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>*/}

                            {/*<div className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                <FormControl fullWidth>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Project Type
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => setProjectState(e.target.value)}
                  >
                    <FormControlLabel
                      value="manual"
                      control={<Radio />}
                      label="Manual"
                    />
                    <FormControlLabel
                      value="asana"
                      control={<Radio />}
                      label="Asana"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Project</InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    name="project"
                    label="Project"
                    id="project"
                    value={formik.values.project}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.project && Boolean(formik.errors.project)
                    }
                    helperText={formik.touched.project && formik.errors.project}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {projectState === "asana"
                      ? table?.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.name}>
                              {item.name}
                            </MenuItem>
                          );
                        })
                      : manualProjects?.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.name}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                  </Select>
                </FormControl>
              </div>*/}
                            <div className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Project Category
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="project_type"
                                        label="Project Category"
                                        id="project_type"
                                        value={formik.values.project_type}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.project_type &&
                                            Boolean(formik.errors.project_type)
                                        }
                                        helperText={
                                            formik.touched.project_type && formik.errors.project_type
                                        }
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                    >
                                        {projectType?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.name}>
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Meeting Status
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="status"
                                        label="Meeting Status"
                                        id="status"
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.status && Boolean(formik.errors.status)
                                        }
                                        helperText={formik.touched.status && formik.errors.status}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                    >
                                        <MenuItem value={0}>To Be Held</MenuItem>
                                        <MenuItem value={1}>Done</MenuItem>
                                    </Select>
                                </FormControl>

                            </div>
                            <div className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                                <TextField
                                    className="w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]"
                                    id="desc"
                                    name="desc"
                                    label="Meeting Link"
                                    value={formik.values.desc}
                                    onChange={formik.handleChange}
                                    error={formik.touched.desc && Boolean(formik.errors.desc)}
                                    helperText={formik.touched.desc && formik.errors.desc}
                                />
                            </div>
                            {/* <div className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                <TextField
                  className="w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]"
                  id="record"
                  name="record"
                  label="Recording Link"
                  value={formik.values.record}
                  onChange={formik.handleChange}
                  error={formik.touched.record && Boolean(formik.errors.record)}
                  helperText={formik.touched.record && formik.errors.record}
                />
              </div> */}
                            <div className="flex justify-end mt-3">
                                <button
                                    className="bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white"
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </DialogContent>
                </Dialog>


        </div>
        </div>

    );
};

const getNextMonthDate = (currentDate) => {
    // Get the month and year of the current date
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Calculate the month and year of the next month
    let nextMonth = currentMonth + 1;
    let nextYear = currentYear;

    if (nextMonth > 11) {
        // If the next month is greater than 11 (December), add 1 to the year and set the month to 0 (January)
        nextMonth = 0;
        nextYear += 1;
    }

    // Create a new Date object for the first day of the next month
    const nextMonthDate = new Date(nextYear, nextMonth, 1);

    return nextMonthDate;
};

export default MeetingBoard;
