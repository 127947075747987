import React from 'react';
import {AssigneeIcon} from "../../../utils/svg";

function CustomIconTitle({icon,text}) {
    return (
        <div className="null_data">
            <div className="empty_circle">{icon}</div>
            {text}
        </div>
    );
}

export default CustomIconTitle;