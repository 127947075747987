import React from 'react';
import InvoiceHistory from "../../components/Invoice/InvoiceHistory";

const InvoiceDetail=()=>{
    return(
        <div>
            <div className='w-[70%] tab:w-[95%] mobile:w-[95%] small:w-[95%] mx-auto ' >
            <div className="invoice-grid-sub-section">
            <div className="invoice-task">
              <p className="invoice-grid-sub-section-title">Invoices</p>
            </div>
            <div className="  invoice-grid-sub-section-history">
              <div className=" shadow invoice-grid-sub-section-box">
                <p className="invoice-grid-sub-section-box-title ">$5000</p>
                <p className="invoice-grid-sub-section-box-desc ">
                  Next Invoice
                </p>
                <p className="invoice-grid-sub-section-box-date ">
                  Due Date: 20/20/2023
                </p>
              </div>
              <div className="shadow1   invoice-grid-sub-section-box ">
                <p className="invoice-grid-sub-section-box-title">$7500</p>
                <p className="invoice-grid-sub-section-box-desc">
                  Previous Invoice
                </p>
                <p className="invoice-grid-sub-section-box-date">
                  Pay Date: 20/20/2023
                </p>
              </div>
            </div>
            <div className="mr-4 mt-[59px]">
              <InvoiceHistory />
            </div>
          </div>
          </div>
        </div>
    )
}

export default InvoiceDetail;