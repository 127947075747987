import React,{useState,useEffect} from 'react'
import Avatar5 from '../data/Avatar5.png'
import { useStateContext } from '../contexts/ContextProvider';
import { Link } from 'react-router-dom';
import axios from 'axios'

const Table=({id})=> {

  const{activeMenu,user} = useStateContext();

  const[project,setProject] = useState([]);
  const getClientProject=()=>{
    axios.get(`${process.env.REACT_APP_BASE_URL}manual-project?project_by_client=${id}`,{
      headers:{
        'Authorization':`Bearer ${user.token}`
      }
    }).then((response)=>{
      const formattedData = response.data.data.map(item => {
        const date = new Date(item.deadline);
        const formattedDate = date.toLocaleDateString();
        return {...item, date: formattedDate};
      });
        setProject(formattedData);
    }).catch((err)=>{
      console.log('err====',err);
    })
    // console.log(table);
  }
  useEffect(() => {
    getClientProject();
  }, []);

  return (
    <div className={ ` relative w-[97%] lg:w-[96%] md:w-[96%] mobile:w-[89%] small:w-[91%]  ${ activeMenu ? ' p-0   h-[284px] table-div mx-4 bg-[#FFFFFF] mt-8 lg:mt-6 md:mt-6 ml-5':' mx-4 bg-[#FFFFFF] mt-10  mt-8 lg:mt-6 md:mt-6  table-div '}`} >
    <div className='project2-task' >
            <p>All Projects</p>
      </div>
 
    <div className={` w-[100%] h-[229px]   overflow-x-auto overflow-y-hidden  bg-[#FAFAFA] table-div1 `}>
      <table className={ ` w-[1100px] lg:w-[840px] md:w-[840px] block  `}>
        <thead className="bg-gray-50s table w-[98%] table-fixed">
        <tr>
          <th className="  p-3 text-[16px] lg:text-[13px] md:text-[13px] text-[#505887]  text-left"><p className='border-r-1 border-gray-100' >Project Name</p></th>
          <th className="  p-3 text-[16px] lg:text-[13px] md:text-[13px] text-[#505887] text-left"><p className='border-r-1 border-gray-100 mr-[-3px] ' >Due Date</p></th>
          <th className="  p-3 text-[16px] lg:text-[13px] md:text-[13px] text-[#505887] text-left"><p className='border-r-1 border-gray-100 mr-[-3px] ' >Project Category</p></th>
          <th className="  p-3 text-[16px] lg:text-[13px] md:text-[13px] text-[#505887] text-left"><p className='border-r-1 border-gray-100 mr-[-3px] ' >Project Phase</p></th>
          <th className="  p-3 text-[16px] lg:text-[13px] md:text-[13px] text-[#505887] text-left"><p className='' >Status</p></th>
        </tr>
        </thead>
        <div className={`bg-white table-body w-[100%]  `} >
        <div className='h-[168px] relative milestone-bar overflow-y-auto' >
        <tbody className="divide-y absolute  w-[100%]  divide-gray-100">
          {project.length !==0 ? 
          project.map((item,index)=>{
            return(
              <tr key={index} className="bg-white w-[100%] hover:bg-[#FAFAFA]  table table-fixed  ">
                
          <td className="p-3 text-sm  text-gray-700">
            <p className=" text-[#505887]  text-[14px] lg:text-[12px] md:text-[12px] font-medium border-r-1 pl-[3px] border-gray-100 ">{item.name}</p>
          </td>
          <td className="p-3 font-medium  text-[14px] lg:text-[12px] md:text-[12px] text-[#718EBF]">
          <p className={` font-medium border-r-1  border-gray-100 ml-[-3px]  `} >{item.date}</p>
          </td>
          <td className="p-3">
          <p className=" text-[#505887]  text-[14px] lg:text-[12px] md:text-[12px] font-medium border-r-1 pl-[3px] border-gray-100 ">{item.category.name}</p>
          </td>
          <td className="p-3 text-[14px] lg:text-[12px] md:text-[12px] text-[#F78F21]"><p className={`font-medium border-r-1 pl-[2px] border-gray-100  `} >{item.phase.name}</p></td>
          <td className="p-3 pl-0 ">
            <span className='p-2 text-[12px] bg-[#F1FFEF] font-medium w-[43px] h-[24px] text-[#7ACB4A]' >Done</span></td>
        </tr>
            )
          })
          :
          <div className='flex justify-center items-center' >
            <p className='text-gray-400' >No Rows</p>
          </div>
          }
        </tbody>
        </div>
        </div>
      </table>
    </div>
  </div>
  )
}

export default Table;
