import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import BenefitRewardTable from '../components/Rewards/BenefitRewardTable';
import ActivityRewardTable from '../components/Rewards/ActivityRewardTable';

const ClientRewardClaim = () => {
  const location = useLocation();
  const [item] = useState(location?.state?.item || JSON.parse(localStorage.getItem('item')));

  useEffect(() => {
    if (location?.state) {
      localStorage.setItem('item', JSON.stringify(location?.state?.item));
    }
  }, [location]);

  return (
    <div className='space-y-[20px]' >
      <ActivityRewardTable item={item} />
      <BenefitRewardTable item={item} />
    </div>
  )
}

export default ClientRewardClaim;
