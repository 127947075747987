export const EventData = [
    {
        id: 1,
        title: "Mega Eat Event 20..",
        start: "2024-05-25T10:00:00",
        end: "2024-05-25T13:00:00",
        borderColor: "#F4C2C2",
        eventDetail: {
            title: "Mega Eat Event 20..",
            skills: [
                {
                    skillName: "Ankle Shot",
                },
                {
                    skillName: "Triangle Choke",
                },
            ],
            discription:
                "Aliquam tempus, velit at pretium semper, massa turpis egestas sapien, at porta sapien massa ut arcu. In et tempor arcu, vel aliquam enim. Nunc quam purus, lobortis sit amet ante vitae",
            location: "Arena Dubai Mall",
            rsvp: false,
            checkIn: false,
            allowSharing: true,
            noOfPeoplejoined: 53,
            recurrence: "Daily",
        },
    },
    {
        id: 2,
        title: "Mega Eat Event 20..",
        start: "2024-05-10T09:00:00",
        end: "2024-05-10T17:00:00",
        borderColor: "#A3C4BC",
        eventDetail: {
            title: "Mega Eat Event 20..",
            skills: [
                {
                    skillName: "Roundhouse Kick",
                },
                {
                    skillName: "Knifehand Strike",
                },
            ],
            discription:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum elementum nunc at nulla pellentesque, vel scelerisque orci rhoncus.",
            location: "Tokyo Martial Arts Center",
            rsvp: true,
            checkIn: true,
            allowSharing: true,
            noOfPeoplejoined: 78,
            recurrence: "Daily",
        },
    },
    {
        id: 3,
        title: "Mega Eat Event 20..",
        start: "2024-05-05T12:00:00",
        end: "2024-05-05T18:00:00",
        borderColor: "#E6B8CE",
        eventDetail: {
            title: "Mega Eat Event 20..",
            skills: [
                {
                    skillName: "Turning Kick",
                },
                {
                    skillName: "Back Kick",
                },
            ],
            discription:
                "Nulla facilisi. Curabitur eu felis nec justo convallis gravida ac ac urna. Fusce ac est turpis.",
            location: "New York Sports Arena",
            rsvp: true,
            checkIn: true,
            allowSharing: true,
            noOfPeoplejoined: 45,
            recurrence: "Weekly on Tuesday",
        },
    },
    {
        id: 4,
        title: "Mega Eat Event 20..",
        start: "2024-05-15T14:00:00",
        end: "2024-05-15T18:00:00",
        borderColor: "#D8F4AC",
        eventDetail: {
            title: "Mega Eat Event 20..",
            skills: [
                {
                    skillName: "Armbar",
                },
                {
                    skillName: "Kimura",
                },
            ],
            discription:
                "Quisque ac feugiat purus, eget sagittis elit. Sed ac semper risus. Vestibulum rhoncus neque a tortor volutpat, et mattis nulla rhoncus.",
            location: "Rio de Janeiro Sports Complex",
            rsvp: true,
            checkIn: true,
            allowSharing: true,
            noOfPeoplejoined: 62,
            recurrence: "Daily",
        },
    },
    {
        id: 5,
        title: "Mega Eat Event 20..",
        start: "2024-05-20T15:00:00",
        end: "2024-05-20T20:00:00",
        borderColor: "#C9D2F3",
        eventDetail: {
            title: "Mega Eat Event 20..",
            skills: [
                {
                    skillName: "Elbow Strike",
                },
                {
                    skillName: "Knee Strike",
                },
            ],
            discription:
                "Aliquam eget justo a nisl facilisis lacinia ac a mi. Duis laoreet tincidunt diam, et cursus mauris vestibulum vitae.",
            location: "Bangkok Arena",
            rsvp: true,
            checkIn: true,
            allowSharing: true,
            noOfPeoplejoined: 35,
            recurrence: "Daily",
        },
    },
    {
        id: 6,
        title: "Mega Eat Event 20..",
        start: "2024-05-08T10:30:00",
        end: "2024-05-08T16:30:00",
        borderColor: "#F2D6A1",
        eventDetail: {
            title: "Mega Eat Event 20..",
            skills: [
                {
                    skillName: "Men Strike",
                },
                {
                    skillName: "Do Strike",
                },
            ],
            discription:
                "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
            location: "Kyoto Martial Arts Hall",
            rsvp: true,
            checkIn: true,
            allowSharing: true,
            noOfPeoplejoined: 51,
            recurrence: "Daily",
        },
    },
    {
        id: 7,
        title: "Mega Eat Event 20..",
        start: "2024-05-28T18:00:00",
        end: "2024-05-28T23:00:00",
        borderColor: "#F1C8E1",
        eventDetail: {
            title: "Mega Eat Event 20..",
            skills: [
                {
                    skillName: "Suplex",
                },
                {
                    skillName: "Powerbomb",
                },
            ],
            discription:
                "Vestibulum hendrerit lacus id justo luctus tristique. Nullam at tincidunt magna, vel facilisis nisi.",
            location: "Madison Square Garden",
            rsvp: true,
            checkIn: true,
            allowSharing: true,
            noOfPeoplejoined: 99,
            recurrence: "Daily",
        },
    },
    {
        id: 8,
        title: "Mega Eat Event 20..",
        start: "2024-05-15T09:00:00",
        end: "2024-05-15T18:00:00",
        borderColor: "#F4C2C2",
        eventDetail: {
            title: "Mega Eat Event 20..",
            skills: [
                {
                    skillName: "Spinning Hook Kick",
                },
                {
                    skillName: "Crescent Kick",
                },
            ],
            discription:
                "Proin nec arcu eu purus dictum fermentum. Fusce eget malesuada justo.",
            location: "Seoul Olympic Stadium",
            rsvp: true,
            checkIn: true,
            allowSharing: true,
            noOfPeoplejoined: 83,
            recurrence: "Daily",
        },
    },
    {
        id: 9,
        title: "Mega Eat Event 20..",
        start: "2024-05-20T14:00:00",
        end: "2024-05-20T20:00:00",
        borderColor: "#A3C4BC",
        eventDetail: {
            title: "Mega Eat Event 20..",
            skills: [
                {
                    skillName: "Jab",
                },
                {
                    skillName: "Cross",
                },
            ],
            discription:
                "Cras bibendum, nunc eget egestas porttitor, nunc quam lacinia sem, quis euismod est velit nec mi.",
            location: "London Boxing Arena",
            rsvp: true,
            checkIn: true,
            allowSharing: true,
            noOfPeoplejoined: 71,
            recurrence: "Daily",
        },
    },
    {
        id: 10,
        title: "Mega Eat Event 20..",
        start: "2024-05-10T11:00:00",
        end: "2024-05-10T22:00:00",
        borderColor: "#E6B8CE",
        eventDetail: {
            title: "Mega Eat Event 20..",
            skills: [
                {
                    skillName: "Ground and Pound",
                },
                {
                    skillName: "Guillotine Choke",
                },
            ],
            discription:
                "Etiam non venenatis metus. Vivamus vel erat in felis ultrices vehicula.",
            location: "Las Vegas Arena",
            rsvp: true,
            checkIn: true,
            allowSharing: true,
            noOfPeoplejoined: 60,
            recurrence: "Daily",
        },
    },
    {
        id: 10,
        title: "Mega Eat Event 20..",
        start: "2024-05-25T15:00:00",
        end: "2024-05-25T18:00:00",
        borderColor: "#F4C2C2",
        eventDetail: {
            title: "Mega Eat Event 20..",
            skills: [
                {
                    skillName: "Ankle Shot",
                },
                {
                    skillName: "Triangle Choke",
                },
            ],
            discription:
                "Aliquam tempus, velit at pretium semper, massa turpis egestas sapien, at porta sapien massa ut arcu. In et tempor arcu, vel aliquam enim. Nunc quam purus, lobortis sit amet ante vitae",
            location: "Arena Dubai Mall",
            rsvp: false,
            checkIn: false,
            allowSharing: true,
            noOfPeoplejoined: 53,
            recurrence: "Daily",
        },
    },
    {
        id: 11,
        title: "Mega Eat Event 20..",
        start: "2024-05-25T05:15:00",
        end: "2024-05-25T07:30:00",
        borderColor: "#F4C2C2",
        eventDetail: {
            title: "Mega Eat Event 20..",
            skills: [
                {
                    skillName: "Ankle Shot",
                },
                {
                    skillName: "Triangle Choke",
                },
            ],
            discription:
                "Aliquam tempus, velit at pretium semper, massa turpis egestas sapien, at porta sapien massa ut arcu. In et tempor arcu, vel aliquam enim. Nunc quam purus, lobortis sit amet ante vitae",
            location: "Arena Dubai Mall",
            rsvp: false,
            checkIn: false,
            allowSharing: true,
            noOfPeoplejoined: 53,
            recurrence: "Daily",
        },
    },
];
