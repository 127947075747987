import React from 'react';
import { Link, NavLink } from "react-router-dom";
import { Container, Row, Col, Table } from "react-bootstrap";
import TeamMemberClientList from './components/TeamMember/TeamMemberClientList';
import TeamMemberManagerList from './components/TeamMember/TeamMemberManagerList';
import { useStateContext } from '../../contexts/ContextProvider';
import { PlusIcon } from '../../app-constants';

function TeamMember() {

    const {
        user,
        role,
        selectedProject
    } = useStateContext();

    return (
        <>
            <div className="main_top_title">
                <div className="main_title"><h1>Team <span>Member</span></h1></div>
                <div className="main_btn_txt">
                    <ul>
                        <li>
                            <NavLink to="">
                                Dashboard
                            </NavLink>
                        </li>
                        <li className="active">
                            <NavLink to="" >
                                Team Member
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
           

            <Container fluid className="p-0">
                <Row>
                    <Col xxl={12}>
                        <div className="main_style_box">
                            { role == "client" ? <TeamMemberClientList /> : <TeamMemberManagerList />}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default TeamMember;