import React, { useState, useEffect } from 'react';

import { Modal, Table, Form } from "react-bootstrap";
import IMG01 from "../../../../img/img1.jpeg";
import { DeletedIcon, DownloadIcon, ViewIcon, ClosedIcon, PlusIcon } from "../../../../app-constants";
import IMG02 from "../../../../img/img2.jpeg";
import IMG03 from "../../../../img/img3.jpeg";
import IMG04 from "../../../../img/img4.jpeg";
// import ModalView from "../ModalView";
import { useStateContext } from '../../../../contexts/ContextProvider';
import { Loader, NoRecordFound, Toast } from '../../../../components/General';
import axios from 'axios';
import { MdDelete } from 'react-icons/md';
import { RiEditBoxFill } from 'react-icons/ri';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select'
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';
import { Oval } from "react-loader-spinner";
import { Row, Col, Container } from "react-bootstrap";

function TeamMemberManagerList() {

    const {
        user,
        role,
        selectedProject,
        manualProjects
    } = useStateContext();

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [editMember, setEditMember] = useState(false);
    const [teamMember, setTeamMember] = useState(false);
    const [formSubmitLoading, setFormSubmitLoading] = useState(false);
    const [designation, setDesignation] = useState(false);
    const [phases, setPhases] = useState([]);
    const [selectedType, setSelectedType] = useState(1);

    const validationSchema = yup.object({
        name: yup
            .string()
            .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed')
            .required('Name is required')
            .max(20, "Name should be maximun of 20 characters")
            .min(4, "Name must be atleast of 4 characters"),

        project_ids: yup.array().min(1, 'At least one project is required').required('Project is required'),
        designation_id: yup.number().required('Designation is required'),
        phase_id: yup.number().required('Phase is required'),
    });

    const formik = useFormik({
        initialValues: {
            designation_id: "",
            project_ids: [],
            name: "",
            phase_id: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {

            if (editMember) {
                updateMember(values, resetForm);
            } else {

                addMember(values, resetForm);
            }


        },
    });

    const addMember = async (item, resetForm) => {


        let payload = {
            name: item.name,
            designation_id: item.designation_id,
            project_ids: item?.project_ids?.length > 0 ? item.project_ids.map(item => item.value).toString() : "",
            team_member_type_id: role == 'client' ? 2 : 1,
            phase_id: item?.phase_id
        }

        setFormSubmitLoading(true);

        await axios.post(`${process.env.REACT_APP_BASE_URL}store_memeber`, payload, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((response) => {

                if (response.status === 200) {

                    Toast.fire({
                        icon: 'success',
                        title: 'Memmber Added successfully!',
                    });

                    getAllTeamMembers();
                }

            }).catch((err) => {
                console.log('err====', err);
            })
            .finally(() => {
                setFormSubmitLoading(false);
                handleClose();
                resetForm({ values: "" });
            })
    };

    const handleClose = () => {
        formik.resetForm();
        setOpen(false);
        setEditMember(false);
        formik.resetForm();
        // setEditMember(false);
    };

    const handleShow = () => setShow(true);

    const getDesignations = async () => {

        await axios.get(`${process.env.REACT_APP_BASE_URL}get_designations/1`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            setDesignation(response.data.data);
        }).catch((err) => {
            console.log('err====', err);
        });
    }

    const getAllTeamMembers = async (loader = false) => {

        setLoading(loader);

        await axios.get(`${process.env.REACT_APP_BASE_URL}get_memebers/${selectedProject}/${selectedType}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            setTeamMember(response.data.data);
        }).catch((err) => {
            console.log('err====', err);
        })
            .finally(() => {
                setLoading(false);
            })
    }

    const getPhases = async () => {

        await axios.get(`${process.env.REACT_APP_BASE_URL}get_phases`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            setPhases(response.data.data);
        }).catch((err) => {
            console.log('err====', err);
        })
            .finally(() => {
                setLoading(false);
            })
    }


    useEffect(() => {
        getAllTeamMembers(true);
    }, [selectedProject]);

    useEffect(() => {
        getAllTeamMembers(true);
    }, [selectedType]);

    useEffect(() => {
        getDesignations();
        getPhases();
    }, []);

    const updateMember = async (updatedItem, resetForm) => {

        if (editMember?.id) {

            let data = {
                name: updatedItem.name,
                designation_id: updatedItem.designation_id,
                project_ids: updatedItem?.project_ids?.length > 0 ? updatedItem.project_ids.map(item => item.value).toString() : "",
                phase_id: updatedItem?.phase_id
            }

            setFormSubmitLoading(true);

            await axios.put(`${process.env.REACT_APP_BASE_URL}update_member/${editMember?.id}`, data, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((response) => {

                    if (response.status === 200) {

                        Toast.fire({
                            icon: 'success',
                            title: 'Team Member Updated successfully!',
                        })

                        getAllTeamMembers();
                    }

                }).catch((err) => {
                    console.log('err====', err);
                })
                .finally(() => {
                    setFormSubmitLoading(false);
                    handleClose();
                    resetForm({ values: "" });
                })
        }
    }

    useEffect(() => {

        const selectedOptions = editMember.projects;

        console.log(selectedOptions);

        formik.setValues({
            name: editMember.name,
            designation_id: editMember.designation_id,
            project_ids: selectedOptions,
            phase_id: editMember.phase_id
        });
    }, [editMember]);


    const handleEditClickOpen = (item) => {
        setEditMember(item);
        setOpen(true);
    };


    const options = manualProjects.length ? manualProjects.map(item => ({
        value: item.id,
        label: item.name
    })) : [];

    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure you want to Delete?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#F37A20",
            cancelButtonColor: "#0F1328",
            confirmButtonText: "Yes, Delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteMember(id);
            }
        });
    }


    const deleteMember = async (id) => {

        if (id) {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}delete_member/${id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((response) => {

                    if (response.status === 200) {

                        Toast.fire({
                            icon: 'success',
                            title: 'Member Deleted successfully!',
                        })

                        getAllTeamMembers();
                    }

                }).catch((err) => {
                    console.log('err====', err);
                })
                .finally(() => {
                    // setFormSubmitLoading(false);
                })
        }

    }

    const handleOpenAddMemberModal = () => {
        setEditMember(false);
        formik.resetForm();
        setOpen(true);
    };

    // if (loading) {
    //     return <Loader />
    // }

    const renderInvoices = () => {
        return (
            <div className=" invoice_tab download_tabs m-3">

                <Container fluid>
                    <Row>
                        <Col md={12}>
                            {selectedType == 1 ?
                                <div className="list_fillter add__button" >
                                    <div className="add_task" onClick={() => handleOpenAddMemberModal()}>
                                        {PlusIcon} Add Member
                                    </div>
                                </div>
                                : ''}
                        </Col>

                        {/* <Col md={3}>
                            <Form.Select
                                name="designation_id"
                                value={formik.values.designation_id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.designation_id && Boolean(formik.errors.designation_id)}
                            >
                                <option value="">Select Phase</option>

                                {designation && designation.map((item) => (
                                    <option value={item.id} key={item.id || item.title}>{item.title}</option>
                                ))}
                            </Form.Select>
                        </Col> */}
                    </Row>
                </Container>

                <Table responsive>
                    <thead>
                        <tr>
                            <th className="text-center">Name</th>
                            <th className="text-center">Designation</th>

                            {selectedType == 1 ? <th className="text-center">Phase</th> : ''}
                            {selectedType == 1 ? <th className="text-center">Action</th> : ''}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            teamMember.length > 0 ? (
                                teamMember.map((item, index) => (

                                    <tr>
                                        <td className="text-center">
                                            {item?.name}
                                        </td>
                                        <td className="text-center">{item?.designation}</td>
                                        {selectedType == 1 ? <td className="text-center">{item?.phase_name || '-'}</td> : ''}
                                        {selectedType == 1 ? <td>
                                            <ul>
                                                <li><RiEditBoxFill color="orange" size={20} onClick={() => handleEditClickOpen(item)} /></li>
                                                <li><MdDelete className='cursor-pointer' onClick={() => handleDelete(item.id)} color="red" size={20} /></li>
                                            </ul>
                                        </td> : ''}


                                    </tr>
                                ))
                            ) : <NoRecordFound />
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    return (
        <>
            <div className="main_content_container">
                <Container fluid className="p-0">
                    <div className="main_style_box">
                        {/* <div className="project_title">Invoice History</div> */}
                        <div className="download_tabs">

                            <ul className="downloadable_tabs">
                                <li
                                    onClick={() => setSelectedType(2)}
                                    className={selectedType === 2 ? 'selected whats-new-tab' : 'un-selected'}
                                >
                                    Client Team
                                </li>
                                <li
                                    onClick={() => setSelectedType(1)}
                                    className={selectedType === 1 ? 'selected whats-new-tab' : 'un-selected'}
                                >
                                    Tekrevol Team
                                </li>
                            </ul>


                            {
                                loading ?
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }} className='mt-5'>
                                        <Oval
                                            visible={true}
                                            height="20"
                                            width="20"
                                            color="#f37a20"
                                            ariaLabel="oval-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                        />
                                        <small>Please wait while the content is loading</small>
                                    </div> :
                                    <>
                                        {
                                            renderInvoices(selectedType)
                                        }
                                    </>
                            }
                        </div>
                    </div>
                </Container>
            </div>

            <Modal show={open} onHide={handleClose}>
                <div className="modal_poopup">
                    {/*<div className="colsed" onClick={handleClose}>*/}
                    {/*    {ClosedIcon}*/}
                    {/*</div>*/}
                    <h4>{editMember ? 'Edit' : 'Add'} Memeber</h4>

                    <form onSubmit={formik.handleSubmit}>

                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text"
                                name="name"
                                label="Name"
                                value={formik.values.name || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.name && Boolean(formik.errors.name)}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formik.touched.name && formik.errors.name}
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group className="mt-3" controlId="project_id">
                            <Form.Label>Project</Form.Label>


                            <Select
                                // defaultValue={[]}
                                value={formik.values.project_ids}
                                isMulti
                                name="project_ids"
                                options={options}
                                className={`basic-multi-select ${formik.errors.project_ids && 'error-select'}`}
                                classNamePrefix="select"
                                onChange={(e) => { formik.setFieldValue('project_ids', e); console.log(e) }}
                                onBlur={formik.handleBlur}
                            // isInvalid={formik.touched.project_ids}
                            />

                            <p style={{ color: '#dc3545' }}>
                                {formik.touched.project_ids && formik.errors.project_ids}
                            </p>


                            {/* <Form.Control.Feedback type="invalid">
                                {formik.errors.project_ids && formik.errors.project_ids}
                            </Form.Control.Feedback> */}
                        </Form.Group>

                        <Form.Group className="mt-3" controlId="designation_id">
                            <Form.Label>Designation</Form.Label>

                            <Form.Select
                                name="designation_id"
                                value={formik.values.designation_id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.designation_id && Boolean(formik.errors.designation_id)}
                            >
                                <option value="">Select Designation</option>

                                {designation && designation.map((item) => (
                                    <option value={item.id} key={item.id || item.title}>{item.title}</option>
                                ))}
                            </Form.Select>

                            <Form.Control.Feedback type="invalid">
                                {formik.touched.designation_id && formik.errors.designation_id}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-3" controlId="phase_id">
                            <Form.Label>Phase</Form.Label>

                            <Form.Select
                                name="phase_id"
                                value={formik.values.phase_id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.phase_id && Boolean(formik.errors.phase_id)}
                            >
                                <option value="">Select Phase</option>

                                {phases && phases.map((item) => (
                                    <option value={item.id} key={item.id}>{item.name}</option>
                                ))}
                            </Form.Select>

                            <Form.Control.Feedback type="invalid">
                                {formik.touched.phase_id && formik.errors.phase_id}
                            </Form.Control.Feedback>
                        </Form.Group>


                        <div className="btn_submit">
                            <button type="submit" className="custom_btn" disabled={formSubmitLoading} >

                                {formSubmitLoading ? <Oval
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#ffffff"
                                    secondaryColor="#ffffff"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{ disply: 'flex', justifyContent: 'center' }}
                                    wrapperClass=""
                                /> : 'Submit'}

                            </button>
                        </div>

                    </form>
                </div>
            </Modal>

            {/* <Modal show={show} onHide={handleClose}>
                <ModalView />
            </Modal> */}
        </>

    );
}

export default TeamMemberManagerList;