import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Form} from "react-bootstrap";
import React from "react";

function ModalView() {
    return (
        <div className="team_member main_popup">
            <div className="main_title">Team <span>Member</span></div>
            <ul className="member_list">
                <li>Name : <span>Hassan Rizwan Shaikh</span></li>
                <li>Role : <span>Vice President PMO</span></li>
                <li>Project : <span>Ezfi</span></li>
            </ul>
        </div>
    );
}

export default ModalView;