import React, {useEffect} from "react";
import Rewards from "../components/Rewards";
import OverviewTab from "../components/OverviewTab";
import {useStateContext} from "../contexts/ContextProvider";
import OverviewResource from "../components/OverviewResources";
import WhatsNew from "../components/WhatsNew";
import FilesTab from "../components/FilesTab";
import DownloadContentTabs from "../components/DownloadContentTabs";
import Avatar5 from '../data/avatar.jpg'
import {useMemo} from "react";
import {MdCreateNewFolder} from 'react-icons/md';
import {useState} from "react";
import {BsFillFilePdfFill, BsFileTextFill, BsFillPlayBtnFill, BsFileZipFill} from 'react-icons/bs'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {
    TextField,
    TextareaAutosize,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel
} from '@material-ui/core';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import axios from 'axios'
import Swal from 'sweetalert2';
import S3FileUpload from 'react-s3';
import {S3Upload} from '../utils/S3Upload'
import {ThreeDots, Oval} from 'react-loader-spinner'
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';

window.Buffer = window.Buffer || require("buffer").Buffer;

const S3_BUCKET = 'tek-crm';
const REGION = 'us-east-1';
const ACCESS_KEY = 'AKIAUPLSLQUZCEBB22HJ';
const SECRET_ACCESS_KEY = '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';

const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const validationSchema = yup.object({
    title: yup.string().required('title is required'),
    description: yup.string().required('description is required'),
    client: yup.array().required('client is required'),
    //discount: yup.string().required('discount is required'),
    start_date: yup.date().required('start_date is required'),
    end_date: yup.date().required('end_date is required'),
});

const FILE_FORMATS = ['jpg', 'jpeg', 'gif', 'png', 'mp4', 'pdf', 'docx']

const Promotions = () => {
    const {activeDownloadTab, setActiveDownloadTab, user, client, project, sessionProject} = useStateContext();
    const {role, id} = user.user;
    const [content, setContent] = useState([]);
    const [clients, setClients] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [users, setUsers] = useState([]);

    const [data, setData] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [searchValue, setSearchValue] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleOpenDialog = (item) => {
        setSelectedItem(item);
        getPromotionUsers(item);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedItem(null);
    };

    const downloadableContentType = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}downloadable-content-type`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setData(response.data.data)
                setActiveDownloadTab(data[0]?.id);
            }
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    const getIndustries = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}get_industry`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setIndustries(response.data.data)
            }
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    const filterFiles = (attachments) => {
        return attachments.filter(item => {
            const fileExtension = item.file_path?.substring(item.file_path.lastIndexOf('.') + 1)?.toLowerCase();
            // console.log(fileExtension);
            return FILE_FORMATS.includes(fileExtension) === true;
        });
    }

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files);
    }

    const handleUpload = async (files) => {
        setLoading(true);
        if (files) {
            const fileValues = Object.values(files);
            const file_detail = await Promise.all(fileValues.map(async (file) => {
                const response = await S3Upload(file.type, file.name, file);
                if (response) {
                    return {
                        "path": response,
                        "mime_type": file.type,
                    };
                }
                return null;
            }));

            const validFileDetail = file_detail.filter(detail => detail !== null);

            UploadContent(validFileDetail);
        }
    };

    const UploadContent = async (files) => {
        setLoading(true);

        const data = {
            "manager_id": user.user.id,
            "attachments": files,
            "title": formik.values.title ? formik.values.title : '',
            "start_date": formik.values.start_date ? new Date(formik.values.start_date)?.toISOString().split('T')[0] : '',
            "end_date": formik.values.end_date ? new Date(formik.values.end_date)?.toISOString().split('T')[0] : '',
            "description": formik.values.description ? formik.values.description : '',
            "client": formik.values.client ? formik.values.client : '',
        }

        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}promotion`, {...data}, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        getAllPromotions();
                        setLoading(false);
                        Swal.fire(
                            {
                                icon: 'success',
                                title: 'File Uploaded successfully!',
                                showConfirmButton: false,
                                timer: 1000
                            }
                        )
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1000
                    })
                })
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const getPromotionUsers = (item) => {
        let url = `${process.env.REACT_APP_BASE_URL}get-promotion-users/${item?.id}`;
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            setClients(response?.data?.data);
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    const getAllPromotions = () => {
        setLoading(true);
        let url = '';

        if (user.user.role !== 'client') {
            url = `${process.env.REACT_APP_BASE_URL}promotion?manager_id=${user.user.id}`
        } else {
            url = `${process.env.REACT_APP_BASE_URL}get-promotion?client_id=${user.user.id}`
        }

        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            setContent(response?.data?.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log('err====', err);
        })
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            client: '',
            //discount: '',
            start_date: '',
            end_date: '',
            file: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, {resetForm}) => {
            handleUpload(selectedFile)
            resetForm({values: ''})
            handleClose()
        },
    });

    useEffect(() => {
        downloadableContentType()
        getIndustries()
    }, [])

    useEffect(() => {
        if (activeDownloadTab) {
            getAllPromotions();
        }
    }, [project, activeDownloadTab])

    const getAllUsers = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}get_users_by_role/client`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            setUsers(response.data.data);
        }).catch((err) => {
            console.log('err====', err);
        })
    }

    useEffect(() => {
        getAllUsers();
    }, []);

    useEffect(() => {
        getAllPromotions();
    }, [searchValue])

    const updatePromotion = async (type, id) => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}update-user-promotion/${id}`,
                {
                    status: parseInt(type)
                },
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`
                    }
                }
            );
            if (response.status === 200) {
                handleCloseDialog()
                getAllPromotions()
                Swal.fire({
                    icon: 'success',
                    title: type == 1 ? 'Request sent successfully!' : 'Request accepted successfully',
                    showConfirmButton: false,
                    timer: 1000
                });
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                showConfirmButton: false,
                timer: 1000
            });
        }
    }

    return (
        <div className="appboard-grid">
            <div className="appboard-sub-grid pt-4">
                <div className="main-top-banner">
                    <div className="main-title">
                        <h1>Promotions &<span> Offers</span></h1>
                    </div>
                    {role !== "client" ? (
                        <div className="main-btn-txt">
                            <button className="btn-banner" onClick={handleClickOpen}>Create New Promotions</button>
                        </div>
                    ) : null}
                </div>


                <div className='appboard-overview-project-main'>
                    <div className="appboard-grid-project-title">All Promotions</div>

                    {
                        loading ?
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }} className='mt-5'>
                                <Oval
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#f37a20"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                                <small>Please wait while the content is loading</small>
                            </div> :
                            <div className="appboard-grid-project-txt">
                                <div className="downloadable_content">
                                    <div className="project_files">
                                        {
                                            content && content.length <= 0 &&
                                            <div>We'll be updating Promotions & Offers soon.</div>
                                        }
                                        {
                                            content && content.length > 0 ?
                                                content?.map((item, index) => {
                                                    return (
                                                        <div className="project_profile_list" key={index}>
                                                            <div className="img_box">
                                                                <img
                                                                    src={item?.attachments[0]?.path || ''}
                                                                    alt="-"/>
                                                            </div>
                                                            <div className="img_content">
                                                                <div className="name_pro">{item?.title || 'N/A'}</div>
                                                                <div
                                                                    className="date_pro">{item?.start_date || 'N/A'}</div>
                                                                <div onClick={() => handleOpenDialog(item)}
                                                                     className="btn-download">View Details
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : ''
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Add New Promotions and Offers"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit} className='space-y-6 '>
                        <div
                            className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                            <TextField
                                className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                id="title"
                                name="title"
                                label="Promotion Title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                helperText={formik.touched.title && formik.errors.title}
                            />
                            {/*<TextField
                                className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                id="discount"
                                name="discount"
                                label="Discount% or Amount"
                                value={formik.values.discount}
                                onChange={formik.handleChange}
                                error={formik.touched.discount && Boolean(formik.errors.discount)}
                                helperText={formik.touched.discount && formik.errors.discount}
                            />*/}
                        </div>

                        <div
                            className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">

                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Client</InputLabel>
                                <Select
                                    multiple
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    name="client"
                                    label="Client"
                                    id="client"
                                    value={formik.values.client || []} // Ensure it's always an array
                                    onChange={formik.handleChange}
                                    error={formik.touched.client && Boolean(formik.errors.client)}
                                    helperText={formik.touched.client && formik.errors.client}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    {users?.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.first_name} {item.last_name}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>
                        </div>

                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <TextareaAutosize
                                style={{borderBottom: '1px solid #00000070'}}
                                className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%] MuiInputBase-input MuiInput-input'
                                aria-label="minimum height"
                                minRows={3}
                                placeholder="Enter Description"
                                id="description"
                                name="description"
                                label="Description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                        </div>

                        <div
                            className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                            <FormControl className="custom-input" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                        <DatePicker
                                            fullWidth
                                            className='w-[100%]'
                                            name="start_date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="start_date"
                                            inputFormat="E MMM dd yyyy HH:MM:SS O"
                                            label="Start Date"
                                            value={formik.values.start_date}
                                            onChange={value => formik.setFieldValue('start_date', value)}
                                            error={formik.touched.start_date && Boolean(formik.errors.start_date)}
                                            helperText={formik.touched.start_date && formik.errors.start_date}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>

                            <FormControl className="custom-input" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                        <DatePicker
                                            fullWidth
                                            className='w-[100%]'
                                            name="end_date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="end_date"
                                            inputFormat="E MMM dd yyyy HH:MM:SS O"
                                            label="End Date"
                                            value={formik.values.end_date}
                                            onChange={value => formik.setFieldValue('end_date', value)}
                                            error={formik.touched.end_date && Boolean(formik.errors.end_date)}
                                            helperText={formik.touched.end_date && formik.errors.end_date}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                        </div>

                        <div
                            className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <div className="file-upload-container">
                                <input
                                    multiple
                                    type="file"
                                    name="file"
                                    error={formik.touched.file && Boolean(formik.errors.file)}
                                    helperText={formik.touched.file && formik.errors.file}
                                    onChange={handleFileInput}/>
                            </div>
                        </div>
                        <div className='flex justify-end mt-3'>
                            <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white'
                                    variant="contained" fullWidth type="submit">
                                Save
                            </button>
                        </div>
                    </form>
                </DialogContent>

            </Dialog>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                {selectedItem && (
                    <>
                        <div className="promotions_popup">

                             <div className="img_gallery">
                                {selectedItem?.attachments && selectedItem?.attachments?.map((attachment) => {
                                    return (
                                        <div className="img_box">
                                            <img src={attachment?.path || ''} alt="img_box"/>
                                        </div>
                                    )
                                })
                                }
                            </div>
                            <h4>{selectedItem.title}</h4>
                            <div className="files_heading">Description:</div>
                            <div className="para_sec mb-2">
                                {selectedItem.description}
                            </div>
                            {/*<div className="files_heading">Discount:</div>
                            <div className="para_discount mb-2">
                                {selectedItem.discount}
                            </div>*/}
                            <div className="files_heading">Start Date: <span
                                className="para_date">{selectedItem.start_date}</span></div>
                            <div className="files_heading">End Date: <span
                                className="para_date">{selectedItem.end_date}</span></div>
                            {role !== "client" && <div>
                                <div className="files_heading">Clients:</div>
                                <div className="client_list">

                                        {clients && clients?.users?.map((user) => {
                                              return (
                                                    <div className="clint_box">
                                                        <h5>{user?.first_name || ""} {user?.last_name || ""}</h5>
                                                        {user?.pivot?.status == 1 &&
                                                                <button
                                                                    onClick={() => updatePromotion(2, user?.pivot?.id)}
                                                                    className="bttn_popup">Approve Offer Request
                                                                </button>
                                                         }
                                                        {user?.pivot?.status == 2 &&
                                                            <div>
                                                                <span className="bttn_popup">Offer Approved!</span>
                                                            </div>
                                                        }
                                                    </div>
                                            )
                                        })}

                                </div>
                            </div>
                            }

                            {
                                role === "client" && selectedItem?.pivot?.status == 0 && <div>
                                    <button
                                        onClick={() => updatePromotion(1, selectedItem?.pivot?.id)}
                                        className="bttn_txt">Claim Offer Now!
                                    </button>
                                </div>
                            }

                            {
                                role === "client" && selectedItem?.pivot?.status == 1 && <div>
                                    <span className="bttn_txt">Waiting For Approval!</span>
                                </div>
                            }

                            {
                                role === "client" && selectedItem?.pivot?.status == 2 && <div>
                                    <span className="bttn_txt">Offer Approved!</span>
                                </div>
                            }

                            {/*{
                                    role !== "client" && <div>
                                        <button
                                            className="bttn_popup">Approve
                                        </button>
                                    </div>
                                }

                                {
                                    role !== "client" && <div>
                                        <button
                                            className="bttn_popup">Complete
                                        </button>
                                    </div>
                                }

                                {
                                    role === "client" && <div>
                                        <button
                                            className="bg-[#F37A20] text-white text-[14px] font-medium p-2 px-4 rounded-md">Avail Offer
                                        </button>
                                    </div>
                                }

                                {
                                    role === "client" && <div>
                                        <button
                                            className="bg-[#F37A20] text-white text-[14px] font-medium p-2 px-4 rounded-md">Waiting For Manager Response
                                        </button>
                                    </div>
                                }*/}

                        </div>
                    </>
                )}
            </Dialog>
        </div>
    );
};

export default Promotions;
