import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useStateContext } from "../../contexts/ContextProvider";
import { formatDrawerDate, getFirstLetters } from "../../utils/Helper";
import LogoImg from "../../img/DummyLogo.png";
import Form from "react-bootstrap/Form";

function StatusComment({ item }) {
    const [stories, setStories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [noComments, setNoComments] = useState(false);
    const [error, setError] = useState(null);
    const { user, selectedProject } = useStateContext();

    const [comment, setComment] = useState("");

    const PostTaskStory = (data) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}add-story`, data, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            let data = response.data.data;
            if (data) {
                setStories([...stories, response.data.data]);
            }
        }).catch((err) => {
            console.log('err====', err);
        })
    }


    const getTaskStories = async (item) => {

        setLoading(true);
        setNoComments(false);
        setError(null);

        console.log(item?.gid);

        await axios.get(`${process.env.REACT_APP_BASE_URL}get-post-task-stories/${item?.gid}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((response) => {
                let data = response.data.data;
                if (data && data.length > 0) {
                    setStories(response.data.data)
                }
                else {
                    setNoComments(true);
                }
            })
            .catch((err) => {
                console.log('err====', err);
                setError('Failed to fetch comments.');
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSubmit = (item) => {

        const data = {
            task_id: item?.gid,
            text: comment,
            client_id: user.user.id
        }

        PostTaskStory(data);
        setComment('');
    }

    return (
        <div className='main_style_box'>
            <div className="project_comments">
                <div className="comments_logo">
                    {console.log(user?.user?.image)}
                    {user?.user?.image ? <img src={user?.user?.image} alt="" /> : <div className="img_placeholder">
                        {getFirstLetters(user?.user?.first_name || 'User')}
                    </div>}
                </div>
                <div className="msg_box">
                    <Form.Control as="textarea" className="msg_box_comments" placeholder="Reply to message..."
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        rows={2}
                    />
                    <button className="msg_box_btn mt-2" onClick={() => handleSubmit(item)}>Send</button>
                </div>
            </div>

            <div className="show_comments">
                {loading ? (
                    <span>Loading...</span>
                ) : noComments ? (
                    <span>No comments to show</span>
                ) : stories.length > 0 ? (
                    // Render nothing or a different message if comments are already loaded
                    null
                ) : (
                    <a href="javascript:void(0);" onClick={() => getTaskStories(item)}>
                        Show Comments
                    </a>
                )}
                {/* <a href="javascript:void(0);" onClick={() => getTaskStories(item)}>
                    Show Comments
                </a> */}
                {/* {
                stories.length > 0 ? (
                    
                ) : (
                    <span>No comments to show</span>
                )
            } */}

                {
                    stories.length > 0 ? stories.map((item, index) => {

                        const nameArray = item.created_by?.name.split(' ');
                        const initials = nameArray.map(word => word.charAt(0).toUpperCase()).join('');
                        const date = new Date(item.created_at);
                        const formattedDate = date.toLocaleDateString();

                        let newText;
                        let projectName;
                        let assetId;
                        const regex = /asset_id=(\d+)/;
                        const regex2 = /https:\/\/app\.asana\.com\/0\/\d+\/list/;
                        const regex3 = /https:\/\/app\.asana\.com\/0\/\d+\/\d+/;

                        if (item.type == 'comment' && item.resource_subtype === 'comment_added') {
                            const url = item.text.match(regex2);
                            const url2 = item.text.match(regex3);

                            // task.projects !== undefined ? task.projects.map((item) => {
                            //     projectName = item.name;
                            // }) : null

                            newText = item.text.replace(url, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`)
                                .replace(/\n/g, '<br/>').replace(url2, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`);
                        }
                        if (item.type == 'system' && item.resource_subtype === 'added_to_project') {
                            const url = item.text.match(regex3);

                            task.projects !== undefined ? task.projects.map((item) => {
                                projectName = item.name;
                            }) : null

                            newText = item.text.replace(url, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`)
                                .replace(/\n/g, '<br/>');
                        }
                        if (item.type == 'system' && item.resource_subtype === 'assigned' || item.resource_subtype === 'due_date_changed' || item.resource_subtype === 'section_changed') {
                            const url = item.text.match(regex3);

                            task.projects !== undefined ? task.projects.map((item) => {
                                projectName = item.name;
                            }) : null

                            newText = item.text.replace(url, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`).replace(/\n/g, '<br/>');
                        }
                        if (item.type == 'system' && item.resource_subtype === 'attachment_added') {
                            const match = item.text.match(regex);
                            assetId = match ? match[1] : null;


                        }
                        if (item.type == 'system' && item.resource_subtype === 'name_changed' || item.resource_subtype === 'notes_changed') {
                            const url = item.text.match(regex3);

                            task.projects !== undefined ? task.projects.map((item) => {
                                projectName = item.name;
                            }) : null

                            newText = item.text.replace(url, `<a class="text-hyperlink" href="${url}" target="_blank">${projectName}</a>`)
                                .replace(/\n/g, '<br/>');

                        }

                        return (

                            <div className="comment_list">
                                <div key={index} className="comments_img">
                                    {
                                        item.type === 'system' && (item.resource_subtype === 'name_changed' || item.resource_subtype === 'notes_changed' || item.resource_subtype === 'comment_liked') ?
                                            null :
                                            item.type === 'system' && item.resource_subtype === 'marked_complete' ?
                                                <span><TiTick color='black' size={30} /></span> :
                                                !item.created_by.photo ?
                                                    <span>{initials}</span> :
                                                    <img
                                                        className='comment_img'
                                                        src={item.created_by.photo.image_27x27}
                                                        alt='User Photo'
                                                    />
                                    }

                                </div>
                                <div className="comments_txt">
                                    <div className="title">{item.created_by?.name} <span className="date">{formatDrawerDate(formattedDate)}</span></div>
                                    {item.type == 'system' && item.resource_subtype === 'name_changed' || item.resource_subtype === 'notes_changed' ? null :
                                        item.type == 'system' && item.resource_subtype === 'comment_liked' ? null
                                            :
                                            <p className='date-img'></p>}
                                    {item.type == 'system' && item.resource_subtype === 'attachment_added' ?
                                        attachments.map((item) => {
                                            if (item.gid === assetId) {
                                                const fileExtension = item.name?.substring(item.name.lastIndexOf('.') + 1)?.toLowerCase();
                                                let icon;
                                                if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'gif') {
                                                    icon = <img src={item.view_url}
                                                        className="rounded-md flex flex-shrink-0"
                                                        alt={`${item.view_url}`} />;
                                                } else if (fileExtension === 'pdf') {
                                                    icon =
                                                        <div
                                                            className=' px-4 h-[80px] justify-center items-center flex gap-2 rounded-md border-1 border-gray-200 '>
                                                            <BsFillFilePdfFill size={35}
                                                                className='mr-2'
                                                                color='red' />
                                                            <p className='text-[11px] break-all text-[#6d6e6f] '>{item.name}</p>
                                                        </div>;
                                                } else if (fileExtension === 'mp4') {
                                                    icon =
                                                        <div
                                                            className=' px-4 h-[80px] justify-center items-center flex gap-2 rounded-md border-1 border-gray-200 '>
                                                            <BsFillPlayBtnFill size={35}
                                                                className='mr-2'
                                                                color='gray' />
                                                            <p className='text-[11px] break-all text-[#6d6e6f] '>{item.name}</p>
                                                        </div>;
                                                    ;
                                                } else if (fileExtension === 'zip') {
                                                    icon = <div
                                                        className=' px-4 h-[80px] justify-center items-center flex gap-2 rounded-md border-1 border-gray-200 '>
                                                        <BsFileZipFill size={35}
                                                            className='mr-2'
                                                            color='gray' />
                                                        <p className='text-[11px] break-all text-[#6d6e6f] '>{item.name}</p>
                                                    </div>;
                                                } else {
                                                    icon = <div
                                                        className=' px-4 h-[80px] justify-center items-center flex gap-2 rounded-md border-1 border-gray-200 '>
                                                        <BsFileTextFill size={35}
                                                            className='mr-2'
                                                            color='blue' />
                                                        <p className='text-[11px] break-all text-[#6d6e6f] '>{item.name}</p>
                                                    </div>;
                                                }
                                                return (
                                                    <Tooltip title={
                                                        <React.Fragment>
                                                            <p>{item.name}</p>

                                                            Uploaded at {formattedDate}
                                                        </React.Fragment>
                                                    } followCursor>
                                                        <div key={index}
                                                            className=' cursor-pointer'>

                                                            {icon}
                                                        </div>
                                                    </Tooltip>
                                                )
                                            }
                                        })
                                        :
                                        item.type == 'system' && item.resource_subtype === 'name_changed' || item.resource_subtype === 'notes_changed' ?
                                            <p className='para_txt'
                                                dangerouslySetInnerHTML={{ __html: newText }}></p>
                                            :
                                            <p className='para_txt'
                                                dangerouslySetInnerHTML={{ __html: newText }}></p>

                                    }

                                </div>
                            </div>
                        )

                    }) : null
                }
            </div>

        </div>
    )
}

export default StatusComment
