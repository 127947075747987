import React, { useEffect, useState } from 'react';
import Rewards from '../components/Rewards';
import backCover from '../data/backCover.png'
import email from '../data/email.png'
import dp from '../data/dp.png'
import editSVG from '../data/editiconsettings.svg'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useStateContext } from '../contexts/ContextProvider';
import axios from 'axios'
import Swal from 'sweetalert2';
import { Oval } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import S3FileUpload from 'react-aws-s3'
import { config } from '../utils/s3config'
import { S3Upload } from '../utils/S3Upload'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const validationSchema = yup.object({
  firstname: yup
    .string()
    .required('First Name is required')
    .max(20, "First name should be maximun of 20 characters")
    .min(4, "First name must be atleast of 4 characters"),
  lastname: yup
    .string()
    .required('Last Name is required')
    .max(20, "Last Name should be maximun of 20 characters")
    .min(4, "Last Name must be atleast of 4 characters"),
  email: yup.string().email("Invalid Email Format").required("Email is Required"),
});

const validationSchemaUpdate = yup.object({
  old_password: yup.string()
    .required("Old Password is Required"),
  password: yup.string()
    .required("Password is Required")
    .max(20, "Password should be maximun of 20 characters")
    .min(6, "Password must be atleast of 6 characters"),
  confirm_password: yup.string()
    .required("Confirm Password is Required")
    .oneOf([yup.ref("password")], "Password do not matched"),
});

const Setting = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user, userprofile, getUserProfile } = useStateContext();
  const [open, setOpen] = React.useState(false);
  const { role } = user.user;
  const [refresh, setRefresh] = useState(false)
  const [initialpwd, setInitialpwd] = useState({
    old_password: '',
    password: '',
    confirm_password: '',
  })

  useEffect(() => {
    setLoading(true);
    getUserProfile()
    setTimeout(() => {
      setLoading(false);
    }, 2000)
  }, [refresh])

  const formik = useFormik({
    initialValues: {
      firstname: userprofile?.first_name,
      lastname: userprofile?.last_name,
      email: userprofile?.email,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      editUser()
      resetForm({ values })
    },
  });

  const formikUpdate = useFormik({
    initialValues: initialpwd,
    enableReinitialize: true,
    validationSchema: validationSchemaUpdate,
    onSubmit: (values, { resetForm }) => {
      editPassword()
      resetForm({ values: '' })

    },
  });

  const [selectedImage, setSelectedImage] = useState(userprofile?.image);
  const [isHovering, setIsHovering] = useState(false);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    // if (file) {
    //   setSelectedImage(file);
    // }

    try {
      const response = await S3Upload(file.type, file.name, file)
      console.log("RESPONSE UPLOAD", response)
      setSelectedImage(response)
    } catch (error) {
      console.error("error", error)
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setInitialpwd({
      old_password: '',
      password: '',
      confirm_password: '',
    })
    formikUpdate.resetForm()
    console.warn("HEE")
    setOpen(false);
  };

  const logout = () => {
    try {
      axios.get(`${process.env.REACT_APP_BASE_URL}user_logout`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
        .then((res) => {
          if (res.status === 200) {
            localStorage.removeItem('tekrevol_user');
            setTimeout(() => {
              navigate('/');
            }, 500)
          }
        })
        .catch((error) => {
          console.log(error.response.data);
        })
    } catch (error) {
      console.log(error.response.data);
    }
  }

  const editUser = async () => {

    setLoading(true);
    const data = {
      ...user.user,
      "first_name": formik.values.firstname,
      "last_name": formik.values.lastname,
      //"email": formik.values.email,
      "image": selectedImage ? selectedImage : null
    }
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}update_user/${user.user.id}`, { ...data, id: user.user.id }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            setLoading(false);
            Swal.fire(
              {
                icon: 'success',
                title: 'User updated successfully!',
                showConfirmButton: false,
                timer: 1000
              }
            )
            // logout()
            setRefresh(prev => !prev)
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1000
          })
        })
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const editPassword = async () => {

    setLoading(true);
    const data = {
      "old_password": formikUpdate.values.old_password,
      "password": formikUpdate.values.password,
      "password_confirmation": formikUpdate.values.confirm_password,
      "type": 0,
    }
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}change_password`, { ...data }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            setLoading(false);
            handleClose();

            Swal.fire(
              {
                icon: 'success',
                title: 'Password updated successfully!',
                showConfirmButton: false,
                timer: 1000,

              }
            )
            logout()
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          handleClose();
          Swal.fire({
            icon: 'error',
            text: error.response.data.message.password,
            showConfirmButton: false,
          })
        })
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  if (!userprofile) {
    return null
  }

  return (
    <div className='settings' >

      <div className={`  `} >
        {
                    loading ?
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} className='mt-5'>
                            <Oval
                                visible={true}
                                height="20"
                                width="20"
                                color="#f37a20"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                            <small>Please wait while the content is loading</small>
                        </div> :
          <div className=' px-[30px] pt-4' >
            <h1 className=' meeting-text  pb-[41px] md:pb-[30px]'>Settings </h1>
            <div className='bg-white rounded-t-[15px] p-[30px]' >
              <div className='holder_dp' >
                <div className='icon__edit'>
                  <img src={editSVG} alt='icon' />
                </div>
                <div onMouseEnter={() => setIsHovering(true)}
                  onMouseLeave={() => setIsHovering(false)} className='settings-dp' >
                  <img className='profile-img ' src={selectedImage ? selectedImage : dp} alt='dp' />
                  <input type="file" className='settings-picker' onChange={handleImageChange} />
                </div>
              </div>
              <div>
                <div className=' mt-[26px] flex justify-start' >

                  <form onSubmit={formik.handleSubmit} className='w-[40%]'>
                    <div className='settings-form' >
                      <div className='w-[45%]'>
                        <label className='text-[#2A303C] text-[14px] md:text-[12px] font-medium' >First name</label><br />
                        <input
                          id='firstname'
                          name='firstname'
                          value={formik.values.firstname}
                          onChange={formik.handleChange}
                          required
                          className='settings-input'
                        />
                        <br />{formik.errors.firstname && <span className="errorform">{formik.errors.firstname}</span>}
                      </div>

                      <div className='w-[45%]'>
                        <label className='text-[#2A303C] text-[14px] md:text-[12px] font-medium' >Last name</label><br />
                        <input
                          id='lastname'
                          name='lastname'
                          value={formik.values.lastname}
                          onChange={formik.handleChange}
                          required
                          className='settings-input'
                        />
                        <br />{formik.errors.lastname && <span className="errorform">{formik.errors.lastname}</span>}
                      </div>
                    </div>
                    <div className='settings-email-div' >
                      <label className='text-[#2A303C] text-[14px] md:text-[12px] font-medium' >Email</label><br />
                      <div className='relative' >
                        <div className="settings-email-div-img">
                          <img src={email} className="pl-2" alt="searchIcon" />
                        </div>
                        <input
                          disabled
                          id='email'
                          name='email'
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          className='settings-input-email'
                          placeholder={user.user.email} />
                      </div>
                      {formik.errors.email && <span className="errorform">{formik.errors.email}</span>}

                    </div>

                    {/* <div className='flex gap-[10px] py-[20px]' >
                    <div className="signin-check">
                      <input type='checkbox' />
                    </div>
                    <p className='text-[#2A303C] text-[14px] font-medium ' >Get Email Notifications</p>
                  </div>
                  <div className='flex gap-[10px]' >
                    <div className="signin-check">
                      <input type='checkbox' />
                    </div>
                    <p className='text-[#2A303C] text-[14px] font-medium ' >Invoice Notification</p>
                  </div> */}
                    <div className='settings-form-submit' >
                      <button type='button' onClick={() => {
                        console.log('back')
                        navigate(-1)
                      }} className='settings-form-submit-cancel' >Cancel</button>
                      <button type='submit' className='settings-form-submit-save'>Save</button>
                      <button type='button' onClick={handleClickOpen} className='settings-form-submit-update' >Update Password</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      {/* {role === 'client' ?
        <div className='settings-rewards pt-5 lg:pr-[25px] lg:pl-[25px] md: pr-[10px] md: pl-[10px]' >
          <Rewards />
        </div>
        : null} */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}

        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update Password"}</DialogTitle>
        <DialogContent>
          <form onSubmit={formikUpdate.handleSubmit} className='space-y-6 ' >
            <div className='settings-password-div' >
              <div className='mt-[19px]' >
                <label className='text-[#2A303C] text-[14px] md:text-[12px] font-medium' >Old Password</label><br />
                <input
                  id='old_password'
                  name='old_password'
                  value={formikUpdate.values.old_password}
                  onChange={formikUpdate.handleChange}
                  type='password'
                  className='settings-input '
                  placeholder='***********' />
                <br />{formikUpdate.errors.old_password && <span className="errorform">{formikUpdate.errors.old_password}</span>}
              </div>
              <div className='mt-[19px]' >
                <label className='text-[#2A303C] text-[14px] md:text-[12px] font-medium' >Password</label><br />
                <input
                  id='password'
                  name='password'
                  value={formikUpdate.values.password}
                  onChange={formikUpdate.handleChange}
                  type='password'
                  className='settings-input '
                  placeholder='***********' />
                <br />{formikUpdate.errors.password && <span className="errorform">{formikUpdate.errors.password}</span>}
              </div>
              <div className='mt-[19px]' >
                <label className='text-[#2A303C] text-[14px] md:text-[12px] font-medium' >Re-Password</label><br />
                <input
                  id='confirm_password'
                  name='confirm_password'

                  value={formikUpdate.values.confirm_password}
                  onChange={formikUpdate.handleChange}
                  type='password'
                  className='settings-input  ' placeholder='***********' />
                <br />{formikUpdate.errors.confirm_password && <span className="errorform">{formikUpdate.errors.confirm_password}</span>}
              </div>

            </div>
            <div className='settings-form-submit' >
              <button type='button' onClick={handleClose} className='settings-form-submit-cancel' >Cancel</button>
              <button type='submit' className='settings-form-submit-save'>Save</button>
            </div>
          </form>
        </DialogContent>

      </Dialog>
    </div>
  )
}

export default Setting;